import { Divider, alpha,  Grid2 as Grid, Hidden, Icon, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import RepairRequests from '../../components/RepairRequests';
import BasicDetails from '../../components/BasicDetails'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { surveyActions } from '../../../../_actions';
import { CircularProgress } from '@mui/material';
import MeterReading from '../../components/MeterReading';
import { Close, WbIncandescentOutlined } from '@mui/icons-material';
import BottomDrawer from '../../components/BottomDrawer';
import clsx from "clsx";
import { groupBy, statusColors, useInternetStatusDetector } from '../../../../_helpers';

export default function InspectionsDetail({ pdfPreview, showActionColumn, isSecondSignature, canGoBack, openRepairRequestMap, setOpenRepairRequestMap, showPdfIcon, showMeterDetails = false, setIsOpenSignature = () => { }, setId, ...props }) {
  const classes = useStyles()
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const isOnline = useInternetStatusDetector();

  //Redux State
  const { selected, all } = useSelector(state => state.buildings);
  const app = useSelector(state => state.app);
  const { selectedInspection, inspectionLoading, repairRequests, loadingRepairRequests } = useSelector(state => state.surveying);

  const [requests, setRequests] = useState(repairRequests);
  useEffect(() => {
    setRequests(repairRequests.map(p => {
      const building = all.find(b => b.buildingId === p.buildingId);
      if (building) p.buildingNoExtern = building.buildingNoExtern;
      return p;
    }));
  }, [repairRequests]);
  const [bottomDrawer, setBottomDrawer] = useState({ open: false, key: '' });
  const requestsStatus = repairRequests && repairRequests.length && groupBy(repairRequests, "status");
  useEffect(() => {
    if (selected && !openRepairRequestMap) {
      dispatch(surveyActions.getSurveyDetailAction(params.inspectionId, false));
      dispatch(surveyActions.getRepairRequests(params.inspectionId));
      setId(params.inspectionId)
    }
    return () => {
      setId(null)
    }
  }, [selected, canGoBack]);

  const bottomDrawerOpenHandler = (key) => {
    setBottomDrawer({ open: true, key })
  }

  return (
    (<div className={clsx(classes.layoutContainer, (inspectionLoading) && classes.layoutContainerHeight)}>
      {inspectionLoading ?
        <div className={classes.layoutLoading}>
          <CircularProgress size={20} />
        </div>
        : <>
          <BasicDetails
            isInspectionInProgress={selectedInspection && selectedInspection.status === 1}
            isSecondSignatureSurvey={isSecondSignature}
            // showPdfIcon={showPdfIcon}
            setIsOpenSignature={setIsOpenSignature}

            showPdfIcon
            
            hideSignatureFromPDF
            showMeldingImage={isOnline}
            onlyOfflinePDFView
            hidePDFShare
            data={selectedInspection} />
          <Hidden smUp>
            <div className={classes.layoutSubContainer}>
              <Paper className={classes.cardPaper} elevation={0} onClick={() => bottomDrawerOpenHandler('gebrekenlijst')}>
                <div className={classes.cardLayout}>
                  <Typography className={classes.cardTitle}>{t("survey.defectsList")}</Typography>
                  {/* <Typography className={classes.cardSubTitle}>{repairRequests && repairRequests.length}</Typography> */}
                  <div className={classes.statusContainer}>
                    <div className={classes.status}>
                      <Typography className={classes.cardStatusTitle}>{t("general.total")}</Typography>
                      <div className={classes.cardStatusSquare}>
                        <Typography className={classes.cardStatusNumber}>{repairRequests && repairRequests.length}</Typography>
                      </div>
                    </div>
                    {Object.keys(requestsStatus).map(key => (
                      <div key={key} className={classes.status}>
                        <Typography className={classes.cardStatusTitle}>{t(key)}</Typography>
                        <div className={classes.cardStatusSquare} style={{ backgroundColor: statusColors[key] }}>
                          <Typography className={classes.cardStatusNumber}>{requestsStatus[key].length}</Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className={classes.square}>
                  <Icon fontSize="default" className={classes.bugIcon}>construction</Icon>
                </div>
              </Paper>
            </div>
          </Hidden>
          {showMeterDetails &&
            <>
              <Hidden smUp>
                <BottomDrawer open={bottomDrawer.open && bottomDrawer.key !== 'gebrekenlijst'} close={() => setBottomDrawer({ open: false })}>
                  <div>
                    <Grid className={classes.stickyHeader} size={{xs:12}}>
                      <div className={classes.drawerHeader}>
                        <Typography className={classes.surveyMomentCardTitle}>{t(`${'survey.meterReadings'}`)}</Typography>
                        <Close className={classes.drawerIcon} onClick={() => setBottomDrawer({ open: false })} />
                      </div>
                      <Divider />
                      <Grid size={{xs: 12}}>
                        <MeterReading isSecondSignature={isSecondSignature} data={selectedInspection} />
                      </Grid>
                    </Grid>
                  </div>
                </BottomDrawer>
              </Hidden>
              <Hidden smDown>
                <div className={classes.layoutSubContainer}>
                  <MeterReading isSecondSignature={isSecondSignature} meterReading data={selectedInspection} />
                </div>
              </Hidden>
              <Hidden smUp>
                <div className={classes.layoutSubContainer}>
                  <Paper className={classes.cardPaper} elevation={0} onClick={() => bottomDrawerOpenHandler('meterkast')}>
                    <div className={classes.cardLayout}>
                      <Typography className={classes.cardTitle}>{t("survey.meterReadings")}</Typography>
                      <Typography className={classes.cardSubTitle}>{t("6")}</Typography>
                    </div>
                    <div className={classes.square}>
                      <WbIncandescentOutlined className={classes.bugIcon} />
                    </div>
                  </Paper>
                </div>
              </Hidden>
            </>
          }

          {selectedInspection && <div className={classes.layoutSubContainer}>
            <RepairRequests
              showActionColumn={showActionColumn}
              isSecondSignature={isSecondSignature}
              selectedInspection={selectedInspection}
              setOpenRepairRequestMap={setOpenRepairRequestMap}
              keyPath={'inspectionId'}
              loading={loadingRepairRequests}
              bottomDrawer={bottomDrawer}
              bottomDrawerClose={() => setBottomDrawer({ open: false })}
              inspection
              data={requests}
              selectedApp={app}
              title={'survey.defectsList'}
              buttonText={'survey.defects'} />
          </div>}
        </>
      }
    </div>)
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiButtonBase-root:focus': {
      outline: 0,
    },
  },
  drawerHeader: {
    display: 'flex',
    padding: theme.spacing(1),
    justifyContent: 'space-between',
    // borderBottom: `1px solid ${theme.palette.grey[300]}`,
    alignItems: 'center',
    // marginBottom: 10,
    paddingBottom: 7
  },
  surveyMomentCardTitle: {
    fontSize: 12,
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 16,
    },
    fontWeight: 'bold',
    color: theme.palette.common.black,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  drawerIcon: {
    fill: theme.palette.grey[300],
    cursor: 'pointer'
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    background: theme.palette.common.white,
    zIndex: 1,
  },
  square: {
    height: 60,
    width: 60,
    background: alpha(theme.palette.primary.main, 0.05),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50
  },
  bugIcon: {
    fontSize: 40,
    fill: theme.palette.grey[500],
    color: theme.palette.grey[500],
  },
  cardTitle: {
    fontSize: 16,
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 16,
    },
    color: theme.palette.grey[700],
    fontWeight: 'bold',
    marginBottom: 7
  },
  cardStatusTitle: {
    color: theme.palette.grey[700],
    fontWeight: 'bold',
    fontSize: 10,
  },
  cardStatusNumber: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: 15,
  },
  cardStatusSquare: {
    width: 30,
    height: 25,
    background: "#006ED8",
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardSubTitle: {
    fontSize: 20,
    color: theme.palette.grey[700],
    fontWeight: 'bold'
  },
  cardLayout: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardPaper: {
    display: 'flex',
    justifyContent: "space-between",
    flexGrow: 1,
    alignItems: 'center',
    padding: theme.spacing(2),
    cursor: 'pointer'
  },
  layoutLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  layoutSubContainer: {
    marginTop: theme.spacing(1),
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
  },
  layoutContainer: {
    padding: theme.spacing(0, 2, 2, 2),
    // width: '100%',
    height: 'auto',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center'
  },
  layoutContainerHeight: {
    height: '100% !important'
  },
  statusContainer: {
    display: "flex"
  },
  status: {
    marginRight: 5
  }
}))
