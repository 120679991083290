// NOTE: If any changes are made to the DesktopGridHeader.jsx file, please ensure that the MobileGridHeader.jsx file is also updated accordingly.
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridCloseIcon,
  GridSearchIcon,
} from "@mui/x-data-grid-pro";
import {
  Box,
  IconButton,
  Stack,
  MenuItem,
  TextField,
  Tooltip,
  useTheme,
  Button,
} from "@mui/material";

import FilterButton from "./FilterButton";
import { common, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

const DesktopGridHeader = ({
  setColumnSelectorType,
  setOpenColumnsSelector,
  handleSearch,
  searchText,
  setSearchText,
  quickFilterItems,
  handleExportModal,
  toolbarStartActions,
  toolbarEndActions,
}) => {
  const { t } = useTranslation();
  const theme = useTheme()

  const CustomExportButton = (props) => {
    return (
      <GridToolbarExportContainer {...props}>
        <GridCsvExportMenuItem
          onClick={(e) => {
            setColumnSelectorType?.("download-csv");
            setOpenColumnsSelector?.(true);
          }}
        />
        <MenuItem
          onClick={handleExportModal}
        >
          {t("datatable.label.toolbar.downloadExcel")}
        </MenuItem>
        <GridPrintExportMenuItem
          onClick={(e) => {
            setColumnSelectorType?.("print");
            setOpenColumnsSelector?.(true);
          }}
        />
      </GridToolbarExportContainer>
    );
  };

  return (
    (<GridToolbarContainer>
      <Stack
        direction="row"
        sx={{
          gap: 2.5,
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          p: "2px 0px",
          marginBottom: "8px"
        }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "5px 0px"
          }}>
          <Box
            sx={{
              width: "100%",
              display: "flex-start",
              justifyContent: "space-around"
            }}>
            {toolbarStartActions?.map((customToolbarStartAction) => {
              const IconComponent = customToolbarStartAction.icon;
              return (
                <Button
                  variant="text"
                  startIcon={<IconComponent color={customToolbarStartAction.iconColor || "primary"} />}
                  onClick={customToolbarStartAction.onClick}
                  sx={{ fontSize: "13px" }}
                >
                  {customToolbarStartAction.title}
                </Button>
              );
            })}
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <CustomExportButton />
            {toolbarEndActions?.map((customToolbarEndAction) => {
              const IconComponent = customToolbarEndAction?.icon;
              return (
                <Button
                  variant="text"
                  startIcon={<IconComponent color={customToolbarEndAction?.iconColor || "primary"} />}
                  onClick={customToolbarEndAction?.onClick}
                  sx={{ fontSize: "13px" }}
                >
                  {customToolbarEndAction?.title}
                </Button>
              );
            })}
          </Box>
          <Stack
            direction="row"
            sx={{
              justifyContent: "right",
              gap: 2
            }}>
            {quickFilterItems?.map((item) => <FilterButton
              onClick={item.onClick}
              isSelected={item.isSelected}
            >
              <Tooltip title={item.title}>
                <div>
                  {item.iconText &&
                    <span
                      style={{ color: item.isSelected ? common.white : grey[600] }}
                    >
                      {item.iconText}
                    </span>}
                  <Box
                    sx={{
                      display: "flex",
                      color: item.isSelected ? common.white : grey[600]
                    }}>
                    {item?.icon}
                  </Box>
                </div>
              </Tooltip>
            </FilterButton>)}
          </Stack>
        </Box>
        <Box
          sx={{
            width: "40%",
            padding: "1px 5px",
            borderRadius: "7px",
            boxShadow: theme.shadows[1],
            margin: "2px",
            display: "flex",
            alignItems: "center"
          }}>
          <TextField
            fullWidth
            id="standard-search"
            placeholder={t("datatable.label.toolbar.search")}
            type="text"
            variant="standard"
            value={searchText}
            onChange={(e) => {
              setSearchText?.(e.target.value);
              handleSearch?.(e.target.value);
            }}
            slotProps={{
              input: {
                disableUnderline: true,
                startAdornment: (
                  <IconButton size="small">
                    <GridSearchIcon
                      sx={{ margin: "0px 5px", color: "primary.main" }}
                    />
                  </IconButton>
                ),
                endAdornment: (
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (searchText !== "") {
                        handleSearch?.("");
                        setSearchText?.("");
                      }
                    }}
                  >
                    {searchText && (
                      <GridCloseIcon
                        fontSize="small"
                        sx={{ margin: "0px 5px", color: "primary.main" }}
                      />
                    )}
                  </IconButton>
                ),
              }
            }}
          />
        </Box>
      </Stack>
    </GridToolbarContainer>)
  );
};

export default DesktopGridHeader;
