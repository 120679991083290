import React, { useRef, useState } from 'react';
import { updateWorkOrderSignature } from "../../apis/aftercareApis";
import { AppBar, Box, Button, Dialog, IconButton, Stack, Typography, DialogContent, TextField, DialogActions, Toolbar, Checkbox, useMediaQuery, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SignaturePad from 'react-signature-canvas';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { throttle } from './../../_helpers/throttle';

const SignatureModal = ({ handleSnackbarOpen,isSignatureModalOpen, workOrder, getWorkOrderDetails, toggleSignatureModal }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    let signaturePadRef = useRef({});
    const { t } = useTranslation();
    const classes = useStyles();
    const [signatureModal, setSignatureModal] = useState({
        signUrl: "",
        signatoryName: workOrder?.signatoryName,
        signedWithSatisfaction: false
    });
    const handleCheckboxChange = (e) => {
        setSignatureModal((prevSignatoryName) => ({
            ...prevSignatoryName, signedWithSatisfaction: e.target.checked
        }))
    };
    const handalSignatoryName = (e) => {
        setSignatureModal((prevSignatoryName) => ({
            ...prevSignatoryName, signatoryName: e.target.value
        }))
    }

    const captureSign = () => {
        const pad = signaturePadRef;
        const signUrl = pad?.getTrimmedCanvas()?.toDataURL('image/png');
        setSignatureModal((prevSignUrl) => ({
            ...prevSignUrl, signUrl
        }))
    };
    const handleCancel = () => {
        toggleSignatureModal()
        setSignatureModal({ signatoryName: workOrder?.signatoryName ? workOrder?.signatoryName : "" })
    }

    const handleCloseModal = () => {
        toggleSignatureModal()
        setSignatureModal({ signatoryName: workOrder?.signatoryName ? workOrder?.signatoryName : "", signUrl: "" })
    }

    const clear = async () => {
        await signaturePadRef?.clear();
        setSignatureModal((prevSignUrl) => ({
            ...prevSignUrl, signUrl: ""
        }))
    };
    const handleSign = async () => {
        const payload = {
            resolverId: workOrder?.resolverId,
            signature: {
                name: signatureModal.signatoryName + new Date().getTime() + ".png",
                content: signatureModal.signUrl && typeof signatureModal.signUrl === 'string' ? signatureModal?.signUrl?.split(',')[1] : ''
            },
            signatoryName: signatureModal.signatoryName?.trim(),
            signedWithSatisfaction: signatureModal.signedWithSatisfaction
        }
        try {
           const signatureUpdate =  await updateWorkOrderSignature(payload);
           if(signatureUpdate?.data?.success){
               getWorkOrderDetails(true);
               handleCloseModal()
               handleSnackbarOpen(t("workOrders.signature.success"),"success")
            }
            else {
                handleSnackbarOpen(t("general.api.error"),"error")
                handleCloseModal()
            }
        } catch (error) {
            if (error.response.status === 400) {
                handleCloseModal()
                getWorkOrderDetails(true);
                handleSnackbarOpen(t("workOrders.signature.canNotSignAgain"),"warning" )
            } else {
                handleSnackbarOpen(t("general.api.error"),"error")
            }
        }
    }
    return (
        (<Dialog fullScreen={fullScreen} onClose={handleCloseModal} aria-labelledby='customized-dialog-title' maxWidth="sm" fullWidth open={isSignatureModalOpen}>
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <Stack
                        direction="row"
                        sx={{
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                        <Typography variant="h6" component="div">
                            {t('general.signature')}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleCloseModal}
                            aria-label="close"
                        >
                            <Close sx={{ cursor: "pointer" }} />
                        </IconButton>
                    </Stack>
                </Toolbar>
            </AppBar>
            <DialogContent>
                <Stack
                    sx={{
                        rowGap: 2,
                        pt: 1,
                        height: "60vh",
                        maxHeight: 540
                    }}>
                    <FormControlLabel control={<Checkbox checked={signatureModal.signedWithSatisfaction}
                        onChange={handleCheckboxChange} />} label={t("workOrders.signatureModal.satisfactionCheckbox.label")} />
                    <Box sx={{
                        width: { xs: "100%", md: "50%" }
                    }}>
                        <TextField
                            fullWidth
                            value={signatureModal.signatoryName}
                            onChange={handalSignatoryName}
                            size="small"
                            id="outlined-helperText"
                            label={t("workOrders.signatoryName")}
                            placeholder={t("workOrders.signatoryName")}
                            autoComplete="off"
                        />
                    </Box>
                    <Typography>{t("general.signature")}</Typography>
                    <div className={classes.signatureMainContainer}>
                        <div className={classes.signatureContainer}>
                            <SignaturePad
                                onEnd={() => captureSign()}
                                clearOnResize={false}
                                canvasProps={{ className: classes.signaturePad }}
                                ref={(ref) => { signaturePadRef = ref; }}
                            />
                        </div>
                    </div>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button size="small" variant="text" onClick={clear} disabled={!signatureModal?.signUrl?.trim()?.length}>
                    {t('survey.clear')}
                </Button>
                <Button size="small" variant="contained" onClick={handleCancel}>{t("general.cancel")}</Button>
                <Button size="small" onClick={throttle(handleSign)} variant="outlined" disabled={!signatureModal?.signatoryName?.trim()?.length || !signatureModal?.signUrl?.trim()?.length}>
                    {t("general.sign")}
                </Button>
            </DialogActions>
        </Dialog>)
    );
}

const useStyles = makeStyles(theme => ({
    signatureMainContainer: {
        width: '100%',
    },
    signatureContainer: {
        width: '100%',
        margin: '0 auto',
        backgroundColor: theme.palette.grey[100],
        borderRadius: 7,
        aspectRatio: '16 / 9',
    },
    signaturePad: {
        width: '100%',
        height: '100%',
    },
}));

export default SignatureModal;
