import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import ReactResizeDetector from "react-resize-detector";
import {
  Avatar,
  Container,
  Grid2 as Grid,
  Typography,
  FormControl,
  InputLabel,
  FormHelperText,
  Divider,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  AppBar,
  IconButton,
  Toolbar,
  Slide,
  Box,
  ListItemAvatar,
  TextField,
  Chip,
  Hidden,
  Collapse,
  Badge,
  Modal,
  Fade,
  MenuItem,
  Button,
  ListItemSecondaryAction,
  Input,
  InputAdornment,
  CircularProgress,
  CardMedia,
  CardActionArea,
  Tooltip,
  Icon,
  ToggleButton,
  Popover,
  Stack,
  ListItemIcon,
  Menu,
  ListItemButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material"
import { alpha } from "@mui/material/styles"
import {
  Search,
  ArrowBack,
  Clear,
  Send,
  Email,
  ExpandMore,
  AssignmentOutlined,
  AttachFile,
  DeleteOutline,
  Close,
  InsertDriveFile,
  Reply,
  PhotoCamera,
  TextFormat,
  PriorityHigh,
  Error,
  Bookmark,
  BookmarkBorder,
  Info,
  InfoOutlined,
  MoreVert,
  Download,
  Visibility,
  ContentCopy,
  ExpandLess,
  Group,
  Lock,
  LockOpen,
  MoreVertSharp,
  Warning
} from "@mui/icons-material";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { history, validateFile, getDateText, md2plaintext, formatTime, formatDate, formatFileSize, authHeader, getNameInitials, downloadFile, getMimeTypefromString, getFileExtentionFromFileName, chatColor } from "../../_helpers";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { withTheme, withStyles } from "@mui/styles";
import { userAccountTypeConstants } from "../../_constants";
import RichTextEditor from "./RichTextEditor";
import Markdown from "../../components/Markdown";
import StandardTextManager from "./StandardTextManager";
import EmojiSelector from "./EmojiSelector";
import SelectMultipleObjectsModal from "../../components/SelectMultipleObjectsModal";
import Dropzone from "../../components/Dropzone";
import { fileUploadConfig } from "../../_helpers/fileUploadConfig";
import { themeBreakpointUp } from "../../_helpers/breakpointHelper";
import { checkHasAdminRightsForChat, goToBuildingOverviewPage } from "./utils"
import { apps } from "../../_constants/apps.constants"
import { throttle } from "../../_helpers/throttle";
import DocumentViewer from "../../components/DocumentViewer";
import { URLS } from "../../apis/urls";
import ParticipantsListDrawer from "./ParticipantsListDrawer";

const colors = ["#35cd96", "#6bcbef", "#e542a3", "#91ab01", "#ffa97a", "#1f7aec", "#dfb610", "#029d00", "#8b7add", "#fe7c7f", "#ba33dc", "#59d368", "#b04632", "#fd85d4", "#8393ca", "#ff8f2c", "#3bdec3", "#b4876e", "#c90379", "#ef4b4f"];

const { webApiUrl } = window.appConfig;

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  groupBadge: {
    '& .MuiBadge-badge': {
      color: theme.palette.primary.main
    }
  },
  slideLeft: {
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    zIndex: 2100,
    right: 0,
    height: "100%",
  },

  bold: {
    fontWeight: "bold",

  },
  fullWidth: {
    width: "100%",
  },
  searchMessagesList: {
    maxHeight: "calc(100% - 48px)",
    overflowX: "hidden",
    overflowY: "auto",
    width: "100%",
  },
  searchMessagesListItem: {
    paddingTop: 0,
    paddingBottom: 0,
    "& .MuiListItemText-root": {
      marginRight: "60px",
    },
  },
  chatBoxContainer: {
    position: "relative",
    backgroundColor: theme.palette.background.default,
    backgroundImage: "url(/content/images/background/brickwall-bg.png)",
    height: "100%",
    "&.mini": {
      position: "absolute",
      zIndex: 2,
    },
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      zIndex: 2,
    },
  },
  chatBoxTitle: {
    overflow: "hidden",
    "& p": {
      lineHeight: 1.2,
    },
  },
  chatBox: {
    width:"100%",
    overflowY: "scroll",
    overflowX: "hidden",
    flex: 1,
    position: "relative",
    padding: theme.spacing(2, 8),
    scrollbarWidth: "thin",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 1, 1, 5),
    },
    "&.mini": {
      padding: theme.spacing(1, 1, 1, 5),
    }
  },
  chatItemsWrapper: {
    maxWidth: 790,
    width: '100%'
  },
  chatCardContainer: {
    marginTop: theme.spacing(-2),
  },
  chatItemAvatar: {
    position: "relative",
    left: theme.spacing(-6),
    top: theme.spacing(1),
    height: 0,
    width: 0,
    [theme.breakpoints.down("sm")]: {
      left: theme.spacing(-4),
      top: theme.spacing(0),
    },
    "&.mini": {
      left: theme.spacing(-4),
      top: theme.spacing(0),
      "& > div": {
        fontSize: ".9rem",
        height: theme.spacing(3.5),
        width: theme.spacing(3.5),
      },
    },
    "& > div": {
      fontSize: "1.1rem",
      [theme.breakpoints.down("sm")]: {
        fontSize: ".9rem",
        height: theme.spacing(3.5),
        width: theme.spacing(3.5),
      },
    },
  },
  chatCard: {
    borderLeftWidth: theme.spacing(0.5),
    borderLeftStyle: "solid",
    borderLeftColor: "transparent",
    boxShadow: "0 1px .5px rgba(0,0,0,.13)",
    minWidth: 120,
    transition: theme.transitions.create(["background-color", "color", "opacity"], { duration: 1500 }),
    "& .MuiCardContent-root": {
      padding: theme.spacing(1, 1.5, 0, 1),
      position: "relative",
      wordWrap: "break-word",
      "& .MuiCardHeader-root": {
        padding: 0,
      },
    },
    "&.sentbyme": {
      backgroundColor: "#e3f2fd",
      "& .chatButtonsContainer": {
        backgroundColor: "#e3f2fd",
      },
    },
    "&.system": {
      backgroundColor: "#fff5c4",
      marginTop: theme.spacing(-1.5),
      "& .chatButtonsContainer": {
        backgroundColor: "#fff5c4",
      },
    },
    "&.focused": {
      backgroundColor: "#cfcfcf",
    },
    "& .chatButtonsContainer": {
      backgroundColor: "#fff",
      position: "absolute",
      right: 0,
      top: 0,
      display: "none",
    },
    "&:focus-within": {
      "& .chatButtonsContainer": {
        display: "block",
      },
    },
    "&:focus": {
      "& .chatButtonsContainer": {
        display: "block",
      },
    },
    "&:hover": {
      "& .chatButtonsContainer": {
        display: "block",
      },
    },
  },
  chatCardImportantIcon: {
    margin: theme.spacing(1, -1.5),
    float: "right",
    "&.mini": {
      margin: theme.spacing(1, -1.25, 0, -1.75),
    },
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1, -1.25, 0, -1.75),
    },
  },
  chatCardInfoIcon: {
    zIndex: 1,
    margin: theme.spacing(0, 1, 0),
    color: theme.palette.primary.main,
  },
  chatMedia: {
    width: "auto",
    maxWidth: "100%",
    height: 150,
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      height: 200,
    },
  },
  quoteMessageCard: {
    width: "100%",
    backgroundColor: "rgba(0,0,0,.05)",
    margin: theme.spacing(1, 0),
    boxShadow: "none",
    borderLeftWidth: 4,
    borderLeftStyle: "solid",
    "& .MuiCardActionArea-root": {
      display: "flex",
      justifyContent: "flex-start",
    },
    "& .MuiCardContent-root": {
      padding: theme.spacing(1, 2),
      flexGrow: 1,
    },
    "& .MuiCardMedia-root": {
      width: 62,
      height: 62,
    },
  },
  chatActions: {
    width:"100%",
    maxHeight: "calc(100% - 64px)",
    padding: theme.spacing(1, 8, 0),
    overflowY: "scroll",
    "&.mini": {
      padding: theme.spacing(1, 1, 0),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 1, 0),
    },
  },
  chatCommentBoxContainer: {
    maxWidth: 774,
    width: "100%",
    margin: "auto",
    borderLeftWidth: theme.spacing(0.5),
    borderLeftStyle: "solid",
    borderLeftColor: "transparent",
    padding: theme.spacing(0.5, 1, 0.5, 0.5),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    "&.mini": {
      maxWidth: "100%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
    "&:focus-within": {
      borderBottomWidth: theme.spacing(0.25),
      borderBottomStyle: "solid",
      borderBottomColor: theme.palette.primary.main,
      paddingBottom: theme.spacing(0.25),
    },
  },
  important: {
    borderLeftWidth: theme.spacing(0.5),
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.secondary.main,
  },
  commentBoxImportantHeader: {
    fontWeight: "bold",
    paddingLeft: theme.spacing(0.5),
  },
  chatActionBoxContainer: {
    padding: theme.spacing(0.5, 1),
    maxWidth: 774,
    margin: "auto",
    "& > div": {
      marginRight: theme.spacing(1),
      "&:last-child": {
        marginRight: theme.spacing(0),
      },
    },
  },
  replyMessageCard: {
    width: "calc(100% - 48px)",
    backgroundColor: "rgba(0,0,0,.05)",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    boxShadow: "none",
    borderLeftWidth: 4,
    borderLeftStyle: "solid",
    marginBottom: theme.spacing(0.5),
    "& .MuiCardContent-root": {
      padding: theme.spacing(1, 2),
      flexGrow: 1,
    },
    "& .MuiCardMedia-root": {
      width: 62,
      height: 62,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalCard: {
    maxWidth: 500,
  },
  modalCardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  inputBoxSearch: {
    color: "inherit",
    maxWidth: "100%",
    margin: "-3px 5px -5px 0",
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
    "& svg": {
      color: "white",
    },
    flexGrow: 1,
  },
  appBar: {
    zIndex: 1
  },
  appBarButton: {
    maxWidth: "100%",
    minWidth: 0,
    overflow: "hidden"
  },
  chatsLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    position: "absolute",
    backgroundColor: alpha(theme.palette.common.black, 0.5),
  },
  checkBoxNotify: {
    padding: theme.spacing(0.5),
  },
  broadcastInputField: {
    cursor: "pointer",
    "& input": {
      cursor: "inherit",
    },
  },
  dropzoneContainer: {
    width: "20%",
    float: "left",
    padding: theme.spacing(0.2),
    [theme.breakpoints.down("xs")]: {
      width: 100,
    },
  },
  broadcastFilesList: {
    width: "80%",
    float: "left",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  }
});


class ChatMessages extends React.Component {
  state = {
    chatItems: [],
    focusedChatMessageId: null,
    chatItemToReplyOn: null,
    chatParticipants: {},
    comment: "",
    dialogOpen: false,
    markCommentImportant: false,
    textToInsertInComment: "",
    richTextToInsertInComment: "",
    standardTexts: null,
    scrollToBottom: false,
    messagesContainerHeight: 0,
    employees: [],
    searchTermMessages: "",
    searchMessagesWithAttachment: false,
    searchMessagesResults: [],
    hasMoreSearchMessagesResults: false,
    uploading: false,
    showRichTextEditorToolbar: false,
    anchorEl: null,
    notifyUserIds: [],
    isLoadingChatItem: !!this.props.selectedChat,
    isNewMessageSending: false,
    broadcasting: false,
    attachmentMenuOpen: false,
    attachmentCopySubMenuIsOpen: false,
    attachmentFilesForPreview: null,
    selectedAttachmentChatItem: null,
    attachmentMenuPosition: { x: 0, y: 0 },
    copyChatAttachmentLoader: false,
    isParticipantsListDrawerOpen: false,
    chatMenuAnchorEl: null,
  };

  importantChatTimeoutId = null;
  markChatReadInProgressMap = {};
  unsupportedDocViewerExtentions = fileUploadConfig.unsupportedDocViewerMimeTypes.map(singleUnsupportedDocViewerMimeType => singleUnsupportedDocViewerMimeType.extension)

  componentDidMount() {
    this.mounted = true;
    this.UpdateActionEmployees();
    this.UpdateImportantChats();
    this.UpdateChatParticipants();
    this.UpdateChatItems();
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    this.mounted = false;
    this.cancelImportantChatsApi();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.selected || prevProps.selected.buildingId !== this.props.selected.buildingId) {
      this.UpdateActionEmployees();
      this.cancelImportantChatsApi();
      this.UpdateImportantChats();
      this.setState({
        isParticipantsListDrawerOpen: false,
      });
    }
    if (prevProps.selected && this.props.selected && prevProps.selected.projectId.toUpperCase() !== this.props.selected.projectId.toUpperCase()) {
      this.closeChatBox();
    }
    if (this.props.chatBoxOpen !== prevProps.chatBoxOpen && this.state.isParticipantsListDrawerOpen) {
      this.setState({
        isParticipantsListDrawerOpen: false,
      });
    }
    if (this.state.searchTermMessages.trim() !== prevState.searchTermMessages.trim() || this.state.searchMessagesWithAttachment !== prevState.searchMessagesWithAttachment) {
      this.SearchChatMessages();
    }
    if (this.state.goToChatMessage === true) {
      this.setState({ goToChatMessage: false });
    } else {
      if (this.props.selectedChat && (!prevProps.selectedChat || prevProps.selectedChat.chatId !== this.props.selectedChat.chatId)) {
        this.setState({ isLoadingChatItem: true });
        this.UpdateChatItems();
      }
      if ((!prevProps.selectedHasUpdate && this.props.selectedHasUpdate) || (this.state.hasNewer === true && this.state.isScrollPositionBottom)) {
        this.UpdateChatItems(true);
      }
    }
    if (this.props.selectedChat && (!prevProps.selectedChat || prevProps.selectedChat.chatId !== this.props.selectedChat.chatId)) {
      this.UpdateChatParticipants();
      this.setState({ broadcastSuccessObjectsCount: 0, notifyImmediately: false });
    }
    if (this.props.broadcast && (prevProps.broadcast !== this.props.broadcast)) {
      this.setState({ broadcastSuccessObjectsCount: 0, notifyImmediately: false });
    }
    if (this.state.scrollToBottom) {
      this.scrollToBottom();
      this.setState({ scrollToBottom: false });
    }
    if (this.props.selectedChat && this.props.selectedChat.unreadMessagesCount > 0 && this.state.isScrollPositionBottom) {
      this.UpdateChatItemAsRead();
    }
    if (this.state.lastScrollHeight && this.state.lastScrollHeight >= 0) {
      ReactDOM.findDOMNode(this.refs.messageList).scrollTop = this.refs.messageList.scrollHeight - this.state.lastScrollHeight;
      this.setState({ lastScrollHeight: -1 });
    }
    if (this.state.focusedChatMessageId !== null) {
      setTimeout(() => {
        this.setState({ focusedChatMessageId: null });
      }, 1500);
    }

    if (this.props.gotoMessage && this.props.gotoMessage.chatMessageId && this.props.chats?.length > 0) {
      if (this.props.selectedChat?.chatId === this.props.gotoMessage.chatId) {
        this.goToChatMessage(this.props.gotoMessage.chatId, this.props.gotoMessage.chatMessageId)
        this.props.updateParentState({ gotoMessage: null })
      }
      else {
        var selectedNew = this.props.chats.find((x) => x.chatId === this.props.gotoMessage.chatId);
        if (selectedNew)
          this.props.updateParentState({ selected: selectedNew, chatBoxOpen: true });
      }
    }
  }

  cancelImportantChatsApi = () => {
    if (this.importantChatTimeoutId || !this.mounted) {
      clearTimeout(this.importantChatTimeoutId);
      if (this.importantChatUpdateAbortController && this.importantChatUpdateAbortController.signal.aborted !== true) {
        this.importantChatUpdateAbortController.abort();
      }
      this.importantChatTimeoutId = null;
    }
  };

  UpdateImportantChats() {
    const { selected, app, user } = this.props;
    if (selected) {
      if (this.importantChatUpdateAbortController && this.importantChatUpdateAbortController.signal.aborted !== true) {
        this.importantChatUpdateAbortController.abort();
      }

      this.importantChatUpdateAbortController = new window.AbortController();

      const url = webApiUrl + "api/chat/" + (!!this.props.repairRequestId || ([apps.aftercare, apps.resolverModule].includes(app) && user.type !== userAccountTypeConstants.buyer) ? "GetImportantMessages" : (this.props.isFilterByBuilding ? "GetImportantMessagesByBuilding/" + selected.buildingId : "GetImportantMessagesByProject/" + selected.projectId)) + "?app=" + app + (this.props.repairRequestId ? "&repairRequestId=" + this.props.repairRequestId : "");
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
        signal: this.importantChatUpdateAbortController.signal,
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          this.props.updateParentState({
            importantChats: findResponse,
          });
        })
        .finally(() => {
          this.cancelImportantChatsApi();
          if (this.mounted) {
            this.importantChatTimeoutId = setTimeout(() => {
              this.UpdateImportantChats();
            }, 10000);
          }
        });
    }
  }

  UpdateChatParticipants = () => {
    const { selectedChat } = this.props;
    if (selectedChat) {
      const url = webApiUrl + "api/chat/GetChatParticipants/" + encodeURI(selectedChat.chatId);
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          var self = findResponse.filter((x) => x.loginId === this.props.user.id);
          var buyers = findResponse.filter((x) => x.isBuyer && x.loginId !== this.props.user.id);
          var others = findResponse.filter((x) => !x.isBuyer && x.loginId !== this.props.user.id);
          var allParticipants = [];
          if (this.props.user.type === userAccountTypeConstants.buyer) allParticipants = others.concat(buyers).concat(self);
          else allParticipants = buyers.concat(others).concat(self);

          var chatParticipantsNew = Object.assign({}, this.state.chatParticipants);
          chatParticipantsNew[selectedChat.chatId] = allParticipants;

          this.setState({ chatParticipants: chatParticipantsNew });
        });
    }
  }


  UpdateChatItems(getNewer = null) {
    const { user, selectedChat } = this.props;
    const { chatItems, isScrollPositionBottom } = this.state;
    if (selectedChat) {
      if (getNewer == null) {
        var defaultComment = localStorage.getItem(selectedChat.chatId + "_" + user.id);
        if (!defaultComment) {
          defaultComment = "";
        }
        this.handleChangeComment(defaultComment);
      }

      const url =
        webApiUrl +
        "api/chat/GetChatItems/" +
        selectedChat.chatId +
        (chatItems && chatItems.length > 0 && getNewer !== null
          ? getNewer === true
            ? "?newer=true&dateTime=" + encodeURIComponent(chatItems[chatItems.length - 1].dateTime)
            : "?newer=false&dateTime=" + encodeURIComponent(chatItems[0].dateTime)
          : "");
      if (this.chatItemsAbortController && this.chatItemsAbortController.signal.aborted !== true) {
        this.chatItemsAbortController.abort();
      }

      this.chatItemsAbortController = new window.AbortController();
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
        signal: this.chatItemsAbortController.signal,
      };
      fetch(url, requestOptions)
        .then(
          (Response) => Response.json(),
          (e) => { }
        )
        .then((findResponse) => {
          if (!findResponse) {
            return;
          }
          var allChatItems = [];
          const hasHistory = getNewer !== true ? findResponse.length === 10 : this.state.hasHistory === true;
          const hasNewer = getNewer === true ? findResponse.length === 10 : this.state.hasNewer === true;
          var chatItemToReplyOn = null;
          if (getNewer === null) {
            allChatItems = findResponse;
          } else {
            allChatItems = chatItems.slice();
            allChatItems = allChatItems.concat(findResponse);
            chatItemToReplyOn = this.state.chatItemToReplyOn;
          }

          let lastScrollHeight = -1;
          if (getNewer === false) {
            lastScrollHeight = this.refs.messageList.scrollHeight;
          }

          allChatItems.sort(function (a, b) {
            return new Date(a.dateTime) - new Date(b.dateTime);
          });

          const scrollToBottom = getNewer === true ? isScrollPositionBottom : getNewer === false ? this.state.scrollToBottom : true;
          this.setState({
            chatItems: allChatItems,
            chatItemToReplyOn,
            hasHistory,
            hasNewer,
            scrollToBottom,
            lastScrollHeight,
            isLoadingChatItem: false,
          });
          this.props.updateParentState({ selectedHasUpdate: false })

          this.updateOnScroll();
        })
        .catch((er) => {
          this.setState({ isLoadingChatItem: false });
        });
    }
  }

  UpdateActionEmployees() {
    const { selected } = this.props;
    if (selected) {
      const url = webApiUrl + "api/home/GetEmployees";

      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          this.setState({
            employees: findResponse,
          });
        });
    }
  }

  SearchChatMessages(refresh = true) {
    const { selectedChat, app } = this.props
    const { searchTermMessages, searchMessagesWithAttachment } = this.state;

    if (selectedChat && ((searchTermMessages && searchTermMessages.trim() !== "") || searchMessagesWithAttachment === true)) {
      const url = webApiUrl + "api/chat/SearchChats" + "?app=" + app;

      if (this.searchMessageAbortController && this.searchMessageAbortController.signal.aborted !== true) {
        this.searchMessageAbortController.abort();
      }

      this.searchMessageAbortController = new window.AbortController();

      const requestOptions = {
        method: "POST",
        headers: authHeader("application/json"),
        body: JSON.stringify({
          chatId: selectedChat.chatId,
          buildingId: null,
          projectId: null,
          searchTerm: searchTermMessages,
          organisationId: null,
          dateTime: refresh !== true && this.state.searchMessagesResults.length > 0 ? this.state.searchMessagesResults[this.state.searchMessagesResults.length - 1].dateTime : null,
          attachment: searchMessagesWithAttachment,
          count: 10,
        }),
        signal: this.searchMessageAbortController.signal,
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          var searchMessagesResults = [];
          const hasMoreSearchMessagesResults = findResponse.length === 10;

          if (refresh === true) {
            searchMessagesResults = findResponse;
          } else {
            searchMessagesResults = this.state.searchMessagesResults.slice();
            searchMessagesResults = searchMessagesResults.concat(findResponse);
          }

          searchMessagesResults.sort(function (a, b) {
            return new Date(b.dateTime) - new Date(a.dateTime);
          });

          this.setState({
            searchMessagesResults,
            hasMoreSearchMessagesResults,
          });

          this.updateSearchMessagesResultsOnScroll();
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            //handle aborterror here
          }
        });
    } else {
      if (this.searchMessageAbortController && this.searchMessageAbortController.signal.aborted !== true) {
        this.searchMessageAbortController.abort();
      }
      this.setState({
        searchMessagesResults: [],
        hasMoreSearchMessagesResults: false,
      });
    }
  }

  updateSearchMessagesResultsOnScroll = () => {
    const { hasMoreSearchMessagesResults } = this.state;
    const { searchMessagesList } = this.refs;
    if (searchMessagesList) {
      const scrollTop = searchMessagesList.scrollTop;
      const scrollHeight = searchMessagesList.scrollHeight;
      const height = searchMessagesList.clientHeight;
      const maxScrollTop = scrollHeight - height;
      const isScrollPositionBottom = scrollTop === maxScrollTop;
      if (isScrollPositionBottom === true && hasMoreSearchMessagesResults === true) {
        this.SearchChatMessages(false);
      }
    }
  };

  UpdateChatItemAsRead() {
    const { selectedChat } = this.props
    let { chatItems } = this.state;
    if (selectedChat && chatItems.length > 0 && this.state.isScrollPositionBottom) {
      const lastMessage = chatItems[chatItems.length - 1];
      if (selectedChat.chatId === lastMessage.chatId && !this.markChatReadInProgressMap[lastMessage.chatMessageId]) {
        this.markChatReadInProgressMap[lastMessage.chatMessageId] = true;
        const url = webApiUrl + "api/chat/MarkLastReadChatItem/" + lastMessage.chatMessageId;
        const requestOptions = {
          method: "GET",
          headers: authHeader(),
        };

        fetch(url, requestOptions)
          .then((Response) => Response.json())
          .then((success) => {
            if (success) {
              let chatsToUpdate = this.props.chats.slice();
              const index = chatsToUpdate.findIndex((x) => x.chatId === selectedChat.chatId);
              let selectedChatToUpdate = { ...selectedChat };
              if (index >= 0) {
                selectedChatToUpdate.unreadMessagesCount = 0;
                chatsToUpdate[index] = {
                  ...chatsToUpdate[index],
                  unreadMessagesCount: 0,
                };
              }
              this.props.updateParentState({ chats: chatsToUpdate, selected: selectedChatToUpdate }, () => (this.markChatReadInProgressMap[lastMessage.chatMessageId] = false))
            }
          });
      }
    }
  }


  uploadAttachment = (e) => {
    const { t, selectedChat } = this.props;

    if (selectedChat) {
      const files = Array.from(e.target.files);

      if (validateFile({ file: files[0] }) === true) {
        this.setState({ uploading: true });

        const formData = new FormData();

        formData.append("file", files[0]);

        fetch(webApiUrl + `api/chat/UploadAttachment/` + selectedChat.chatId, {
          method: "POST",
          headers: authHeader(),
          body: formData,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res === false) {
              alert(t("general.api.error"));
            }
            this.setState({ uploading: false });
          })
          .catch((e) => {
            this.setState({ uploading: false });
            alert(t("general.api.error"));
          });
      }
    }
    e.target.value = "";
  };

  sendNewChatMessage = () => {
    const { user, selectedChat } = this.props;
    const { comment, chatItemToReplyOn, markCommentImportant, notifyImmediately, notifyUserIds } = this.state;
    //var comments = stateToMarkdown(comment.getCurrentContent());
    if (selectedChat && comment.trim() !== "") {
      this.setState({ isNewMessageSending: true })
      const url = webApiUrl + "api/chat/AddNewChatMessage";
      const requestOptions = {
        method: "POST",
        headers: authHeader("application/json"),
        body: JSON.stringify({
          chatId: selectedChat.chatId,
          message: comment,
          important: markCommentImportant,
          replyToChatMessageId: chatItemToReplyOn ? chatItemToReplyOn.chatMessageId : null,
          notify: !!notifyImmediately || notifyUserIds.length > 0,
          loginIdsToNotify: notifyUserIds
        }),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((success) => {
          if (success) {
            localStorage.removeItem(selectedChat.chatId + "_" + user.id);
            this.setState({
              isScrollPositionBottom: true,
              comment: "",
              markCommentImportant: false,
              chatItemToReplyOn: null,
              showRichTextEditorToolbar: false,
              notifyImmediately: false,
              notifyUserIds: []
            });
            this.props.updateParentState({ selectedHasUpdate: true })
          }
          this.setState({ isNewMessageSending: false })
        }).catch(err => {
          console.log({ err })
          this.setState({ isNewMessageSending: false })
        });
    }
  };

  handleBroadcast = (event) => {
    const { t, broadcast } = this.props;
    const { broadcasting, broadcastFiles, broadcastObjects, comment, markCommentImportant, notifyImmediately } = this.state;
    if (broadcast && !broadcasting && comment.trim() !== "" && broadcastObjects && broadcastObjects.length) {
      this.setState({ broadcasting: true });

      const formData = new FormData();

      formData.append("message", comment);
      formData.append("important", !!markCommentImportant);
      formData.append("notify", !!notifyImmediately);

      for (var i = 0; i < broadcastObjects.length; i++) {
        formData.append("buildingIds", broadcastObjects[i].buildingId);
      }

      if (broadcastFiles) {
        for (var j = 0; j < broadcastFiles.length; j++) {
          formData.append("files", broadcastFiles[j]);
        }
      }

      fetch(webApiUrl + "api/chat/BroadcastMessage", {
        method: "POST",
        headers: authHeader(),
        body: formData,
      })
        .then((Response) => Response.json())
        .then((res) => {
          this.props.updateParentState({ broadcast: false });
          this.setState({
            broadcastSuccessObjectsCount: broadcastObjects.length,
            broadcasting: false,
            notifyImmediately: false,
            broadcastObjects: [],
            comment: "",
            broadcastFiles: [],
            markCommentImportant: false,
          });
        })
        .catch((e) => {
          this.setState({ broadcasting: false });
          alert(t("general.api.error"));
        });
    } else {
      event.preventDefault();
    }
  };

  handleMarkUnmarkImportantChatMessage(chatItem, isMark) {
    const { selectedChat } = this.props;

    const url = webApiUrl + "api/chat/MarkUnmarkChatMessageImportant/" + chatItem.chatMessageId + "?isMark=" + isMark;
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    // BEGIN COMMENT--- this piece of code is written before fetch to make it look smooth on UI, need to fix in future

    // END COMMENT

    fetch(url, requestOptions)
      .then((Response) => {

        var importantChats = this.props.importantChats?.slice() || [];

        var chatToUpdate = importantChats.find((x) => x.chatId.toUpperCase() === chatItem.chatId.toUpperCase());
        if (chatToUpdate) {
          if (isMark) {
            var importantChatMessage = {
              chatMessageId: chatItem.chatMessageId,
              message: chatItem.message,
              dateTime: chatItem.dateTime,
              chatParticipantId: selectedChat.chatParticipantId,
              isFile: chatItem.isFile,
            };

            var messages = chatToUpdate.messages;
            messages.push(importantChatMessage);
            messages.sort(function (a, b) {
              return new Date(a.dateTime) - new Date(b.dateTime);
            });
            chatToUpdate.messages = messages;

            this.props.updateParentState({
              importantChats,
            });
          } else {
            chatToUpdate.messages = chatToUpdate.messages.filter((x) => x.chatMessageId.toUpperCase() !== chatItem.chatMessageId.toUpperCase());
            this.props.updateParentState({
              importantChats,
            });
          }
        }
      })
      .catch((error) => {
        console.log("Error while deleting", error);
      });
  }

  isChatMessageImportant = (chatItem) => {
    if (this.props.importantChats) {
      var importantChats = this.props.importantChats.slice();
      var importantChat = importantChats.find((x) => x.chatId.toUpperCase() === chatItem.chatId.toUpperCase());
      if (importantChat) {
        var importantChatMessage = importantChat.messages.find((x) => x.chatMessageId.toUpperCase() === chatItem.chatMessageId.toUpperCase());
        if (importantChatMessage) {
          return true;
        }
      }
    }
    return false;
  };

  handleReplyChatMessage = (chatItem) => {
    this.setState({ chatItemToReplyOn: chatItem });
  };

  handleCloseReply = () => {
    this.setState({ chatItemToReplyOn: null });
  };

  handleDeleteChatMessage = (chatItem) => {
    const { user } = this.props;
    const url = webApiUrl + "api/chat/DeleteChatMessage/" + chatItem.chatMessageId;
    const requestOptions = {
      method: "DELETE",
      headers: authHeader(),
    };

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        var chatItems = this.state.chatItems.slice();
        chatItem = chatItems.find((x) => x.chatMessageId.toUpperCase() === chatItem.chatMessageId.toUpperCase());
        chatItem.message = "";
        chatItem.deleted = true;
        chatItem.deletedByParticipant = user.name;
        this.setState({
          chatItems,
        });
      })
      .catch((error) => {
        console.log("Error while deleting");
      });
  };

  getUserColor(chatId, loginId) {
    const { chatParticipants } = this.state;
    if (loginId === null) {
      return "#3f51b5";
    }
    if (chatParticipants[chatId]) {
      for (var i = 0; i < chatParticipants[chatId].length; i++) {
        if (chatParticipants[chatId][i].loginId === loginId) {
          return colors[i % 20];
        }
      }
    }
    return "";
  }

  getUserAvatar = (chatId, loginId, userInitials) => {
    const { chatParticipants } = this.state;

    var backgroundColor = "";

    if (chatParticipants[chatId]) {
      for (var i = 0; i < chatParticipants[chatId].length; i++) {
        if (chatParticipants[chatId][i].loginId === loginId) {
          backgroundColor = colors[i % 20];
          if (chatParticipants[chatId][i].hasPhoto === true) {
            var srcUrl = webApiUrl + "api/home/GetPersonPhoto/" + chatParticipants[chatId][i].personId;
            return <Avatar style={{ backgroundColor }} alt={userInitials} src={srcUrl} />;
          }
        }
      }
    }
    return <Avatar style={{ backgroundColor }}>{userInitials}</Avatar>;
  }

  getUserColorByParticipantId(chatId, chatParticipantId) {
    const { chatParticipants } = this.state;
    if (chatParticipantId === null) {
      return "#3f51b5";
    }
    if (chatParticipants[chatId]) {
      for (var i = 0; i < chatParticipants[chatId].length; i++) {
        if (chatParticipants[chatId][i].chatParticipantId === chatParticipantId) {
          return colors[i % 20];
        }
      }
    }
    return "";
  }

  getNameByParticipantId(chatId, chatParticipantId) {
    const { chatParticipants } = this.state;
    const { user, t } = this.props;
    if (chatParticipants[chatId]) {
      var chatParticipant = chatParticipants[chatId].find((x) => x.chatParticipantId === chatParticipantId);
      if (chatParticipant) {
        if (chatParticipant.loginId === user.id) return t("U");
        return chatParticipant.name;
      }
    }
    return "";
  }

  toggleOpenSearchChatMessages = () => {
    document.activeElement.blur();
    this.setState({
      openSearchChatMessages: !this.state.openSearchChatMessages,
      chatMenuAnchorEl: null
    });
  };

  toggleParticipantListDrawer = () => {
    document.activeElement.blur();
    this.setState({
      isParticipantsListDrawerOpen: !this.state.isParticipantsListDrawerOpen,
      chatMenuAnchorEl: null
    });
  };

  handleChangeSearchMessages = (event) => {
    this.setState({
      searchTermMessages: event.target.value,
    });
  };

  toggleSearchMessagesWithAttachment = () => {
    document.activeElement.blur();
    this.setState({
      searchMessagesWithAttachment: !this.state.searchMessagesWithAttachment,
    });
  };

  clearAndCloseSearchMessages = () => {
    this.setState({
      openSearchChatMessages: false,
      searchTermMessages: "",
      searchMessagesResults: [],
      searchMessagesWithAttachment: false,
      hasMoreSearchMessagesResults: false,
    });
  };

  closeChatBox = () => {
    this.setState({
      chatItems: [],
      broadcastSuccessObjectsCount: 0,
    });
    this.props.updateParentState({ selected: null, chatBoxOpen: false, broadcast: false })
  };

  handleChangeComment = (comment) => {
    const { user, selectedChat } = this.props;
    if (selectedChat && user) {
      localStorage.setItem(selectedChat.chatId + "_" + user.id, comment);
    }
    this.setState({ comment: comment });
  };

  repairRequestRedirectHelper(id) {
    const { user, app, selected, selectedChat ,buildings } = this.props;
    
    const chatBuilding = buildings?.all?.find((building)=>building.buildingId === selectedChat?.buildingId)
    const isUserBuyer = user.type === userAccountTypeConstants.buyer;
    let url = (app === apps.huisinfo 
                 ? "/object/" + chatBuilding.buildingNoIntern 
                 : (app !== apps.aftercare 
                      ? "/werk/" + selected.projectNo + "/kwaliteitsborging"
                      : (isUserBuyer ? "/nazorg/details" : "/nazorg/melding")));
    if (id) url += `/${id}`;
    history.push(url);
  }

  handleRedirect = () => {
    const { selectedChat } = this.props;
    this.repairRequestRedirectHelper(selectedChat?.repairRequestId)
  }
  getChatSubTitle = (chat) => {
    const { user, selected, selectedChat, app } = this.props;
    if (app !== apps.huisinfo) {
      return `${selectedChat?.repairRequestNo} ${selectedChat?.chatSubject}`
    }
    if (user.type !== userAccountTypeConstants.buyer) {
      return chat?.buildingNoExtern;
    }
    return chat?.organisationName;
  };

  updateOnScroll = () => {
    const { refs } = this;
    const { messageList } = refs;
    if (messageList) {
      const scrollTop = messageList.scrollTop;
      if (scrollTop === 0 && this.state.hasHistory && !this.state.isLoadingChatItem) {
        this.UpdateChatItems(false);
      }

      const scrollHeight = messageList.scrollHeight;
      const height = messageList.clientHeight;
      const maxScrollTop = scrollHeight - height;
      const isScrollPositionBottom = Math.abs(scrollTop - maxScrollTop) <= 2;
      this.setState({ isScrollPositionBottom });
    }
  };

  onResizeMessageListBox = (width, height) => {
    const { messageList } = this.refs;
    if (messageList) {
      const { messagesContainerHeight } = this.state;
      if (messagesContainerHeight !== messageList.clientHeight) {
        const maxScrollTop = messageList.scrollHeight - messageList.clientHeight;
        if (messageList.scrollTop !== maxScrollTop) {
          const scrollTop = messageList.scrollTop + Math.ceil(messagesContainerHeight - messageList.clientHeight);
          ReactDOM.findDOMNode(messageList).scrollTop = scrollTop;
        }
      }
    }
    this.setState({ messagesContainerHeight: messageList.clientHeight });
  };

  scrollToBottom = () => {
    const { messageList } = this.refs;
    const scrollHeight = messageList.scrollHeight;
    const height = messageList.clientHeight;
    const maxScrollTop = scrollHeight - height;
    ReactDOM.findDOMNode(messageList).scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  };

  handleChatActionModalOpen = (chatMessage) => {
    const chatAction = Object.assign({}, chatMessage);
    chatAction.date = Date.now();
    this.setState({ chatAction });
  };

  handleChatActionModalClose = () => {
    this.setState({ chatAction: null });
  };

  handleModalDateChange = (date) => {
    const chatAction = Object.assign({}, this.state.chatAction);
    chatAction.date = date;
    this.setState({ chatAction });
  };

  handleModalChangeTextField = (name) => (event) => {
    const chatAction = Object.assign({}, this.state.chatAction);
    switch (name) {
      case "employeeId":
        chatAction.employeeId = event.target.value;
        break;
      case "description":
        chatAction.description = event.target.value;
        break;
      case "bericht":
        chatAction.message = event.target.value;
        break;
      default:
        return;
    }
    this.setState({ chatAction });
  };

  handleClick = (event) => {
    (this.props.app !== apps.huisinfo) && this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleAttachmentMenuClose = () => {
    this.setState({
      attachmentMenuOpen: false
    });
  };

  copyChatAttachment = (chatItemWithAttachment, clickedOn) => {
    const { t, refreshParentState, selectedChat } = this?.props || {}
    const url = webApiUrl + 'api/RepairRequest/CopyChatAttachment';
    this.setState({ copyChatAttachmentLoader: true })
    const requestOptions = {
      method: 'POST',
      headers: authHeader('application/json'),
      body: JSON.stringify({
        attachmentId: chatItemWithAttachment?.attachmentId,
        repairRequestId: selectedChat?.repairRequestId,
        ...(clickedOn === "solutionAttachments" ? { resolverId: this.props?.resolverId } : {})
      })
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(findResponse => {
        if (findResponse?.success && selectedChat?.repairRequestId) {
          refreshParentState?.()
          if (!refreshParentState) {
            this.repairRequestRedirectHelper(selectedChat?.repairRequestId)
          }
          this.setState({
            attachmentMenuOpen: false,
            copyChatAttachmentLoader: false
          });
        }
      })
      .catch(e => {
        alert(t("general.api.error"));
        console.log({ e })
        this.setState({
          attachmentMenuOpen: false,
          copyChatAttachmentLoader: false
        });
      });
  }

  handleAttachmentMenuClick = (event, chatItem) => {
    event.preventDefault();
    this.setState({
      attachmentMenuPosition: {
        x: event.clientX,
        y: event.clientY
      },
      selectedAttachmentChatItem: chatItem,
      attachmentMenuOpen: true
    })
  };

  handleDownloadFile = (currentChat) => {
    const url = URLS.GET_ATTACHMENT + encodeURI(currentChat?.attachmentId)
    downloadFile(url)
    this.setState({
      attachmentMenuOpen: false
    });
  };

  handleFileAttachmentModalClose = () => {
    this.setState({ attachmentFilesForPreview: null });
  };

  handleFileAttachmentModalOpen = (currentChat) => {
    const fileExtention = getFileExtentionFromFileName(currentChat?.message)
    if (this.unsupportedDocViewerExtentions.includes(fileExtention)) {
      return
    }
    this.setState({
      attachmentMenuOpen: false,
      attachmentFilesForPreview:
        [
          {
            uri: URLS.GET_ATTACHMENT + encodeURI(currentChat?.attachmentId),
            fileType: getMimeTypefromString(fileExtention),
          }
        ],
    });
  };

  toggleAttachmentCopySubMenu = () => {
    this.setState((prevState) => ({ attachmentCopySubMenuIsOpen: !prevState.attachmentCopySubMenuIsOpen }));
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  handleModalActionSubmit = (event) => {
    event.preventDefault();
    const chatAction = Object.assign({}, this.state.chatAction);
    const { t, selectedChat } = this.props;

    if (selectedChat && event.target.checkValidity()) {
      chatAction.submitting = true;
      this.setState({ chatAction });

      const url = webApiUrl + "api/home/AddNewAction";
      const requestOptions = {
        method: "POST",
        headers: authHeader("application/json"),
        body: JSON.stringify({
          buildingId: selectedChat.buildingId,
          employeeId: chatAction.employeeId,
          description: chatAction.description,
          descriptionExtended: chatAction.message,
          dateTime: new Date(chatAction.date).toJSON(),
        }),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((success) => {
          if (success) {
            alert(t("buyersGuide.actionAddedInfo"));
            this.handleChatActionModalClose();
          }
        })
        .catch((error) => {
          alert(t("general.api.error"));
          chatAction.submitting = false;
          this.setState({ chatAction });
        });
    } else {
      chatAction.submitted = true;
      this.setState({ chatAction });
    }
  };

  goToChatMessage = (chatId, chatMessageId) => {
    const { selectedChat, chats } = this.props
    const { chatItems } = this.state;
    if (selectedChat && selectedChat.chatId === chatId) {
      var findChatItem = chatItems.find((x) => x.chatMessageId === chatMessageId);
      if (findChatItem) {
        this.props.updateParentState({ chatBoxOpen: true });
        setTimeout(() => {
          const { messageList } = this.refs;
          var item = document.getElementById("message-" + chatMessageId);
          if (messageList && item) {
            const scrollHeight = messageList.scrollHeight;
            const height = messageList.clientHeight;
            const maxScrollTop = scrollHeight - height;
            const scrollTopValue = maxScrollTop > 0 && maxScrollTop > item.offsetTop - 50 ? item.offsetTop - 50 : maxScrollTop;
            ReactDOM.findDOMNode(messageList).scrollTop = scrollTopValue;
            this.setState({
              scrollToBottom: false,
              isScrollPositionBottom: false,
              openSearchChatMessages: false,
              focusedChatMessageId: chatMessageId,
              searchTermMessages: ""
            });
          }
        }, 500);
        return;
      }
      else {
        this.setState({ isLoadingChatItem: true });
        const url = webApiUrl + "api/chat/GetChatItemsByMessageId/" + chatMessageId;

        const requestOptions = {
          method: "GET",
          headers: authHeader(),
        };
        fetch(url, requestOptions)
          .then((Response) => Response.json())
          .then((findResponse) => {
            var chatItems = findResponse;

            var hasHistory = true;
            var hasNewer = true;
            var chatItemsCopy = this.state.chatItems.slice();
            if (chatItems.filter((x) => chatItemsCopy.find((y) => y.chatMessageId === x.chatMessageId && y.chatId === x.chatId)).length > 0) {
              hasHistory = this.state.hasHistory;
              hasNewer = this.state.hasNewer;
              for (var i = 0; i < chatItemsCopy; i++) {
                if (!chatItems.find((x) => x.chatMessageId === chatItemsCopy[i].chatMessageId)) {
                  chatItems.push(chatItemsCopy[i]);
                }
              }
            }
            chatItems.sort(function (a, b) {
              return new Date(a.dateTime) - new Date(b.dateTime);
            });

            const categoryId = this.props.user.type !== userAccountTypeConstants.buyer ? selectedChat.buildingId : selectedChat.organisationId;
            var expandedList = this.props.expandedList.slice();
            if (!expandedList.find((x) => x === categoryId)) {
              expandedList.push(categoryId);
            }
            this.props.updateParentState({ expandedList, broadcast: false, selectedHasUpdate: false });

            this.setState({
              broadcastSuccessObjectsCount: 0,
              chatItems,
              hasHistory,
              hasNewer,
              lastScrollHeight: -1,
              scrollToBottom: false,
              goToChatMessage: true,
              isScrollPositionBottom: false,
              isLoadingChatItem: false,
            });

            this.goToChatMessage(chatId, chatMessageId);
          });
      }
    }
    else {
      var selectedNew = this.props.chats.find((x) => x.chatId === chatId);
      if (selectedNew)
        this.props.updateParentState({ selected: selectedNew, chatBoxOpen: true, gotoMessage: { chatId, chatMessageId } });
    }
  }

  handleShowHideRichTextEditorToolbar = () => {
    this.setState({
      showRichTextEditorToolbar: !this.state.showRichTextEditorToolbar,
    });
  };

  handleMarkCommentImportant = () => {
    this.setState({ markCommentImportant: !this.state.markCommentImportant });
  };

  handleSelectBroadcastFiles = (data) => {
    const { t } = this.props;
    const selectedFiles = Array.from(data);
    let files = this.state.broadcastFiles ? this.state.broadcastFiles.slice() : [];
    for (var i = 0; i < selectedFiles.length; i++) {
      if (files.length >= 10) {
        alert(t("general.filesAllowedMax", { noOfFiles: 10 }));
        break;
      }
      if (validateFile({ file: selectedFiles[i] }) === true) {
        files.push(selectedFiles[i]);
      }
    }
    this.setState({ broadcastFiles: files });
  };

  handleRemoveBroadcastFile = (index) => {
    let files = this.state.broadcastFiles ? this.state.broadcastFiles.slice() : [];
    files.splice(index, 1);
    this.setState({ broadcastFiles: files });
  };


  handleChatMenu = (event) => {
    this.setState({ chatMenuAnchorEl: this.state.chatMenuAnchorEl ? null : event.currentTarget })
  };
  
  updateChatLock = () => {
    const { selectedChat } = this.props;
    const isLock = selectedChat?.isLocked;
    if (!isLock && !this.state.dialogOpen) {
      this.handleDialogOpen();
      return;
    }

    
    const url = webApiUrl + "api/Chat/UpdateChatLock/" + selectedChat?.chatId + "?isLock=" + (!isLock).toString();
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    fetch(url, requestOptions)
      .then(response => response.text())
      .then(() => {
        this.props.updateParentChats&& this.props.updateParentChats(selectedChat?.chatId, true);
        this.handleDialogClose();
      })
      .catch(error => console.error(error));
  };
  handleDialogOpen = () => {
    this.setState({ dialogOpen: true});
  };

  handleDialogClose = () => {
    this.setState({ dialogOpen: false });
  };


  render() {
    const { user, t, classes, isFullWidth, rights, theme, selectedChat, broadcast, chatBoxOpen, chatStartList, app, applyMinStyles, buildings, hideBackBtn } = this.props;
    const {
      chatItems,
      dialogOpen,
      focusedChatMessageId,
      chatItemToReplyOn,
      comment,
      markCommentImportant,
      textToInsertInComment,
      richTextToInsertInComment,
      standardTexts,
      chatAction,
      openSearchChatMessages,
      searchTermMessages,
      searchMessagesResults,
      searchMessagesWithAttachment,
      uploading,
      selectedAttachmentChatItem,
      chatParticipants,
      showRichTextEditorToolbar,
      isLoadingChatItem,
      isNewMessageSending,
      broadcasting,
      copyChatAttachmentLoader,
      isParticipantsListDrawerOpen
    } = this.state;

    const matches = themeBreakpointUp(window, theme, "md") && !applyMinStyles;
    var date, prevDate;
    const isUserBuyer = user.type === userAccountTypeConstants.buyer;
    const hasAdminRightsForChat = checkHasAdminRightsForChat({ app, buildings, buildingId: selectedChat?.buildingId, projectId: selectedChat?.projectId });
    const currentChatFileMimeType = getMimeTypefromString(getFileExtentionFromFileName(selectedAttachmentChatItem?.message))
    const isPdfOrImg = currentChatFileMimeType.includes("image/") || currentChatFileMimeType.includes("application/pdf")
    var signature = null;
    if (standardTexts && standardTexts.filter((x) => x.isSignature === true).length > 0) {
      const text = standardTexts.find((x) => x.isSignature === true).textBlock;
      if (text && text.trim() !== "") {
        signature = text;
      }
    }

    const { anchorEl, attachmentMenuOpen } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const participants = chatParticipants?.[selectedChat?.chatId]
    const actionableNotifyParticipant = participants?.filter((participant) => !(!participant?.active || participant.loginId === user.id)) || []
    const disabledNotify = app !== apps.huisinfo && actionableNotifyParticipant.length <= 0
    const isLoginUserNotPartOfChat = participants?.findIndex((p) => p?.loginId === user?.id && p?.active) === -1;
    const isReadOnlyChat = selectedChat?.archived || selectedChat?.isLocked
    const disabledChat = isReadOnlyChat || user.viewOnly === true || (app === apps.huisinfo && !rights["chat.details.write"]) || isLoginUserNotPartOfChat;


    //lock icon props

    const lockIconProps = { tooltip: "", disabled: true }

    if (hasAdminRightsForChat && !selectedChat?.archived) {
      lockIconProps.tooltip = selectedChat?.isLocked ? t("repairRequest.chat.clickToUnLock") : t("repairRequest.chat.clickToLock")
      lockIconProps.disabled = false
    } else {
      lockIconProps.tooltip = selectedChat?.isLocked ? t("survey.filter.locked") : t("survey.unLock")
    }



    return (<>
      <Slide direction="left" appear={!matches} in={chatBoxOpen || matches} mountOnEnter unmountOnExit>
        <Grid
          container
          direction="column"
          className={applyMinStyles ? clsx(classes.chatBoxContainer, 'mini') : classes.chatBoxContainer}
          sx={{
            justifyContent: "center"
          }}
          size={{
            xs: 12,
            md: applyMinStyles ? 12 : 8,
            lg: applyMinStyles ? 12 : (isFullWidth ? 9 : 8)
          }}>
          <AppBar position="static" color="inherit" className={classes.appBar} sx={{ background: chatColor[selectedChat?.color]?.main }}>
            <Toolbar variant="dense">
              {broadcast || !!this.state.broadcastSuccessObjectsCount ? (
                <>
                  {
                    <Hidden mdUp={!applyMinStyles}>
                      <IconButton edge="start" aria-label="GoBack" color="inherit" onClick={this.closeChatBox}>
                        <ArrowBack />
                      </IconButton>
                    </Hidden>
                  }
                  <div className={clsx(classes.grow, classes.chatBoxTitle)}>
                    <Typography className={classes.bold} noWrap>
                      {t("chat.broadcast.title")}
                    </Typography>
                  </div>
                </>
              ) : (
                selectedChat && (
                  <React.Fragment>
                    {
                      !hideBackBtn && <Hidden mdUp={!applyMinStyles}>
                        <IconButton edge="start" aria-label="GoBack" color="inherit" onClick={this.closeChatBox}>
                          <ArrowBack />
                        </IconButton>
                      </Hidden>
                    }
                    <div className={clsx(classes.grow, classes.chatBoxTitle)}>
                      {(isUserBuyer && app === apps.huisinfo) || app === apps.resolverModule ? (
                        <Typography className={classes.bold} noWrap>
                          {this.getChatSubTitle(selectedChat)}
                        </Typography>
                      ) : (
                        <Button className={classes.appBarButton} variant="text" size="small" color="inherit"
                          onClick={() =>
                            app !== app.huisinfo
                              ? this.handleRedirect()
                              : goToBuildingOverviewPage(selectedChat.buildingId, this.props.buildings.all, this.props.dispatch, history)
                          }
                        >
                          <Typography className={classes.bold} noWrap>
                            {this.getChatSubTitle(selectedChat)}
                          </Typography>
                        </Button>
                      )}
                      <Typography component="p" variant="caption" className={classes.grow} noWrap>
                        {app !== apps.huisinfo ? chatParticipants[selectedChat.chatId]?.filter(x => !!x.active).map((x) => " " + x.name)
                          ?.join()
                          ?.trim() : selectedChat.buildingNoExtern +
                          ", " +
                          selectedChat.organisationName +
                        (chatParticipants[selectedChat.chatId]
                          ? " (" +
                          chatParticipants[selectedChat.chatId]
                            .map((x) => " " + x.name)
                            .join()
                            .trim() +
                          ")"
                          : ""
                        )
                        }
                      </Typography>
                    </div>



                    {!matches && <>
                      {
                        app !== apps.huisinfo && selectedChat?.isLocked &&
                        <Tooltip title={lockIconProps.tooltip}>
                          <IconButton onClick={this.updateChatLock} disabled={lockIconProps.disabled}><Lock /></IconButton>
                        </Tooltip>
                      }
                      <IconButton
                        onClick={this.handleChatMenu}
                        edge="end"
                      >
                        <MoreVertSharp />
                      </IconButton>

                      <Popover
                        id={Boolean(this.state.chatMenuAnchorEl) ? "three-dot-anchorEl" : undefined}
                        open={Boolean(this.state.chatMenuAnchorEl)}
                        anchorEl={this.state.chatMenuAnchorEl}
                        onClose={this.handleChatMenu}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <List dense>
                          <ListItem disablePadding>
                            <ListItemButton onClick={this.toggleOpenSearchChatMessages}>
                              <ListItemIcon sx={{ minWidth: 40 }}>
                                <Search />
                              </ListItemIcon>
                              <ListItemText primary={t("general.search")} />
                            </ListItemButton>
                           </ListItem>
                          <ListItem disablePadding>
                            <ListItemButton onClick={this.toggleParticipantListDrawer}>
                              <ListItemIcon sx={{ minWidth: 40 }}>
                                <Badge className={classes.groupBadge} max={9} badgeContent={participants?.filter(participant => !!participant?.active)?.length} >
                                  <Group color="action" />
                                </Badge>
                              </ListItemIcon>
                              <ListItemText primary={t("chat.add.chatParticipants")} />
                            </ListItemButton>
                           </ListItem>
                          {
                            app !== apps.huisinfo &&
                            <ListItem disablePadding>
                              <ListItemButton onClick={this.updateChatLock} disabled={lockIconProps.disabled}>
                                <ListItemIcon sx={{ minWidth: 40 }}>
                                  {selectedChat?.isLocked ? <Lock /> : <LockOpen />}
                                </ListItemIcon>
                                <ListItemText primary={selectedChat?.isLocked ? t("chat.unLockChat") : t("chat.lockChat")} />
                              </ListItemButton>
                             </ListItem>
                          }
                        </List>
                      </Popover></>}

                    {matches && <>
                      {
                        app !== apps.huisinfo &&
                        <Tooltip title={lockIconProps.tooltip}>
                          <IconButton
                            onClick={this.updateChatLock} disabled={lockIconProps.disabled}

                          > {selectedChat?.isLocked ? <Lock /> : <LockOpen />} </IconButton>
                        </Tooltip>
                      }
                      <IconButton onClick={this.toggleParticipantListDrawer}>
                        <Badge className={classes.groupBadge} max={9} badgeContent={participants?.filter(participant => !!participant?.active)?.length} >
                          <Group color="action" />
                        </Badge>
                      </IconButton>
                      <IconButton edge="end" aria-label="Zoek" color="inherit" onClick={this.toggleOpenSearchChatMessages}>
                        <Search />
                      </IconButton>
                    </>}
                  </React.Fragment>
                )
              )}
            </Toolbar>

          </AppBar>
          <Grid container direction="column" className={applyMinStyles ? clsx(classes.chatBox, 'mini') : classes.chatBox} ref="messageList" onScroll={this.updateOnScroll} sx={{
            alignItems: "center"
          }}>
            <ReactResizeDetector handleWidth handleHeight onResize={this.onResizeMessageListBox}>
              {broadcast ? (
                <Grid className={classes.chatItemsWrapper} container spacing={2} sx={{
                  alignContent: "flex-start"
                }}>
                  <Grid size={{xs:12}}>
                    <FormControl fullWidth error={this.state.broadcastErrors}>
                      <InputLabel shrink="true" htmlFor="building-ids">
                        {t("general.objects.select")}
                      </InputLabel>
                      <Input
                        id="building-ids"
                        name="buildingIds"
                        value={t("general.objectsSelected", { count: this.state.broadcastObjects ? this.state.broadcastObjects.length : 0 })}
                        className={classes.broadcastInputField}
                        onClick={() => this.setState({ objectsModal: true })}
                        inputProps={{ spellCheck: "false" }}
                        endAdornment={
                          <InputAdornment position="end">
                            <ExpandMore className={classes.inputFieldExpandIcon} />
                          </InputAdornment>
                        }
                      />
                      <FormHelperText error>{this.state.broadcastErrors}</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid size={{xs:12}}>
                    <div className={classes.dropzoneContainer}>
                      <Dropzone onFilesAdded={this.handleSelectBroadcastFiles} disabled={!!this.state.broadcastFiles && this.state.broadcastFiles.length >= 10} accept={fileUploadConfig.allowedMimeTypes.map((f) => f.mime).join()} />
                    </div>
                    <List dense className={classes.broadcastFilesList}>
                      {this.state.broadcastFiles &&
                        this.state.broadcastFiles.map((file, index) => {
                          return (
                            <ListItem key={index}>
                              <ListItemAvatar>
                                <Avatar>
                                  <AttachFile />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={file.name} secondary={formatFileSize(file.size)} />
                              <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete" onClick={() => this.handleRemoveBroadcastFile(index)}>
                                  <Clear />
                                </IconButton>
                              </ListItemSecondaryAction>
                             </ListItem>
                          )
                        })}
                    </List>
                  </Grid>
                </Grid>
              ) : selectedChat ? (
                <Grid className={classes.chatItemsWrapper} container spacing={2} sx={{
                  alignContent: "flex-start"
                }}>
                  {!isLoadingChatItem && (chatItems.length > 0 ? (
                    chatItems.map((chatItem, index) => {
                      date = new Date(chatItem.dateTime)
                      const dateText = formatDate(date)
                      const timeText = formatTime(date)
                      date.setHours(0, 0, 0, 0)
                      const showDate = index === 0 || date - prevDate !== 0
                      prevDate = date
                      const isSender = chatItem.senderLoginId === user.id
                      const isImportant = this.isChatMessageImportant(chatItem)
                      const isFocused = focusedChatMessageId === chatItem.chatMessageId
                      const isSystemMessage = chatItem.senderLoginId == null
                      const sameAsPrevSender = index > 0 && !showDate && chatItems[index - 1].senderLoginId === chatItem.senderLoginId

                      const userInitials = getNameInitials(chatItem.senderName)

                      let classesForCard = [classes.chatCard]
                      if (chatItem.important === true) {
                        classesForCard.push(classes.important)
                      }
                      if (isSystemMessage === true) {
                        classesForCard.push("system")
                      }
                      if (isFocused === true) {
                        classesForCard.push("focused")
                      } else if (isSender === true) {
                        classesForCard.push("sentbyme")
                      }

                      const justifyAlignment = isSystemMessage ? "center" : isSender ? "flex-end" : "flex-start"

                      return (
                        (<React.Fragment key={chatItem.chatMessageId}>
                          {showDate && (
                            <Grid
                              container
                              spacing={1}
                              sx={{
                                justifyContent: "center"
                              }}
                              size={{xs:12}}>
                              <Chip label={dateText} className={classes.chip} size="small" />
                            </Grid>
                          )}
                          <Grid
                            id={"message-" + chatItem.chatMessageId}
                            container
                            size={{xs:12}}
                            className={sameAsPrevSender ? classes.chatCardContainer : ""}
                           p={1}
                            sx={{
                              justifyContent: justifyAlignment,
                            }}>
                            {/**
                                                                          !isSender &&
                                                                          <Grid item>
                                                                              <Avatar>U{chatItem.senderId}</Avatar>
                                                                          </Grid>
                                                                          **/}
                            <Grid
                              container
                              sx={{
                                justifyContent: justifyAlignment
                              }}
                              size={{
                                xs: 10,
                                md: 9
                              }}>
                              <Grid style={{ maxWidth: "100%" }}>
                                {!isSender && !sameAsPrevSender && !isSystemMessage && <div className={applyMinStyles ? clsx(classes.chatItemAvatar, 'mini') : classes.chatItemAvatar}>{this.getUserAvatar(chatItem.chatId, chatItem.senderLoginId, userInitials)}</div>}
                                {isSystemMessage === true && <Info className={classes.chatCardInfoIcon} />}
                                {chatItem.important === true && <Error className={applyMinStyles ? clsx(classes.chatCardImportantIcon, 'mini') : classes.chatCardImportantIcon} color="secondary" />}
                                <Card tabIndex={0} className={clsx(classesForCard)}>
                                  <CardContent>
                                    {chatItem.deleted !== true && (
                                      <div className="chatButtonsContainer">
                                        <IconButton aria-label="Reply" disabled={disabledChat} size="small" onClick={() => this.handleReplyChatMessage(chatItem)}>
                                          <Reply fontSize="inherit" />
                                        </IconButton>
                                        {isUserBuyer !== true && app === apps.huisinfo && (
                                          <IconButton aria-label="Alert" disabled={disabledChat} size="small" onClick={() => this.handleChatActionModalOpen(chatItem)}>
                                            <AssignmentOutlined fontSize="inherit" />
                                          </IconButton>
                                        )}
                                        <IconButton aria-label="Alert" disabled={disabledChat} size="small" onClick={() => this.handleMarkUnmarkImportantChatMessage(chatItem, !isImportant)}>
                                          {isImportant ? <Bookmark color="primary" fontSize="inherit" /> : <BookmarkBorder fontSize="inherit" />}
                                        </IconButton>
                                        {isUserBuyer !== true && isSystemMessage !== true && hasAdminRightsForChat && (
                                          <IconButton aria-label="Alert" disabled={disabledChat} size="small" onClick={() => this.handleDeleteChatMessage(chatItem)}>
                                            <DeleteOutline fontSize="inherit" />
                                          </IconButton>
                                        )}
                                        {(chatItem.isFile || chatItem.isImage) &&
                                          <IconButton size="small" onClick={(event) => this.handleAttachmentMenuClick(event, chatItem)} >
                                            <MoreVert fontSize="inherit" />
                                          </IconButton>
                                        }
                                      </div>
                                    )}
                                    {!isSender && !sameAsPrevSender && (
                                      <Typography
                                        variant="caption"
                                        style={{
                                          color: this.getUserColor(chatItem.chatId, chatItem.senderLoginId),
                                          //marginRight: isUserBuyer === true ? 40 : 80
                                        }}
                                      >
                                        {chatItem.senderName}
                                      </Typography>
                                    )}
                                    {chatItem.important === true && (
                                      <Typography variant="subtitle2" className={classes.bold} sx={{
                                        color: "secondary.main"
                                      }}>
                                        {t("chat.messages.important")}
                                      </Typography>
                                    )}
                                    {chatItem.replyToChatMessageId && (
                                      <Card
                                        className={classes.quoteMessageCard}
                                        style={{
                                          borderLeftColor: this.getUserColorByParticipantId(chatItem.chatId, chatItem.replyToChatMessageSenderChatParticipantId),
                                        }}
                                      >
                                        <CardActionArea onClick={() => this.goToChatMessage(chatItem.chatId, chatItem.replyToChatMessageId)}>
                                          <CardContent>
                                            <Typography
                                              variant="caption"
                                              style={{
                                                color: this.getUserColorByParticipantId(chatItem.chatId, chatItem.replyToChatMessageSenderChatParticipantId),
                                              }}
                                            >
                                              {chatItem.replyToChatMessageSenderChatParticipantId === null ? (
                                                <React.Fragment>
                                                  <Info
                                                    style={{
                                                      marginLeft: -4,
                                                    }}
                                                  />{" "}
                                                  {t("chat.messages.infoMessage")}
                                                </React.Fragment>
                                              ) : (
                                                this.getNameByParticipantId(chatItem.chatId, chatItem.replyToChatMessageSenderChatParticipantId)
                                              )}
                                            </Typography>
                                            {chatItem.replyToChatMessageDeleted === true ? (
                                              <Typography variant="caption" component="p" sx={{
                                                color: "error.main"
                                              }}>
                                                {t("chat.messages.messageHasBeenRemoved")}
                                              </Typography>
                                            ) : (
                                              <Typography
                                                variant="body2"
                                                component="div"
                                                style={{
                                                  maxHeight: 60,
                                                  overflow: "hidden",
                                                }}
                                              >
                                                {chatItem.replyToChatMessageIsFile ? (
                                                  chatItem.replyToChatMessageIsImage === true ? (
                                                    <React.Fragment>
                                                      <PhotoCamera />
                                                      {t("chat.messages.photo")}
                                                    </React.Fragment>
                                                  ) : (
                                                    <React.Fragment>
                                                      <InsertDriveFile />
                                                      {chatItem.replyToChatMessageMessage}
                                                    </React.Fragment>
                                                  )
                                                ) : (
                                                  <Markdown value={chatItem.replyToChatMessageMessage} />
                                                )}
                                              </Typography>
                                            )}
                                          </CardContent>
                                          {chatItem.replyToChatMessageIsImage === true &&
                                            <CardMedia title={chatItem.replyToChatMessageMessage} image={`${URLS.GET_ATTACHMENT_THUMBNAIL}${chatItem?.attachmentId}`} />
                                          }
                                        </CardActionArea>
                                      </Card>
                                    )}
                                    {chatItem.deleted === true ? (
                                      <Typography variant="caption" component="p" sx={{
                                        color: "error.main"
                                      }}>
                                        {t("chat.messages.messageDeletedBy") + " " + chatItem.deletedByParticipant}
                                      </Typography>
                                    ) : chatItem.isFile ? (
                                      chatItem.isImage ? (
                                        <CardMedia
                                          component="img"
                                          onClick={() => this.handleFileAttachmentModalOpen(chatItem)}
                                          className={classes.chatMedia}
                                          title={chatItem.message}
                                          image={`${URLS.GET_ATTACHMENT_THUMBNAIL}${chatItem?.attachmentId}`}
                                        />
                                      ) : (
                                        <>
                                          <CardMedia
                                            component="img"
                                            onClick={() => this.handleFileAttachmentModalOpen(chatItem)}
                                            className={classes.chatMedia}
                                            title={chatItem.message}
                                            image={`${URLS.GET_ATTACHMENT_THUMBNAIL}${chatItem?.attachmentId}`}
                                          />
                                          <Typography sx={{
                                            mt: 1
                                          }}>{chatItem.message}</Typography>
                                        </>
                                      )
                                    ) : (
                                      <Typography variant="body2" component="div">
                                        <Markdown value={chatItem.message} />
                                      </Typography>
                                    )}
                                    <Typography variant="caption" component="p" align="right" sx={{
                                      color: "text.secondary"
                                    }}>
                                      {timeText}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Grid>
                            </Grid>
                          </Grid>
                        </React.Fragment>)
                      );
                    })
                  ) : (
                    <Grid container spacing={1} size={{xs:12}}>
                      <Typography component="p" align="center">
                        {selectedChat ? t("chat.messages.noMessagesYet") : ""}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid
                  container
                  direction="column"
                  flex={1}
                  sx={{
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                  size={{xs:12}}>
                  <Grid>
                    <img src={webApiUrl + "api/Config/WebLogo"} width="300" alt="JPDS" />
                  </Grid>
                  <Grid>
                    <Typography variant="h4" align="center" sx={{
                      color: "text.secondary"
                    }}>
                      {!this.state.broadcastSuccessObjectsCount
                        ? t("chat.messages")
                        : t("chat.broadcast.success", {
                          noOfObjects: this.state.broadcastSuccessObjectsCount,
                        })}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </ReactResizeDetector>
          </Grid>
          {!isLoadingChatItem && (
            <Grid container className={applyMinStyles ? clsx(classes.chatActions, 'mini') : classes.chatActions} ref="commentBox">
              {(selectedChat || broadcast) && (
                <React.Fragment>
                  <Grid
                    container
                    className={markCommentImportant ? clsx(classes.chatCommentBoxContainer, classes.important, applyMinStyles ? "mini" : "") : (applyMinStyles ? clsx(classes.chatCommentBoxContainer, 'mini') : classes.chatCommentBoxContainer)}
                    size={{xs:12}}>
                    {markCommentImportant === true && (
                      <Typography variant="subtitle2" className={classes.commentBoxImportantHeader} sx={{
                        color: "secondary.main"
                      }}>
                        {t("chat.messages.important")}
                      </Typography>
                    )}
                    {chatItemToReplyOn && (
                      <Grid
                        container
                        direction="row"
                        sx={{
                          alignItems: "center",
                          justifyContent: "center"
                        }}
                        size={{xs:12}}>
                        <Card
                          className={classes.replyMessageCard}
                          style={{
                            borderLeftColor: this.getUserColor(chatItemToReplyOn.chatId, chatItemToReplyOn.senderLoginId),
                          }}
                        >
                          <CardContent>
                            <Typography
                              variant="caption"
                              style={{
                                color: this.getUserColor(chatItemToReplyOn.chatId, chatItemToReplyOn.senderLoginId),
                              }}
                            >
                              {chatItemToReplyOn.senderChatParticipantId === null ? (
                                <React.Fragment>
                                  <Info style={{ marginLeft: -4 }} /> {t("chat.messages.infoMessage")}
                                </React.Fragment>
                              ) : (
                                this.getNameByParticipantId(chatItemToReplyOn.chatId, chatItemToReplyOn.senderChatParticipantId)
                              )}
                            </Typography>
                            {chatItemToReplyOn.deleted === true ? (
                              <Typography variant="caption" component="p" color="error">
                                {t("chat.messages.messageDeletedBy") + " " + chatItemToReplyOn.deletedByParticipant}
                              </Typography>
                            ) : (
                              <Typography
                                variant="body2"
                                component="div"
                                sx={{
                                  maxHeight: chatItemToReplyOn?.attachmentId && (chatItemToReplyOn.isFile || chatItemToReplyOn.isImage) ? 64 : 40,
                                  overflow: "hidden"
                                }}>
                                {chatItemToReplyOn.isFile ? (
                                  <Stack direction="row" spacing={1} sx={{
                                    alignItems: "center"
                                  }}>
                                    <Box
                                      component="img"
                                      src={`${URLS.GET_ATTACHMENT_THUMBNAIL}${chatItemToReplyOn?.attachmentId}`}
                                      sx={{
                                        width: { xs: 50, md: 64 },
                                        height: { xs: 50, md: 64 },
                                        backgroundSize: "cover"
                                      }} />
                                    <Typography sx={{
                                      fontSize: { xs: 14, sm: 16 }
                                    }} >{chatItemToReplyOn.isImage === true ? t("chat.messages.photo") : chatItemToReplyOn.message}</Typography>
                                  </Stack>
                                ) : (
                                  <Markdown value={chatItemToReplyOn.message} />
                                )}
                              </Typography>
                            )}
                          </CardContent>
                        </Card>
                        <Grid>
                          <IconButton aria-label="Reply" onClick={this.handleCloseReply}>
                            <Close />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )}
                    <Grid size={{xs:12}}>
                      <RichTextEditor
                        label={`${t("general.message")}...`}
                        showToolbar={showRichTextEditorToolbar}
                        onChange={this.handleChangeComment}
                        textToInsertAtCursor={textToInsertInComment}
                        richTextToInsertAtCursor={richTextToInsertInComment}
                        onCompleteTextInsert={() =>
                          this.setState({
                            textToInsertInComment: "",
                            richTextToInsertInComment: "",
                          })
                        }
                        value={comment}
                        readOnly={disabledChat || isNewMessageSending || broadcasting}
                        applyMinStyles={applyMinStyles}
                        autocomplete={{
                          strategies: standardTexts
                            ? [
                              {
                                items:
                                  standardTexts &&
                                  standardTexts.map((text) => ({
                                    keys: [text.hashtag],
                                    value: text.textBlock,
                                    content: <Markdown value={text.textBlock} />,
                                  })),
                                triggerChar: "#",
                                atomicBlockName: "markdown",
                              },
                            ]
                            : [],
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={classes.chatActionBoxContainer}
                    direction="row"
                    sx={{
                      alignItems: "flex-start",
                      justifyContent: "flex-start"
                    }}
                    size={{xs:12}}>
                    <Grid>
                      <IconButton
                        size="small"
                        aria-label="Chat"
                        disabled={disabledChat}
                        color={showRichTextEditorToolbar === true ? "primary" : "default"}
                        onClick={this.handleShowHideRichTextEditorToolbar}
                      >
                        <TextFormat />
                      </IconButton>
                    </Grid>
                    {!isUserBuyer && hasAdminRightsForChat && (
                      <React.Fragment>
                        <Grid>
                          <IconButton
                            size="small"
                            aria-label="Important"
                            disabled={disabledChat}
                            color={markCommentImportant === true ? "secondary" : "default"}
                            onClick={this.handleMarkCommentImportant}
                          >
                            <PriorityHigh />
                          </IconButton>
                        </Grid>
                        <Grid>
                          <StandardTextManager
                            size="small"
                            disabled={disabledChat}
                            projectId={selectedChat?.projectId}
                            onSelect={(value) =>
                              this.setState({
                                richTextToInsertInComment: value,
                              })
                            }
                            onChange={(texts) => this.setState({ standardTexts: texts })}
                          />
                        </Grid>
                        {signature && (
                          <Grid>
                            <Tooltip title={t("general.signature")}>
                              <IconButton
                                size="small"
                                aria-label="Signature"
                                disabled={disabledChat}
                                onClick={() =>
                                  this.setState({
                                    richTextToInsertInComment: signature,
                                  })
                                }
                              >
                                <Icon className="fas fa-signature" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        )}
                      </React.Fragment>
                    )}
                    {!broadcast && (
                      <Grid>
                        <React.Fragment>
                          <input accept="*" style={{ display: "none" }} disabled={disabledChat || uploading} id="icon-button-file" type="file" onChange={this.uploadAttachment} />
                          <label htmlFor={`${!this.props.selected || disabledChat ? "" : "icon-button-file"}`} style={{ margin: 0 }}>
                            {uploading ? (
                              <CircularProgress size={24} />
                            ) : (
                              <IconButton size="small" color="inherit" aria-label="uploads" component="span" disabled={!this.props.selected || disabledChat}>
                                <AttachFile />
                              </IconButton>
                            )}
                          </label>
                        </React.Fragment>
                      </Grid>
                    )}
                    <Grid>
                      <EmojiSelector size="small" disabled={disabledChat} onSelect={(value) => this.setState({ textToInsertInComment: value })} />
                    </Grid>
                    <Grid className={classes.grow}></Grid>
                    {!isUserBuyer && hasAdminRightsForChat && (
                      <Grid
                        sx={{
                          position: "relative"
                        }}>
                        {actionableNotifyParticipant?.length > 0 && <Popover
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={this.handleClose}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                        >
                          <Stack
                            direction="column"
                            spacing={1}
                            sx={{
                              borderRadius: 2,
                              p: 1,
                              pr: 2
                            }}>
                            {actionableNotifyParticipant.map((participant) => <Stack direction="row" spacing={2} sx={{
                              alignItems: "center"
                            }}>
                              <Checkbox onChange={() => {
                                const notifyUserIds = this.state.notifyUserIds
                                const filteredNotifyUserIds = notifyUserIds.filter((id) => id !== participant.chatParticipantId)
                                this.setState({ notifyUserIds: filteredNotifyUserIds.length === notifyUserIds.length ? [...notifyUserIds, participant.chatParticipantId] : filteredNotifyUserIds })
                              }} />
                              <Typography>{participant?.name}</Typography>
                            </Stack>
                            )}
                          </Stack>
                        </Popover>}
                        <Tooltip title={t("general.notifyImmediately")}>
                          <Badge
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            badgeContent={<Email fontSize="inherit" color={disabledChat || disabledNotify ? "disabled" : "primary"} />}
                            overlap="circular"
                          >
                            <Checkbox
                              disabled={disabledChat || disabledNotify}
                              size="small"
                              className={classes.checkBoxNotify}
                              color="primary"
                              checked={this.state.notifyImmediately || this.state.notifyUserIds.length > 0}
                              aria-describedby={id}
                              variant="contained"
                              onClick={this.handleClick}
                              onChange={() =>
                                (app === apps.huisinfo) && this.setState({
                                  notifyImmediately: !this.state.notifyImmediately,
                                })
                              }
                              inputProps={{
                                "aria-label": "primary checkbox",
                              }}
                            />
                          </Badge>
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid>
                      <IconButton disabled={disabledChat || isNewMessageSending || broadcasting} edge="end" size="small" aria-label="Bericht" onClick={throttle(!broadcast ? this.sendNewChatMessage : this.handleBroadcast)}>
                        <Send />
                      </IconButton>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          )}
          {(isLoadingChatItem || !!this.state.broadcasting) && (
            <div className={classes.chatsLoading}>
              <CircularProgress size={24} />
            </div>
          )}
        </Grid>
      </Slide>
      <Slide direction="left" in={openSearchChatMessages} mountOnEnter unmountOnExit>
        <Grid
          container
          direction="column"
          className={classes.slideLeft}
          size={{
            xs: 12,
            md: applyMinStyles ? 12 : 4,
            lg: applyMinStyles ? 12 : (isFullWidth ? 3 : 4)
          }}>
          <AppBar position="static">
            <Toolbar variant="dense">
              <Input
                color="primary"
                className={classes.inputBoxSearch}
                type="search"
                value={searchTermMessages}
                onChange={this.handleChangeSearchMessages}
                endAdornment={
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                }
              />
              <ToggleButton style={{ color: "inherit", borderColor: "transparent" }} size="small" value="attachment" selected={searchMessagesWithAttachment} onChange={this.toggleSearchMessagesWithAttachment} aria-label="attachments">
                <AttachFile />
              </ToggleButton>
              <IconButton aria-label="Close" color="inherit" onClick={this.clearAndCloseSearchMessages}>
                <Close />
              </IconButton>
            </Toolbar>
          </AppBar>

          <List className={classes.searchMessagesList} ref="searchMessagesList" onScroll={this.updateSearchMessagesResultsOnScroll}>
            {searchMessagesResults && searchMessagesResults.length > 0 ? (
              searchMessagesResults.map((message, indexMessage) => (
                <React.Fragment key={indexMessage}>
                  <ListItemButton
                    className={classes.searchMessagesListItem}
                    onClick={() => this.goToChatMessage(message.chatId, message.chatMessageId)}>
                    <ListItemText
                      primary={
                        <Typography variant="caption" sx={{
                          color: "text.secondary"
                        }}>
                          {getDateText(new Date(message.dateTime))}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" noWrap>
                          {message.isFile && <AttachFile fontSize="small" style={{ marginLeft: -5 }} />}
                          {message.isSender !== true && (
                            <React.Fragment>
                              {message.senderName === null ? (
                                <React.Fragment>
                                  <InfoOutlined style={{ marginTop: "-3px" }} fontSize="small" />
                                  &nbsp;
                                </React.Fragment>
                              ) : (
                                <React.Fragment>{message.senderName}:&nbsp;</React.Fragment>
                              )}
                            </React.Fragment>
                          )}
                          {md2plaintext(message.message)}
                        </Typography>
                      }
                    />
                   </ListItemButton>
                  <Divider component="li" />
                </React.Fragment>
              ))
            ) : (
              <ListItem>
                <ListItemText secondary={t("chat.messages.noMessages")} />
               </ListItem>
            )}
          </List>
        </Grid>
      </Slide>
      <ParticipantsListDrawer chatSubTitle={this.getChatSubTitle(selectedChat)} isParticipantsListDrawerOpen={isParticipantsListDrawerOpen} applyMinStyles={applyMinStyles} isFullWidth={isFullWidth} toggleParticipantListDrawer={this.toggleParticipantListDrawer} participants={participants} getUserAvatar={this.getUserAvatar} selectedChat={selectedChat} isReadOnlyChat={disabledChat} updateChatParticipants={this.UpdateChatParticipants} />
      <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" className={classes.modal} open={!!chatAction} onClose={this.handleChatActionModalClose} closeAfterTransition>
        <Fade in={!!chatAction}>
          <Card className={classes.modalCard}>
            <CardHeader id="transition-modal-title" title={<Typography variant="h6">{t("buyersGuide.addAction.label")}</Typography>} className={classes.modalCardHeader} />
            <CardContent id="transition-modal-description">
              {chatAction && (
                <form noValidate onSubmit={this.handleModalActionSubmit} disabled={chatAction.submitting}>
                  <Grid container spacing={1} sx={{
                    justifyContent: "space-around"
                  }}>
                    <Grid size={{xs:12}}>
                      <MobileDateTimePicker
                        id="date-time-picker"
                        label={t("general.startDateTime")}
                        format="dd/MM/yyyy HH:mm"
                        value={chatAction.date}
                        onChange={(date) => this.handleModalDateChange(date)}
                        inputVariant="outlined"
                        autoOk
                        ampm={false}
                        className={classes.fullWidth}
                        required
                        error={chatAction.submitted && !chatAction.date}
                        disabled={chatAction.submitting}
                        slotProps={{
                          textField: {
                            size: "small",
                          }
                        }}
                      />
                    </Grid>
                    <Grid size={{xs:12}}>
                      <TextField
                        id="outlined-behandelaar"
                        select
                        label="Select"
                        value={!chatAction.employeeId ? "" : chatAction.employeeId}
                        onChange={this.handleModalChangeTextField("employeeId")}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        required
                        error={chatAction.submitted && !chatAction.employeeId}
                        disabled={chatAction.submitting}
                        size="small"
                        slotProps={{
                          select: {
                            MenuProps: {
                              className: classes.menu,
                            },
                          }
                        }}
                      >
                        <MenuItem value="">
                          <em>-</em>
                        </MenuItem>
                        {this.state.employees &&
                          this.state.employees.length > 0 &&
                          this.state.employees.map((employee, index) => (
                            <MenuItem key={index} value={employee.id}>
                              {employee.name}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid size={{xs:12}}>
                      <TextField
                        label={t("general.subject")}
                        value={chatAction.description}
                        onChange={this.handleModalChangeTextField("description")}
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        required
                        error={chatAction.submitted && !chatAction.description}
                        disabled={chatAction.submitting}
                        size="small"
                      />
                    </Grid>
                    <Grid size={{xs:12}}>
                      <TextField
                        label={t("buyersGuide.detailDescription.label")}
                        value={chatAction.message}
                        onChange={this.handleModalChangeTextField("bericht")}
                        margin="dense"
                        variant="outlined"
                        multiline
                        fullWidth
                        required
                        error={chatAction.submitted && !chatAction.message}
                        disabled={chatAction.submitting}
                        size="small"
                      />
                    </Grid>
                    <Grid
                      container
                      sx={{
                        justifyContent: "flex-end"
                      }}
                      size={{xs:12}}>
                      <Button type="submit" color="primary" variant="outlined" disabled={chatAction.submitting}>
                        {t("general.add")}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </CardContent>
          </Card>
        </Fade>
      </Modal>
      <SelectMultipleObjectsModal
        open={!!this.state.objectsModal}
        buildings={
          this.props.buildings
            ? this.props.buildings.all
              .filter((x) => x.projectId === this.props.selected.projectId)
              .map((x) => {
                return {
                  ...x,
                  disableSelect: !chatStartList.filter((y) => y.buildingId === x.buildingId).length,
                }
              })
            : []
        }
        selectedObjects={this.state.broadcastObjects || []}
        immediateUpdate
        onChange={(objects) => this.setState({ broadcastObjects: objects })}
        onClose={() => this.setState({ objectsModal: false })}
      />
      <DocumentViewer open={!!this.state.attachmentFilesForPreview} onClose={this.handleFileAttachmentModalClose} documents={this.state.attachmentFilesForPreview} />
      <Menu
        id="attchment-menu"
        open={attachmentMenuOpen}
        onClose={this.handleAttachmentMenuClose}
        anchorOrigin={{
          vertical: this.state.attachmentMenuPosition?.y,
          horizontal: this.state.attachmentMenuPosition?.x,
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "background.paper"
          }}>
          {copyChatAttachmentLoader ? <Stack
            direction="row"
            sx={{
              minWidth: 236,
              bgcolor: "background.paper",
              justifyContent: "center",
              p: 1
            }}><CircularProgress size={25} /></Stack> :
            <><MenuItem onClick={() => { this.handleDownloadFile(selectedAttachmentChatItem) }}>
              <ListItemIcon>
                <Download />
              </ListItemIcon>
              <ListItemText primary={t("general.download")} />
            </MenuItem>

              {!this.unsupportedDocViewerExtentions.includes(getFileExtentionFromFileName(selectedAttachmentChatItem?.message)) && <MenuItem onClick={() => { this.handleFileAttachmentModalOpen(selectedAttachmentChatItem) }}>
                <ListItemIcon>
                  <Visibility />
                </ListItemIcon>
                <ListItemText primary={t("general.preview")} />
              </MenuItem>}
              {((selectedChat?.repairRequestId) && isPdfOrImg && !disabledChat && isUserBuyer !== true && hasAdminRightsForChat && app !== apps.huisinfo) && (
                <MenuItem onClick={this.toggleAttachmentCopySubMenu}>
                  <ListItemIcon  >
                    <ContentCopy />
                  </ListItemIcon>
                  <ListItemText primary={t("repairRequest.copyFile")} />
                  {this.state.attachmentCopySubMenuIsOpen ? <ExpandLess /> : <ExpandMore />}
                </MenuItem>)}
              <Collapse in={this.state.attachmentCopySubMenuIsOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {selectedChat?.repairRequestId && (
                    <MenuItem sx={{ pl: 7 }} onClick={() => { this.copyChatAttachment(selectedAttachmentChatItem, "repairRequestAttachment") }}>
                      <ListItemText primary={t("repairRequests.repairRequestAttachment")} />
                    </MenuItem>)
                  }
                  {(selectedChat?.repairRequestId && this.props?.resolverId) && (
                    <MenuItem sx={{ pl: 7 }} onClick={() => { this.copyChatAttachment(selectedAttachmentChatItem, "solutionAttachments") }}>
                      <ListItemText primary={t("repairRequest.solutionAttachments")} />
                    </MenuItem>
                  )
                  }
                </List>
              </Collapse>
            </>}
        </Box>
      </Menu>
      <Dialog open={dialogOpen} onClose={this.handleDialogClose}>
        <DialogTitle>
          <Stack direction={'row'} sx={{
            alignItems: 'center'
          }} >
            <Warning color="warning" sx={{ mr: 1 }} />
            {t("chat.lock.dialog.title")}
          </Stack>
        </DialogTitle>
        <DialogContent>
          {t("chat.lock.dialog.subTitle")}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleDialogClose} color="primary">
            {t("general.cancel")}
          </Button>
          <Button onClick={this.updateChatLock} color="primary">
             {t("chat.lock.dialog.confirmButtonText")}
          </Button>
        </DialogActions>
      </Dialog>
    </>);
  }
}

function mapStateToProps(state) {
  const { authentication, buildings, app } = state;
  const { user } = authentication;
  const { selected, rights } = buildings;
  return {
    user,
    selected,
    buildings,
    rights,
    app 
  };
}

const connectedMessagesPage = connect(mapStateToProps)(withTheme(withTranslation()(withStyles(styles)(ChatMessages))));
export { connectedMessagesPage as ChatMessages };