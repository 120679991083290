import { Box } from "@mui/material";
import {
  Chat,
  Image,
  MapOutlined,
  PriorityHigh,
  Schedule,
} from "@mui/icons-material";
import * as XLSX from "xlsx";

import { LOCAL_STORAGE_GRID_KEYS, userAccountRoleConstants } from "../../../_constants";
import {
  LabelColumnV2,
  LinkColumnV2,
  MultiLabelColumnV2,
  PriorityIconColumnV2,
  Overdue,
  Attention,
  HoverImage,
  Messages
} from "../../../components/DataGridV2/DataColumnsV2";
import { buildingManagerRole, formatDate, nl2br, repairRequestReceivedVia } from "../../../_helpers";
import { getGridContextMenuList } from "../../../_helpers/getGridContextMenuList";

export const getAfterCareRepairRequestVisibleColumns  = ({
  t,
  i18n,
  listToMapRedirectHandler,
  projectNames,
  locations,
  carryOutAsTypeList,
  statusList,
  organizations,
  classes,
  handleCellFilter,
  apiRef,
  handleCellSort,
  isSiteManagerOrAfterCareEmployeeForBuildings,
  isAfterCareApp
}) => {

  const getOptions = getGridContextMenuList({
    handleCellSort, sortKey: LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_SORT[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
    handleCellFilter, filterKey: LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
    i18n,
    t,
    apiRef,
    getValueForFilter: (rowData, extraData) => {
      let value = ""
      switch (extraData.fieldName) {
        case "date":
          value = formatDate(new Date(rowData.date)).split("-").reverse().join("-")
          break;
        case "targetCompletionDate":
          value = formatDate(new Date(rowData.targetCompletionDate)).split("-").reverse().join("-")
          break;
        case "settledOn":
          value = formatDate(new Date(rowData.settledOn)).split("-").reverse().join("-")
          break;
        case "resolvers":
          const resolverIds = new Set(rowData[extraData.fieldName]?.map((resolver) => resolver.organisatonId) || [])
          value = [...resolverIds]
          break;
        case "priority":
        case "overdue":
          value = rowData[extraData.fieldName]?.toString()
          break;
        case "isRequiredHandling":
          value = rowData?.isRequiredHandling?.toString()
          break;
        default:
          value = rowData[extraData.fieldName]
          break;
      }
      return value
    },
    getValueForCopy: (rowData, extraData) => {
      let text = ""
      switch (extraData.fieldName) {
        case "projectId":
          text = rowData.projectName
          break;
        case "date":
          text = formatDate(new Date(rowData.date))
          break;
        case "settledOn":
          text = formatDate(new Date(rowData.settledOn))
          break;
        case "targetCompletionDate":
          text = !rowData.isAfterCareEmployeeOrSiteManager ? "" : formatDate(new Date(rowData.targetCompletionDate))
          break;
        case "resolvers":
          text = rowData.resolvers?.map(r => r.name).join(",")
          break;
        default:
          text = rowData[extraData.fieldName]
          break;
      }
      return text
    }
  })
  return [
    {
      download: false,
      print: true,
      field: "isRequiredHandling",
      headerName: t("general.attention.label"),
      maxWidth: 70,
      minWidth: 60,
      resizable: false,
      sortable: false,
      flex: 1,
      type: "boolean",
      renderHeader: () => (
        <Box sx={{
          mr: 0.6
        }}>
          <PriorityHigh color="secondary" size="small" />
        </Box>
      ),
      renderCell: (params) => {
        const request = params.row;
        return (
          <Attention request={request} t={t} options={getOptions({ sortable: false, field: "isRequiredHandling", isQuickFilterDisplay: Boolean(request.isRequiredHandling) })} />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "unreadMessagesCount",
      headerName: t("chat.messages"),
      type: "number",
      editable: false,
      maxWidth: 70,
      minWidth: 60,
      resizable: false,
      sortable: false,
      disableRowSelectionOnClick: true,
      flex: 1,
      headerAlign: "center",
      filterable: true,
      renderHeader: () => (
        <Box sx={{
          mr: 0.6
        }}>
          <Chat size="small" />
        </Box>
      ),
      renderCell: (parmes) => {
        const request = parmes.row;
        return <Messages request={request} t={t} options={getOptions({ sortable: false, field: "unreadMessagesCount", isQuickFilterDisplay: Boolean(request.unreadMessagesCount) })} />
      },
    },
    {
      download: false,
      print: true,
      field: "overdue",
      headerName: t("general.overdue"),
      minWidth: 70,
      maxWidth: 70,
      resizable: false,
      sortable: false,
      filterable: !isSiteManagerOrAfterCareEmployeeForBuildings.NO_ONE,
      flex: 1,
      type: "boolean",
      renderHeader: () => (
        <Box sx={{
          mr: 0.6
        }}>
          <Schedule fontSize="small" />
        </Box>
      ),
      renderCell: (params) => {
        const request = params.row;
        return (
          <Overdue request={request} t={t} options={getOptions({ sortable: false, field: "overdue", isQuickFilterDisplay: Boolean(!isSiteManagerOrAfterCareEmployeeForBuildings.NO_ONE && request.isAfterCareEmployeeOrSiteManager && request.overdue) })} classes={classes} />
        );
      },
    },
    {
      download: true,
      print: true,  
      field: "number",
      headerName: t("general.number"),
      editable: false,
      flex: 1,
      align: "start",
      minWidth: 80,
      renderCell: (params) => {
        const request = params.row;
        return (
          <LinkColumnV2
            disabled={request.disabled}
            label={request.number}
            to={isAfterCareApp  ? `/nazorg/${request.isAfterCareEmployeeOrSiteManager ? "melding" : "details"
              }/${request.requestId}` : `/werk/${request.projectNo}/kwaliteitsborging/${request.requestId}`}
            cellSubMenuOptions={{ options: getOptions({ field: "number", isQuickFilterDisplay: Boolean(request.number) }), extraData: { fieldName: "number" } }}
            request={request}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "desc",
      headerName: t("general.description"),
      flex: 1,
      align: "start",
      minWidth: 265,
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            tooltipLabel={nl2br(request.detailDesc)}
            label={request.desc}
            cellSubMenuOptions={{ options: getOptions({ field: "desc", isQuickFilterDisplay: Boolean(request.detailDesc) }), extraData: { fieldName: "desc" } }}
            request={request}
            sx={{ minWidth: "265px" }}
          />
        );
      },
    },
    {
      download: false,
      print: true,
      field: "firstFileId",
      headerName: t("general.image"),
      editable: false,
      disableColumnMenu: true,
      flex: 1,
      align: "center",
      filterable: false,
      minWidth: 45,
      maxWidth: 60,
      sortable: false,
      renderCell: (params) => {
        const imageId = params?.row?.firstFileId;
        return (
          <HoverImage defaultSize={20} imageId={imageId} index={params.id} />
        );
      },
      renderHeader: () => {
        return (
          (<Box component="div" sx={{
            textAlign: "center"
          }}>
            <Image />
          </Box>)
        );
      },
    },
    {
      download: true,
      print: true,
      field: "location",
      headerName: t("general.location"),
      flex: 1,
      align: "start",
      minWidth: 108,
      editable: false,
      disableRowSelectionOnClick: true,
      type: "singleSelect",
      valueOptions: locations,
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            label={request.location}
            Icon={MapOutlined}
            showIcon={request.drawingPinLocation}
            clickable
            sx={{ minWidth: "108px" }}
            onClick={(e) =>
              !request.disabled && listToMapRedirectHandler(e, request)
            }
            cellSubMenuOptions={{ options: getOptions({ field: "location", isQuickFilterDisplay: Boolean(request.location) }), extraData: { fieldName: "location", type: "singleSelect" } }}
            request={request}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "date",
      type: "date",
      headerName: t("general.date"),
      minWidth: 83,
      flex: 1,
      align: "start",
      valueGetter: (params) => {
        const request = params.row;
        if (!request) return "";
        return new Date(request.date);
      },
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return <LabelColumnV2 date={request.date} sx={{ minWidth: "83px" }} cellSubMenuOptions={{ options: getOptions({ field: "date", isQuickFilterDisplay: Boolean(request.date) }), extraData: { fieldName: "date", type: "date" } }}
          request={request} />;
      },
    },
    {
      download: true,
      print: true,
      field: "targetCompletionDate",
      headerName: t("repairRequest.targetCompletionDate.shortLabel"),
      type: "date",
      minWidth: 83,
      valueGetter: (params) => {
        const request = params.row;
        if (!request || !request.isAfterCareEmployeeOrSiteManager) return "";
        return new Date(request.targetCompletionDate);
      },
      editable: false,
      filterable: !isSiteManagerOrAfterCareEmployeeForBuildings.NO_ONE,
      flex: 1,
      align: "start",
      cursor: "pointer",
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";

        return <LabelColumnV2 date={request.targetCompletionDate} sx={{ minWidth: "83px" }} cellSubMenuOptions={{ options: getOptions({ field: "targetCompletionDate", isQuickFilterDisplay: Boolean(!isSiteManagerOrAfterCareEmployeeForBuildings.NO_ONE && request.isAfterCareEmployeeOrSiteManager && request.targetCompletionDate) }), extraData: { fieldName: "targetCompletionDate", type: "date" } }}
          request={request} isBlankCell={!request.isAfterCareEmployeeOrSiteManager} />
      },
    },
    {
      download: true,
      print: true,
      field: "projectNo",
      headerName: t("general.projectNumber"),
      editable: false,
      flex: 1,
      minWidth: 55,
      align: "start",
      renderCell: (params) => {
        const request = params.row;
        const field = params.field;
        return (
          <LabelColumnV2
            label={request[field === "projectId" ? "projectName" : field]}
            cellSubMenuOptions={{ options: getOptions({ field: "projectNo", isQuickFilterDisplay: Boolean(request.projectName) },), extraData: { fieldName: "projectNo" } }}
            request={request}
            sx={{ minWidth: "55px" }}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "projectId",
      headerName: t("general.projectName"),
      minWidth: 175,
      flex: 1,
      align: "start",
      renderCell: (params) => {
        const request = params.row;
        return (
          <LabelColumnV2
            label={request.projectName}
            cellSubMenuOptions={{ options: getOptions({ field: "projectId" }), extraData: { fieldName: "projectId", type: "singleSelect" } }}
            request={request}
            sx={{ minWidth: "175px" }}
          />
        );
      },
      type: "singleSelect",
      valueOptions: projectNames,
    },
    {
      download: true,
      print: true,
      field: "buildingNoIntern",
      headerName: t("general.buildingNumber"),
      flex: 1,
      align: "start",
      editable: false,
      minWidth: 70,
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            label={request.buildingNoIntern}
            cellSubMenuOptions={{ options: getOptions({ field: "buildingNoIntern", isQuickFilterDisplay: Boolean(request.buildingNoIntern) }), extraData: { fieldName: "buildingNoIntern" } }}
            request={request}
            sx={{ minWidth: "70px" }}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "addressPostcode",
      headerName: t("general.address.postcode"),
      flex: 1,
      align: "start",
      minWidth: 75,
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            label={request.addressPostcode}
            cellSubMenuOptions={{ options: getOptions({ field: "addressPostcode", isQuickFilterDisplay: Boolean(request.addressPostcode) }), extraData: { fieldName: "addressPostcode" } }}
            request={request}
            sx={{ minWidth: "75px" }}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "addressText",
      headerName: t("general.objectAddress"),
      flex: 1,
      align: "start",
      editable: false,
      minWidth: 200,
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            label={request.addressText}
            cellSubMenuOptions={{ options: getOptions({ field: "addressText", isQuickFilterDisplay: Boolean(request.addressText) }), extraData: { fieldName: "addressText" } }}
            request={request}
            sx={{ minWidth: "200px" }}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "addressPlace",
      headerName: t("general.address.city"),
      flex: 1,
      align: "start",
      editable: false,
      minWidth: 108,
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            label={request.addressPlace}
            cellSubMenuOptions={{ options: getOptions({ field: "addressPlace", isQuickFilterDisplay: Boolean(request.addressPlace) }), extraData: { fieldName: "addressPlace" } }}
            request={request}
            sx={{ minWidth: "108px" }}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "carryOutAsType",
      headerName: t("repairRequest.type.label"),
      flex: 1,
      align: "start",
      type: "singleSelect",
      minWidth: 90,
      valueOptions: carryOutAsTypeList,
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            label={request.carryOutAsType}
            cellSubMenuOptions={{ options: getOptions({ field: "carryOutAsType", isQuickFilterDisplay: Boolean(request.carryOutAsType) }), extraData: { fieldName: "carryOutAsType", type: "singleSelect" } }}
            request={request}
            sx={{ minWidth: "90px" }}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "priority",
      headerName: t("repairRequest.priority.short"),
      type: "singleSelect",
      editable: false,
      flex: 1,
      headerAlign: "center",
      minWidth: 30,
      filterable: !isSiteManagerOrAfterCareEmployeeForBuildings.NO_ONE,
      valueOptions: [
        { value: "0", label: t("repairRequest.priority.0") },
        { value: "1", label: t("repairRequest.priority.1") },
        { value: "2", label: t("repairRequest.priority.2") },
      ],
      renderCell: (parmes) => {
        const request = parmes.row;
        if (!request) return "";
        return <PriorityIconColumnV2 request={request} value={request.priority} cellSubMenuOptions={{ options: getOptions({ copy: false, field: "priority", isQuickFilterDisplay: Boolean(!isSiteManagerOrAfterCareEmployeeForBuildings.NO_ONE && request.isAfterCareEmployeeOrSiteManager && request.priority) }), extraData: { fieldName: "priority", type: "singleSelect" } }} />
      },
    },
    {
      download: true,
      print: true,
      field: "buyerRenterName",
      headerName: t("general.buyer"),
      flex: 1,
      align: "start",
      minWidth: 265,
      renderCell: (parmes) => {
        const request = parmes.row;
        return (
          <LabelColumnV2
            label={request.buyerRenterName}
            cellSubMenuOptions={{ options: getOptions({ field: "buyerRenterName", isQuickFilterDisplay: Boolean(request.buyerRenterName) }), extraData: { fieldName: "buyerRenterName" } }}
            request={request}
            sx={{ minWidth: "30px" }}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "settledOn",
      type: "date",
      headerName: t("general.completedOn"),
      minWidth: 83,
      flex: 1,
      align: "start",
      valueGetter: (params) => {
        const request = params?.row;
        if (!request) return "";
        return new Date(request.settledOn);
      },
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return <LabelColumnV2 date={request.settledOn} sx={{ minWidth: "83px" }} cellSubMenuOptions={{ options: getOptions({ field: "date", isQuickFilterDisplay: Boolean(request.settledOn) }), extraData: { fieldName: "settledOn", type: "date" } }}
          request={request} />;
      },
    },
    {
      download: true,
      print: true,
      field: "resolvers",
      headerName: t("general.resolvers"),
      minWidth: 142,
      editable: false,
      disableRowSelectionOnClick: true,
      type: "singleSelect",
      flex: 1,
      align: "start",
      sortable: false,
      valueOptions: organizations,
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <MultiLabelColumnV2
            data={request.resolvers}
            cellSubMenuOptions={{ options: getOptions({ sortable: false, field: "resolvers", isQuickFilterDisplay: Boolean(request.resolvers?.length) }), extraData: { fieldName: "resolvers", type: "singleSelect" } }}
            request={request}
            sx={{ minWidth: "142px" }}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "status",
      headerName: t("general.status"),
      editable: false,
      type: "singleSelect",
      flex: 1,
      minWidth: 90,
      align: "start",
      valueOptions: statusList,
      renderCell: (parmes) => {
        const request = parmes.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            color={
              !request.isAfterCareEmployeeOrSiteManager
                ? "black"
                : request.completed
                  ? "#2DA800"
                  : request.overdue
                    ? "#f00"
                    : "black"
            }
            label={request.status}
            sx={{ minWidth: "90px" }}
            cellSubMenuOptions={{ options: getOptions({ field: "status", isQuickFilterDisplay: Boolean(request.status) }), extraData: { fieldName: "status", type: "singleSelect" } }}
            request={request}
          />
        );
      },
    },
    {
      field: "requestId",
      headerName: t("general.primaryKey"),
      download: true,
      print: false,
      filterable: false,
      sortable: false,
      hidden: true,
    },
  ];
};


export const getAfterCareRepairRequestHiddenColumns  = (t, isSiteManagerOrAfterCareEmployeeForBuildings) => {

  if (!isSiteManagerOrAfterCareEmployeeForBuildings) {
    return []
  }
  return [
    {
      field: "reporterRole",
      headerName: t("general.reporter"),
      type: "singleSelect",
      download: true,
      valueOptions: Object.keys(buildingManagerRole).map((key)=> ({value : key.toString() , label: buildingManagerRole[key]})),
    },
    {
      field: "receivedVia",
      type: "singleSelect",
      download: true,
      headerName: t("repairRequest.repairRequestReceivedVia"),
      valueOptions: Object.keys(repairRequestReceivedVia).map((key)=> ({value : key.toString() , label: repairRequestReceivedVia[key]})),
    },
    {
      field: "reporterBuyerRenter",
      download: true,
      headerName: t("repairRequest.filter.reporterBuyerRenterName"),
    },
    {
      field: "reporterOrganisation",
      download: true,
      headerName: t("repairRequest.filter.reporterOrganisation"),
    },
    {
      field: "reporterRelation",
      download: true,
      headerName: t("repairRequest.filter.reporterRelation"),
    },
    {
      field: "nature",
      download: true,
      headerName: t("repairRequest.nature.label"),
    },
    {
      field: "locationSpecification",
      download: true,
      headerName: t("repairRequest.filter.locationSpecification"),
    },
    {
      field: "cause",
      headerName: t("repairRequest.cause"),
    },
    {
      field: "causer",
      download: true,
      headerName: t("repairRequest.filter.causer"),
    },
    {
      field: "productService",
      download: true,
      headerName: t("repairRequest.productOrService"),
    },
    {
      field: "productServiceSub1",
      download: true,
      headerName: t("repairRequest.filter.productServiceSub1"),
    },
    {
      field: "productServiceSub2",
      download: true,
      headerName: t("repairRequest.filter.productServiceSub2"),
    },
    {
      field: "clientReference",
      download: true,
      headerName: t("repairRequest.clientReference"),
    },
    {
      field: "causerOrganisation",
      download: true,
      headerName: t("repairRequest.filter.causerOrganisation"),
    },
    {
      field: "adoptedBy",
      download: true,
      headerName: t("repairRequest.filter.adoptedBy"),
    },
    {
      field: "reporterEmployee",
      download: true,
      headerName: t("repairRequest.filter.reporterEmployee"),
    },
    {
      field: "completionTerm",
      download: true,
      headerName: t("repairRequest.completionTerm"),
    },
    {
      field: "bookingPeriod",
      download: true,
      type: "number",
      headerName: t("repairRequest.bookingPeriod"),
    },
    {
      field: "leadTime",
      download: true,
      headerName: t("repairRequest.leadTime"),
      type: "number"
    },
    {
      field: "deliveryDate",
      download: true,
      headerName: t("general.deliveryDate"),
      type: "date"
    },
    {
      field: "maintenanceEndDate",
      download: true,
      type: "date",
      headerName: t("general.endOfMaintenancePeriod"),
    },
    {
      field: "warrantyEndDate",
      download: true,
      headerName: t("repairRequest.filter.warrantyEndDate"),
      type: "date"
    },
    {
      field: "workOrderText",
      download: true,
      headerName: t("repairRequest.filter.workOrderText"),
    },
    {
      field: "notes",
      download: true,
      headerName: t("repairRequest.filter.notes"),
    },
    {
      field: "buildingUserOrganisation",
      download: true,
      headerName: t("repairRequest.filter.buildingUserOrganisation"),
    },
    {
      field: "daysAfterTargetCompletionDate",
      download: true,
      headerName: t("repairRequest.filter.daysAfterTargetCompletionDate"),
      type: "number"
    },
    {
      field: "buildingUserRelation",
      download: true,
      headerName: t("repairRequest.filter.buildingUserRelation"),
    },
    {
      field: "clientOrganisation",
      download: true,
      headerName: t("repairRequest.filter.clientOrganisation"),
    },
    {
      field: "clientRelation",
      download: true,
      headerName: t("repairRequest.filter.clientRelation"),
    },
    {
      field: "rejectionText",
      download: true,
      headerName: t("general.reasonOfRejection"),
    },
    {
      field: "vveOrganisation",
      download: true,
      headerName: t("repairRequest.filter.vveOrganisation"),
    },
    {
      field: "vveRelation",
      download: true,
      headerName: t("repairRequest.filter.vveRelation"),
    },
    {
      field: "vveAdministratorOrganisation",
      headerName: t("repairRequest.filter.vveAdministratorOrganisation"),
    },
    {
      field: "vveAdministratorRelation",
      download: true,
      headerName: t("repairRequest.filter.vveAdministratorRelation"),
    },
    {
      field: "propertyManagerOrganisation",
      download: true,
      headerName: t("repairRequest.filter.propertyManagerOrganisation"),
    },
    {
      field: "propertyManagerRelation",
      download: true,
      headerName: t("repairRequest.filter.propertyManagerRelation"),
    },
    {
      field: "createdOn",
      download: true,
      headerName: t("general.insertedOn"),
      type: "dateTime"
    },
    {
      field: "createdBy",
      download: true,
      headerName: t("general.insertedBy"),
    },
    {
      field: "modifiedOn",
      download: true,
      headerName: t("repairRequest.filter.modifiedOn"),
      type: "dateTime"
    },
    {
      field: "modifiedBy",
      download: true,
      headerName: t("repairRequest.filter.modifiedBy"),
    },
    {
      field: "surveyBuildingNoIntern",
      download: true,
      headerName: t("repairRequest.filter.surveyBuildingNoIntern"),
    },
    {
      field: "secondSignatureAgreement",
      download: true,
      headerName: t("repairRequest.filter.secondSignatureAgreement"),
    },
    {
      field: "isRework",
      download: true,
      headerName: t("repairRequest.filter.isRework"),
      type: "boolean"
    },
    {
      field: "completedByBuyer",
      download: true,
      headerName: t("repairRequest.filter.completedByBuyer"),
      type: "boolean"
    },
    {
      field: "is48HoursReminder",
      download: true,
      headerName: t("general.48hours"),
      type: "boolean"
    },
    {
      field: "repairRequestBuyerRenter",
      download: true,
      headerName: t("repairRequest.filter.repairRequestBuyerRenter")
    },
  ]
}

export const ADDITIONAL_FIELDS_REQUIRED_IN_PRINT_AND_DOWNLOAD = [{ field: "isAfterCareEmployeeOrSiteManager" }, { field: "buildingId" }, { field: "projectId" }]

const isAfterCareEmployeeOrSiteManagerRole = (data) =>
  data &&
  data.roles &&
  (data.roles.includes(userAccountRoleConstants.aftercareEmployee) ||
    data.roles.includes(userAccountRoleConstants.siteManager));

export const getModifiedResponse = (buildings, t) => {
  return (res) => {
    const data = Object.assign([], res);
    data.forEach((request, index) => {
      const building = buildings.find(
        (x) => x.buildingId === request.buildingId
      );
      const projectBuilding = buildings.find(
        (x) =>
          x.projectId === request.projectId ||
          x.buildingId === request.buildingId
      );
      request.projectName =
        projectBuilding &&
        projectBuilding.projectName.replace(
          `${projectBuilding.projectNo} - `,
          ""
        );
      request.projectNo = projectBuilding && projectBuilding.projectNo;

      request.disabled = !building && !projectBuilding;

      request.isAfterCareEmployeeOrSiteManager =
        isAfterCareEmployeeOrSiteManagerRole(building || projectBuilding);

      request.id = request.requestId;
      request.priorityText =
        request.priority >= 0 && request.priority <= 2
          ? t("repairRequest.priority." + request.priority)
          : "";
    });
    return data;
  };
};

export const jsonToExcel = (jsonData, fileName = "exported_data.xlsx") => {
  const worksheet = XLSX.utils.json_to_sheet(jsonData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
  XLSX.writeFile(workbook, fileName);
};

export const isHiddenResponsibleMenuItem = ({ selectedItems, allBuildings, repairRequests }) => {
  if (selectedItems.length === 0) {
    return true;
  }
  const buildingIds = [];
  const projectIds = []; 
  const selectedRepairRequests = repairRequests.filter(repairRequest => selectedItems.includes(repairRequest.requestId))
  selectedRepairRequests?.forEach((repairRequest) => {
    if (repairRequest?.buildingId ) {
      !buildingIds.includes(repairRequest.buildingId) &&buildingIds.push(repairRequest.buildingId)
    }
    else if (repairRequest?.projectId) {
      !projectIds.includes(repairRequest.projectId) &&  projectIds.push(repairRequest.projectId)
    }
  })
  const isHiddenForAllBuildings =  buildingIds?.length > 0 &&  allBuildings.filter(building => buildingIds.includes(building.buildingId))?.some(building => !(building.roles && (building.roles.includes(userAccountRoleConstants.aftercareEmployee) || building.roles.includes(userAccountRoleConstants.siteManager))))
  let isHiddenForAllProjectBuildings = false
  if (!isHiddenForAllBuildings && projectIds?.length > 0) {
    isHiddenForAllProjectBuildings = allBuildings.filter(building => projectIds.includes(building.projectId))?.some(building => !(building.roles && (building.roles.includes(userAccountRoleConstants.aftercareEmployee) || building.roles.includes(userAccountRoleConstants.siteManager))))
  }
  return  isHiddenForAllBuildings || isHiddenForAllProjectBuildings
}
