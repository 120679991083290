import React from "react";
import { connect } from "react-redux";
import {
  Avatar,
  Container,
  Grid2 as Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  AppBar,
  IconButton,
  Toolbar,
  Slide,
  Box,
  ListItemAvatar,
  TextField,
  Collapse,
  Badge,
  MenuItem,
  Button,
  ListItemSecondaryAction,
  Input,
  InputAdornment,
  CircularProgress,
  Tooltip,
  Icon,
  ToggleButton,
  ListItemButton
} from "@mui/material"
import { alpha } from "@mui/material/styles"
import {
  Chat,
  Search,
  ArrowBack,
  ExpandLess,
  ExpandMore,
  AttachFile,
  ChevronRight,
  Close,
  Bookmarks,
  Bookmark,
  InfoOutlined,
  AddSharp
} from "@mui/icons-material";
import { history, getDateText, md2plaintext, authHeader, chatColor } from "../../_helpers";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { withTheme, withStyles } from "@mui/styles";
import { userAccountTypeConstants , apps} from "../../_constants";
import { ChatMessages } from "./ChatMessages";
import { getTotalUnreadCount, goToBuildingOverviewPage } from "./utils"

const { webApiUrl } = window.appConfig;

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  mainContainer: {
    paddingTop: theme.spacing(5),
    height: "100%",
    "&.fullwidth": {
      maxWidth: "100%",
      padding: theme.spacing(0),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  chatContainer: {
    backgroundColor: theme.palette.background.paper,
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  allChats: {
    position: "relative",
    zIndex: 1,
    height: "100%",
  },
  slideRight: {
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    zIndex: 2,
    height: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
  fullWidth: {
    width: "100%",
  },
  chatList: {
    maxHeight: "calc(100% - 48px)",
    overflowX: "hidden",
    overflowY: "auto",
    width: "100%",
    padding: 0,
  },
  chatListIcon: {
    right: -3,
    top: "50%",
    position: "absolute",
    marginTop: -12,
  },
  chatListDate: {
    position: "absolute",
    right: 18,
    top: 6,
  },
  chatListCount: {
    position: "absolute",
    right: 28,
    bottom: 16,
  },
  chatListItem: {
    paddingTop: 0,
    paddingBottom: 0,
    "& .MuiListItemText-root": {
      marginRight: "60px",
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      "& < *": {
        lineHeight: "1.4!important",
      },
    },
  },
  searchList: {
    maxHeight: "calc(100% - 48px)",
    overflowX: "hidden",
    overflowY: "auto",
    width: "100%",
  },
  searchListItem: {
    paddingTop: 0,
    paddingBottom: 0,
    "& .MuiListItemText-root": {
      marginRight: "60px",
    },
  },
  subjectList: {
    maxHeight: "calc(100% - 48px)",
    overflow: "auto",
    width: "100%",
  },
  importantChatsList: {
    maxHeight: "calc(100% - 48px)",
    overflow: "auto",
    width: "100%",
  },
  importantChatCategory: {
    fontWeight: "bold",
    backgroundColor: theme.palette.background.default,
    paddingTop: 0,
    paddingBottom: 0,
  },
  importantChatItem: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 80,
    height: 48,
    "& .MuiListItemText-root": {
      maxHeight: 40,
      overflow: "hidden",
      marginTop: 0,
      marginBottom: 0,
    },
  },
  importantChatBadge: {
    position: "absolute",
    right: 48,
    top: 20,
  },
  inputBoxSearch: {
    color: "inherit",
    maxWidth: "100%",
    margin: "-3px 5px -5px 0",
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
    "& svg": {
      color: "white",
    },
    flexGrow: 1,
  },
  selectBoxSearch: {
    minWidth: "initial",
    "& .MuiInputBase-root": {
      color: "inherit",
      minWidth: 52,
      maxWidth: 60,
      margin: "-3px 0 -5px",
      textAlign: "right",
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
      "& .MuiSelect-root": {
        paddingRight: 22,
      },
      "& svg": {
        color: "inherit",
      },
    },
  },
  textButton: {
    maxWidth: "100%",
    minWidth: 0,
    overflow: "hidden",
    paddingY: 0,
    marginLeft: theme.spacing(-1),
  }
});

class MessagesPage extends React.Component {
  state = {
    chats: [],
    loading: false,
    chatStartList: [],
    chatBoxOpen: false,
    openAddNew: false,
    expandedList: [],
    openImportantChats: false,
    importantExpandedList: [],
    searchTerm: "",
    searchCategoryId: "all",
    searchWithAttachment: false,
    searchResults: [],
    hasMoreSearchResults: false,
    broadcast: false,
    totalUnreadCount:0,
    isComponentInViewPort: true,
  };
  chatTimeoutId = null;

  observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            this.setState({isComponentInViewPort : true})
        } else {
          this.setState({isComponentInViewPort : false})
        }
    });
});
 
  componentDidMount() {
    const { location } = this.props;
    this.mounted = true;
    this.UpdateChatStartList();
    this.UpdateChats(this.props.startChatId, true);

    const openImportantChats = location && location.state && location.state.showImportantMessages === true;

    // var intervalId = setInterval(this.timer, 10000);
    // store intervalId in the state so it can be accessed later:
    this.setState({ openImportantChats });
    const element = document.querySelector('#messagesContainer');
    this.observer.observe(element);
  }

  componentWillReceiveProps(nextProps) {
    this.openChatMessage(nextProps);
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    this.mounted = false;
    this.cancelChatsApi();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.selected || prevProps.selected.buildingId !== this.props.selected.buildingId) {
      this.cancelChatsApi();
      this.UpdateChatStartList();
      this.UpdateChats(null, true);
    }

    if (this.props.hideChatMessages && prevProps.hideChatMessages !== this.props.hideChatMessages) {
      this.closeChatBox()
    }

    if (prevProps.selected && this.props.selected && prevProps.selected.projectId.toUpperCase() !== this.props.selected.projectId.toUpperCase()) {
      this.closeChatBox();
    }
    if (this.state.searchTerm.trim() !== prevState.searchTerm.trim() || this.state.searchCategoryId !== prevState.searchCategoryId || this.state.searchWithAttachment !== prevState.searchWithAttachment) {
      this.SearchChats();
    }

    if(this.props.updateParentTotalUnreadCount){
      const latestTotalUnReadCount = getTotalUnreadCount(this.state.chats)
      if(prevState.totalUnreadCount !== latestTotalUnReadCount){
        this.setState({ totalUnreadCount: latestTotalUnReadCount });
        this.props.updateParentTotalUnreadCount(latestTotalUnReadCount)
      }
    }

    if (this.state.isComponentInViewPort && prevState.isComponentInViewPort !== this.state.isComponentInViewPort) {
      this.UpdateChats()
    }
  }

  cancelChatsApi = () => {
    if (this.chatTimeoutId || !this.mounted) {
      clearTimeout(this.chatTimeoutId);
      if (this.chatUpdateAbortController && this.chatUpdateAbortController.signal.aborted !== true) {
        this.chatUpdateAbortController.abort();
      }
      this.chatTimeoutId = null;
    }
  };

  isFilterByBuilding() {
    const { user, selectedBuildingOnly } = this.props;
    return selectedBuildingOnly === true || user.type === userAccountTypeConstants.buyer;
  }

  UpdateChatStartList() {
    const { selected,app } = this.props;
    if (selected && app === apps.huisinfo) {
      const url = webApiUrl + "api/chat/" + (!this.isFilterByBuilding() ? "GetChatStartListByProject/" + selected.projectId : "GetChatStartListByBuilding/" + selected.buildingId);

      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };
      this.setState({ loading: true });
      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          this.setState({ chatStartList: findResponse });
        });
    }
  }

  UpdateChats = (startChatId = null, refresh = false) => {
    const { selected ,app ,user} = this.props;
    const { chats } = this.state;
    if (selected && this.mounted && this.state.isComponentInViewPort) {
      if (this.chatUpdateAbortController && this.chatUpdateAbortController.signal.aborted !== true) {
        this.chatUpdateAbortController.abort();
      }

      if (refresh) clearTimeout(this.chatTimeoutId);

      this.chatUpdateAbortController = new window.AbortController();
      const url =
        webApiUrl + "api/chat/" + (!!this.props.repairRequestId || ([apps.aftercare , apps.resolverModule].includes(app) && user.type !== userAccountTypeConstants.buyer) ? "GetChats" :(this.isFilterByBuilding() ? "GetChatsByBuilding/" + selected.buildingId : "GetChatsByProject/" + selected.projectId)) + "?" + (chats.length > 0 && !refresh ? "dateTime=" + encodeURIComponent(chats[0].dateTime) +"&" : "") +"app=" +app + (this.props.repairRequestId ? "&repairRequestId=" +this.props.repairRequestId:"" );

      const requestOptions = {
        method: "GET",
        headers: authHeader(),
        signal: this.chatUpdateAbortController.signal,
      };
      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          var allChats = refresh === true ? [] : chats.slice();
          var expandedList = refresh === true ? [] : this.state.expandedList.slice();
          let selectedHasUpdate = false;
          let selectedNew = Object.assign({}, this.state.selected);

          for (var i = 0; i < findResponse.length; i++) {
            const index = allChats.findIndex((x) => x.chatId === findResponse[i].chatId);
            let existingChat = allChats[index];
            if (existingChat) {
              existingChat = {
                ...existingChat,
                dateTime: findResponse[i].dateTime,
                lastChatMessagePartialText: findResponse[i].lastChatMessagePartialText,
                isSender: findResponse[i].isSender,
                senderName: findResponse[i].senderName,
                unreadMessagesCount: findResponse[i].unreadMessagesCount,
                lastMessageIsAttachment: findResponse[i].lastMessageIsAttachment,
              };
              allChats[index] = {
                ...allChats[index],
                ...existingChat,
              };
              if (this.state.selected && this.state.selected.chatId === existingChat.chatId) {
                selectedHasUpdate = true;
                selectedNew = findResponse[i];
              }
            } else {
              allChats.push(findResponse[i]);
            }
          }

          if (!selectedHasUpdate) {
            selectedNew = this.state.selected;
          }

          allChats.sort(function (a, b) {
            return new Date(b.dateTime) - new Date(a.dateTime);
          });

          if (refresh === true) {
            this.setState({
              chats: allChats,
              selected: selectedNew,
              selectedHasUpdate: false,
              expandedList,
            });
          } else {
            this.setState({
              chats: allChats,
              selected: selectedNew,
              selectedHasUpdate,
            });
          }

          if (startChatId) {
            const chat = this.state.chats.find((x) => x.chatId.toUpperCase() === startChatId.toUpperCase());
            if (chat) {
              this.selectChat(chat);
            }
          }

          if (refresh === true) {
            this.openChatMessage(this.props, allChats);
          }
          this.setState({ loading: false, retryUpdateChatCount: 0 });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.cancelChatsApi();
          if (this.mounted) {
            this.chatTimeoutId = setTimeout(() => {
              this.UpdateChats();
            }, 10000);
          }
        });
    }
  }

  openChatMessage(props, allChats = this.state.chats) {
    const { location } = props;
    if (location && location.state) {
      const { selectedChatId, selectedChatMessageId } = location.state;

      if (!!selectedChatId) {
        if (!!selectedChatMessageId) {
          this.goToChatMessage(selectedChatId, selectedChatMessageId);
        } else {
          const chat = allChats.find((x) => x.chatId.toUpperCase() === selectedChatId.toUpperCase());
          this.selectChat(chat);
        }
      }
    }
  }



  SearchChats(refresh = true) {
    const { user, selected , app } = this.props;
    const { searchTerm, searchCategoryId, searchWithAttachment } = this.state;

    if (selected && searchTerm && searchTerm.trim() !== "") {
      const url = webApiUrl + "api/chat/SearchChats" +"?app=" + app;

      if (this.searchAbortController && this.searchAbortController.signal.aborted !== true) {
        this.searchAbortController.abort();
      }

      this.searchAbortController = new window.AbortController();

      const requestOptions = {
        method: "POST",
        headers: authHeader("application/json"),
        body: JSON.stringify({
          chatId: null,
          buildingId: this.isFilterByBuilding() ? selected.buildingId : searchCategoryId !== "all" ? searchCategoryId : null,
          projectId: !this.isFilterByBuilding() ? selected.projectId : null,
          searchTerm: searchTerm,
          organisationId: searchCategoryId !== "all" && user.type === userAccountTypeConstants.buyer ? searchCategoryId : null,
          dateTime: refresh !== true && this.state.searchResults.length > 0 ? this.state.searchResults[this.state.searchResults.length - 1].dateTime : null,
          attachment: searchWithAttachment,
          count: 10,
          repairRequestId:this.props.repairRequestId
        }),
        signal: this.searchAbortController.signal,
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          var searchResults = [];
          const hasMoreSearchResults = findResponse.length === 10;

          if (refresh === true) {
            searchResults = findResponse;
          } else {
            searchResults = this.state.searchResults.slice();
            searchResults = searchResults.concat(findResponse);
          }

          searchResults.sort(function (a, b) {
            return new Date(b.dateTime) - new Date(a.dateTime);
          });

          this.setState({
            searchResults,
            hasMoreSearchResults,
          });

          this.updateSearchResultsOnScroll();
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            //handle aborterror here
          }
        });
    } else {
      if (this.searchAbortController && this.searchAbortController.signal.aborted !== true) {
        this.searchAbortController.abort();
      }
      this.setState({
        searchResults: [],
        hasMoreSearchResults: false,
      });
    }
  }

  updateSearchResultsOnScroll = () => {
    const { hasMoreSearchResults } = this.state;
    const { searchList } = this.refs;
    if (searchList) {
      const scrollTop = searchList.scrollTop;
      const scrollHeight = searchList.scrollHeight;
      const height = searchList.clientHeight;
      const maxScrollTop = scrollHeight - height;
      const isScrollPositionBottom = scrollTop === maxScrollTop;
      if (isScrollPositionBottom === true && hasMoreSearchResults === true) {
        this.SearchChats(false);
      }
    }
  };

  createNewChat(obj) {
    const url = webApiUrl + "api/chat/AddNewChat";
    const requestOptions = {
      method: "POST",
      headers: authHeader("application/json"),
      body: JSON.stringify({
        buildingId: obj.buildingId,
        organisationId: obj.organisationId,
        subject: "",
      }),
    };

    fetch(url, requestOptions).then((response) => {
      if (response.status === 200) {
        response.text().then((chatId) => {
          const chat = this.state.chats.find((chat) => chat?.chatId?.toUpperCase() === chatId.toUpperCase());
          if (chat) {
            this.selectChat(chat);
          } else {
            this.UpdateChats(chatId);
          }
        });
      }
    });
    this.setState({ openAddNew: false });
  }


  handleUnmarkImportantChatMessage(message, chatId) {
    const url = webApiUrl + "api/chat/MarkUnmarkChatMessageImportant/" + message.chatMessageId + "?isMark=" + false;
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    // BEGIN COMMENT--- this piece of code is written before fetch to make it look smooth on UI, need to fix in future

    // END COMMENT

    fetch(url, requestOptions)
      .then((Response) => {
        var importantChats = this.state.importantChats.slice();

        var chatToUpdate = importantChats.find((x) => x.chatId.toUpperCase() === chatId.toUpperCase());
        if (chatToUpdate) {
          chatToUpdate.messages = chatToUpdate.messages.filter((x) => x.chatMessageId.toUpperCase() !== message.chatMessageId.toUpperCase());
          this.setState({
            importantChats,
          });
        }
      })
      .catch((error) => {
        console.log("Error while removing");
      });
  }

  selectChat = (chat) => {
    this.setState({
      selected: chat,
      chatBoxOpen: true,
      broadcast: false,
    });
  };

  toggleOpenAddNew = () => {
    this.setState({ openAddNew: !this.state.openAddNew });
  };

  handleChatCategoryToggle = (categoryId) => {
    var expandedList = this.state.expandedList.slice();
    if (expandedList.find((x) => x === categoryId)) {
      expandedList = expandedList.filter((x) => x !== categoryId);
    } else {
      expandedList.push(categoryId);
    }
    this.setState({ expandedList });
  };

  toggleOpenSearchChats = () => {
    document.activeElement.blur();
    this.setState({ openSearchChats: !this.state.openSearchChats });
  };

  handleChangeSearch = (event) => {
    this.setState({
      searchTerm: event.target.value,
    });
  };

  handleChangeSearchCategory = (event) => {
    this.setState({
      searchCategoryId: event.target.value,
    });
  };

  toggleSearchWithAttachment = () => {
    document.activeElement.blur();
    this.setState({ searchWithAttachment: !this.state.searchWithAttachment });
  };

  clearAndCloseSearch = () => {
    this.setState({
      openSearchChats: false,
      searchTerm: "",
      searchResults: [],
      searchCategoryId: "all",
      searchWithAttachment: false,
      hasMoreSearchResults: false,
    });
  };

  toggleOpenImportantChats = () => {
    this.setState({ openImportantChats: !this.state.openImportantChats });
  };

  handleImportantChatCategoryToggle = (chatId) => {
    var importantExpandedList = this.state.importantExpandedList.slice();
    if (importantExpandedList.find((x) => x === chatId)) {
      importantExpandedList = importantExpandedList.filter((x) => x !== chatId);
    } else {
      importantExpandedList.push(chatId);
    }
    this.setState({ importantExpandedList });
  };

  isImportantChatExpanded = (chatId) => {
    if (this.state.importantExpandedList.find((x) => x === chatId)) {
      return true;
    }
    return false;
  };

  closeChatBox = () => {
    this.setState({
      selected: null,
      broadcast: false,
      chatBoxOpen: false,
    });
  };

  repairRequestRedirectHelper(id) {
    const { user, app, selected } = this.props;
    const isUserBuyer = user.type === userAccountTypeConstants.buyer;
    let url = app !== apps.aftercare ? "/werk/" + selected.projectNo + "/kwaliteitsborging" : (isUserBuyer ? "/nazorg/details" : "/nazorg/melding");
    if (id) url += `/${id}`;
    history.push(url);
  }


  handleRedirect = (chatId) => {
    const { chats } = this.state;
    const chat = chats.find(x => x.chatId === chatId);
    if (chat) {
      this.repairRequestRedirectHelper(chat?.repairRequestId)
    }
  }

  getChatSubTitle = (chat) => {
    const { user, app } = this.props;
    if (app !== apps.huisinfo) {
      const chatItem = this.state.chats?.find(x => x.chatId === chat.chatId);
      if(!chatItem)
        return `${chat?.repairRequestNo || ""} ${chat.chatSubject || ''}`
      else
        return `${chatItem?.repairRequestNo} ${chatItem?.chatSubject}`
    }
    if(user.type !== userAccountTypeConstants.buyer){
      return chat.buildingNoExtern;
    }
    return chat.organisationName;
  };

  goToChatMessage(chatId, chatMessageId) {
    this.setState({
      gotoMessage: { chatId, chatMessageId }
    });
  }


  updateParentState = (object, fallback = () => { }) => {
    this.setState({ ...object }, fallback)
  }

  render() {
    const { user, t, classes, isFullWidth, rights,app, applyMinStyles } = this.props;
    const {
      selected,
      selectedHasUpdate,
      chatBoxOpen,
      chats,
      broadcast,
      openAddNew,
      openImportantChats,
      chatStartList,
      importantChats,
      expandedList,
      openSearchChats,
      searchTerm,
      searchCategoryId,
      searchResults,
      searchWithAttachment
    } = this.state;

    const isUserBuyer = user.type === userAccountTypeConstants.buyer;

    var chatCategories = null;
    if (chats && chats.length > 0) {
      chatCategories = chats.reduce((catsSoFar, chat) => {
        var category = isUserBuyer !== true ? chat.buildingId : chat.organisationId;
        if (!catsSoFar[category]) {
          catsSoFar[category] = {
            chats: [],
            sum: 0,
            expanded: expandedList.findIndex((x) => x === category) >= 0,
          };
        }
        catsSoFar[category].chats.push(chat);
        catsSoFar[category].sum += chat.unreadMessagesCount;
        return catsSoFar;
      }, {});
    }
    return (
      (<Container id="messagesContainer" className={isFullWidth === true ? clsx(classes.mainContainer, "fullwidth") : classes.mainContainer}>
        <Grid container className={classes.chatContainer}>
          <Grid
            container
            direction="column"
            className={classes.allChats}
            component={Box}
            sx={{
              boxShadow: 1
            }}
            size={{
              xs: 12,
              md: applyMinStyles ? 12:4,
              lg: applyMinStyles ? 12 : (isFullWidth ? 3 : 4)
            }}>
            <AppBar position="static" color="inherit" sx={{zIndex:1}}>
              <Toolbar variant="dense" >
                {app === apps.huisinfo && <IconButton disabled={!rights["chat.details.write"]} aria-label="Berichten" color="inherit" onClick={this.toggleOpenAddNew}>
                  <Chat />
                </IconButton>}
                <IconButton aria-label="Zoek" color="inherit" onClick={this.toggleOpenSearchChats}>
                  <Search />
                </IconButton>
                <IconButton aria-label="Important" color="inherit" onClick={this.toggleOpenImportantChats}>
                  <Bookmarks />
                </IconButton>
                {this.props.isAbleToCreateChat&& <IconButton aria-label="Zoek" color="inherit" onClick={this.props.handleInitiateChat}>
                  <AddSharp />
                </IconButton>}
                {!isUserBuyer && app === apps.huisinfo && (
                  <Tooltip title={t("chat.broadcast.title")}>
                    <IconButton
                      aria-label="Broadcast"
                      color="inherit"
                      onClick={() =>
                        this.setState({
                          broadcast: true,
                          selected: null,
                          chatBoxOpen: true,
                        })
                      }
                    >
                      <Icon className="fas fa-bullhorn" />
                    </IconButton>
                  </Tooltip>
                )}
              </Toolbar>
            </AppBar>

            <List className={classes.chatList}>
              {this.state.loading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress size="md" />
                </div>
              ) : chats && chats.length > 0 ? (
                chats.map((chat, index) => {
                  let chatTitle = this.getChatSubTitle(chat)
                  return (
                    (<React.Fragment key={`${index}-${chat.chatId}`}>
                      {index !== 0 && <Divider component="li" />}
                      <ListItemButton
                        sx={{background:chatColor[chat.color]?.main ,"&:hover":{background:chatColor[chat.color]?.hover}}}
                        className={classes.chatListItem}
                        title={(app !== apps.huisinfo) ? chat.repairRequestDesc :chatTitle}
                        selected={selected && selected.chatId === chat.chatId}
                        onClick={() => this.selectChat(chat)}>
                        <ListItemAvatar>
                          <Avatar src={`${webApiUrl}api/RepairRequest/GetRepairRequestThumbnail/${chat?.repairRequestId}`} alt={chatTitle} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Typography noWrap>{chatTitle}</Typography>}
                          secondary={
                            <Typography variant="body2" noWrap sx={{
                              color: "text.secondary"
                            }}>
                              {chat.lastMessageIsAttachment && <AttachFile fontSize="small" style={{ marginLeft: -5 }} />}
                              {chat.lastChatMessagePartialText ? (
                                <React.Fragment>
                                  {chat.isSender !== true && (
                                    <React.Fragment>
                                      {chat.senderName === null ? (
                                        <React.Fragment>
                                          <InfoOutlined style={{ marginTop: "-3px" }} fontSize="small" />
                                          &nbsp;
                                        </React.Fragment>
                                      ) : (
                                        <React.Fragment>{chat.senderName}:&nbsp;</React.Fragment>
                                      )}
                                    </React.Fragment>
                                  )}
                                  {md2plaintext(chat.lastChatMessagePartialText)}
                                </React.Fragment>
                              ) : (
                                "..."
                              )}
                            </Typography>
                          }
                          secondaryTypographyProps={{ component: "div" }}
                        />
                        <Typography variant="caption" className={classes.chatListDate}>
                          {getDateText(new Date(chat.dateTime))}
                        </Typography>
                        {chat.unreadMessagesCount > 0 && (
                          <Badge className={classes.chatListCount} badgeContent={chat.unreadMessagesCount} color="primary">
                            <span></span>
                          </Badge>
                        )}
                        <ChevronRight className={classes.chatListIcon} color="action" />
                       </ListItemButton>
                    </React.Fragment>)
                  );
                })
              ) : (
                <ListItem>
                  <ListItemText secondary={t("chat.messages.noMessages")} />
                 </ListItem>
              )}
            </List>
          </Grid>
          <Slide direction="right" in={openSearchChats} mountOnEnter unmountOnExit>
            <Grid
              container
              direction="column"
              className={classes.slideRight}
              size={{
                xs: 12,
                md: applyMinStyles ? 12 :4,
                lg: applyMinStyles ? 12 : (isFullWidth ? 3 : 4)
              }}>
              <AppBar position="static">
                <Toolbar variant="dense">
                  <Input
                    color="primary"
                    className={classes.inputBoxSearch}
                    type="search"
                    value={searchTerm}
                    onChange={this.handleChangeSearch}
                    endAdornment={
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    }
                  />
                  <TextField id="standard-select-category" variant="standard" select className={classes.selectBoxSearch} value={searchCategoryId} onChange={this.handleChangeSearchCategory}>
                    <MenuItem value="all">{t("general.all")}</MenuItem>
                    {chatCategories &&
                      Object.keys(chatCategories).map((category) => (
                        <MenuItem key={category} value={category}>
                          {this.getChatSubTitle(chatCategories[category].chats[0])}
                        </MenuItem>
                      ))}
                  </TextField>
                  <ToggleButton style={{ color: "inherit", borderColor: "transparent" }} size="small" value="attachment" selected={searchWithAttachment} onChange={this.toggleSearchWithAttachment} aria-label="attachments">
                    <AttachFile />
                  </ToggleButton>
                  <IconButton aria-label="Close" color="inherit" onClick={this.clearAndCloseSearch}>
                    <Close />
                  </IconButton>
                </Toolbar>
              </AppBar>

              <List className={classes.searchList} ref="searchList" onScroll={this.updateSearchResultsOnScroll}>
                {searchResults && searchResults.length > 0 ? (
                  searchResults.map((message, indexMessage) => (
                    <React.Fragment key={indexMessage}>
                      <ListItemButton
                        className={classes.searchListItem}
                        onClick={() => this.goToChatMessage(message.chatId, message.chatMessageId)}>
                        <ListItemText
                          primary={
                            <Grid container sx={{
                              alignItems: "center"
                            }}>
                              <Grid size={{xs:12}}>
                                {(isUserBuyer && app === apps.huisinfo) || app === apps.resolverModule ? (
                                  <Typography noWrap>{this.getChatSubTitle(message)}</Typography>
                                ) : (
                                  <Button className={classes.textButton} variant="text" color="primary" size="small" onClick={() => app !== app.huisinfo ? this.handleRedirect(message.chatId) : goToBuildingOverviewPage(message.buildingId, this.props.buildings.all,this.props.dispatch ,history,)}>
                                    <Typography noWrap>{this.getChatSubTitle(message)}</Typography>
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          }
                          secondary={
                            <Typography variant="body2" noWrap sx={{
                              color: "text.secondary"
                            }}>
                              {message.isFile && <AttachFile fontSize="small" style={{ marginLeft: -5 }} />}
                              {message.isSender !== true && (
                                <React.Fragment>
                                  {message.senderName === null ? (
                                    <React.Fragment>
                                      <InfoOutlined style={{ marginTop: "-3px" }} fontSize="small" />
                                      &nbsp;
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment>{message.senderName}:&nbsp;</React.Fragment>
                                  )}
                                </React.Fragment>
                              )}
                              {md2plaintext(message.message)}
                            </Typography>
                          }
                          secondaryTypographyProps={{ component: "div" }}
                        />
                        <Typography variant="caption" className={classes.chatListDate}>
                          {getDateText(new Date(message.dateTime))}
                        </Typography>

                        <ChevronRight className={classes.chatListIcon} color="action" />
                       </ListItemButton>
                      <Divider component="li" />
                    </React.Fragment>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText secondary={t("chat.messages.noMessages")} />
                   </ListItem>
                )}
              </List>
            </Grid>
          </Slide>

          <Slide direction="right" in={openAddNew} mountOnEnter unmountOnExit>
            <Grid
              container
              direction="column"
              className={classes.slideRight}
              size={{
                xs: 12,
                md: applyMinStyles ? 12 :4,
                lg: applyMinStyles ? 12 :(isFullWidth ? 3 : 4)
              }}>
              <AppBar position="static" color="inherit" className={classes.appBar}>
                <Toolbar variant="dense">
                  <IconButton edge="start" aria-label="GoBack" color="inherit" onClick={this.toggleOpenAddNew}>
                    <ArrowBack />
                  </IconButton>
                  <Typography className={clsx(classes.grow, classes.bold)} noWrap>
                    {t("chat.messages.newMessage")}
                  </Typography>
                </Toolbar>
              </AppBar>
              <List className={classes.subjectList}>
                {chatStartList.map((item, index) => (
                  <React.Fragment key={index}>
                    {index !== 0 && <Divider component="li" />}
                    <ListItemButton key={index} onClick={() => this.createNewChat(item)}>
                      <ListItemText primary={item.title} />
                     </ListItemButton>
                  </React.Fragment>
                ))}
              </List>
            </Grid>
          </Slide>

          <Slide direction="right" in={openImportantChats} mountOnEnter unmountOnExit>
            <Grid
              container
              direction="column"
              className={classes.slideRight}
              size={{
                xs: 12,
                md: applyMinStyles?12: 4,
                lg: applyMinStyles ? 12 : ( isFullWidth ? 3 : 4)
              }}>
              <AppBar position="static">
                <Toolbar variant="dense">
                  <IconButton edge="start" aria-label="GoBack" color="inherit" onClick={this.toggleOpenImportantChats}>
                    <ArrowBack />
                  </IconButton>
                  <Typography className={clsx(classes.grow, classes.bold)} noWrap>
                    {t("chat.importantMessages")}
                  </Typography>
                </Toolbar>
              </AppBar>
              <List className={classes.importantChatsList}>
                {importantChats &&
                  importantChats.length > 0 &&
                  importantChats.map((chat, index) => (
                    <React.Fragment key={index}>
                      {index !== 0 && <Divider component="li" />}
                      <ListItemButton
                        className={classes.importantChatCategory}
                        onClick={() => this.handleImportantChatCategoryToggle(chat.chatId)}>
                        <ListItemText
                          primary={
                            <div style={{ maxWidth: "100%" }}>
                              {(isUserBuyer && app === apps.huisinfo) || app === apps.resolverModule ? (
                                <Typography noWrap>{this.getChatSubTitle(chat)}</Typography>
                              ) : (
                                <Button className={classes.textButton} variant="text" color="primary" size="small" onClick={() => app !== app.huisinfo ? this.handleRedirect(chat.chatId) : goToBuildingOverviewPage(chat.buildingId, this.props.buildings.all, this.props.dispatch, history)}>
                                  <Typography noWrap>{this.getChatSubTitle(chat)}</Typography>
                                </Button>
                              )}
                              <Badge className={classes.importantChatBadge} badgeContent={chat.messages.length} color="primary">
                                <span></span>
                              </Badge>
                            </div>
                          }
                        />
                        {this.isImportantChatExpanded(chat.chatId) ? <ExpandLess className={classes.impChatListIcon} color="action" /> : <ExpandMore className={classes.impChatListIcon} color="action" />}
                       </ListItemButton>

                      <Collapse in={this.isImportantChatExpanded(chat.chatId)} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {chat.messages.length > 0 &&
                            chat.messages.map((message, indexMessage) => (
                              <React.Fragment key={indexMessage}>
                                <Divider component="li" />
                                <ListItemButton
                                  className={classes.importantChatItem}
                                  selected={selected && selected.chatId === chat.chatId}
                                  onClick={() => this.goToChatMessage(chat.chatId, message.chatMessageId)}>
                                  <ListItemText
                                    primary={
                                      <Typography variant="body2">
                                        {message.isFile && <AttachFile fontSize="small" style={{ marginLeft: -5 }} />}
                                        {md2plaintext(message.message)}
                                      </Typography>
                                    }
                                  />
                                  <ListItemSecondaryAction>
                                    <Grid container direction="column" sx={{
                                      alignItems: "flex-end"
                                    }}>
                                      <Typography variant="caption" className={classes.impChatListDate}>
                                        {getDateText(new Date(message.dateTime))}
                                      </Typography>
                                      <IconButton edge="end" aria-label="important" size="small" disabled={user.viewOnly === true || !rights["chat.details.write"]} onClick={() => this.handleUnmarkImportantChatMessage(message, chat.chatId)}>
                                        <Bookmark color="primary" />
                                      </IconButton>
                                    </Grid>
                                  </ListItemSecondaryAction>
                                 </ListItemButton>
                              </React.Fragment>
                            ))}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  ))}
              </List>
            </Grid>
          </Slide>

         <ChatMessages
          selectedChat={selected}
          chatBoxOpen={chatBoxOpen}
          updateParentState={this.updateParentState}
          chats={chats}
          isFullWidth={isFullWidth}
          gotoMessage={this.state.gotoMessage}
          chatStartList={chatStartList}
          expandedList={expandedList}
          broadcast={broadcast}
          importantChats={importantChats}
          isFilterByBuilding={this.isFilterByBuilding()}
          selectedHasUpdate={selectedHasUpdate}
          applyMinStyles={applyMinStyles}
          repairRequestId={this.props.repairRequestId}
          updateParentChats={this.UpdateChats}
          resolverId={this.props?.resolverId}
          />

        </Grid>
      </Container>)
    );
  }
}

function mapStateToProps(state) {
  const { authentication, buildings,app } = state;
  const { user } = authentication;
  const { selected, rights } = buildings;
  return {
    user,
    selected,
    buildings,
    rights,
    app
  };
}

const connectedMessagesPage = connect(mapStateToProps)(withTheme(withTranslation()(withStyles(styles)(MessagesPage))));
export { connectedMessagesPage as MessagesPage };