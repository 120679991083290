import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getDataTableTextLabels, groupBy } from "../../_helpers";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import { commonActions } from "../../_actions/common.actions";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getRRDashboardCounts } from "../../apis/surveyingApis";
import { apps, userAccountRoleConstants } from "../../_constants";

export default function ProjectOverview(props) {
  const theme = useTheme();
  const { history } = props;
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    name: "projectName",
    direction: "desc",
  });
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const {
    all = [],
    selected,
    loading: buildingLoading,
  } = useSelector((state) => state.buildings);
  const app = useSelector((state) => state.app);
  let [columnsVisibility, setColumnsVisibility] = useState([
    { name: "projectNo", visible: true },
    { name: "projectName", visible: true },
    { name: "address", visible: true },
    { name: "objects", visible: true },
    { name: "newRepairRequests", visible: true },
    { name: "inProgressRepairRequests", visible: true },
    { name: "overdueRepairRequests", visible: true },
    { name: "openWorkOrders", visible: true },
    { name: "overdueWorkOrders", visible: true },
  ]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      all.every(
        (a) =>
          a.roles &&
          a.roles.every(
            (b) =>
              b === userAccountRoleConstants.subContractorSupervisor ||
              b === userAccountRoleConstants.subContractorEmployee
          )
      )
    )
      history.push("/kwaliteitsborging");
  }, [all]);

  useEffect(() => {
    setRequests([]);
    setLoading(true);
    const projectsGroup = groupBy(all, "projectId");
    const projects = Object.keys(projectsGroup);
    if (!buildingLoading) {
      getRRDashboardCounts(app, projects).then(({ data: response }) => {
        const data = [];
        for (let i = 0; i < projects.length; i++) {
          const currentProject = all.find((p) => p.projectId === projects[i]);
          const project = response.find((p) => p.projectId === projects[i]);
          if (project) {
            const objects = projectsGroup[project.projectId].length;
            const newRepairRequests = project.new;
            const inProgressRepairRequests = project.inProgress;
            const overdueRepairRequests = project.overDue;
            // const subContractors = requests ? requests.reduce((p, c) => p + (c.resolvers ? c.resolvers.length : 0), 0) : 0;
            const openWorkOrders = project.workOrdersInProgress;
            const overdueWorkOrders = project.workOrdersOverdue;
            data.push({
              ...currentProject,
              ...project,
              address: project.city,
              objects,
              inProgressRepairRequests,
              newRepairRequests,
              overdueRepairRequests,
              openWorkOrders,
              overdueWorkOrders,
            });
          }
        }
        setRequests(data);
        setLoading(false);
      });
    }
    return () => {
      setRequests([]);
    };
  }, [history.location.key, all, app, buildingLoading]);

  useEffect(() => {
    if (
      JSON.parse(localStorage.getItem("siteManager_ColumnsVisibility")) ==
      undefined
    ) {
      localStorage.setItem(
        "siteManager_ColumnsVisibility",
        JSON.stringify(columnsVisibility)
      );
    } else {
      columnsVisibility = JSON.parse(
        localStorage.getItem("siteManager_ColumnsVisibility")
      );
    }

    var rowsPerPage = parseInt(
      localStorage.getItem("CQRepairRequests_RowsPerPage")
    );
    if (!!rowsPerPage && rowsPerPage > 0 && rowsPerPage !== rowsPerPage) {
      setRowsPerPage(rowsPerPage);
    }
  }, []);

  const isColumnVisible = (columnName) => {
    const isSmallScreen = true; //isWidthDown('sm', width);
    var column = columnsVisibility.find((x) => x.name === columnName);
    if (column) {
      return column.visible;
    } else if (isSmallScreen) {
      return false;
    } else {
      return true;
    }
  };

  const columns = [
    {
      name: "projectId",
      options: {
        display: "excluded",
        filter: false,
        download: false,
        print: false,
      },
    },
    {
      name: "projectNo",
      label: t("general.projectNumber"),
      options: {
        display: isColumnVisible("projectNo"),
        filter: true,
      },
    },
    {
      name: "projectName",
      label: t("settings.table.column.project.name"),
      options: {
        display: isColumnVisible("projectName"),
        filter: true,
      },
    },
    {
      name: "address",
      label: t("general.address.city"),
      options: {
        display: isColumnVisible("address"),
        filter: true,
      },
    },
    {
      name: "objects",
      label: t("general.objects"),
      options: {
        display: isColumnVisible("objects"),
        filter: true,
      },
    },
    {
      name: "newRepairRequests",
      label: t("site.manager.new.repairrequests"),
      options: {
        display: isColumnVisible("newRepairRequests"),
        filter: true,
      },
    },
    {
      name: "inProgressRepairRequests",
      label: t("site.manager.inprogress.repairrequests"),
      options: {
        display: isColumnVisible("inProgressRepairRequests"),
        filter: true,
      },
    },
    {
      name: "overdueRepairRequests",
      label: t("site.manager.overdue.repairrequests"),
      options: {
        display: isColumnVisible("overdueRepairRequests"),
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span style={{ color: "#ff1744" }}>{value}</span>;
        },
      },
    },
    {
      name: "openWorkOrders",
      label: t("site.manager.open.work.orders"),
      options: {
        display: isColumnVisible("openWorkOrders"),
        filter: true,
      },
    },
    {
      name: "overdueWorkOrders",
      label: t("site.manager.overdue.work.orders"),
      options: {
        display: isColumnVisible("overdueWorkOrders"),
        filter: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span style={{ color: "#ff1744" }}>{value}</span>;
        },
      },
    },
  ];

  let columnOrder = [];
  columnOrder =
    JSON.parse(localStorage.getItem("siteManager_ColumnOrder")) == undefined
      ? columns.forEach((column, index) => {
          columnOrder.push(index);
        })
      : JSON.parse(localStorage.getItem("siteManager_ColumnOrder"));

  const options = {
    draggableColumns: { enabled: true },
    filterType: "dropdown",
    responsive: "vertical",
    rowsPerPageOptions: [25, 50, 100],
    rowsPerPage: rowsPerPage,
    download: !isExtraSmallScreen,
    print: !isExtraSmallScreen,
    columnOrder,
    sortOrder,
    onColumnSortChange: (name, direction) => {
      setSortOrder({ name, direction });
    },
    onCellClick: (cellData, { colIndex, dataIndex }, _metaData) => {
      const columnName = columns[colIndex].name;
      const selectedItem = all.filter(
        (x) => x.projectId === requests[dataIndex].projectId
      )[0];
      if (requests[dataIndex].projectId !== selected.projectId) {
        dispatch(commonActions.selectBuilding(selectedItem));
      }
      let url = "/werk/" + selectedItem.projectNo + "/kwaliteitsborging";
      if (app === apps.aftercare) url = "/nazorg/meldingen";

      if (columnName.includes("WorkOrder"))
        url = url.replace(
          app === apps.aftercare ? "meldingen" : "kwaliteitsborging",
          "werkbonnen"
        );
      history.push({ pathname: url, state: { filter: columnName } });
    },
    customToolbarSelect: (selectedRows) => (
      //<CustomToolbarSelect selectedRows={selectedRows} />
      (<></>)
    ),
    onColumnOrderChange: (newColumnOrder, columnIndex, newPosition) => {
      localStorage.setItem(
        "siteManager_ColumnOrder",
        JSON.stringify(newColumnOrder)
      );
    },
    onViewColumnsChange: (changedColumn, action) => {
      if (action == "add") {
        var listToUpdate = columnsVisibility;

        var column = listToUpdate.find((x) => x.name === changedColumn);
        if (column) {
          column.visible = true;
        } else {
          listToUpdate.push({ name: changedColumn, visible: true });
        }
        setColumnsVisibility(listToUpdate);
        localStorage.setItem(
          "siteManager_ColumnsVisibility",
          JSON.stringify(listToUpdate)
        );
      } else if (action == "remove") {
        var listToUpdate = columnsVisibility;

        var column = listToUpdate.find((x) => x.name === changedColumn);
        if (column) {
          column.visible = false;
        } else {
          listToUpdate.push({ name: changedColumn, visible: false });
        }
        setColumnsVisibility(listToUpdate);
        localStorage.setItem(
          "siteManager_ColumnsVisibility",
          JSON.stringify(listToUpdate)
        );
      }
    },
    textLabels: getDataTableTextLabels(t, loading),
    onChangeRowsPerPage: (rowsPerPage) => {
      setRowsPerPage(rowsPerPage);
    },
  };

  if (buildingLoading)
    return (
      <div className={classes.loader}>
        <CircularProgress size={25} />
      </div>
    );

  return (
    <Container maxWidth={false} className={classes.mainContainer}>
      <MUIDataTable
        className={classes.dataTable}
        title={t("constructionquality.dashboard")}
        data={requests}
        columns={columns}
        options={options}
      />
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      display: "flex",
      "& > div": {
        padding: 0,
        textAlign: "right",
        flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTableCell-root": {
      "& .MuiButton-root > span div ": {
        textAlign: "start",
      },
      padding: theme.spacing(0, 0.5, 0, 0),
      cursor: "pointer",
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "left",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
        "& .MuiButtonBase-root": {
          textAlign: "left",
        },
      },
    },
  },
  grow: {
    flexGrow: 1,
  },
  fullWidth: {
    width: "100%",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}))
