import { Checkbox, Tooltip, Typography } from '@mui/material';
import { CheckCircle, History, VisibilityOff, PriorityHigh, } from '@mui/icons-material';
import React from 'react';
import clsx from "clsx";
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../_helpers';
import { useDispatch } from 'react-redux';
import { dossiersActions } from '../../../_actions/dossiers.actions';

const DeadLineCellInfo = ({ expandClass, selectedDossier, selectedBuildings, dossier, classes, indeterminate, isSelected, ...props }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSelectDossier = (e, dossierId) => {
        e.stopPropagation();
        dispatch(dossiersActions.selectAllDossiersBuilding(dossierId));
    }

    return (<>
        <div className={classes.dossierHeaderTitles} style={{ justifyContent: 'flex-start' }}>
            <Checkbox
                indeterminate={indeterminate}
                checked={isSelected}
                onChange={(e) => handleSelectDossier(e, dossier.id)} name="checkbox"
                color='default' className={classes.checkBox} />
            <div className={expandClass}>
                <Typography className={`${classes.dossierName} ${classes.grow}`} title={dossier.name}>
                    &nbsp;
                    {dossier.name}
                    &nbsp;
                    {
                        dossier.status === 2 &&
                        <Tooltip title={t('dossier.status.2')}><CheckCircle fontSize="medium" className={classes.colorSuccess} /></Tooltip>
                    }
                    {
                        dossier.status !== 2 && (dossier.isOverdue || dossier.is48hoursReminder) &&
                        <Tooltip
                            title={dossier.is48hoursReminder ? t("general.48hours") : dossier.isOverdue ? t("general.overdue") : ''}>
                            <History
                                className={clsx(dossier.is48hoursReminder && classes.warning, dossier.isOverdue && classes.pending, classes.icon)} />
                        </Tooltip>
                    }
                    {
                        dossier.hasUpdates &&
                        <Tooltip title={t('dossier.sidebar.priority.high')}>
                            <PriorityHigh color="error" />
                        </Tooltip>
                    }
                </Typography>
                {
                    dossier.isExternal !== true &&
                    <Tooltip title={t('dossier.sidebar.invisibleToBuyers')}><VisibilityOff fontSize="medium" color="action" /></Tooltip>
                }
            </div>
        </div>
        <div className={clsx(classes.dossierHeaderTitles, expandClass)}>
            <Typography variant="body2" sx={{
                color: "text.secondary"
            }}>
                {dossier.deadline && formatDate(new Date(dossier.deadline))}
            </Typography>
        </div>
    </>);
}

export default DeadLineCellInfo;