
import React from 'react'
import { Box, Card, Divider, Drawer, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material'
import {CancelRounded, Close, RemoveRedEye } from '@mui/icons-material';
import { useState } from 'react';
import CustomPopover from './CustomPopover';
import { useEffect } from 'react';
import { getOrganizations } from '../../../apis/surveyingApis';
import Loader from './Loader';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';
import { formatDate } from "../../../_helpers";




function StakeHolders({isDisabledEditing, isOrganisation = false, contractDetails,updateContractDetails, loadingKey}) {
  const [isStackHolderBuyerRecordDrawerOpen, setIsStackHolderBuyerRecordDrawerOpen] = useState(false)
  const [isOrganisationDetailsLoading, setIsOrganisationDetailsLoading] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [organisationDetails, setOrganisationDetails] = useState({organisationList : [] , organisationListWithrelations:[]})
  const [organizationSubListKey, setOrganizationSubListKey] = useState("");
  const {t} = useTranslation()
  const theme = useTheme();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubListItemClick = (value, responseKey)=>{
    if(organizationSubListKey){
      updateContractDetails(organizationSubListKey, value, responseKey)
      setOrganizationSubListKey("")
      setAnchorEl(null)
    }
  }

  const open = Boolean(anchorEl?.anchorEl);
  const id = open ? 'simple-popover' : undefined;


  useEffect(() => {
    (async()=>{
      try{
        setIsOrganisationDetailsLoading(true);
        const res = await getOrganizations([]);
        setOrganisationDetails({
          organisationList : res.data?.map((organisation)=>({organisationName:organisation?.name , organisationId:organisation?.id })),
          organisationListWithrelations: res.data?.map((organisation)=>({organisationName:organisation?.name , organisationId:organisation?.id , relations:organisation?.relations?.map((relation) => ({name: relation?.name, id: relation?.id})) })),
        })
        setIsOrganisationDetailsLoading(false)
      }catch(e){
        console.log(e)
      }
    })()
  }, [])

  const organisationListWithrelations = organisationDetails?.organisationListWithrelations
  const buyerOrganisationId = contractDetails?.buyerOrganisationId

  const buyerOrganisation = organisationListWithrelations?.find((organisationListWithrelation)=>organisationListWithrelation?.organisationId === buyerOrganisationId)
  const handleClick = (event, field , responseKey) => {
    setAnchorEl({field , anchorEl: event.currentTarget , responseKey});
    setOrganizationSubListKey(field)
  };

  const formatBuyerOrganisationRelationAddress = (addressObject) => {
    const { houseNo = "", houseNoAddition = "", place = "", postcode = "", street = "" } = addressObject ?? {}
    let address = ""
    // FIXME: address format confirmation
    if (street) address += `${street} `
    if (houseNo) address += `${houseNo} `
    if (houseNoAddition) address += `${houseNoAddition} `
    if (postcode) address += `, ${postcode} `
    if (place) address += `${place}`
    return address
  }

  return (
    (<div><Typography variant='subtitle1' sx={{
            mb: 1.5
          }}>{t("contract.details.stakeholders.title")}</Typography>
      <Stack sx={{
        rowGap: 2
      }}>
        {
          isOrganisation ?
           <>
            <TextField
              fullWidth
              label={t("contract.details.stakeholders.buyerOrganization")}
              type="text"
              size='small'
              onClick={() => setIsStackHolderBuyerRecordDrawerOpen(true)}
              value={contractDetails?.buyerOrganisationName}
              slotProps={{
                input: {
                  endAdornment: <RemoveRedEye sx={{color: theme.palette.grey[500]}}/>,
                },

                htmlInput: { readOnly: true, },

                inputLabel: {
                  shrink: true,
                }
              }} />
            <TextField
              fullWidth
              label={t("contract.details.stakeholders.buyerCompanyRepresentative1")}
              type="text"
              size='small'
              value={contractDetails?.buyer1?.signerName}
              error={!contractDetails?.buyer1?.email}
              helperText={!contractDetails?.buyer1?.email ?  t("contract.details.stakeholders.buyer1EmailRequired"):contractDetails?.buyer1?.email}
              slotProps={{
                htmlInput: { readOnly: true, },

                inputLabel: {
                  shrink: true,
                }
              }} />
            
             {loadingKey === "buyerrelation2" ? <Loader />:   <TextField
               fullWidth
               label={t("contract.details.stakeholders.buyerCompanyRepresentative2")}
               type="text"
               size='small'
               value={contractDetails?.buyer2?.signerName}
               error={contractDetails?.buyer2 && !contractDetails?.buyer2?.email}
               helperText={contractDetails?.buyer2 && !contractDetails?.buyer2?.email ?  t("contract.details.stakeholders.buyer2EmailRequired"):contractDetails?.buyer2?.email}
               aria-describedby={anchorEl?.field === "buyerrelation2"? id:undefined}
               onClick={(event)=>isDisabledEditing ? null : handleClick(event, "buyerrelation2" ,"seller2.searchName")}
               disabled={isDisabledEditing}
               slotProps={{
                 input: {
                   endAdornment:  (
                     <InputAdornment position='end'>
                     <IconButton sx={{p:0}}
                       disabled={isDisabledEditing}
                       onClick={(e) =>{
                         if(!isDisabledEditing){
                         e.stopPropagation()
                          updateContractDetails("buyerrelation2","" , "seller2.searchName", true)}
                         }}
                       ><CancelRounded sx={{color: theme.palette.grey[500]}}/></IconButton>
                       </InputAdornment>
                   )
                 },

                 inputLabel: {
                   shrink: true,
                 }
               }} />}
          </> :
           <>
            <Stack
              direction="row"
              sx={{
                columnGap: 1.5,
                my: 1.5
              }}>
              <TextField
                fullWidth
                error={!contractDetails?.buyer1?.email}
                helperText={!contractDetails?.buyer1?.email ?  t("contract.details.stakeholders.buyer1EmailRequired"):contractDetails?.buyer1?.email}
                label={t("general.buyer1")}
                type="text"
                size='small'
                onClick={() => setIsStackHolderBuyerRecordDrawerOpen(true)}
                value={contractDetails?.buyer1?.searchName}
                slotProps={{
                  input: {
                    endAdornment: <RemoveRedEye sx={{color: theme.palette.grey[500]}}/>,
                  },

                  htmlInput: { readOnly: true, },

                  inputLabel: {
                    shrink: true,
                  }
                }} />
            </Stack>
           {contractDetails?.buyer2 && <Stack
             direction="row"
             sx={{
               columnGap: 1.5,
               my: 1.5
             }}>
              <TextField
                fullWidth
                label={t("general.buyer2")}
                type="text"
                size='small'
                onClick={() => setIsStackHolderBuyerRecordDrawerOpen(true)}
                value={contractDetails?.buyer2?.searchName}
                error={contractDetails?.buyer2 && !contractDetails?.buyer2?.email}
                helperText={contractDetails?.buyer2 && !contractDetails?.buyer2?.email ?  t("contract.details.stakeholders.buyer2EmailRequired"):contractDetails?.buyer2?.email}
                slotProps={{
                  input: {
                    endAdornment: <RemoveRedEye sx={{color: theme.palette.grey[500]}}/>,
                  },

                  htmlInput: { readOnly: true, },

                  inputLabel: {
                    shrink: true,
                  }
                }} />
            </Stack>}
          </>
        }

      {loadingKey === "sellerrelation1" ? <Loader />:
      <TextField
        fullWidth
        helperText={!contractDetails?.seller1?.email ?  t("contract.details.stakeholders.entrepreneur1EmailRequired"):contractDetails?.seller1?.email}
        error={!contractDetails?.seller1?.email}
        id="component-error-text"
        label={t("contract.details.stakeholders.entrepreneur1")}
        type="text"
        size='small'
        value={contractDetails?.seller1?.searchName}
        aria-describedby={anchorEl?.field === "sellerrelation1"? id:undefined}
        onClick={(event)=>isDisabledEditing ? null : handleClick(event, "sellerrelation1")}
        disabled={isDisabledEditing}
        slotProps={{
          input: {
            endAdornment:  (
              <InputAdornment position='end'>
              <IconButton sx={{p:0}}
                disabled={isDisabledEditing}
                onClick={(e) =>{
                  if(!isDisabledEditing){
                  e.stopPropagation()
                   updateContractDetails("sellerrelation1","" , "seller1.searchName", true)}
                  }
                  }
                ><CancelRounded sx={{color: theme.palette.grey[500]}}/></IconButton>
                </InputAdornment>
            )
          },

          inputLabel: {
            shrink: true,
          }
        }} />
        }

      {loadingKey === "sellerrelation2" ? <Loader />:
       <TextField
         fullWidth
         error={contractDetails?.seller2 && !contractDetails?.seller2?.email}
         helperText={contractDetails?.seller2 && !contractDetails?.seller2?.email ?  t("contract.details.stakeholders.entrepreneur2EmailRequired"):contractDetails?.seller2?.email}
         label={t("contract.details.stakeholders.entrepreneur2")}
         type="text"
         size='small'
         value={contractDetails?.seller2?.searchName}
         aria-describedby={anchorEl?.field === "sellerrelation2"? id:undefined}
         onClick={(event)=>isDisabledEditing ? null : handleClick(event, "sellerrelation2" , "seller2.searchName")}
         disabled={isDisabledEditing}
         slotProps={{
           input: {
             endAdornment:  (
               <InputAdornment position='end'>
               <IconButton sx={{p:0}}
                 disabled={isDisabledEditing}
                 onClick={(e) =>{
                   if(!isDisabledEditing){
                   e.stopPropagation()
                   updateContractDetails("sellerrelation2","", "seller2.searchName", true)}
                 }
                   }
                 ><CancelRounded sx={{color: theme.palette.grey[500]}}/></IconButton>
                 </InputAdornment>
             )
           },

           inputLabel: {
             shrink: true,
           }
         }} />
        }

       

       <CustomPopover open={open} anchorEl={anchorEl?.anchorEl} handleClose={handleClose} data={organizationSubListKey === "buyerrelation2"? 
        [buyerOrganisation]
       : organisationDetails?.organisationListWithrelations  } handleSubListItemClick={handleSubListItemClick} responseKey={anchorEl?.responseKey} 
       isOrganisationDetailsLoading={isOrganisationDetailsLoading}
       />
      </Stack>
      {/* buyer record drawer start */}
      <Drawer
          anchor="right"
          open={isStackHolderBuyerRecordDrawerOpen}
          PaperProps={{
            sx: {
              width: {
                xs: "100vw", md: "30vw"
              },
              maxWidth: "508px",
              height: {
                top: "64px",
                xs: "calc(100% - 64px)", md: "calc(100% - 64px)"
              }
            },
          }}
          hideBackdrop
          onClose={() => setIsStackHolderBuyerRecordDrawerOpen(false)}>
          <Box>
            <Stack
              direction='row'
              sx={{
                justifyContent: 'space-between',
                px: 2.5,
                py: 1,
                alignItems: "center"
              }}>
              <Box>{isOrganisation && t("contract.details.drawer.buyerOrganisation.title")}</Box>
              <Stack direction="row" sx={{
                columnGap: 1
              }}>
                <IconButton onClick={() => setIsStackHolderBuyerRecordDrawerOpen(false)}>
                  <Close />
                </IconButton>
              </Stack>
            </Stack>
            <Divider />
                    {
                        isOrganisation &&
                        <Box
                          sx={{
                            px: 2.5,
                            py: 1
                          }}>{contractDetails?.buyerOrganisationName}</Box>
                    }

            <Stack
              sx={{
                rowGap: 3,
                p: 2.5
              }}>
              {/* buyer 1 start */}
              <Card sx={{ px: 2.5, py: 1.5 }}>
                <Typography variant='subtitle1' sx={{
                  mb: 1.5
                }}>{isOrganisation ? t("contract.details.drawer.buyerRelation1.title") : t("general.buyer1")}</Typography>
                <Stack sx={{
                  rowGap: 2
                }}>
                  <TextField
                    fullWidth
                    label={t("general.lastName")}
                    type="text"
                    size='small'
                    disabled
                    value={contractDetails?.buyer1?.lastName}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                  <TextField
                    fullWidth
                    size='small'
                    label={t("general.firstName")}
                    type="text"
                    disabled
                    value={contractDetails?.buyer1?.firstName}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                    <TextField
                    fullWidth
                    size='small'
                    label={t("general.namePrefixes")}
                    type="text"
                    disabled
                    value={contractDetails?.buyer1?.middleName}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                    <TextField
                    fullWidth
                    size='small'
                    label={t("general.initials")}
                    type="text"
                    disabled
                    value={contractDetails?.buyer1?.initials}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                  <TextField
                    fullWidth
                    label={t("general.placeOfBirth")}
                    size='small'
                    type="text"
                    disabled
                    value={contractDetails?.buyer1?.birthPlace}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                  <TextField
                    fullWidth
                    label={t("general.dateOfBirth")}
                    size='small'
                    disabled
                    type="text"
                    value={contractDetails?.buyer1?.birthDate && formatDate(new Date(contractDetails?.buyer1?.birthDate), false)}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                  <TextField
                    fullWidth
                    label={(t("general.address"))}
                    size='small'
                    disabled
                    type="text"
                    value={formatBuyerOrganisationRelationAddress(contractDetails?.buyer1?.address)} 
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                  <TextField
                    fullWidth
                    label={t("general.mobileNumber")}
                    size='small'
                    disabled
                    type="text"
                    value={contractDetails?.buyer1?.mobile}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                  <TextField
                    fullWidth
                    label={t("general.email.label")}
                    size='small'
                    disabled
                    type="text"
                    value={contractDetails?.buyer1?.email}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                  <TextField
                    fullWidth
                    label={t("contract.details.stakeholders.maritalStatus")}
                    size='small'
                    disabled
                    type="text"
                    value={contractDetails?.buyer1?.maritalStatus}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                </Stack>
              </Card>
              {/* buyer 1 end */}

              {/* buyer 2 start */}
                        {
                            !!contractDetails?.buyer2 &&
              <Card sx={{ px: 2.5, py: 1.5 }}>
                <Typography variant='subtitle1' sx={{
                  mb: 1.5
                }}>{isOrganisation ? t("contract.details.drawer.buyerRelation2.title") : t("general.buyer2")}</Typography>
                <Stack sx={{
                  rowGap: 2
                }}>
                <TextField
                    fullWidth
                    label={t("general.lastName")}
                    type="text"
                    size='small'
                    disabled
                    value={contractDetails?.buyer2?.lastName}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                  <TextField
                    fullWidth
                    size='small'
                    label={t("general.firstName")}
                    type="text"
                    disabled        
                    value={contractDetails?.buyer2?.firstName}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                  <TextField
                    fullWidth
                    label={t("general.placeOfBirth")}
                    size='small'
                    type="text"
                    disabled
                    value={contractDetails?.buyer2?.birthPlace}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                  <TextField
                    fullWidth
                    value={contractDetails?.buyer2?.birthDate && formatDate(new Date(contractDetails?.buyer2?.birthDate), false)}
                    disabled
                    label={t("general.dateOfBirth")}
                    size='small'
                    type="text"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                  <TextField
                    fullWidth
                    value={formatBuyerOrganisationRelationAddress(contractDetails?.buyer2?.address)} 
                    disabled
                    label={(t("general.address"))}
                    size='small'
                    type="text"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                  <TextField
                    fullWidth
                    label={t("general.mobileNumber")}
                    size='small'
                    type="text"
                    value={contractDetails?.buyer2?.mobile}
                    disabled
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                  <TextField
                    fullWidth
                    label={t("general.email.label")}
                    size='small'
                    type="text"
                    value={contractDetails?.buyer2?.email}
                    disabled
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />

                  <TextField
                    fullWidth
                    label={t("contract.details.stakeholders.maritalStatus")}
                    size='small'
                    disabled
                    type="text"
                    value={contractDetails?.buyer2?.maritalStatus}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                
                  
                </Stack>
                            </Card>
                        }
              {/* buyer 2 end */}
            </Stack>
          </Box>
        </Drawer>
      {/* buyer record drawer end */}
    </div>)
  );
}

export default StakeHolders