import React, { useEffect, useState } from "react";
import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  Slide,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  Hidden,
  Snackbar,
  Chip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  AddCircleOutline,
  Cancel,
  CheckCircle,
  DeleteOutline,
} from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import clsx from "clsx";
import Dropzone from "../../../components/Dropzone";
import { TextField } from "@mui/material";
import { useTheme } from "@mui/material";
import Camera from "../components/Camera";
import { CircularProgress } from "@mui/material";
import {
  isValidFiles,
  toBase64,
  totalFilesSizeIsValid,
} from "../../../_helpers";
import { useSelector } from "react-redux";
import { Alert } from "@mui/material";
import { useDispatch } from "react-redux";
import { surveyActions } from "../../../_actions";
import SelectOrganizations from "./SelectOrganizations";

const { webApiUrl } = window.appConfig;
const buttonText = {
  rework: "Bijwerken",
  reject: "Opnieuw uitvoeren",
  approve: "Goedkeuren",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RepairRequestInfoModal({
  open,
  selectedRequest,
  infoType,
  canDetailView,
  handleClose,
  ...props
}) {
  const { t } = useTranslation();
  const [isOpenResolver, setIsOpenResolver] = useState(null);
  const [openCameraDrawer, setOpenCameraDrawer] = useState(false);
  const classes = useStyles();
  const muiTheme = useTheme();
  const matchWidthDownSm = useMediaQuery(muiTheme.breakpoints.down("md"));
  const matchWidthDownXs = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const [organizationLoading, setOrganizationLoading] = useState(false);
  const { isCompleteRRLoading, isUpdateReworkLoading } = useSelector(
    (state) => state.surveying
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [oplossers, setOplossers] = useState();
  const [fileError, setFileError] = useState({
    filesSizeIsInValid: false,
    fileUploadErrorMessage: null,
  });
  const [allOrgs, setAllOrgs] = useState([]);
  const dispatch = useDispatch();
  const [request, setRequest] = useState({
    attachments: [],
    shortDesc: "",
    detailedDesc: "",
    term: "",
    location: "",
    remarks: "",
  });

  useEffect(() => {
    if (isSubmitting && (isCompleteRRLoading || isUpdateReworkLoading)) {
      handleClose();
    }
  }, [isSubmitting, isCompleteRRLoading, isUpdateReworkLoading]);
  useEffect(() => {
    if (open && selectedRequest) {
      setRequest((p) => ({
        ...p,
        shortDesc: selectedRequest.desc,
        detailedDesc: selectedRequest.detailDesc,
        location: selectedRequest.location,
        remarks: "",
      }));
    }
  }, [open, selectedRequest]);

  const handleChange = (field, value) => {
    setRequest((p) => ({ ...p, [field]: value }));
  };

  const closeCameraHandler = () => {
    setOpenCameraDrawer(false);
  };

  const cameraFileSelectHandler = async (file) => {
    const data = [...request.attachments];
    setOpenCameraDrawer(false);
    const attachmentUrl = await toBase64(file);
    data.push({ file, attachmentUrl, attachmentId: Math.random().toString() });
    setRequest((p) => ({ ...p, attachments: data }));
  };

  const handleRequest = async (event) => {
    const formData = new FormData();
    formData.append("repairRequestId", selectedRequest.requestId);
    if (infoType === "rework" || infoType === "reject") {
      const resolverOrganisations = oplossers
        ? oplossers.orgIds.map((orgId) => {
            const selectedPerson =
              oplossers.relationIds.find((p) => p.orgId === orgId) || {};
            return {
              organisationId: orgId,
              relationId: selectedPerson.relationId,
              createWorkorder: selectedPerson.createWorkOrder,
              notify: selectedPerson.notify,
              resolverId: selectedPerson.resolverId,
            };
          })
        : [];

      formData.append(
        "resolverOrganisations",
        JSON.stringify(resolverOrganisations)
      );
      for (var i = 0; i < request.attachments.length; i++) {
        formData.append("files", request.attachments[i].file);
      }
    }
    formData.append("desc", request.remarks);

    dispatch(
      surveyActions[
        infoType === "approve" ? "markCompleteRRActions" : "addOrUpdateRework"
      ](
        infoType === "approve"
          ? [{ ...selectedRequest, ...request }]
          : formData,
        selectedRequest.requestId
      )
    );
    setIsSubmitting(true);
  };

  const fileUploadHandler = (data, isExistingImage) => {
    let attachments = [...request.attachments];
    data = Array.from(data);
    if (isExistingImage) {
      setRequest((p) => ({ ...p, attachments: attachments.concat(data) }));
      return;
    }
    if (!isValidFiles(data, ["images"])) {
      setFileError({
        filesSizeIsInValid: true,
        fileUploadErrorMessage: "files.total.type.notvalid.error",
      });
      return;
    }
    if (
      totalFilesSizeIsValid([...attachments.map((f) => f.file), ...data], 25)
    ) {
      Promise.all(
        data.map(async (file, i) => {
          const attachmentUrl = await toBase64(file);
          const attachmentId = Math.random().toString();
          return { attachmentUrl, attachmentId, file };
        })
      ).then((files) => {
        setRequest((p) => ({ ...p, attachments: attachments.concat(files) }));
      });
    } else
      setFileError({
        filesSizeIsInValid: true,
        fileUploadErrorMessage: "files.total.size.notvalid.error",
      });
  };

  const handleSelectPerson = (data, organizations) => {
    setAllOrgs(organizations);
    setOplossers(data);
    setIsOpenResolver(null);
  };

  const handleRemoveImage = (e, index) => {
    e.stopPropagation();
    const data = Object.assign([], request.attachments);
    data.splice(index, 1);
    setRequest((p) => ({ ...p, attachments: data }));
  };
  return (selectedRequest && (<>
    <Dialog
      onClose={handleClose}
      fullScreen={matchWidthDownXs}
      TransitionComponent={Transition}
      fullWidth
      className={classes.requestContainer}
      maxWidth="sm"
      open={open}
      aria-labelledby="form-dialog-title"
      scroll="paper"
    >
      <DialogTitle
        className={classes.dialogTitle}
        id="dialog-objects-title"
      >
        <Grid className={classes.dialogTitleContent} container spacing={1}>
          <Grid className={classes.grow}>
            <Typography variant="h5" className={classes.dialogTitleTypo}>
              {t("repairRequest.deffectNoWithDescription", {
                number: selectedRequest.number,
                description: selectedRequest.desc,
              })}
            </Typography>
          </Grid>
          {canDetailView ? (
            <Grid>
              <IconButton
                onClick={handleClose}
                className={classes.closeButton}
                color="inherit"
                aria-label="close"
                size="large"
              >
                <Close className={classes.iconStyle} />
              </IconButton>
            </Grid>
          ) : (
            <Grid className={classes.buttonsContainer}>
              <Button
                variant="outlined"
                onClick={handleClose}
                className={classes.closeButton}
                aria-label="close"
              >
                <Typography>{t("general.cancel")}</Typography>
              </Button>
              <Button
                disabled={isCompleteRRLoading || isUpdateReworkLoading}
                variant="outlined"
                onClick={() => handleRequest(infoType)}
                className={classes.addButton}
                startIcon={
                  (isCompleteRRLoading || isUpdateReworkLoading) && (
                    <CircularProgress size={18} />
                  )
                }
              >
                <Typography>{t(buttonText[infoType])}</Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogTitle>
      {infoType === "approve" && (
        <DialogContent className={classes.dialogContent}>
          <Grid container className={classes.gridContainerInfo}>
            <Grid size={{xs: 12}}>
              <TextField
                multiline
                id="outlined-select-currency"
                margin="normal"
                className={classes.textArea}
                fullWidth
                size="small"
                label={t("general.comments.label")}
                name="remarks"
                value={request.remarks}
                onChange={({ target: { value, name } }) =>
                  handleChange(name, value)
                }
                variant="outlined"
                slotProps={{
                  inputLabel: {
                    shrink: true,
                  }
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      )}
      {infoType === "reject" && (
        <DialogContent className={classes.dialogContent}>
          <Snackbar
            TransitionComponent={(props) => (
              <Slide {...props} direction="left" />
            )}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={fileError.filesSizeIsInValid}
            onClose={() =>
              setFileError((p) => ({ ...p, filesSizeIsInValid: false }))
            }
            autoHideDuration={3000}
            key={"file-size-validation"}
          >
            <Alert elevation={6} variant="filled" severity="error">
              {t(fileError.fileUploadErrorMessage)}
            </Alert>
          </Snackbar>
          <Grid container className={classes.gridContainerInfo}>
            <Grid size={{xs: 12}}>
              <CardContent
                className={classes.explanationContainer}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <Typography className={classes.subTitle} gutterBottom>
                  {t("repairRequest.addImages")}
                </Typography>
                <Grid className={classes.filesContainer} size={{xs:12}}>
                  <Grid
                    className={classes.attachmentItemContainer}
                    style={{ marginLeft: 0 }}
                    container
                    sx={{
                      justifyContent: "center"
                    }}
                  >
                    <Dropzone
                      openCamera={
                        matchWidthDownSm
                          ? () => setOpenCameraDrawer(true)
                          : false
                      }
                      iconClasses={classes.uploadIcon}
                      className={classes.dropzone}
                      // withUploadFileDialog
                      existingImageModal
                      accept="image/*"
                      isSurveying
                      disabled={infoType === "approve"}
                      isHideLocatie
                      title="general.choosePhotosOrAttachments"
                      handleSelectExistingImages={(data) =>
                        fileUploadHandler(data, true)
                      }
                      onFilesAdded={fileUploadHandler}
                    />
                  </Grid>
                  <Grid container className={classes.gridContainer}>
                    {request.attachments &&
                      request.attachments.map((p, i) => (
                        <Grid
                          key={p.attachmentId}
                          className={classes.attachmentItemContainer}
                          style={{ minWidth: 77 }}
                        >
                          {
                            <div className={classes.overlay}>
                              <div className={classes.isRight}>
                                <Tooltip title={t("general.remove")}>
                                  <IconButton
                                    className={clsx(
                                      classes.deleteButton,
                                      classes.noOutline
                                    )}
                                    size={"small"}
                                  >
                                    <DeleteOutline
                                      className={classes.deleteIcon}
                                      size={"small"}
                                      onClick={(e) =>
                                        handleRemoveImage(e, i)
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          }
                          <Grid className={classes.thumnailContainer}>
                            <Grid
                              className={classes.thumnailContainer}
                              container
                              sx={{
                                justifyContent: "center"
                              }}
                              size={{xs:12}}
                            >
                              {p.attachmentUrl ? (
                                <img
                                  className={classes.thumbnail}
                                  src={p.attachmentUrl}
                                  alt="img"
                                />
                              ) : (
                                <img
                                  className={classes.thumbnail}
                                  src={`${webApiUrl}api/RepairRequest/GetImage/${p.requestId}/${p.attachmentId}`}
                                  alt="img"
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>
                <Grid className={classes.oplosserContainer} size={{xs:12}}>
                  <Typography className={classes.oplosserSubTitle}>
                    {t(
                      infoType === "approve"
                        ? "general.resolvers"
                        : "Oude oplossers(s)"
                    )}
                  </Typography>
                  {selectedRequest &&
                    selectedRequest.resolvers &&
                    !!selectedRequest.resolvers.length && (
                      <>
                        {selectedRequest.resolvers.map((r) => {
                          return (
                            <Chip
                              key={r.name}
                              className={classes.chip}
                              label={
                                <div className={classes.oplosserContainer}>
                                  {r.relationName ? (
                                    <>
                                      <span>{r.relationName}</span>
                                      <span>{r.name}</span>
                                    </>
                                  ) : (
                                    <>{r.name}</>
                                  )}
                                </div>
                              }
                            />
                          );
                        })}
                      </>
                    )}
                  <Button
                    onClick={({ currentTarget }) =>
                      setIsOpenResolver(currentTarget)
                    }
                    disabled={organizationLoading || infoType === "approve"}
                    className={classes.oplossersButton}
                    startIcon={<AddCircleOutline />}
                  >
                    <Typography>{t("general.resolvers")}</Typography>
                  </Button>
                  <Typography className={classes.oplosserSubTitle}>
                    {t("repairRequest.newResolverS")}
                  </Typography>
                  {oplossers &&
                    oplossers.orgIds.map((orgId) => {
                      const selectedOrg =
                        allOrgs.find((p) => p.id === orgId) || {};
                      const person = selectedOrg.relations.find(
                        (a) =>
                          a.id ===
                          (
                            oplossers.relationIds.find(
                              (p) => p.orgId === orgId
                            ) || {}
                          ).relationId
                      );
                      return (
                        <Chip
                          className={classes.chip}
                          label={
                            <div className={classes.oplosserContainer}>
                              {person ? (
                                <>
                                  <span>{person.name}</span>
                                  <span>{selectedOrg.name}</span>
                                </>
                              ) : (
                                <>{selectedOrg.name}</>
                              )}
                            </div>
                          }
                          onDelete={(e) =>
                            setOplossers((p) => ({
                              ...p,
                              orgIds: p.orgIds.filter((a) => a !== orgId),
                            }))
                          }
                        />
                      );
                    })}
                </Grid>
                <Grid size={{xs: 12}}>
                  <TextField
                    multiline
                    id="outlined-select-currency"
                    margin="normal"
                    className={classes.textArea}
                    fullWidth
                    size="small"
                    label={t("general.comments.label")}
                    name="remarks"
                    value={request.remarks}
                    onChange={({ target: { value, name } }) =>
                      handleChange(name, value)
                    }
                    variant="outlined"
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      }
                    }}
                  />
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
        </DialogContent>
      )}
      {canDetailView && (
        <DialogActions style={{ justifyContent: "flex-end" }}>
          <Button
            disabled={
              isCompleteRRLoading ||
              isUpdateReworkLoading ||
              (!selectedRequest.completed && selectedRequest.isRework)
            }
            variant="outlined"
            onClick={() => handleRequest("reject")}
            startIcon={
              isCompleteRRLoading || isUpdateReworkLoading ? (
                <CircularProgress size={18} />
              ) : (
                <Cancel color="error" size="small" />
              )
            }
          >
            <Typography>{t("general.button.reject")}</Typography>
          </Button>
          <Button
            disabled={
              isCompleteRRLoading ||
              isUpdateReworkLoading ||
              selectedRequest.completedByBuyer
            }
            variant="outlined"
            onClick={() => handleRequest("approve")}
            startIcon={
              isCompleteRRLoading || isUpdateReworkLoading ? (
                <CircularProgress size={18} />
              ) : (
                <CheckCircle
                  className={classes.colorSuccess}
                  size="small"
                />
              )
            }
          >
            <Typography>{t("general.approve")}</Typography>
          </Button>
        </DialogActions>
      )}
    </Dialog>
    <Hidden only={["md", "lg", "xl"]}>
      <Camera
        closeDrawer={cameraFileSelectHandler}
        open={openCameraDrawer}
        close={closeCameraHandler}
      />
    </Hidden>
    <SelectOrganizations
      projectId={selectedRequest && selectedRequest.projectId}
      onSubmit={handleSelectPerson}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      selectOrg
      oplossers={oplossers}
      loading={organizationLoading}
      setLoading={(val) => setOrganizationLoading(val)}
      anchorEl={isOpenResolver}
      onClose={() => setIsOpenResolver(null)}
    />
  </>));
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  chip: {
    height: "fit-content",
    padding: theme.spacing(0.6),
    margin: 5,
  },
  chipContainer: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  appHeader: {
    display: "flex",
    maxWidth: "50%",
    overflow: "hidden",
  },
  appBarText: {
    color: theme.palette.common.white,
    fontSize: 16,
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  buttonsContainer: {
    width: "fit-content",
    whiteSpace: "nowrap",
  },
  autoComplete: {
    width: 190,
    [theme.breakpoints.down("md")]: {
      width: 160,
    },
  },
  headerNextPrevIconStyle: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.6),
    },
    "&:focus": {
      outline: "none !important",
    },
  },
  zoomButton: {
    borderRadius: 5,
    overflow: "hidden",
    padding: theme.spacing(0.5),
    margin: `0 5px`,
    height: "28px",
    width: "28px",
    "&:focus": {
      outline: "none !important",
    },
    "&::before": {
      content: '""',
      height: "100%",
      width: "100%",
      overflow: "hidden",
      opacity: 0.2,
      zIndex: "-1",
      position: "absolute",
      backgroundColor: theme.palette.grey[100],
    },
    "& .MuiSvgIcon-root": {
      fontSize: 18,
    },
  },
  autoTrim: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  autoCompleteInput: {
    marginTop: 0,
    marginBottom: 0,
    "& > .MuiAutocomplete-inputRoot": {
      background: theme.palette.common.white,
      "& > input": {
        fontSize: 12,
        maxWidth: 150,
      },
    },
  },
  root: {
    background: theme.palette.common.white,
    height: "100%",
    overflow: "auto",
    position: "relative",
  },
  circularProgress: {
    position: "absolute",
  },
  appBarSubTitle: {
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  appBarContainer: {
    display: "flex",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      display: "-webkit-box",
      flexFlow: "row wrap",
    },
    flexDirection: "row",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    height: "fit-content",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: `${theme.spacing(0)} !important`,
  },
  surveyMomentsMainHeaderSecond: {
    borderRadius: "0 !important",
    margin: "0 !important",
    padding: theme.spacing(1),
    [theme.breakpoints.up("xs")]: {
      height: "initial !important",
    },
    height: "100px !important",
    width: "100% !important",
  },
  documentNameContainer: {
    display: "flex",
    alignItems: "center",
  },
  oplosserContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: 10,
  },
  oplosserSubTitle: {
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: 16,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: "bold",
  },
  colorSuccess: {
    color: theme.palette.success.main,
  },
  wrapperClass: {
    height: "100%",
    width: "100%",
    // [theme.breakpoints.up('md')]:{
    //     display:'none'
    // }
    // // overflow: 'auto !important'
  },
  mapContainer: {
    height: "100%",
    width: "100%",
    // [theme.breakpoints.down('sm')]:{
    //     display:'none'
    // }
  },
  contentClass: {
    height: "100%",
    width: "100%",
  },
  docContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  canvasContainer: {
    height: "calc(100% - 35px)",
    margin: "auto",
  },
  locationOn: {
    position: "absolute",
    zIndex: 1,
  },
  requestContainer: {
    "& .MuiPaper-root": {
      borderRadius: 10,
      minWidth: 295,
    },
  },
  confirmationDialog: {
    "& .MuiPaper-root": {
      width: 450,
      borderRadius: 10,
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(3.75, 3.75, 0, 3.75),
      "& p": {
        color: theme.palette.grey[700],
        textAlign: "center",
      },
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",

      "& .MuiButtonBase-root": {
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(1, 1, 2, 1),
        },
        margin: "10px 17px 25px 17px",
      },
    },
  },
  mapDailog: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    "& .MuiPaper-root": {
      width: "100%",
      height: "100%",
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: "88%",
        borderRadius: 10,
        marginLeft: 18,
        marginTop: 60,
        marginRight: 18,
      },
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(3.75, 3.75, 0, 3.75),
      "& p": {
        color: theme.palette.grey[700],
        textAlign: "center",
      },
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",

      "& .MuiButtonBase-root": {
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(1, 1, 2, 1),
        },
        margin: "10px 17px 25px 17px",
      },
    },
  },
  dialogContextText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  dialogButtonPrimary: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 80,
      padding: theme.spacing(0.3),
    },
    minWidth: 100,
    background: theme.palette.primary.main,
    padding: theme.spacing(0.875, 1.25),
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  measure: {
    position: "absolute",
  },
  dialogButtonSecondary: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 80,
      padding: theme.spacing(0.3),
    },
    minWidth: 100,
    background: theme.palette.grey[500],
    padding: theme.spacing(0.875, 1.25),
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.grey[500],
    },
  },
  dialogTitle: {
    padding: theme.spacing(0.625),
    background: theme.palette.primary.main,
    "& .MuiIconButton-label > .MuiSvgIcon-root": {
      fill: theme.palette.grey[300],
    },
  },
  dialogTitleTypo: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  dialogTitleContent: {
    padding: theme.spacing(1.25),
    alignItems: "center",
    flexWrap: "nowrap",
  },
  closeButton: {
    marginRight: 5,
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    // padding: theme.spacing(0)
  },
  iconStyle: {
    fill: "white",
    width: "1em",
    height: "1em",
  },
  dialogContent: {
    padding: theme.spacing(1.25),
    minHeight: "40vh",
    display: "flex",
  },
  subTitle: {
    fontSize: 16,
  },
  filesContainer: {
    display: "flex",
    margin: `5px 0`,
    flexBasis: 0,
  },
  gridContainer: {
    display: "flex",
    flexWrap: "nowrap",
    overflow: "auto",
  },
  gridContainerInfo: {
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  explanationContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  attachmentItemContainer: {
    position: "relative",
    margin: "0 4px",
    background: theme.palette.grey[100],
    boxShadow: "3px 3px 4px #eee",
    height: 88,
    maxWidth: 88,
    borderRadius: 5,
    "&:hover": {
      "& $overlay": {
        opacity: 1,
      },
      "& $attachmentStatus": {
        opacity: 1,
        right: 10,
      },
    },
  },
  overlay: {
    position: "absolute",
    borderRadius: 5,
    color: " #f1f1f1",
    transition: ".5s ease",
    opacity: 0,
    fontSize: "20px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 77,
    zIndex: 1,
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
  thumnailContainer: {
    width: "100%",
    height: "100%",
  },
  thumbnail: {
    width: "100%",
    height: "100%",
    OObjectFit: "cover",
  },
  deleteIcon: {
    fill: theme.palette.grey[700],
    fontSize: 18,
  },
  isRight: {
    position: "absolute",
    right: 3,
    top: 2,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noOutline: {
    outline: "none !important",
  },
  textField: {
    "& > label": {
      color: theme.palette.primary.dark,
    },
  },
  textArea: {
    marginTop: 0,
    height: "100%",
    "& > label": {
      color: theme.palette.primary.dark,
    },
    "& > div": {
      height: "100%",
      "& > textarea": {
        overflow: "auto !important",
        height: "100% !important",
      },
    },
  },
  oplossersButton: {
    borderRadius: 50,
    margin: theme.spacing(1, 0, 1, 0),
    boxShadow: `3px 3px 6px ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[300],
    "& .MuiButton-label": {
      paddingRight: theme.spacing(1),
      fontWeight: "normal",
      fontSize: 14,
      color: theme.palette.grey[800],
      "& .MuiButton-startIcon": {
        marginRight: 10,
        marginLeft: 3,
        color: theme.palette.common.black,
      },
    },
  },
  locationContainer: {
    maxWidth: "fit-content",
    width: "100%",
    position: "relative",
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  locationPreview: {
    maxWidth: 250,
    maxHeight: 200,
    cursor: "pointer",
    borderRadius: 6,
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `1px 1px 3px ${theme.palette.grey[400]}`,
  },
  locationMarkPreview: {
    position: "absolute",
  },
  addButton: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
}));
