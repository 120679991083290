// NOTE: If any changes are made to the MobileGridHeader.jsx file, please ensure that the DesktopGridHeader.jsx file is also updated accordingly.
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem,
  GridCloseIcon,
  GridSearchIcon,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";
import { Box, IconButton, MenuItem, Stack, TextField, useMediaQuery } from "@mui/material";
import { common, grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import FilterButton from "./FilterButton";
import { useTheme } from "@mui/styles";

const MobileGridHeader = ({
  setColumnSelectorType,
  setOpenColumnsSelector,
  handleSearch,
  searchText,
  setSearchText,
  quickFilterItems,
  handleExportModal,
  toolbarStartActions,
  toolbarEndActions,
  currentView
}) => {
  const { t } = useTranslation();
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const hideGridToolbarOptionsForTabletOrMobile = !(isTabletOrMobile && currentView === "list") // hide column selection and density option for mobile or tablet

  const CustomExportButton = (props) => {
    return (
      <GridToolbarExportContainer {...props}>
        <GridCsvExportMenuItem
          onClick={(e) => {
            setColumnSelectorType("download-csv");
            setOpenColumnsSelector(true);
          }}
        />
        <MenuItem
          onClick={handleExportModal}
        >
          {t("datatable.label.toolbar.downloadExcel")}
        </MenuItem>
        <GridPrintExportMenuItem
          onClick={(e) => {
            setColumnSelectorType("print");
            setOpenColumnsSelector(true);
          }}
        />
      </GridToolbarExportContainer>
    );
  };

  return (
    (<GridToolbarContainer>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          display: { xs: "block" }
        }}>
        <Box
          sx={{
            width: "100%",
            padding: "1px 5px",
            borderRadius: "7px",
            boxShadow: theme.shadows[1],
            display: "flex",
            alignItems: "center"
          }}>
          <TextField
            fullWidth
            id="standard-search"
            placeholder={t("datatable.label.toolbar.search")}
            type="text"
            variant="standard"
            value={searchText}
            onChange={(e) => {
              setSearchText?.(e.target.value);
              handleSearch?.(e.target.value);
            }}
            sx={{ width: "100%", fontSize: "15px" }}
            slotProps={{
              input: {
                disableUnderline: true,
                startAdornment: (
                  <IconButton size="small">
                    <GridSearchIcon
                      sx={{ margin: "0px 5px", color: "primary.main" }}
                    />
                  </IconButton>
                ),
                endAdornment: (
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (searchText !== "") {
                        handleSearch?.("");
                        setSearchText?.("");
                      }
                    }}
                  >
                    {searchText && (
                      <GridCloseIcon
                        fontSize="small"
                        sx={{ margin: "0px 5px", color: "primary.main" }}
                      />
                    )}
                  </IconButton>
                ),
              }
            }}
          />
        </Box>
        <Stack
          direction="row"
          sx={{
            width: "100%",
            justifyContent: "space-around",
            alignItems: "center",
            mt: 1
          }}>
          <Stack
            direction="row"
            sx={[{
              justifyContent: "space-around",
              pt: 0.25,
              overflowX:"auto"
            }, quickFilterItems?.length === 0 ? {
              width: "100%"
            } : {
              width: "50%"
            }]}>
          {toolbarStartActions?.map((customToolbarStartAction) => {
              const IconComponent = customToolbarStartAction.icon;
              return (
                <IconButton onClick={customToolbarStartAction.onClick}>
                  <IconComponent color={customToolbarStartAction.iconColor || "primary"} />
                </IconButton>
              );
            })}
            {
              hideGridToolbarOptionsForTabletOrMobile &&
            <GridToolbarColumnsButton sx={{ minWidth: "auto" }} />
            }
            <GridToolbarFilterButton sx={{ minWidth: "auto" }} />
            {
              hideGridToolbarOptionsForTabletOrMobile &&
              <GridToolbarDensitySelector sx={{ minWidth: "auto" }}/>
            }
            <CustomExportButton sx={{ minWidth: "auto" }} />
            {toolbarEndActions?.map((customToolbarEndAction) => {
              const IconComponent = customToolbarEndAction.icon;
              return (
                <IconButton onClick={customToolbarEndAction.onClick}>
                  <IconComponent color={customToolbarEndAction.iconColor || "primary"} />
                </IconButton>
              );
            })}
          </Stack>
         {quickFilterItems?.length !== 0 &&
           <Box
             sx={{
               borderRight: "1px solid black",
               height: "20px",
               borderColor: grey[400],
               marginRight : "8px",
               overflowX:'auto'
             }}>
           <span></span>
         </Box>
         }
          <Stack
            direction="row"
            spacing={1}
            sx={[{
              justifyContent: "space-around",
              overflowX: "auto",
              overflowY: "hidden"
            }, quickFilterItems?.length === 0 ? {
              width: ""
            } : {
              width: "50%"
            }]}>
            {quickFilterItems?.map((item) => <FilterButton
              onClick={item.onClick}
              isSelected={item.isSelected}
            >
              {item.iconText && <span
                style={{
                  color: item.isSelected ? common.white : grey[600],
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {item.iconText}
              </span>}

              <Box
                sx={{
                  display: 'flex',
                  color: item.isSelected ? common.white : grey[600]
                }}>
                {item?.icon}
              </Box>
            </FilterButton>)}
          </Stack>
        </Stack>
      </Stack>
    </GridToolbarContainer>)
  );
};

export default MobileGridHeader;
