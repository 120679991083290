import { Box, CircularProgress } from '@mui/material'
import React from 'react'

const Loader = ({ backdrop, ...rest }) => {
    const styles = backdrop && {
        position: 'absolute',
        top: 0, left: 0,
        zIndex: 1,
        backgroundColor: 'rgba(0,0,0,0.5)'
    };
  return (
      (<Box
          style={styles}
          {...rest}
          sx={[{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
          }, ...(Array.isArray(rest.sx) ? rest.sx : [rest.sx])]}> <CircularProgress /></Box>)
  );
}

export default Loader