import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Container,  Grid2 as Grid, Typography, Button } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { withTranslation } from "react-i18next";

const styles = (theme) => ({
  mainContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(5, 0, 6),
    padding: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
    },
  },
  button: {
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
  },
});
class Page extends React.Component {
  state = {
    title: "",
    desc: "",
    files: [],
  };

  handleChangeTextField = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  render() {
    const { t, classes } = this.props;
    return (
      (<Container className={classes.mainContainer}>
        <Grid container sx={{
          justifyContent: "center"
        }}>
          <Grid
            container
            spacing={2}
            className={classes.container}
            size={{
              xs: 12,
              md: 7
            }}>
            <Grid
              container
              sx={{
                justifyContent: "center"
              }}
              size={{xs:12}}>
              <Typography variant="h4">
                {t("buyersGuide.thankyou.thankyouForOrder")}
              </Typography>
            </Grid>
            <Grid size={{xs: 12}}>
              <Typography align="center" sx={{
                marginBottom: "16px"
              }}>
                {t("buyersGuide.orderComplete.message")}
              </Typography>
            </Grid>
            <Grid size={{xs: 12}}>
              <Typography align="center">
                {t("buyersGuide.thankYou.orderStatusInfo")}:
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                justifyContent: "center"
              }}
              size={{xs:12}}>
              <Button
                component={Link}
                to="/home"
                variant="outlined"
                color="primary"
                className={classes.button}
              >
                {t("buyersGuide.orderComplete.statusOverviewOption")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>)
    );
  }
}

function mapStateToProps(state) {
  const { authentication, buildings } = state;
  const { user } = authentication;
  const { selected } = buildings;
  return {
    user,
    selected,
  };
}

const connectedPage = connect(mapStateToProps)(
  withTranslation()(withStyles(styles)(Page))
);
export { connectedPage as ThankYouOrderPage };
