import {
    Badge,
    Divider,
    alpha,
    Grid2 as Grid,
    Hidden,
    Icon,
    IconButton,
    ListItemIcon,
    MenuItem,
    Popover,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AccessTime, ArrowBack, CheckCircle, HomeWorkOutlined, MoreVert } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {getDossiersDetailPathByModule} from "../../../_helpers/redirectHelper";
import { getLocalStorageItem, setLocalStorageItem } from "../../../_helpers";

export default function ObjectList(props) {
    const theme = useTheme();
    const matchesWidthUpMd = useMediaQuery(theme.breakpoints.down('md'));
    const { selectedDossier } = useSelector(state => state.dossier);
    const app = useSelector(state => state.app);
    const { all, selected } = useSelector(state => state.buildings);
    const [buildingInfoList, setBuildingInfoList] = useState([]);
    const [isSticky, setIsSticky] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const params = new URLSearchParams(window.location.search)
    const buildingId = params.get('buildingId');
    const dossierId = params.get('dossierId');
    const history = useHistory();
    const { viewType, selectedDataId } = useParams();
    const { t } = useTranslation();
    const [objectListHeight, setObjectListHeight] = useState(42);
    const [objectListScrollHeight, setObjectListScrollHeight] = useState(42);
    const [objectListCollapsed, setObjectListCollapsed] = useState(false);
    const buildingList = useRef(null);

    const classes = useStyles({ matchesWidthUpMd, divCollapse: objectListCollapsed });
    useEffect(() => {
        setIsSticky(!!getLocalStorageItem("Dossiers_Object_List_Pin_Status"));
    }, []);

    useEffect(() => {
        if (!objectListCollapsed) {
            setObjectListHeight(buildingList.current && buildingList.current.clientHeight);
            setObjectListScrollHeight(buildingList.current && buildingList.current.scrollHeight)
        }
    }, [buildingList.current && buildingList.current.scrollHeight, objectListCollapsed]);

    const toggleObjectListHandler = () => {
        setObjectListCollapsed(!objectListCollapsed)
    }

    useEffect(() => {
        if (selectedDossier && selectedDossier.buildingInfoList) {
            const data = selectedDossier.buildingInfoList.filter(b => all.find(a => a.projectId === selectedDossier.projectId && a.buildingId === b.buildingId)).map(b => {
                const { buildingNoExtern } = all.find(a => a.buildingId === b.buildingId) || {};
                return { ...b, buildingNoExtern }
            }).sort((a, b) => a.buildingNoExtern > b.buildingNoExtern ? 1 : -1);
            setBuildingInfoList(data);
        }
    }, [all, selectedDossier]);

    const onPinClick = () => {
        setIsSticky(p => {
            setLocalStorageItem("Dossiers_Object_List_Pin_Status", p);
            return !p;
        });
    }

    const handleOpenDossier = (id = selectedDossier.id, objectId) => {
        history.push(getDossiersDetailPathByModule({
            dossierId,
            buildingId: objectId,
            viewType,
            projectId:selectedDataId,
            projectNo: selected.projectNo,
            app,
            isBuyer: props.isBuyer
        }));
    }

    const handleBack = () => {
        history.goBack();
    }

    return viewType !== "dossier" ?
        <Grid className={classes.objectListContainer} size={{xs:12}}>
            <div className={classes.mainContainer}>
                <div>
                    <IconButton
                        onClick={handleBack}
                        color="primary"
                        className={classes.iconButton}
                        size="small">
                        <ArrowBack />
                    </IconButton>
                </div>
            </div>
        </Grid>
        : <>
            <Grid
                className={clsx(classes.objectListContainer, objectListCollapsed && classes.objectListContainerExpanded, isSticky && classes.objectListStickyContainer)}
                ref={buildingList}
                size={{xs:12}}>
                <div className={classes.mainContainer}>
                    <Hidden mdUp>
                        <div>
                            <IconButton
                                onClick={handleBack}
                                color="primary"
                                className={classes.iconButton}
                                size="small">
                                <ArrowBack />
                            </IconButton>
                        </div>
                    </Hidden>
                    <div className={classes.objectList}>
                        {objectListCollapsed || (objectListScrollHeight > objectListHeight) ?
                            <div className={classes.container}>
                                {matchesWidthUpMd && <Divider className={classes.objectDivider} />}
                                <div className={clsx(classes.objectInfoChip)}>
                                    <IconButton
                                        color="primary"
                                        className={classes.iconButton}
                                        onClick={toggleObjectListHandler}
                                        size="small">
                                        <Badge className={classes.dotBadge} color="secondary" variant="dot">
                                            <Icon>{objectListCollapsed ? "keyboard_double_arrow_up" : "keyboard_double_arrow_down"}</Icon>
                                        </Badge>
                                    </IconButton>
                                </div>
                            </div> : null
                        }
                        <div className={classes.container}>
                            <Divider className={classes.objectDivider} />
                            <div
                                onClick={() => handleOpenDossier()}
                                className={clsx(classes.objectInfoChip, !buildingId && classes.selectedObject)}>
                                <div className={classes.objectContainer}>
                                    <Typography className={clsx(classes.objectNumber, classes.homeIcon)}>
                                        <HomeWorkOutlined />
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        {buildingInfoList.map((a, i) => (
                            <div className={classes.container}>
                                <Divider className={classes.objectDivider} />
                                <div
                                    className={clsx(classes.objectInfoChip, buildingId === a.buildingId && classes.selectedObject)}
                                    onClick={() => handleOpenDossier(selectedDossier.id, a.buildingId)}>
                                    <div className={classes.objectContainer}>
                                        <Badge className={classes.dotBadge} color="secondary" variant="dot" invisible={!a.hasUpdates}>
                                            <Typography className={classes.objectNumber}>{a.buildingNoExtern}</Typography>
                                        </Badge>
                                        {a.status === 2 &&
                                            <Tooltip title={t('dossier.status.2')}>
                                                <CheckCircle className={classes.completedIcon} />
                                            </Tooltip>
                                        }
                                        {(a.isOverdue || a.is48hoursReminder) &&
                                            <Tooltip
                                                title={a.is48hoursReminder ? t("general.48hours") : a.isOverdue ? t("general.overdue") : ''}>
                                                <AccessTime className={clsx(a.is48hoursReminder && classes.warning, a.isOverdue && classes.dangerColor, classes.overdueIcon)} />
                                            </Tooltip>
                                        }
                                    </div>
                                </div>
                                <Divider className={classes.objectDivider} />
                            </div>
                        ))}
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <IconButton
                            onClick={(e) => setAnchorEl(e.currentTarget)}
                            color="primary"
                            className={classes.iconButton}
                            size="small">
                            <MoreVert />
                        </IconButton>
                    </div>
                </div>


            </Grid>
            {<Popover
                className={classes.filterOptions}
                id={'dossier-menu-popover'}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <MenuItem className={classes.menuItem} onClick={onPinClick}>
                    <ListItemIcon>
                        <Icon>push_pin</Icon>
                    </ListItemIcon>
                    <Typography variant="inherit">{isSticky ? t("dossier.general.unpin") : t("dossier.general.pin")}</Typography>
                </MenuItem>
            </Popover>}
        </>;
}

const useStyles = makeStyles(theme => ({
    objectListStickyContainer: {
        position: "sticky",
        top: theme.spacing(0),
        [theme.breakpoints.down('md')]: {
            top: theme.spacing(0),
        },
        zIndex: theme.zIndex.appBar
    },
    mainContainer: {
        display: "flex",
        flexWrap: 'nowrap',
        position: 'relative'
    },
    collapseContainer: {
        "& .MuiCollapse-wrapperInner": {
            [theme.breakpoints.up("md")]: {
                top: theme.spacing(0),
                marginLeft: theme.spacing(5.4)
            },
            marginLeft: theme.spacing(1.4),
            display: "flex",
            flexWrap: "wrap"
        }
    },
    objectListContainer: {
        overflow: "hidden",
        padding: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        width: "100%",
        borderBottom: `2px solid ${theme.palette.grey[200]}`,
        height: 48,
        [theme.breakpoints.down('md')]: {
            background: theme.palette.common.white,
            boxShadow: theme.shadows[3],
        }
    },
    objectListContainerExpanded: {
        height: 'fit-content'
    },
    menuItem: {
        "& .MuiListItemIcon-root": {
            minWidth: 30
        }
    },
    filterOptions: {
        "& svg": {
            color: `${theme.palette.grey[500]} !important`,
        },
        "& .MuiIcon-root": {
            color: `${theme.palette.grey[500]} !important`,
        },
        "& .MuiPopover-paper": {
            color: `${theme.palette.grey[500]} !important`,
            minWidth: 100
        }
    },
    selectedObject: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        "& span": {
            color: `${theme.palette.common.white} !important`,
        },
        "& p": {
            color: `${theme.palette.common.white} !important`,
        },
        "& .MuiSvgIcon-root": {
            color: `${theme.palette.common.white} !important`,
        },
        "& .MuiBadge-dot": {
            backgroundColor: `${theme.palette.common.white} !important`
        }
    },
    objectInfoChip: {
        minWidth: theme.spacing(5.5525),
        transition: '0.5s all',
        cursor: "pointer",
        height: 26,
        padding: theme.spacing(1),
        margin: theme.spacing(0, 1),
        borderRadius: 50,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "&:hover": {
            background: alpha(theme.palette.primary.main, .2),
        }
    },
    homeIconChip: {
        margin: theme.spacing(0, .6),
        minWidth: "auto"
    },
    completedIcon: {
        "&.MuiSvgIcon-root": {
            color: theme.palette.success.main,
        },
        transition: '0.5s all',
        height: `12px !important`,
        width: `12px !important`,
        fontSize: `12px !important`,
        marginLeft: 5
    },
    dangerColor: {
        "&.MuiSvgIcon-root": {
            color: `${theme.palette.error.main} !important`,
        },
        height: `12px !important`,
        width: `12px !important`,
        fontSize: `12px !important`,
        marginLeft: 5
    },
    warning: {
        "&.MuiSvgIcon-root": {
            color: `${theme.palette.warning.main} !important`,
        },
        height: `12px !important`,
        width: `12px !important`,
        fontSize: `12px !important`,
        marginLeft: 5
    },
    objectNumber: {
        transition: '0.5s all',
        color: theme.palette.common.black,
        fontSize: `10px !important`
    },
    iconButton: {
        margin: theme.spacing(-.25, 0)
    },
    objectContainer: {
        display: 'flex',
        alignItems: "center",
    },

    container: {
        display: "flex",
        alignItems: "center",
        margin: theme.spacing(.25, 0)
    },
    objectDivider: {
        height: "100%",
        width: 1
    },
    dotBadge: {
        transition: '0.5s all',
        "& .MuiBadge-dot": {
            height: 4,
            minWidth: 4,
        }
    },
    homeIcon: {
        color: alpha(theme.palette.common.black, .54)
    },
    objectIconContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        [theme.breakpoints.down('md')]: {
            marginLeft: 22
        },
    },
    objectList: {
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: '7px',
    },
}));
