import { Box, ListItem, Popover,List,ListItemButton,ListItemIcon,ListItemText,Collapse } from '@mui/material'
import React, { useState } from 'react';
import {ExpandLess,ExpandMore} from '@mui/icons-material';
import Loader from './Loader';

const CustomPopover = ({ open, anchorEl, handleClose, data, handleSubListItemClick, responseKey, isOrganisationDetailsLoading }) => {
  const [openIndexes, setOpenIndexes] = useState([]);

  const handleListItemClick = (index) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((idx) => idx !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  const handleSubListClick = (relationId) => {
    handleSubListItemClick(relationId, responseKey)
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      width="100%"
    >
      <List sx={{ maxHeight: 250, overflow: 'scroll', minWidth: 350 }}>
        {
          isOrganisationDetailsLoading ? <Loader /> : <>
            {data.map((item, index) => {
              return (
                <Box key={item.organisationId}>
                  <ListItem disablePadding divider>
                    <ListItemButton onClick={() => handleListItemClick(index)} sx={{ px: 1, py: 0 }}>
                      <ListItemText primary={item?.organisationName ?? ""} />
                      {
                        item?.relations?.length > 0 && (<ListItemIcon sx={{ minWidth: 10 }}>
                          {openIndexes.includes(index) ? <ExpandLess /> : <ExpandMore />}
                        </ListItemIcon>)
                      }
                    </ListItemButton>
                   </ListItem>
                  {item?.relations?.length > 0 && (
                    <Collapse in={openIndexes.includes(index)} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item?.relations?.map((relation) => (
                          <ListItemButton key={relation.id} sx={{ pl: 4, py: 0 }} onClick={() => handleSubListClick(relation.id)}>
                            <ListItemText primary={relation?.name ?? ""} />
                          </ListItemButton>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </Box>
              )
            })}
          </>
        }
      </List>
    </Popover>
  )
}

export default CustomPopover