import {
  Divider,
  Grid2 as Grid,
  Hidden,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  AddCircleOutline,
  Cancel,
  CheckCircle,
  Close,
  Delete,
  Image,
  MapOutlined,
  MoreVert,
  PictureAsPdf,
  Visibility,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  formatDate,
  generateLocalStorageKey,
  getLocalStorageItem,
  setLocalStorageItem,
  useCanLockedInspection,
  useInternetStatusDetector,
} from "../../../_helpers";
import BottomDrawer from "./BottomDrawer";
import clsx from "clsx";
import { isReadOnly } from "../../../_helpers/enumsHelper";
import { alpha } from "@mui/material/styles";
import { surveyActions } from "../../../_actions";
import RepairRequestInfoModal from "./RepairRequestInfoModal";
import { apps, userAccountTypeConstants } from "../../../_constants";
import { URLS } from "../../../apis/urls";
import DataGrid from "../../../components/DataGrid";
import RepairRequestCustomRow from "./RepairRequestCustomRow";
import {
  ImageColumn,
  LabelColumn,
  MultiLabelColumn,
} from "../../../components/DataColumns";
import DataGridSetting from "../../../components/DataGridSetting";
import OfflineFileWrapper from "../../../components/OfflineFileWrapper";

 function MeldingenTable({
  canDeleteRR,
  showActionColumn,
  isSecondSignature,
  setOpenRepairRequestMap,
  selectedInspection,
  bottomDrawer,
  bottomDrawerClose,
  loading,
  title,
  keyPath,
  buttonText,
  inspection,
  selectedApp,
  assignmentDetail,
  wbObjectId,
  gridDataTitle,
  data,
  ...props
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const matchWidthDownSm = useMediaQuery(theme.breakpoints.down("md"));
  const [requests, setRequests] = useState([]);
  const [readOnly, setReadOnly] = useState(false);
  const [defaultFilter, setDefaultFilter] = useState(null);
  const classes = useStyles({ readOnly });
  const [canListType, setCanListType] = useState(false);
  const [imageSize, setImageSize] = useState(20);
  const [fontSize, setFontSize] = useState(12);
  const [openTooltip, setOpenTooltip] = useState({ element: null, image: "" });
  const { user } = useSelector((state) => state.authentication);
  const { drawingFiles } = useSelector((state) => state.dossier);
  const { selected } = useSelector((state) => state.buildings);
  const app = useSelector((state) => state.app);
  const { isCompleteRRLoading, isUpdateReworkLoading, deleteRRLoading } =
    useSelector((state) => state.surveying);

  const [columnsVisibility, setColumnsVisibility] = useState([
    { name: "action", visible: true },
    { name: "location", visible: true },
    { name: "requestId", visible: "excluded" },
    { name: "attachments", visible: true },
    { name: "number", visible: true },
    { name: "date", visible: true },
    { name: "desc", visible: true },
    { name: "textResolvers", visible: true },
    { name: "targetCompletionDate", visible: true },
    { name: "carryOutAsType", visible: true },
    { name: "status", visible: true },
  ]);
  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);
  const [infoType, setInfoType] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [canDeleteRequest, setCanDeleteRequest] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsSelected, setRowsSelected] = useState({ data: [], list: [] });
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const canLockedInspection = useCanLockedInspection();
  const isOnline = useInternetStatusDetector();
  const handleOpenMenu = (event, request) => {
    setAnchorEl(event.currentTarget);
    setSelectedRequest(request);
  };

  const isColumnVisible = (columnName) => {
    const isSmallScreen = true;
    var column = columnsVisibility.find((x) => x.name === columnName);
    if (column) {
      return column.visible;
    } else if (isSmallScreen) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (user) {
      const localFilters = getLocalStorageItem(`RepairRequest_Grid_Filters`);
      if (localFilters) setDefaultFilter(localFilters);
    }
  }, [user]);

  useEffect(() => {
    const columnsVisibilityLocal = getLocalStorageItem(
      "RepairRequest_ColumnsVisibility"
    );
    let gridStyle = getLocalStorageItem("Detail_RepairRequest_Grid_Style");
    gridStyle = gridStyle === null ? matchWidthDownSm : gridStyle;
    const size = getLocalStorageItem("RepairRequest_Lists_Image_Size");
    setCanListType(gridStyle);
    setImageSize((p) => +size || p);
    const fontSizeLocal = getLocalStorageItem("RepairRequest_Lists_Font_Size");
    setFontSize(+fontSizeLocal || fontSize);
    if (!columnsVisibilityLocal) {
      setLocalStorageItem("RepairRequest_ColumnsVisibility", columnsVisibility);
    } else {
      setColumnsVisibility(columnsVisibilityLocal);
    }
  }, [matchWidthDownSm]);

  useEffect(() => {
    if (rowsSelected && rowsSelected.data && selectedInspection) {
      const canDelete = rowsSelected.data.every(({ requestId }) => {
        const request = requests.find((p) => p.requestId === requestId);
        const isOfflineRequest = (!isOnline && request.sync === 0) || isOnline;
        return (
          isOfflineRequest &&
          request &&
          !request.completed &&
          (!request.resolvers ||
            (request.resolvers &&
              !request.resolvers.some((r) => r.workOrderNumber)))
        );
      });
      setCanDeleteRequest(
        canDelete &&
          !canLockedInspection &&
          (!selectedInspection.lockId ||
            (selectedInspection.lockId &&
              rowsSelected.data.every(({ requestId }) => {
                const request = requests.find((p) => p.requestId === requestId);
                return request.sync === 0;
              })))
      );
    }
  }, [rowsSelected, canLockedInspection, isOnline]);

  const handleCloseMenu = () => {
    setSelectedRequest(null);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (selectedRequestId && !deleteRRLoading) {
      setSelectedRequestId(null);
    }
  }, [selectedRequestId, deleteRRLoading]);

  useEffect(() => {
    if (selectedInspection) {
      setReadOnly(
        !selectedInspection.hasRights ||
          (isSecondSignature
            ? selectedInspection.secondSignatureDate
            : isReadOnly(selectedInspection.status)) ||
          canLockedInspection
      );
    }
  }, [selectedInspection && selectedInspection.status]);

  useEffect(() => {
    if (data)
      setRequests(
        data.map((request) => {
          if (request.sync) {
            request.textResolvers = [];
            if (request.resolvers && request.resolvers.length)
              request.textResolvers = request.resolvers.map((x) => x.name);
          }
          return request;
        })
      );
  }, [data, isCompleteRRLoading, isUpdateReworkLoading, deleteRRLoading]);

  const handleTooltipClose = (event) => {
    event.stopPropagation();
    setOpenTooltip({ element: null, image: null });
  };

  const infoModalTogglerHandler = (type) => {
    !isOpenInfoModal && setAnchorEl(null);
    setInfoType(isOpenInfoModal ? null : type);
    setIsOpenInfoModal((p) => !p);
  };

  const handleDeleteRR = () => {
    const requestsData = rowsSelected.data.map(({ requestId }) => {
      const request = requests.find((p) => p.requestId === requestId);
      return request;
    });
    dispatch(surveyActions.deleteRepairRequestAction(requestsData, true));
    setRowsSelected({ data: [], list: [] });
  };

  const actionColumns = showActionColumn
    ? [
        {
          name: "action",
          label: t("general.action"),
          options: {
            display: isColumnVisible("action"),
            filter: false,
            sort: false,
            download: false,
            print: false,
            maxWidth: 100,
            customBodyRenderLite: (rowIndex) => {
              const request = requests[rowIndex];
              if (!request) return "";
              return (
                request &&
                (!request.completed && !readOnly ? (
                  <IconButton
                    disabled={
                      selectedInspection &&
                      !selectedInspection.isSecondSignatureInitiated
                    }
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    className={classes.moreButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenMenu(e, request);
                    }}
                    size="large"
                  >
                    <MoreVert />
                  </IconButton>
                ) : (
                  <CheckCircle className={classes.colorSuccess} />
                ))
              );
            },
          },
        },
      ]
    : [];

  const inspectionDetailColumns = inspection
    ? [
        {
          name: "location",
          label: t("general.location"),
          options: {
            display: isColumnVisible("location"),
            filter: true,
            filterList: defaultFilter ? defaultFilter[3] : [],
            filterType: "multiselect",
            maxWidth: 100,
            customBodyRenderLite: (rowIndex) => {
              const request = requests[rowIndex];
              if (!request) return "";
              return (
                <LabelColumn
                  label={request.location}
                  tooltipLabel={request.location}
                />
              );
            },
            sort: true,
            setCellHeaderProps: () => ({ className: classes.customMainHeader }),
          },
        },
      ]
    : [];

  const columns = [
    {
      name: "requestId",
      options: {
        display: isColumnVisible("requestId"),
        filter: false,
        print: false,
        download: false,
        maxWidth: 100,
      },
    },
    {
      name: "attachments",
      label: t("general.image"),
      options: {
        display: isColumnVisible("attachments"),
        filter: false,
        sort: false,
        print: false,
        download: false,
        maxWidth: 100,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          let imageURL = "";
          let attachmentId;
          const fileType = request?.attachments?.[0]?.type?.toLowerCase(); //Note:- Offline mode - if the pdf/image file is present then only type is exist in the attachements object
          if (request?.sync === 0) {
            // offline requestes
            if (request?.attachments?.length > 0 && fileType !== "application/pdf") {
              imageURL = `${window.URL.createObjectURL(
                new Blob([request.attachments[0]])
              )}`;
            }
          } else if (request?.sync !== 0) {
            // online requestes
            if (request?.attachments?.length > 0) {
              if (inspection) {
                attachmentId = request.attachments[0]?.attachmentId;
                imageURL = `${URLS.GET_ATTACHMENT_THUMBNAIL}${request.attachments[0]?.attachmentId}`;
              }
              else {
                imageURL = `${request?.attachments?.[0]?.attachmentUrl}`;
              }
            } else if (request?.pdfAttachments?.length > 0) {
              imageURL = `${URLS.GET_ATTACHMENT_THUMBNAIL}${request.pdfAttachments[0]?.attachmentId}`;
            }
          }
          return (<>
            {fileType === "application/pdf" ?
              <Stack
                direction="row"
                sx={{
                  width: "100%",
                  justifyContent: "center"
                }}>
                <PictureAsPdf sx={{ color: "primary.main" }} />
              </Stack>
              :
              <OfflineFileWrapper id={attachmentId} url={imageURL}>
                {(url) => <ImageColumn src={url} size={imageSize} />}
              </OfflineFileWrapper>}
          </>);
        },
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ textAlign: "center" }}>
              <Image />
            </div>
          );
        },
      },
    },
    {
      name: "number",
      label: t("general.number"),
      options: {
        display: isColumnVisible("number"),
        filter: false,
        maxWidth: 100,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return (
            <LabelColumn
              label={request.number || t("general.draft")}
              tooltipLabel={request.number || t("general.draft")}
            />
          );
        },
      },
    },
    ...inspectionDetailColumns,
    {
      name: "date",
      label: t("general.date"),
      options: {
        display: isColumnVisible("date"),
        filter: false,
        maxWidth: 100,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return <LabelColumn date={new Date(request.date)} />;
        },
      },
    },
    {
      name: "desc",
      label: t("general.description"),
      options: {
        display: isColumnVisible("desc"),
        filter: false,
        maxWidth: 100,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return (
            <LabelColumn label={request.desc} tooltipLabel={request.desc} />
          );
        },
      },
    },
    {
      name: "textResolvers",
      label: t("general.resolvers"),
      options: {
        display: isColumnVisible("textResolvers"),
        filter: true,
        filterList: defaultFilter ? defaultFilter[6] : [],
        filterType: "multiselect",
        maxWidth: 100,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return (
            <MultiLabelColumn
              data={request.textResolvers.map((name) => ({ name }))}
            />
          );
        },
      },
    },
    {
      name: "targetCompletionDate",
      label: t("repairRequest.targetCompletionDate.shortLabel"),
      options: {
        display: isColumnVisible("targetCompletionDate"),
        filter: false,
        maxWidth: 100,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return (
            <LabelColumn
              label={
                !request.targetCompletionDate
                  ? "--"
                  : formatDate(new Date(request.targetCompletionDate))
              }
            />
          );
        },
      },
    },
    {
      name: "status",
      label: t("general.status"),
      options: {
        display: isColumnVisible("status"),
        filter: true,
        filterList: defaultFilter ? defaultFilter[8] : [],
        filterType: "multiselect",
        maxWidth: 100,
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return <LabelColumn label={request.status} />;
        },
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
      },
    },
    ...actionColumns,
  ];

  const listToMapRedirectHandler = () => {
    history.push({
      pathname:
        user.type !== userAccountTypeConstants.buyer && app !== apps.aftercare
          ? `/werk/${selected.projectNo}/${
              app === 3 ? "kwaliteitsborging/meldingen/map" : "meldingen/map"
            }`
          : `/meldingen/map`,
      state: {
        selectedRequest: null,
        buildingId: selectedInspection && selectedInspection.buildingId,
        requests: requests,
      },
    });
  };

  const handleChangeGridStyle = (val) => {
    setCanListType(val);
    setLocalStorageItem("Detail_RepairRequest_Grid_Style", val);
  };

  const handleResizeImage = (value) => {
    setImageSize(+value);
    setLocalStorageItem("RepairRequest_Lists_Image_Size", +value);
  };

  const handleResizeFont = (value) => {
    setFontSize(+value);
    setLocalStorageItem("RepairRequest_Lists_Font_Size", +value);
  };

  function onFilterChange(_, list) {
    setDefaultFilter(list);
    setLocalStorageItem(`RepairRequest_Grid_Filters`, list);
  }

  const handleRowClick = (selectedRow) => {
    if (
      selectedRow &&
      selectedRow.sync === 0 &&
      !isReadOnly(selectedInspection.status)
    ) {
      setOpenRepairRequestMap(selectedRow);
    } else if (isOnline && selectedRow.sync !== 0) {
      switch (app) {
        case apps.constructionQuality: {
          history.push(
            `/werk/${selectedInspection.projectNo}/kwaliteitsborging/opname/melding/${selectedRow.requestId}`
          );
          break;
        }
        case apps.aftercare: {
          history.push(`/nazorg/opname/melding/${selectedRow.requestId}`);
          break;
        }
        default: {
          break;
        }
      }
    }
  };

  const options = {
    rowsPerPage: rowsPerPage,
    pagination: true,
    rowsPerPageOptions: [5, 10, 20, 50, 75, 100],
    print: false,
    search: true,
    viewColumns: true,
    // filter: true,
    download: false,
    // pagination: false,
    selectableRowsHeader: !isSecondSignature && !readOnly,
    selectableRows: isSecondSignature || readOnly ? "none" : "multiple",
    //selectableRowsOnClick: !isSecondSignature && !readOnly,
    selectToolbarPlacement: "replace",
    sortingList: [
      { label: "general.description", key: "desc", type: "string" },
      { label: "general.location", key: "location", type: "string" },
      {
        label: "repairRequest.targetCompletionDate.shortLabel",
        key: "targetCompletionDate",
        type: "date",
      },
      { label: "general.status", key: "status", type: "string" },
    ],
    customToolbarSelect: (selectedRows, _, setSelectedRows) => (
      <>
        <IconButton
          color={"error"}
          onClick={() => {
            handleDeleteRR();
            setSelectedRows([]);
          }}
          className={classes.meldingenTableDeleteButton}
          disabled={!canDeleteRequest}
          size="large"
        >
          <Delete />
        </IconButton>
      </>
    ),
    customToolbar: () => {
      return (
        <>
          {
            <DataGridSetting
              canListType={canListType}
              imageSize={imageSize}   
              onChangeImageSize={(val) => handleResizeImage(val)}
              fontSize={fontSize}
              onChangeFontSize={(val) => handleResizeFont(val)}
              onChangeGridViewType={(val) => handleChangeGridStyle(val)}
            />
          }
          {!readOnly && !isSecondSignature && (
            <IconButton
              disabled={readOnly || canLockedInspection}
              onClick={(e) => setOpenRepairRequestMap()}
              className={classes.meldingenTableButton}
              size="large"
            >
              <AddCircleOutline />
            </IconButton>
          )}
        </>
      );
    },
    onViewColumnsChange: (changedColumn, action) => {
      if (action == "add") {
        var listToUpdate = Object.assign([], columnsVisibility);

        var column = listToUpdate.find((x) => x.name === changedColumn);
        if (column) {
          column.visible = true;
        } else {
          listToUpdate.push({ name: changedColumn, visible: true });
        }
        setColumnsVisibility(listToUpdate);
        setLocalStorageItem("RepairRequest_ColumnsVisibility", listToUpdate);
      } else if (action == "remove") {
        var listToUpdate = Object.assign([], columnsVisibility);

        var column = listToUpdate.find((x) => x.name === changedColumn);
        if (column) {
          column.visible = false;
        } else {
          listToUpdate.push({ name: changedColumn, visible: false });
        }
        setColumnsVisibility(listToUpdate);
        setLocalStorageItem("RepairRequest_ColumnsVisibility", listToUpdate);
      }
    },
    onFilterChange,
    onTableChange: (action, tableState) => {
      const newRowsPerPage = tableState.rowsPerPage;
      switch (action) {
        case "changeRowsPerPage": {
          setRowsPerPage(newRowsPerPage);
          break;
        }
        default: {
          break;
        }
      }
    },
    onRowClick: (rowData, rowMeta) => {
      const requestId = rowData[0];
      const selectedRow =
        requests && requests.find((x) => x.requestId === requestId);
      if (!!selectedRow) {
        handleRowClick(selectedRow);
      }
    },
  };
  const canShowMapIcon =
    requests && requests.length && requests.some((p) => p.drawingPinLocation);
    
  return (<>
    <Hidden smUp>
      <BottomDrawer
        open={bottomDrawer.open && bottomDrawer.key === "gebrekenlijst"}
        close={bottomDrawerClose}
      >
        <div>
          {title && (
            <Grid className={classes.stickyHeader} size={{xs:12}}>
              <div className={classes.drawerHeader}>
                <Typography
                  className={clsx(
                    classes.surveyMomentCardTitle,
                    classes.headerIconContainer
                  )}
                >
                  {t(`${title}`)}
                  {!!canShowMapIcon &&
                    drawingFiles &&
                    !!drawingFiles.length && (
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => listToMapRedirectHandler()}
                        size="large"
                      >
                        <MapOutlined className={classes.mapIcon} />
                      </IconButton>
                    )}
                </Typography>
                <div>
                  <Close
                    className={classes.drawerIcon}
                    onClick={bottomDrawerClose}
                  />
                </div>
              </div>
              <Divider />
            </Grid>
          )}
          <DataGrid
            canListType={canListType}
            CustomRowRender={
              matchWidthDownSm && !!canListType
                ? (rest) => (
                    <RepairRequestCustomRow
                      onRowClick={handleRowClick}
                      {...props}
                      {...rest}
                      canShowCheckBox={!isSecondSignature && !readOnly}
                    />
                  )
                : null
            }
            options={options}
            identifier="requestId"
            onRowSelect={(data) => setRowsSelected(data)}
            localColumnOrdersIdentifier={generateLocalStorageKey(
              "RepairRequest_Table_Order"
            )}
            localColumnSortIdentifier={generateLocalStorageKey(
              "RepairRequest_Table_Sort"
            )}
            columns={columns}
            columnsVisibilityIdentifier={generateLocalStorageKey(
              "RepairRequest_ColumnsVisibility"
            )}
            data={requests}
            loading={loading}
            canGoBack={false}
            toolbarHide
            fontSize={fontSize}
          />
        </div>
      </BottomDrawer>
    </Hidden>
    <Hidden smDown>
      <DataGrid
        canListType={canListType}
        CustomRowRender={
          matchWidthDownSm && !!canListType
            ? (rest) => (
                <RepairRequestCustomRow
                  onRowClick={handleRowClick}
                  {...props}
                  {...rest}
                  canShowCheckBox={!isSecondSignature && !readOnly}
                />
              )
            : null
        }
        options={options}
        identifier="requestId"
        onRowSelect={(data) => setRowsSelected(data)}
        localColumnSortIdentifier={generateLocalStorageKey(
          "RepairRequest_Table_Sort"
        )}
        localColumnOrdersIdentifier={generateLocalStorageKey(
          "RepairRequest_Table_Order"
        )}
        columns={columns}
        columnsVisibilityIdentifier={generateLocalStorageKey(
          "RepairRequest_ColumnsVisibility"
        )}
        data={requests}
        title={title}
        className={classes.dataTable}
        IconComponent={() =>
          !!canShowMapIcon &&
          drawingFiles &&
          !!drawingFiles.length && (
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() => listToMapRedirectHandler()}
              size="large"
            >
              <MapOutlined className={classes.mapIcon} />
            </IconButton>
          )
        }
        loading={loading}
        canGoBack={false}
        fontSize={fontSize}
      />
    </Hidden>
    <Popover
      open={openTooltip.element && openTooltip.image}
      anchorEl={openTooltip.element}
      onClose={handleTooltipClose}
      disableRestoreFocus
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
    >
      <div className={classes.tooltipImageContainer}>
        <img src={openTooltip.image} alt="" />
      </div>
    </Popover>
    {isOpenInfoModal && (
      <RepairRequestInfoModal
        selectedRequest={selectedRequest}
        handleClose={infoModalTogglerHandler}
        open={isOpenInfoModal}
        infoType={infoType}
      />
    )}
    {selectedRequest && (
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={() =>
            infoModalTogglerHandler(
              selectedRequest.isRework ? "rework" : "reject"
            )
          }
        >
          <ListItemIcon style={{ minWidth: "40px" }}>
            {!selectedRequest.isRework ? (
              <Cancel color="error" size="small" />
            ) : (
              <Visibility color="primary" size="small" />
            )}
          </ListItemIcon>
          <Typography>
            {t(
              selectedRequest.isRework
                ? t("repairRequest.viewRepairRequestRework")
                : t("general.button.reject")
            )}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => infoModalTogglerHandler("approve")}>
          <ListItemIcon style={{ minWidth: "40px" }}>
            <CheckCircle size="small" className={classes.colorSuccess} />
          </ListItemIcon>
          <Typography>{t("general.approve")}</Typography>
        </MenuItem>
      </Menu>
    )}
  </>);
}

export default MeldingenTable

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  customCheckbox: {
    [theme.breakpoints.down("md")]: {
      "& .MuiSvgIcon-root": {
        height: 14,
      },
    },
  },
  drawerHeader: {
    display: "flex",
    padding: theme.spacing(1),
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 7,
  },
  surveyMomentCardTitle: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.common.black,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  drawerIcon: {
    fill: theme.palette.grey[300],
    cursor: "pointer",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    background: theme.palette.common.white,
    zIndex: 101,
  },
  meldingenTableButton: {
    color: theme.palette.grey[700],
    "& svg": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px !important",
      },
    },
    "& .MuiButton-label": {
      fontSize: 16,
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px !important",
        lineHeight: "0px !important",
      },
    },
    "& .MuiButton-label > .MuiButton-startIcon": {
      [theme.breakpoints.down("sm")]: {
        marginRight: "4px !important",
      },
    },
  },
  meldingenTableDeleteButton: {
    marginRight: 10,
  },
  customMainHeader: {
    fontSize: 14,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    "& .MuiButtonBase-root": {
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16,
      },
      fontSize: 14,
      padding: 0,
      margin: 0,
      whiteSpace: "nowrap ",
    },
  },
  papers: {
    minWidth: 100,
    flexGrow: 1,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderRadius: 0,
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 1),
    },
    [theme.breakpoints.up("sm")]: {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
      "&:nth-last-child(1)": {
        borderRight: `none`,
      },
    },
  },
  paperSubContainer: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: 22,
  },
  cardTitle: {
    fontSize: 12,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    color: theme.palette.grey[500],
    whiteSpace: "wrap",
  },
  removePadding: {
    padding: `${theme.spacing(0)} !important`,
  },
  expandableRow: {
    background: theme.palette.common.white,
    boxShadow: "0.6px 1px 3px #ccc",
    display: "table-row",
    position: "relative",
    top: 0,
    borderRadius: 4,
    "& .MuiTableCell-root": {
      "&:first-child": {
        borderBottomLeftRadius: 4,
      },
      "&:last-child": {
        borderBottomRightRadius: 4,
      },
    },
  },
  wrapMomentTitle: {
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    wordBreak: "break-all" /* number of lines to show */,
    display: "-webkit-box",
    boxOrient: "vertical",
  },
  wrapMomentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  minCellWidth: {
    minWidth: 100,
  },
  customCell: {
    position: "relative",
  },
  dataTable: {
    paddingBottom: theme.spacing(1),
  },
  mapIcon: {
    fill: theme.palette.primary.main,
    fontSize: 18,
  },

  headerIconContainer: {
    display: "flex",
    alignItems: "center",
  },
  tooltipImageContainer: {
    padding: theme.spacing(1.25),
    "& > img": {
      maxWidth: "200px",
      maxHeight: "200px",
    },
  },
  colorSuccess: {
    padding: theme.spacing(0.2),
    color: theme.palette.success.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: `${alpha(theme.palette.success.main, 0.7)} !important`,
    },
  },
  moreButton: {
    padding: theme.spacing(0.2),
  },
}));
