import { Box, LinearProgress, Typography } from '@mui/material'
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

const LinearProgressWithLabel = (props) => {
  return (
    (<Box
      sx={{
        display: "flex",
        alignItems: 'center'
      }}>
      <Box
        sx={{
          width: "100%",
          mr: 1
        }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: 12,
            color: "text.secondary"
          }}>{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>)
  );
}

const FileUploadProgress = ({ progress = 0,fileName = "file.xlsx", fileSize = "3.5 MB" }) => {

  const { t } = useTranslation();

  return (
    (<Box
      sx={{
        display: 'flex',
        p: 2.75,
        bgcolor: grey[100],
        justifyContent: 'center',
        alignItems: "center",
        borderRadius: 2
      }}>
      <Box
        component="img"
        alt="excel"
        src={"/Content/icon/ms_excel.svg"}
        sx={{
          width: 29,
          height: 29,
          mr: 1.25
        }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}>
          <Typography variant='caption'>{fileName}</Typography>
          <Typography variant='caption'>{fileSize}</Typography>
        </Box>
        <Box sx={{
          position: 'relative'
        }}>
          <LinearProgressWithLabel value={progress} />
        </Box>
      </Box>
      <Box sx={{
        ml: 2
      }}>
        <Typography variant='caption' sx={{
          color: "error.light"
        }}>
          {t("general.cancel")}
        </Typography>
      </Box>
    </Box>)
  );
}

export default FileUploadProgress