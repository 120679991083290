import React, { useEffect, useRef, useState } from "react";
import { createTheme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  LinearProgress,
  ThemeProvider,
  StyledEngineProvider,
  Badge,
  IconButton,
  Typography,
  MenuItem,
  Tooltip,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Schedule,
  PriorityHigh,
  Message,
  Block,
  CheckCircle,
  ErrorOutline,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import UserInformation from "./components/UserInformation";
import {
  formatDate,
  getAppsBuildings,
  useInternetStatusDetector,
  setLocalStorageItem,
  getLocalStorageItem,
  generateLocalStorageKey,
} from "../../_helpers";
import { surveyActions } from "../../_actions";
import { useRedirectionPath } from "../../_helpers/redirectHelper";
import { SurveyType, WKB_API_INTERVAL_TIME } from "../../_helpers/enumsHelper";
import { surveyConstants } from "../../_constants";
import DataGrid from "../../components/DataGrid";
import { SurveyingMomentCustomRow } from "./components/SurveyingMomentCustomRow";
import { LabelColumn } from "../../components/DataColumns";
import DataGridSetting from "../../components/DataGridSetting";
import SelectSingleObjectModal from "../../components/SelectSingleObjectModal";

export function NumberLinearProgress(props) {
  const classes = useStyles();
  return (
    (<Box
      sx={{
        display: "flex",
        alignItems: "center"
      }}>
      <Box sx={{
        width: "100%"
      }}>
        <LinearProgress variant="determinate" value={props.value} />
      </Box>
      <Typography
        className={classes.progressStyle}
      >{`${props.value}%`}</Typography>
    </Box>)
  );
}

const SurveyMoments = (props) => {
  const { history } = props;
  const { t } = useTranslation();
  const [canListType, setCanListType] = useState(false);
  const muiTheme = useTheme();
  const matchWidthOnlyXs = useMediaQuery(muiTheme.breakpoints.only("xs"));
  const matchWidthOnlyMd = useMediaQuery(muiTheme.breakpoints.only("md"));
  const matchWidthDownSm = useMediaQuery(muiTheme.breakpoints.down("md"));
  const matchWidthOnlySm = useMediaQuery(muiTheme.breakpoints.only("sm"));
  const classes = useStyles({
    matchWidthOnlyXs,
    matchWidthOnlySm,
    matchWidthOnlyMd,
  });
  const muiTableRef = useRef();
  const ref = useRef(null);
  //Redux State
  const selectedApp = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.authentication);
  const { selected } = useSelector((state) => state.buildings);
  const { surveys, getSurveysLoading } = useSelector(
    (state) => state.surveying
  );
  const { availableSurveyModules } = useSelector(
    (state) => state.availableSurveyModules
  );

  const dispatch = useDispatch();
  const redirectPatch = useRedirectionPath(SurveyType.WKB);
  const [intervalId, setIntervalId] = useState(null);
  const [defaultFilter, setDefaultFilter] = useState(null);
  //Online/offline detector
  const isOnline = useInternetStatusDetector();
  const [columnsVisibility, setColumnsVisibility] = useState([
    { name: "surveyingMomentId", visible: "excluded" },
    { name: "objectId", visible: "excluded" },
    { name: "extraInformation", visible: true },
    { name: "number", visible: true },
    { name: "object", visible: true },
    { name: "name", visible: true },
    { name: "startDate", visible: true },
    { name: "assignments", visible: true },
    { name: "repairRequestCount", visible: true },
    { name: "uitvoerende", visible: true },
    { name: "responsiblePerson", visible: true },
    { name: "responsiblePersonPlainText", visible: "excluded" },
    { name: "responsiblePersonCompanyPlainText", visible: "excluded" },
    { name: "internalStatusPlainText", visible: true },
    { name: "wbSurveyor", visible: false },
    { name: "progress", visible: true },
  ]);

  useEffect(() => {
    const id = setInterval(timer, WKB_API_INTERVAL_TIME);
    setIntervalId(id);
    return () => {
      clearInterval(intervalId);
      if (!history.location.pathname.includes("toezichtsmomenten")) {
        dispatch(
          surveyActions.success({
            type: surveyConstants.GET_SURVEYS_MOMENTS_SUCCESS,
            surveys: [],
          })
        );
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  const timer = () => {
    if (selected && selected.projectId) {
      dispatch(
        surveyActions.getSurveyingMoments(
          selected.projectId,
          selectedApp,
          false
        )
      );
    }
  };

  useEffect(() => {
    if (muiTableRef.current) {
      [
        { dataId: "Print-iconButton", id: "print-moments-lists" },
        { dataId: "Download CSV-iconButton", id: "download-moments-lists" },
      ].map((p) => {
        const printElement = document.querySelector(
          `[data-testid="${p.dataId}"]`
        );
        if (printElement) {
          printElement.setAttribute("id", p.id);
          printElement.style.display = "none";
        }
      });
    }
  }, [muiTableRef.current]);

  const isColumnVisible = (columnName) => {
    const isSmallScreen = true;
    var column = columnsVisibility.find((x) => x.name === columnName);
    if (column) {
      return column.visible;
    } else if (isSmallScreen) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const columnsVisibilityLocal = getLocalStorageItem(
      "SurveyingMoments_ColumnsVisibility"
    );
    let gridStyle = getLocalStorageItem("SurveyingMoments_Grid_Style");
    gridStyle = gridStyle === null ? matchWidthDownSm : gridStyle;
    const localFilters = getLocalStorageItem(`SurveyingMoments_Grid_Filters`);
    if (localFilters) setDefaultFilter(localFilters);
    setCanListType(gridStyle);
    if (!columnsVisibilityLocal) {
      setLocalStorageItem(
        "SurveyingMoments_ColumnsVisibility",
        columnsVisibility
      );
    } else {
      setColumnsVisibility(columnsVisibilityLocal);
    }
  }, [matchWidthDownSm]);

  const [isObjectModal, setIsObjectModal] = useState(false);
  const [mappedState, setMappedState] = useState({
    selectedBuildingId: null,
    selectedObjectId: null,
  });

  const objectModalListHandler = (
    event,
    selectedBuildingId,
    selectedObjectId
  ) => {
    event.stopPropagation();
    setIsObjectModal(true);
    setMappedState({ selectedBuildingId, selectedObjectId });
  };

  const handleCloseObjects = () => {
    setIsObjectModal(false);
    setMappedState({ selectedBuildingId: null, selectedObjectId: null });
  };

  const handleSaveObjects = (buildingId) => {
    dispatch(
      surveyActions.mapBuildingObject({
        objectId: mappedState.selectedObjectId,
        buildingId,
      })
    );
    handleCloseObjects();
  };
  const [all, setAll] = useState([]);

  useEffect(() => {
    if (selected) {
      getAppsBuildings({ projectId: selected.projectId || "" })
        .then(async (res) => {
          setAll(res);
        })
        .catch((er) => {
          console.log(er);
        });
    }
  }, [selected]);

  let columns = [
    {
      name: "surveyingMomentId",
      options: {
        display: isColumnVisible("surveyingMomentId"),
        filter: false,
        print: false,
        download: false,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
      },
    },
    {
      name: "objectId",
      options: {
        display: isColumnVisible("objectId"),
        filter: false,
        print: false,
        download: false,
        sort: true,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
      },
    },

    {
      name: "extraInformation",
      label: " ",
      options: {
        filter: false,
        download: false,
        maxWidth: 170,
        display: isColumnVisible("extraInformation"),
        sort: true,
        setCellHeaderProps: () => ({
          className: clsx(classes.customMainHeader),
        }),
        customHeadLabelRender: (columnMeta) => {
          return (
            <div
              noWrap
              style={{
                display: "flex",
                alignItems: "center",
                overflow: "visible",
              }}
            >
              <div className={classes.iconContainer}>
                <PriorityHigh color="secondary" className={classes.icon} />
              </div>
              <div className={classes.iconContainer}>
                <ErrorOutline fontSize="small" color="error" />
              </div>
              <div className={classes.iconContainer}>
                <Schedule
                  fontSize="small"
                  className={clsx(classes.pending, "pending")}
                />
              </div>
              <div className={classes.iconContainer}>
                <Message className={classes.tableIcon} />
              </div>
            </div>
          );
        },
        customBodyRenderLite: (rowIndex) => {
          const surveyingMoment = surveys[rowIndex];
          if (!surveyingMoment) return "";
          const {
            is48hoursReminder,
            hasUpdate,
            overdue,
            messageCount,
            wbStatus,
            buildingId,
            objectId,
            isBlocked,
            isBuildingMapped,
          } = surveyingMoment || {};

          return (
            <Typography
              noWrap
              style={{
                display: "flex",
                alignItems: "center",
                overflow: "visible",
              }}
            >
              <div className={classes.iconContainer}>
                {hasUpdate && (
                  <Tooltip title={t("general.actionRequired")}>
                    <PriorityHigh color="secondary" className={classes.icon} />
                  </Tooltip>
                )}
              </div>
              <div className={classes.iconContainer}>
                {objectId && !isBuildingMapped && (
                  <Tooltip title={t("wkb.objectIsNotLinked")}>
                    <IconButton
                      className={classes.iconButton}
                      onClick={(e) =>
                        objectModalListHandler(e, buildingId, objectId)
                      }
                      size="large"
                    >
                      <ErrorOutline className={classes.colorWarning} />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              <div className={classes.iconContainer}>
                {(is48hoursReminder || overdue || wbStatus || isBlocked) && (
                  <Tooltip
                    title={t(
                      wbStatus
                        ? t("wkb.surveyingMoment.internalStatus.2")
                        : isBlocked
                        ? t("survey.blocked")
                        : "general.overdue"
                    )}
                  >
                    {wbStatus ? (
                      <CheckCircle
                        fontSize="small"
                        className={classes.colorSuccess}
                      />
                    ) : isBlocked ? (
                      <Block fontSize="small" color="error" />
                    ) : (
                      <Schedule
                        fontSize="small"
                        color="error"
                        className={clsx(
                          is48hoursReminder && classes.warning,
                          overdue && classes.pending,
                          classes.icon
                        )}
                      />
                    )}
                  </Tooltip>
                )}
              </div>
              <div className={classes.iconContainer}>
                <Tooltip title={t("layout.navbar.messages")}>
                  <Badge
                    badgeContent={messageCount}
                    color="secondary"
                    className={classes.messageCountIcon}
                  >
                    <Message className={classes.tableIcon} />
                  </Badge>
                </Tooltip>
              </div>
            </Typography>
          );
        },
      },
    },
    {
      name: "number",
      label: t("general.id"),
      options: {
        display: isColumnVisible("number"),
        filter: false,
        maxWidth: 50,
        sort: true,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const surveyingMoment = surveys[rowIndex];
          if (!surveyingMoment) return "";
          return (
            <LabelColumn
              label={surveyingMoment.number}
              tooltipLabel={surveyingMoment.number}
            />
          );
        },
      },
    },
    {
      name: "object",
      label: t("general.object"),
      options: {
        display: isColumnVisible("object"),
        maxWidth: 80,
        filter: true,
        filterList: defaultFilter ? defaultFilter[4] : [],
        sort: true,
        customBodyRenderLite: (rowIndex) => {
          const surveyingMoment = surveys[rowIndex];
          if (!surveyingMoment) return "";
          return <LabelColumn label={surveyingMoment.object} />;
        },
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
      },
    },
    {
      name: "name",
      label: t("wkb.surveyingMoment"),
      options: {
        display: isColumnVisible("name"),
        filter: false,
        maxWidth: 210,
        sort: true,
        setCellHeaderProps: () => ({
          className: clsx(classes.customMainHeader),
        }),
        customBodyRenderLite: (rowIndex) => {
          const surveyingMoment = surveys[rowIndex];
          if (!surveyingMoment) return "";
          return (
            <LabelColumn
              label={surveyingMoment.name}
              tooltipLabel={surveyingMoment.name}
            />
          );
        },
      },
    },
    {
      name: "startDate",
      label: t("wkb.surveyingMoment.handelingPeriod"),
      options: {
        maxWidth: 180,
        display: isColumnVisible("startDate"),
        filter: false,
        sort: true,
        sortCompare:
          (order) =>
          (
            { data: column1, rowData: columnRowData1 },
            { data: column2, rowData: columnRowData2 }
          ) => {
            const surveyingMomentColumn1 = surveys.find(
              (r) => r.surveyingMomentId === columnRowData1[0]
            );
            const surveyingMomentColumn2 = surveys.find(
              (r) => r.surveyingMomentId === columnRowData2[0]
            );
            if (!surveyingMomentColumn1 || !surveyingMomentColumn2) return 0;
            const startDateColumn1 = surveyingMomentColumn1.startDate
              ? new Date(surveyingMomentColumn1.startDate)
              : "";
            const startDateColumn2 = surveyingMomentColumn2.startDate
              ? new Date(surveyingMomentColumn2.startDate)
              : "";
            const endDateColumn1 = surveyingMomentColumn1.endDate
              ? new Date(surveyingMomentColumn1.endDate)
              : "";
            const endDateColumn2 = surveyingMomentColumn2.endDate
              ? new Date(surveyingMomentColumn2.endDate)
              : "";
            if (order === "asc")
              return (startDateColumn1 - startDateColumn2) * 1;
            if (order === "desc") return (endDateColumn1 - endDateColumn2) * -1;
            return 0;
          },
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const surveyingMoment = surveys[rowIndex];
          if (!surveyingMoment) return "";
          const startDate = surveyingMoment.startDate
            ? formatDate(new Date(surveyingMoment.startDate), false, "-")
            : "";
          const endDate = surveyingMoment.startDate
            ? formatDate(new Date(surveyingMoment.endDate), false, "-")
            : "";
          if (startDate || endDate) {
            return (
              <LabelColumn
                tooltipLabel={`${startDate} - ${endDate}`}
                label={`${startDate} - ${endDate}`}
              />
            );
          }
        },
      },
    },
    {
      name: "assignments",
      label: t("wkb.assignments.label"),
      options: {
        display: isColumnVisible("assignments"),
        maxWidth: 105,
        filterList: defaultFilter ? defaultFilter[7] : [],
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const surveyingMoment = surveys[rowIndex];
          if (!surveyingMoment) return "";
          const totalAssignments = surveyingMoment.totalAssignments;
          const completedAssignments = surveyingMoment.completedAssignments;
          return (
            <LabelColumn
              label={`${completedAssignments}/${totalAssignments}`}
            />
          );
        },
      },
    },
    {
      name: "repairRequestCount",
      label: t("general.repairRequests"),
      options: {
        display: isColumnVisible("repairRequestCount"),
        maxWidth: 95,
        filter: false,
        sort: true,
        customBodyRenderLite: (rowIndex) => {
          const surveyingMoment = surveys[rowIndex];
          if (!surveyingMoment) return "";
          return <LabelColumn label={surveyingMoment.repairRequestCount} />;
        },
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
      },
    },
    {
      name: "uitvoerende",
      label: t("wkb.executive"),
      options: {
        display: isColumnVisible("uitvoerende"),
        filter: false,
        maxWidth: 110,
        sort: true,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const surveyingMoment = surveys[rowIndex];
          if (!surveyingMoment) return "";
          const person = surveyingMoment.responsiblePerson;
          return (
            <ResponsiblePersonLabel
              person={person}
              surveyingMoment={surveyingMoment}
            />
          );
        },
      },
    },
    {
      name: "responsiblePerson",
      label: t("general.company"),
      options: {
        display: isColumnVisible("responsiblePerson"),
        filter: false,
        maxWidth: 120,
        sort: true,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const surveyingMoment = surveys[rowIndex];
          if (!surveyingMoment || !surveyingMoment.responsiblePerson) return "";
          return (
            <LabelColumn
              label={surveyingMoment.responsiblePerson.name}
              tooltipLabel={surveyingMoment.responsiblePerson.name}
            />
          );
        },
      },
    },
    {
      name: "responsiblePersonPlainText",
      label: t("wkb.executive"),
      options: {
        display: isColumnVisible("responsiblePersonPlainText"),
        filter: true,
        filterList: defaultFilter ? defaultFilter[11] : [],
        sort: false,
        print: false,
        download: true,
      },
    },
    {
      name: "responsiblePersonCompanyPlainText",
      label: t("general.company"),
      options: {
        display: isColumnVisible("responsiblePersonCompanyPlainText"),
        filter: true,
        filterList: defaultFilter ? defaultFilter[12] : [],
        sort: false,
        print: false,
        download: true,
      },
    },
    {
      name: "internalStatusPlainText",
      label: t("wkb.surveyingMoment.internalStatus"),
      options: {
        display: isColumnVisible("internalStatusPlainText"),
        filter: true,
        filterList: defaultFilter ? defaultFilter[13] : [],
        maxWidth: 120,
        sort: true,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const surveyingMoment = surveys[rowIndex];
          if (!surveyingMoment) return "";
          return (
            <LabelColumn
              label={t(surveyingMoment.internalStatusPlainText)}
              tooltipLabel={t(surveyingMoment.internalStatusPlainText)}
            />
          );
        },

        filterOptions: {
          names: [
            t("wkb.surveyingMoment.internalStatus.0"),
            t("wkb.surveyingMoment.internalStatus.1"),
            t("wkb.surveyingMoment.internalStatus.2"),
            t("wkb.surveyingMoment.internalStatus.3"),
          ],
          logic: (value, filters, row) => {
            if (filters.length && filters.includes(t(value)) && value)
              return false;
            return true;
          },
        },
      },
    },
    {
      name: "wbSurveyor",
      label: t("wkb.woningborg.label"),
      options: {
        display: "excluded",
        filter: false,
        maxWidth: 100,
        print: false,
        download: false,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
      },
    },
    {
      name: "progress",
      label: t("wkb.surveyingMoment.progress"),
      options: {
        display: isColumnVisible("progress"),
        filter: false,
        sort: true,
        maxWidth: 100,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const surveyingMoment = surveys[rowIndex];
          if (!surveyingMoment) return "";
          const { totalAssignments, completedAssignments } = surveyingMoment;
          return (
            <div className={classes.progressWrapper}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  {
                    <NumberLinearProgress
                      value={(
                        (completedAssignments / totalAssignments) *
                        100
                      ).toFixed(2)}
                    />
                  }
                </ThemeProvider>
              </StyledEngineProvider>
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    if (redirectPatch) history.push(redirectPatch);
  }, [availableSurveyModules, user]);

  useEffect(() => {
    if (muiTableRef.current) {
      [
        { dataId: "Print-iconButton", id: "print-moments-lists" },
        { dataId: "Download CSV-iconButton", id: "download-moments-lists" },
      ].map((p) => {
        const printElement = document.querySelector(
          `[data-testid="${p.dataId}"]`
        );
        printElement.setAttribute("id", p.id);
        printElement.style.display = "none";
      });
    }
  }, [muiTableRef.current]);

  useEffect(() => {
    if (selected && selected.projectId) {
      dispatch(
        surveyActions.getSurveyingMoments(selected.projectId, selectedApp, true)
      );
    }
  }, [selected, history.location, isOnline]);

  const getSurveyingMomentsForPrintAndDownload = async (tableRef) => {
    const { displayData, data: tableData, columns } = tableRef.current.state;
    const data = displayData.map((a) =>
      tableData.find((p) => p.index === a.dataIndex)
    );
    return data.map((a) => {
      const surveyingMoment =
        surveys.find((r) => r.surveyingMomentId === a.data[0]) || {};
      return columns.reduce((prev, c, i) => {
        const selectedCellData = a.data[i];
        const obj = { ...prev };
        switch (c.name) {
          case "extraInformation": {
            const array = [];
            if (surveyingMoment.hasUpdate)
              array.push({ icon: "PriorityHigh", color: "secondary" });
            if (surveyingMoment.objectId && !surveyingMoment.isBuildingMapped)
              array.push({ icon: "ErrorOutline", color: "error" });
            if (
              surveyingMoment.is48hoursReminder ||
              surveyingMoment.overdue ||
              surveyingMoment.wbStatus ||
              surveyingMoment.isBlocked
            ) {
              surveyingMoment.wbStatus
                ? array.push({ icon: "CheckCircle", color: "success" })
                : surveyingMoment.isBlocked
                ? array.push({ icon: "Block", color: "error" })
                : array.push({ icon: "Schedule", color: "error" });
            }
            array.push({
              icon: "Message",
              count: surveyingMoment.messageCount,
            });
            obj[c.name] = array;
            break;
          }
          case "startDate": {
            const startDate = surveyingMoment.startDate
              ? formatDate(new Date(surveyingMoment.startDate), false, "-")
              : "";
            const endDate = surveyingMoment.startDate
              ? formatDate(new Date(surveyingMoment.endDate), false, "-")
              : "";
            obj[c.name] = `${startDate} - ${endDate}`;
            break;
          }
          case "assignments": {
            const totalAssignments = surveyingMoment.totalAssignments;
            const completedAssignments = surveyingMoment.completedAssignments;
            obj[c.name] = `${totalAssignments}/${completedAssignments}`;
            break;
          }
          case "uitvoerende": {
            const person = surveyingMoment.responsiblePerson;
            obj[c.name] = person ? person.relationName : "";
            break;
          }
          case "responsiblePerson": {
            obj[c.name] = selectedCellData ? selectedCellData.name : "";
            break;
          }
          case "progress": {
            const { totalAssignments, completedAssignments } =
              surveyingMoment || {};
            obj[c.name] = `${(
              (completedAssignments / totalAssignments) *
              100
            ).toFixed(2)}%`;
            break;
          }
          default: {
            obj[c.name] =
              c.name === "internalStatusPlainText"
                ? t(selectedCellData)
                : selectedCellData;
            break;
          }
        }
        return obj;
      }, {});
    });
  };

  const handleChangeGridStyle = (val) => {
    setCanListType(val);
    setLocalStorageItem("SurveyingMoments_Grid_Style", val);
  };

  function onFilterChange(_, list) {
    setDefaultFilter(list);
    setLocalStorageItem(`SurveyingMoments_Grid_Filters`, list);
  }
  function onViewColumnsChange(changedColumn, action) {
    if (action == "add") {
      var listToUpdate = Object.assign([], columnsVisibility);

      var column = listToUpdate.find((x) => x.name === changedColumn);
      if (column) {
        column.visible = true;
      } else {
        listToUpdate.push({ name: changedColumn, visible: true });
      }
      setColumnsVisibility(listToUpdate);
      setLocalStorageItem("SurveyingMoments_ColumnsVisibility", listToUpdate);
    } else if (action == "remove") {
      var listToUpdate = Object.assign([], columnsVisibility);

      var column = listToUpdate.find((x) => x.name === changedColumn);
      if (column) {
        column.visible = false;
      } else {
        listToUpdate.push({ name: changedColumn, visible: false });
      }
      setColumnsVisibility(listToUpdate);
      setLocalStorageItem("SurveyingMoments_ColumnsVisibility", listToUpdate);
    }
  }
  function customToolbar() {
    return (
      <>
        {matchWidthDownSm && (
          <DataGridSetting
            canListType={canListType}
            hideImageSizePicker
            onChangeGridViewType={(val) => handleChangeGridStyle(val)}
          />
        )}
      </>
    );
  }

  const handleRowClick = (selectedRow) => {
    history.push({
      pathname: props.match.url.replace("toezichtsmomenten", "opdrachten"),
      state: {
        filter: {
          moment: selectedRow.name,
          object: selectedRow.object,
        },
        canGoBack: true,
      },
    });
  };

  const options = {
    sortingList: [
      { label: "wkb.surveyingMoment", key: "name", type: "string" },
      { label: "general.object", key: "object", type: "string" },
      { label: "general.startDate", key: "startDate", type: "date" },
      { label: "general.endDate", key: "endDate", type: "date" },
      {
        label: "wkb.surveyingMoment.internalStatus",
        key: "internalStatusPlainText",
        type: "string",
      },
      {
        label: "wkb.executive",
        key: "responsiblePersonPlainText",
        type: "string",
      },
      {
        label: "general.company",
        key: "responsiblePersonCompanyPlainText",
        type: "string",
      },
    ],
    onRowClick: (rowData, rowMeta) => {
      const surveyingMomentId = rowData[0];
      const selectedRow =
        surveys &&
        surveys.find((x) => x.surveyingMomentId === surveyingMomentId);
      if (!!selectedRow) {
        handleRowClick(selectedRow);
      }
    },
    download: true,
    print: true,
    customToolbar,
    onViewColumnsChange,
    onFilterChange,
  };

  return (
    <div className={classes.scrollContainer}>
      <Container ref={ref} maxWidth={false} className={classes.mainContainer}>
        <DataGrid
          canListType={canListType}
          CustomRowRender={
            matchWidthDownSm && !!canListType
              ? (rest) => (
                  <SurveyingMomentCustomRow
                    onRowClick={handleRowClick}
                    {...props}
                    {...rest}
                  />
                )
              : null
          }
          options={options}
          identifier="surveyingMomentId"
          localColumnOrdersIdentifier={generateLocalStorageKey(
            "Surveying_Moments_Table_List_Order"
          )}
          localColumnSortIdentifier={generateLocalStorageKey(
            "Surveying_Moments_Table_List_Sort"
          )}
          columns={columns}
          columnsVisibilityIdentifier={generateLocalStorageKey(
            "SurveyingMoments_ColumnsVisibility"
          )}
          data={surveys}
          rowsPerPageIdentifier={generateLocalStorageKey(
            "SurveyingMoments_RowsPerPage"
          )}
          title={"wkb.surveyingMoment"}
          loading={getSurveysLoading}
          getData={getSurveyingMomentsForPrintAndDownload}
          canGoBack={false}
          fontSize={12}
        />
        {isObjectModal && (
          <SelectSingleObjectModal
            open={isObjectModal}
            buildings={all}
            canProjectLevel
            selectedBuilding={mappedState.selectedBuildingId}
            onSave={handleSaveObjects}
            onClose={handleCloseObjects}
          />
        )}
      </Container>
    </div>
  );
};

const ResponsiblePersonLabel = ({ person, surveyingMoment, ...props }) => {
  const classes = useStyles();
  const [popover, setPopover] = React.useState({ element: null, data: {} });
  const [timeOutId, setTimeOutId] = useState(null);
  const handlePopoverClose = (e) => {
    e && e.stopPropagation && e.stopPropagation();
    clearTimeout(timeOutId);
    setPopover((p) => ({ element: null, data: {} }));
  };
  const handlePopoverOpen = ({ currentTarget }, data) => {
    clearTimeout(timeOutId);
    const id = setTimeout(() => {
      setPopover({ element: currentTarget, data });
    }, [1500]);
    setTimeOutId(id);
  };
  const open = Boolean(popover.element);
  return (
    <>
      <Typography
        className={clsx(classes.customTableCell, classes.content)}
        aria-owns={open ? "user-info" : undefined}
        aria-haspopup="true"
        onMouseOver={(e) =>
          handlePopoverOpen(e, {
            userInformation: person,
            surveyingMomentId: surveyingMoment.surveyingMomentId,
          })
        }
        onMouseLeave={() => {
          clearTimeout(timeOutId);
        }}
      >
        {person ? person.relationName : ""}
      </Typography>
      <UserInformation
        requestKey={"updateResponsiblePersonMoments"}
        open={open}
        projectId={surveyingMoment && surveyingMoment.projectId}
        repairRequestRight={false}
        hideExtraInfo
        data={popover.data}
        anchorEl={popover.element}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onMouseLeave={handlePopoverClose}
        onClose={handlePopoverClose}
      />
      <div className={classes.spacer}> {person ? person.relationName : ""}</div>
      <span>&nbsp;</span>
    </>
  );
};

export default SurveyMoments;

const StyledMenuItem = withStyles((theme) => ({
  root: {
    whiteSpace: "pre-wrap",
    padding: theme.spacing(1, 0),
    "& div": {
      minWidth: 40,
      color: theme.palette.grey[700],
    },
    "& span": {
      color: theme.palette.grey[700],
    },
  },
}))(MenuItem);

const theme = createTheme({
    palette: {
      primary: {
        main: "#2DA800",
      },
    },
    components: {
      MuiLinearProgress: {
        styleOverrides: {
        root: {
          borderRadius: 50,
          height: 15,
        },
        bar1Determinate: {
          borderRadius: 4,
        },
        colorPrimary: {
          backgroundColor: "#9b9b9b",
        },
      },
    },
    },
  }
);

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  progressStyle: {
    fontWeight: "bold",
    fontSize: "12px",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, 0)",
    color: "#fff",
  },
  warning: {
    fill: "#ffc107",
  },
  pending: {
    fill: "#dc3545",
  },
  colorSuccess: {
    color: theme.palette.success.main,
  },
  progressWrapper: {
    position: "relative",
    color: theme.palette.grey[800],
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "100%",
    },
  },

  customMainHeader: {
    fontSize: 12,
    "& .MuiButtonBase-root": {
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16,
      },
      fontSize: 14,
      padding: 0,
      margin: 0,
      whiteSpace: "nowrap ",
    },
  },
  tableIcon: {
    fontSize: 20,
    boxShadow: "none",
  },
  messageCountIcon: {
    "& span": {
      [theme.breakpoints.down("md")]: {
        fontSize: 9,
        height: 14,
        minWidth: 14,
        padding: theme.spacing(0, 0.4),
      },
    },
  },
  iconContainer: {
    [theme.breakpoints.only("sm")]: {
      minWidth: 20,
    },
    [theme.breakpoints.only("xs")]: {
      minWidth: 15,
    },
    "& svg": {
      fontSize: 16,
    },
    minWidth: 32,
    display: "flex",
    justifyContent: "center",
  },
  menuContent: {
    "& .MuiPopover-paper": {
      borderRadius: 10,
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important",
    },
    "& ul": {
      padding: theme.spacing(0),
    },
    "& ul > ul": {
      padding: theme.spacing(2),
    },
  },
  menuListStyle: {
    padding: theme.spacing(1.875, 1.25),
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: 10,
    maxWidth: 325,
    width: "fit-content",
    minWidth: 200,
  },

  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: theme.spacing(0),
    background: theme.palette.common.white,
  },
  scrollContainer: {
    height: "100%",
    padding: theme.spacing(0),
    overflow: "auto",
  },

  customTableCell: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.up("sm")]: {
      whiteSpace: "nowrap",
    },
    fontSize: "14px ",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  iconButton: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0),
    },
    padding: theme.spacing(0.45, 0.5),
  },
  colorWarning: {
    color: theme.palette.error.main,
    fontSize: 16,
  },
  content: {
    position: "absolute",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  spacer: {
    height: 0,
    overflow: "hidden",
  },
}));
