import React from "react";
import { connect } from "react-redux";
import {
    Container,
    Grid2 as Grid,
    Typography, AppBar, Toolbar, Table, TableBody, TableRow, TableCell, IconButton, Hidden,
    Dialog,
    DialogTitle,
    Button,
    Stack
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { ArrowBack, PictureAsPdf, Cancel, Close, Call } from "@mui/icons-material"
import { withTranslation } from 'react-i18next';
import clsx from "clsx";
import { history, nl2br, formatDate, authHeader, getMimeTypefromString } from '../../_helpers';
import { apps, userAccountRoleConstants } from "../../_constants";
import RepairRequestContactCard from "../ConstructionQuality/RepairRequestContactCard";
import { URLS } from "../../apis/urls";
import DocumentViewer from "../../components/DocumentViewer";
import { Tooltip } from "@mui/material";
import NotificationPanel from "../ConstructionQuality/NotificationPanel";
import { DialogContent } from "@mui/material";
import { DialogActions } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/material";

const { webApiUrl } = window.appConfig;

const styles = theme => ({
    appBar: {
    },
    bold: {
        fontWeight: "bold"
    },
    mainContainer: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0)
        }
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(5, 0, 6),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(0)
        }
    },
    innerContainer: {
        padding: theme.spacing(2, 3, 4)
    },
    bigAvatar: {
        margin: 'auto',
        width: 120,
        height: 120
    },
    halfWidth: {
        width: '50%',
        verticalAlign: 'top'
    },
    button: {
        '&:hover': {
            color: theme.palette.primary.contrastText
        }
    },
    thumbnail: {
        width: '100%',
        minWidth: 100,
        height: 50,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center',
        backgroundSize: 'contain',
        '&.big': {
            width: '100%',
            height: 0,
            padding: '50%',
            borderRadius: theme.spacing(1),
            backgroundPosition: 'center',
            backgroundColor: 'rgba(0,0,0,0.1)',
            backgroundSize: 'cover',
            cursor: "pointer"
        }
    },
    modalContentIcon: {
        display: "block",
        maxWidth: "150px",
        maxHeight: "40px",
        height: "100%",
        marginRight: "1rem",
        objectFit: "contain"
    },
    pdfTextWrapper: {
        position: "absolute",
        top: "21px",
        width: "100%",
        textAlign: 'center',
        fontSize: "24px",
        fontWeight: "bolder"
    },
    galleryImageTile: {
        position: "relative",
    },
    notificationContainer: {
        borderWidth: '1px',
        borderColor: theme.palette.grey[300],
        borderStyle: 'solid'
    }
});

const isPropertyManagerRole = (data) => (data.roles && data.roles.length === 1 && data.roles.includes(userAccountRoleConstants.propertyManager));
class Page extends React.Component {
    state = {
        repairRequest: null,
        isPropertyManager: false,
        documents: null,
        isAppoinmentCancelDialogOpen: false
    };
    componentDidMount() {
        this.GetRepairRequestDetails();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.selected || prevProps.selected.buildingId !== this.props.selected.buildingId) {
            this.GetRepairRequestDetails();
        }
    }

    GetRepairRequestDetails() {
        let { repairRequestId } = this.props.match.params;
        const { selected, buildings } = this.props;
        if (selected && repairRequestId) {
            const url = webApiUrl + 'api/RepairRequest/GetRepairRequestDetails/' + encodeURI(repairRequestId);
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            this.setState({
                repairRequest: null,
            });

            fetch(url, requestOptions)
                .then(Response => Response.json())
                .then(findResponse => {
                    if (findResponse.number) {
                        const building = buildings.find((x) => x.buildingId === findResponse.buildingId);
                        const projectBuilding = buildings.find((x) => (x.projectId === findResponse.projectId || x.buildingId === findResponse.buildingId));
                        const isPropertyManager = isPropertyManagerRole(building || projectBuilding);
                        this.setState({ repairRequest: findResponse, isPropertyManager });
                    }
                });
        }
    }

    handleFileModalClose = () => {
        this.setState({ attachmentIndex: null, documents: null });
    }
    handleFileModalOpen = (index, type = "image") => {
        const { repairRequest } = this.state;
        const isImage = type === "image"
        const filesAttribute = isImage ? "attachments" : "pdfAttachments"

        this.setState({
            attachmentIndex: index,
            documents: [{
                uri: URLS.GET_ATTACHMENT + encodeURI(repairRequest[filesAttribute][index] ? repairRequest[filesAttribute][index].attachmentId : ''),
                fileType: getMimeTypefromString(isImage ? ".jpg" : ".pdf"), //TODO: use mimetype from server after api changes
            }]
        });
    };

    handleAppoinmentCancelDialogClose = () => {
        this.setState({ isAppoinmentCancelDialogOpen: false });
    }
    handleAppoinmentCancelDialogOpen = () => {
        this.setState({ isAppoinmentCancelDialogOpen: true });
    }

    render() {
        const { t, classes } = this.props;
        const { repairRequest, isPropertyManager, attachmentIndex, documents } = this.state;

        return (
            (<Container className={classes.mainContainer} >
                <Grid container>
                    <Grid container className={classes.container} size={{xs:12}}>
                        <AppBar position="sticky" color='inherit' className={classes.appBar}>
                            <Toolbar variant="dense">
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    aria-label="go back"
                                    component="span"
                                    onClick={history.goBack}
                                    size="large">
                                    <ArrowBack />
                                </IconButton>
                                <Typography className={classes.bold}>{t('repairRequest.repairRequestDetails') + ' ' + (repairRequest ? repairRequest.number : '')}</Typography>
                            </Toolbar>
                        </AppBar>
                        {

                            repairRequest && (
                                <>
                                    <Grid
                                        container
                                        className={classes.innerContainer}
                                        sx={{
                                            rowGap: 2,
                                            alignItems: "flex-start"
                                        }}
                                        size={{
                                            xs: 12,
                                            md: 6
                                        }}>
                                        <Grid size={{xs: 12}}>
                                            <Typography variant="h6">{t('general.generalInformation')}</Typography>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" className={classes.halfWidth}>
                                                            {t('general.date') + ':'}
                                                        </TableCell>
                                                        <TableCell>{repairRequest.date && formatDate(new Date(repairRequest.date))}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            {t('general.type') + ':'}
                                                        </TableCell>
                                                        <TableCell>{repairRequest.repairRequestType && repairRequest.repairRequestType}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            {t('general.status') + ':'}
                                                        </TableCell>
                                                        <TableCell>{repairRequest.status && repairRequest.status}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                        <Grid size={{xs: 12}}>
                                            <Typography variant="h6">{t('layout.navbar.address')}</Typography>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" className={classes.halfWidth}>
                                                            {t('general.streetAndHouseNumber') + ':'}
                                                        </TableCell>
                                                        <TableCell>{repairRequest.address && (repairRequest.address.street ? repairRequest.address.street : "") + " " + (repairRequest.address.houseNo ? repairRequest.address.houseNo : "") + (repairRequest.address.houseNoAddition ? " " + repairRequest.address.houseNoAddition : "")}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            {t('general.address.postcode') + ':'}
                                                        </TableCell>
                                                        <TableCell>{repairRequest.address && repairRequest.address.postcode && repairRequest.address.postcode}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            {t('general.address.city') + ':'}
                                                        </TableCell>
                                                        <TableCell>{repairRequest.address && repairRequest.address.place && repairRequest.address.place}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>

                                        <Grid size={{xs: 12}}>
                                            <Typography variant="h6">{t('repairRequest.repairRequestDescription')}</Typography>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row" className={classes.halfWidth}>
                                                            {t('general.location') + ':'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {repairRequest.location && repairRequest.location}
                                                            {repairRequest.drawingPinLocation && repairRequest.drawingPinLocation.dossierFileId &&
                                                                <div
                                                                    onClick={() => this.setState({ openDrawingLocation: true })}
                                                                    class={clsx(classes.thumbnail, 'big')} style={{
                                                                        backgroundImage: `url(${webApiUrl}api/RepairRequest/GetPinLocationImage/${repairRequest.requestId})`
                                                                    }} />
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            {t('general.description') + ':'}
                                                        </TableCell>
                                                        <TableCell>{repairRequest.desc && repairRequest.desc}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            {t('general.explanation') + ':'}
                                                        </TableCell>
                                                        <TableCell>{nl2br(repairRequest.detailDesc)}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                            {
                                                repairRequest.completed === true &&
                                                <>
                                                    <br />
                                                    <Typography variant="h6">{repairRequest.status !== "Afgewezen" ? t('repairRequest.solution') : t('general.reasonOfRejection')}</Typography>
                                                    <Table>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>
                                                                    {
                                                                        nl2br(
                                                                            repairRequest.status !== "Afgewezen"
                                                                                ?
                                                                                (
                                                                                    repairRequest.completionText
                                                                                        ?
                                                                                        repairRequest.completionText
                                                                                        :
                                                                                        ""
                                                                                )
                                                                                :
                                                                                (
                                                                                    repairRequest.rejectionText
                                                                                        ?
                                                                                        repairRequest.rejectionText
                                                                                        :
                                                                                        ""
                                                                                )
                                                                        )
                                                                    }
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </>
                                            }
                                        </Grid>
                                        {isPropertyManager && <Grid
                                            size={{
                                                xs: 12,
                                                md: 6
                                            }}>
                                            <Typography variant="h6">
                                                {t('general.contactDetails')}
                                            </Typography>
                                            <Grid container spacing={1}>
                                                {
                                                    repairRequest.contactInfo.buyer &&
                                                    <>
                                                        {
                                                            repairRequest.contactInfo.buyer.type === 0 &&
                                                            <>
                                                                <Grid size={{xs: 12}}>
                                                                    <RepairRequestContactCard
                                                                        hidePointOfContactCheckbox
                                                                        object={repairRequest.contactInfo.buyer.p1}
                                                                        pointOfContactType={0}
                                                                        selectedPointOfContactType={repairRequest.pointOfContact}
                                                                        subTitle={t("general.buyer1")} />
                                                                </Grid>
                                                                {
                                                                    repairRequest.contactInfo.buyer.p2 &&
                                                                    <Grid size={{xs: 12}}>
                                                                        <RepairRequestContactCard
                                                                            hidePointOfContactCheckbox
                                                                            object={repairRequest.contactInfo.buyer.p2}
                                                                            pointOfContactType={0}
                                                                            selectedPointOfContactType={repairRequest.pointOfContact}
                                                                            subTitle={t("general.buyer2")} />
                                                                    </Grid>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </Grid>
                                        </Grid>}

                                    </Grid>
                                    <Grid
                                        container
                                        className={classes.innerContainer}
                                        sx={{
                                            alignItems: "flex-start"
                                        }}
                                        size={{
                                            xs: 12,
                                            md: 6
                                        }}>
                                        <Grid className={classes.notificationContainer} size={{xs:12}}>
                                            <NotificationPanel expandViewTitle={`${repairRequest.number} - ${repairRequest.desc}`} hasChats={repairRequest.hasChats} repairRequestId={repairRequest.requestId} repairRequestNumber={repairRequest.number} resolverId={repairRequest.resolverId} hideWorkOrderLink={true} canInitiateChat={repairRequest?.canInitiateChat} hideAddChatButton  refreshParentState={()=>this.GetRepairRequestDetails()} />
                                        </Grid>
                                    </Grid>
                                </>
                            )
                        }
                        {
                            repairRequest && ((repairRequest.attachments && repairRequest.attachments.length > 0) || (repairRequest.pdfAttachments && repairRequest.pdfAttachments.length > 0)) &&
                            <Grid container spacing={2} className={classes.innerContainer} size={{xs:12}}>
                                <Grid size={{xs: 12}}>
                                    <Typography variant="h6">{t('general.images')}</Typography>
                                </Grid>
                                {repairRequest.attachments &&
                                    repairRequest.attachments.length > 0 &&
                                    repairRequest.attachments.map((attachment, index) =>
                                        <Grid
                                            size={{
                                                xs: 6,
                                                sm: 4,
                                                md: 3,
                                                lg: 2
                                            }}>
                                            <div
                                                key={`${attachment.attachmentId}-${index}`}
                                                onClick={() => this.handleFileModalOpen(index)}
                                                class={clsx(classes.thumbnail, 'big')} style={{
                                                    backgroundImage: `url(${webApiUrl}api/home/getattachment/${attachment.attachmentId})`
                                                }} />
                                        </Grid>
                                    )
                                }
                                {repairRequest.pdfAttachments &&
                                    repairRequest.pdfAttachments.length > 0 &&
                                    repairRequest.pdfAttachments.map((file, index) =>
                                        <Grid
                                            size={{
                                                xs: 6,
                                                sm: 4,
                                                md: 3,
                                                lg: 2
                                            }}>
                                            <div className={classes.galleryImageTile} onClick={() => this.handleFileModalOpen(index, "pdf")}>
                                                <div
                                                    key={`${file.attachmentId}-${index}`}
                                                    class={clsx(classes.thumbnail, 'big')} style={{
                                                        backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${file.attachmentId})`
                                                    }} />
                                                <Typography className={classes.pdfTextWrapper} > <PictureAsPdf sx={{ fontSize: "40px" }} color={"error"} /></Typography>
                                            </div>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        }

                        {
                            repairRequest?.resolvers &&
                            <Grid
                                className={classes.innerContainer}
                                sx={{
                                    mt: -2
                                }}
                                size={{xs:12}}>
                                <Typography variant="h6">{t('general.resolvers')}</Typography>
                                <div style={{ overflow: 'auto' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {t('general.logo')}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {t('general.organisation')}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {t('repairRequest.phoneNumber')}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {t('repairRequest.notifiedOn')}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {t('general.appointment')}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {/* empty cell for showing cancel icon */}
                                                </TableCell>
                                            </TableRow>
                                            {
                                                repairRequest.resolvers.map((resolver, index) => (
                                                    <TableRow key={index}>
                                                        <Dialog
                                                            open={this.state.isAppoinmentCancelDialogOpen}
                                                            onClose={this.handleAppoinmentCancelDialogClose}
                                                            maxWidth="xs"
                                                            fullWidth
                                                        >
                                                            <DialogTitle sx={{ m: 0, p: 1.5, bgcolor: grey[200] }} id="customized-dialog-title">
                                                                <Typography>
                                                                    <Cancel color="primary" sx={{ fontSize: 30 }} /> &nbsp;
                                                                    {t("repairRequest.cancelOrRescheduleAppointment.title")}
                                                                </Typography>
                                                            </DialogTitle>
                                                            <IconButton
                                                                aria-label="close"
                                                                onClick={() => this.handleAppoinmentCancelDialogClose()}
                                                                sx={theme => ({
                                                                    position: 'absolute',
                                                                    right: 8,
                                                                    top: 8,
                                                                    color: theme.palette.grey[500]
                                                                })}
                                                            >
                                                                <Close sx={{ color: grey[900] }} />
                                                            </IconButton>
                                                            <DialogContent>
                                                                <Stack spacing={2}>
                                                                    <Typography
                                                                        variant="h6"
                                                                        sx={{
                                                                            textAlign: "center",
                                                                            fontWeight: 400
                                                                        }}>
                                                                        {t("repairRequest.cancelOrRescheduleAppointment.subTitle")}
                                                                    </Typography>
                                                                    <Stack
                                                                        direction="row"
                                                                        sx={{
                                                                            rowGap: 1,
                                                                            p: 0.5,
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            bgcolor: grey[100]
                                                                        }}>
                                                                        <Box
                                                                            component="img"
                                                                            src={`${webApiUrl}api/organisation/GetOrganisationLogo/${resolver.organisatonId}`}
                                                                            class={classes.modalContentIcon}
                                                                        />
                                                                        <Stack sx={{
                                                                            columnGap: 1
                                                                        }}>
                                                                            <Typography variant="body2" sx={{
                                                                                color: grey[900]
                                                                            }}>{resolver?.name}</Typography>
                                                                            {
                                                                                resolver?.telephone && <Typography variant="body2" sx={{color: "primary.main"}} >
                                                                                    <Call fontSize="small" sx={{ pr: 0.5 }} />
                                                                                    {resolver.telephone}
                                                                                </Typography>
                                                                            }

                                                                        </Stack>
                                                                    </Stack>
                                                                </Stack>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button autoFocus onClick={this.handleAppoinmentCancelDialogClose}>
                                                                    {t("general.cancel")}
                                                                </Button>
                                                            </DialogActions>
                                                        </Dialog>

                                                        <TableCell>
                                                            <div
                                                                class={classes.thumbnail}
                                                                style={{
                                                                    backgroundImage: `url(${webApiUrl}api/organisation/GetOrganisationLogo/${resolver.organisatonId})`
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>{resolver.name && resolver.name}</TableCell>
                                                        <TableCell>{resolver.telephone && resolver.telephone}</TableCell>
                                                        <TableCell>{resolver.dateNotified && formatDate(new Date(resolver.dateNotified))}</TableCell>
                                                        <TableCell>{ resolver?.appointmentDateTime  &&<Typography
                                                            sx={{
                                                                color: resolver?.appointmentCancelled ? "error" : "initial",
                                                                textDecoration: resolver?.appointmentCancelled ? 'line-through' : 'none'
                                                            }}>
                                                            {formatDate(new Date(resolver?.appointmentDateTime), true)}
                                                        </Typography>}</TableCell>
                                                        <TableCell>
                                                            {!resolver?.appointmentCancelled && resolver?.appointmentDateTime &&
                                                                <IconButton onClick={this.handleAppoinmentCancelDialogOpen} >
                                                                    <Tooltip title={t("general.cancel")} arrow enterTouchDelay={0} >
                                                                        <Cancel />
                                                                    </Tooltip>
                                                                </IconButton>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            }

                                        </TableBody>
                                    </Table>
                                </div>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                {<DocumentViewer radius={classes.docRadius} isSurveying open={attachmentIndex != null && this.state.documents} onClose={this.handleFileModalClose} documents={this.state.documents} />}
                {
                    repairRequest && repairRequest.drawingPinLocation && repairRequest.drawingPinLocation.dossierFileId && this.state.openDrawingLocation && <DocumentViewer
                        radius={classes.docRadius}
                        isSurveying
                        open={this.state.openDrawingLocation}
                        onClose={() => { this.setState({ openDrawingLocation: false }); }}
                        documents={[{
                            uri: `${webApiUrl}api/RepairRequest/GetPinLocationImage/${repairRequest.requestId}`,
                            fileType: getMimeTypefromString(".jpg"), //TODO: use mimetype from server after api changes
                        }]} />
                }
            </Container>)
        );
    }
}

function mapStateToProps(state) {
    const { authentication, buildings, app } = state;
    const { user } = authentication;
    const { selected, all } = buildings;
    return {
        user,
        selected,
        buildings: all,
        app
    };
}

const connectedPage = connect(mapStateToProps)(withTranslation()(withStyles(styles)(Page)));
export { connectedPage as RepairRequestDetailsPage };