import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  Container,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { LOCAL_STORAGE_GRID_KEYS } from "../../_constants";
import { formatDate, getLocalStorageItem, LoginRequestStatusKeys, setLocalStorageItem } from "../../_helpers";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { getGridContextMenuList } from "../../_helpers/getGridContextMenuList";
import DataGridV2 from "../../components/DataGridV2";
import { LabelColumnV2, LinkColumnV2 } from "../../components/DataGridV2/DataColumnsV2";
import { getLoginRequests } from "../../apis/loginRequestApi";
import useGridDataModalHandler from "../../_hooks/useGridDataModalHandler";
import { getStatusColor } from "./LoginRequestDetails";

const LoginRequests = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const app = useSelector((state) => state.app);
  const apiRef = useGridApiRef();
  const muiTableRef = useRef();
  const theme = useTheme()
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const {
    loading,
    loadedRows,
    sortModel,
    handleSort,
    handleCellSort,
    filterModel,
    handleFilter,
    handleSearch,
    searchText,
    getAllRecords,
    handleCellFilter,
    totalRecords,
    paginationModel,
    onPaginationModelChange,
    getAllExportRecords
  } = useGridDataModalHandler({
    additionalParams: { app },
    rowIdentifier: "loginRequestId",
    apiCall: getLoginRequests,
    sortModal: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.LOGIN_REQUEST_COLUMN_SORT.AFTERCARE, undefined
    ),
    filterModal: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.LOGIN_REQUEST_COLUMN_FILTER.AFTERCARE, undefined
    ),
    paginationModel: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.LOGIN_REQUEST_PAGINATION.AFTERCARE, undefined
    ),
    searchText: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.LOGIN_REQUEST_SEARCH_TEXT.AFTERCARE, ""
    ),
    dateTimeFields: ["createdOn", "modifiedOn"]
  });

  const getLoginRequestsForPrintAndDownload = async ({ selectedColumnsNames = [], isAllSelected = true }) => {
    const {
      data: { results },
    } = await (selectedColumnsNames.length > 0 ? getAllExportRecords({
      exportColumns: selectedColumnsNames,
      additionalCondition: isAllSelected ? [] : [{
        field: "loginRequestId",
        operator: "isAnyOf",
        value: [
          ...(rowSelectionModel)
        ],
      }]
    }) : getAllRecords());
    const data = results?.map((result) => {
      const singleLoginReqObj = { ...result }
      if (singleLoginReqObj?.["requestedDate"]) singleLoginReqObj["requestedDate"] = formatDate(new Date(singleLoginReqObj["requestedDate"]))
      if (singleLoginReqObj?.["loginRequestId"]) singleLoginReqObj["id"] = singleLoginReqObj["loginRequestId"]
      if (singleLoginReqObj?.["loginRequestStatus"]?.toString()) singleLoginReqObj["loginRequestStatus"] = t(`loginRequest.status.${singleLoginReqObj["loginRequestStatus"]}`)
      return singleLoginReqObj;
    });
    return data;
  };


  const getOptions = getGridContextMenuList({
    handleCellSort,
    handleCellFilter,
    filterKey: LOCAL_STORAGE_GRID_KEYS.LOGIN_REQUEST_COLUMN_FILTER.AFTERCARE,
    sortKey: LOCAL_STORAGE_GRID_KEYS.LOGIN_REQUEST_COLUMN_SORT.AFTERCARE,
    i18n,
    t,
    apiRef,
    getValueForFilter: (rowData, extraData) => {
      let value = ""
      switch (extraData.fieldName) {
        case "loginRequestStatus":
          value = rowData?.loginRequestStatus?.toString()
          break;
        default:
          value = rowData[extraData.fieldName]
          break;
      }
      return value
    },
    getValueForCopy: (rowData, extraData) => {
      let text = ""
      switch (extraData.fieldName) {
        case "status":
          text = t(`loginRequest.status.${rowData.loginRequestStatus}`)
          break;
        default:
          text = rowData[extraData.fieldName]
          break;
      }
      return text
    }
  })

  const columns = useMemo(() => {
    return [
      {
        field: "requestedDate",
        download: true,
        type: "date",
        valueGetter: (params) => {
          const rowData = params.row;
          if (!rowData?.requestedDate) return "";
          return new Date(rowData?.requestedDate);
        },
        headerName: t("loginRequest.dateOfRequest"),
        flex: 1,
        renderCell: (params) => {
          const rowData = params.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2
              date={rowData.requestedDate}
              request={rowData}
              cellSubMenuOptions={{ options: getOptions({ field: "requestedDate", isQuickFilterDisplay: Boolean(rowData.requestedDate) }), extraData: { fieldName: "requestedDate", type: "date" } }}
            />
          )
        },
      },
      {
        field: "address",
        download: true,
        headerName: t("loginRequest.addressOfProperty"),
        flex: 1,
        renderCell: (params) => {
          const rowData = params.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2
              label={rowData.address}
              request={rowData}
              cellSubMenuOptions={{ options: getOptions({ field: "address", isQuickFilterDisplay: Boolean(rowData.address) }), extraData: { fieldName: "address" } }}
            />
          )
        },
      },
      {
        field: "loginRequestNumber",
        download: true,
        headerName: t("loginRequest.applicantInfo.requestNo"),
        flex: 1,
        renderCell: (params) => {
          const rowData = params.row;
          if (!rowData) return "";
          return (
            <LinkColumnV2
              label={rowData.loginRequestNumber}
              to={`/nazorg/inlogverzoeken/${rowData.loginRequestId}`}
              request={rowData}
              cellSubMenuOptions={{ options: getOptions({ field: "loginRequestNumber", isQuickFilterDisplay: Boolean(rowData.loginRequestNumber) }), extraData: { fieldName: "loginRequestNumber" } }}
            />
          )
        },
      },
      {
        field: "person1FullName",
        download: true,
        headerName: t("loginRequest.personOneName"),
        flex: 1,
        renderCell: (params) => {
          const rowData = params.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2
              label={rowData.person1FullName}
              request={rowData}
              cellSubMenuOptions={{ options: getOptions({ field: "person1FullName", isQuickFilterDisplay: Boolean(rowData.person1FullName) }), extraData: { fieldName: "person1FullName" } }}
            />
          )
        },
      },
      {
        field: "person1Email",
        download: true,
        headerName: t("loginRequest.personOneEmail"),
        flex: 1,
        renderCell: (params) => {
          const rowData = params.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2
              label={rowData.person1Email}
              request={rowData}
              cellSubMenuOptions={{ options: getOptions({ field: "person1Email", isQuickFilterDisplay: Boolean(rowData.person1Email) }), extraData: { fieldName: "person1Email" } }}
            />
          )
        },
      },
      {
        field: "person2FullName",
        download: true,
        headerName: t("loginRequest.personTwoName"),
        flex: 1,
        renderCell: (params) => {
          const rowData = params.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2
              label={rowData.person2FullName}
              request={rowData}
              cellSubMenuOptions={{ options: getOptions({ field: "person2FullName", isQuickFilterDisplay: Boolean(rowData.person2FullName) }), extraData: { fieldName: "person2FullName" } }}
            />
          )
        },
      },
      {
        field: "person2Email",
        download: true,
        headerName: t("loginRequest.personTwoEmail"),
        flex: 1,
        renderCell: (params) => {
          const rowData = params.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2
              label={rowData.person2Email}
              request={rowData}
              cellSubMenuOptions={{ options: getOptions({ field: "person2Email", isQuickFilterDisplay: Boolean(rowData.person2Email) }), extraData: { fieldName: "person2Email" } }}
            />
          )
        },
      },
      {
        field: "loginRequestStatus",
        download: true,
        headerName: t("general.status"),
        flex: 1,
        type: "singleSelect",
        valueOptions: Object.values(LoginRequestStatusKeys).map((loginRequestStatusKey) => ({ value: loginRequestStatusKey.toString(), label: t(`loginRequest.status.${loginRequestStatusKey}`) })),
        renderCell: (params) => {
          const rowData = params.row;
          if (!rowData) return "";
          return (
            <LabelColumnV2
              label={t(`loginRequest.status.${rowData.loginRequestStatus}`)}
              request={params.row}
              color={getStatusColor(rowData.loginRequestStatus, theme)}
              cellSubMenuOptions={{ options: getOptions({ field: "loginRequestStatus", isQuickFilterDisplay: Boolean(rowData.loginRequestStatus?.toString()) }), extraData: { fieldName: "loginRequestStatus", type: "singleSelect" } }}
            />
          )
        },
      },
      {
        field: "loginRequestId",
        headerName: t("general.primaryKey"),
        download: true,
        print: false,
        filterable: false,
        sortable: false,
        hidden: true,
      },
    ];
  }, [t, getOptions, theme]);

  const onFilterModelChange = useCallback((newFilterMModel) => {
    handleFilter(newFilterMModel, (newFilterMModel) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.LOGIN_REQUEST_COLUMN_FILTER.AFTERCARE,
        newFilterMModel
      );
    })
  }, [handleFilter])

  const getToolbarProps = useMemo(() => {
    const toolbarProps = {
      apiRef: apiRef,
      columns: columns,
      title: t("loginRequest"),
      totalRecords: totalRecords,
      getData: getLoginRequestsForPrintAndDownload,
      handleSearch: (value) => {
        handleSearch(value, (value) => {
          setLocalStorageItem(
            LOCAL_STORAGE_GRID_KEYS.LOGIN_REQUEST_SEARCH_TEXT.AFTERCARE,
            value
          );
        });
      },
      searchText: searchText,
      selectedRows: rowSelectionModel,
    };
    return toolbarProps;
  }, [apiRef, columns, handleSearch, rowSelectionModel, searchText, t, totalRecords]);

  return (
    <Container maxWidth={false} className={classes.mainContainer}>
      <DataGridV2
        localSearchIdentifier={LOCAL_STORAGE_GRID_KEYS.LOGIN_REQUEST_SEARCH_TEXT.AFTERCARE}
        localColumnOrderIdentifier={LOCAL_STORAGE_GRID_KEYS.LOGIN_REQUEST_COLUMN_ORDER.AFTERCARE}
        localColumnWidthIdentifier={LOCAL_STORAGE_GRID_KEYS.LOGIN_REQUEST_COLUMN_WIDTH.AFTERCARE}
        localColumnVisibilityIdentifier={LOCAL_STORAGE_GRID_KEYS.LOGIN_REQUEST_COLUMN_VISIBILITY.AFTERCARE}
        rowSelectionModel={rowSelectionModel}
        checkboxSelection
        columns={columns}
        ref={muiTableRef}
        apiRef={apiRef}
        loading={loading}
        toolbarProps={getToolbarProps}
        rowCount={totalRecords}
        rows={loadedRows}
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={(paginationModal) =>
          onPaginationModelChange(paginationModal, (paginationModal) => {
            setLocalStorageItem(
              LOCAL_STORAGE_GRID_KEYS.LOGIN_REQUEST_PAGINATION.AFTERCARE,
              paginationModal
            );
          })}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) =>
          handleSort(newSortModel, (newSortModal) => {
            setLocalStorageItem(
              LOCAL_STORAGE_GRID_KEYS.LOGIN_REQUEST_COLUMN_SORT.AFTERCARE,
              newSortModal
            );
          })
        }
        filterMode="server"
        onFilterModelChange={onFilterModelChange}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        {...(!!filterModel && { filterModel })}
      />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
}));

export default LoginRequests;
