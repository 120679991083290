import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Container,  Grid2 as Grid, Typography, Divider, List, ListItem, ListItemText, AppBar, IconButton, Toolbar, Slide, Box, Hidden, Collapse, Button, Input, InputAdornment, Accordion, AccordionSummary, AccordionDetails, Tooltip,ListItemButton } from "@mui/material"
import { withStyles, withTheme } from "@mui/styles"
import { Search, ArrowBack, ExpandLess, ExpandMore, DeleteOutline, ChevronRight, Close, Menu, ArrowDropDown, FormatQuote, Edit, ErrorOutline } from "@mui/icons-material"
import { history, nl2br, formatDate, authHeader } from "../../_helpers";
//import './home.css';
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import OptionCard from "./OptionCard";
import { NumericFormat } from "react-number-format";
import RequestIndividualOption from "./RequestIndividualOption";
import { themeBreakpointUp } from "../../_helpers/breakpointHelper";
const { webApiUrl } = window.appConfig;

const styles = (theme) => ({
  appBar: {},
  grow: {
    flexGrow: 1,
  },
  mainContainer: {
    height: "100%",
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  standardOptionsContainer: {
    backgroundColor: theme.palette.background.paper,
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  slideRight: {
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    zIndex: 2,
    height: "100%",
    "&.shoppingCartVisible": {
      paddingBottom: 48,
    },
  },
  slideLeft: {
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    zIndex: 2,
    right: 0,
    height: "100%",
    "&.shoppingCartVisible": {
      paddingBottom: 48,
    },
  },
  bold: {
    fontWeight: "bold",
  },
  fullWidth: {
    width: "100%",
  },
  categoryList: {
    height: "calc(100% - 48px)",
    overflow: "auto",
    width: "100%",
  },
  categoryHeader: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 0,
    paddingBottom: 0,
    "& .MuiListItemText-root": {
      margin: theme.spacing(0.25, 0),
    },
  },
  categoryHeaderIndividual: {
    backgroundColor: theme.palette.background.default,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  optionsContainer: {
    position: "absolute",
    zIndex: 1,
    height: "100%",
    right: 0,
    "&.shoppingCartVisible": {
      paddingBottom: 48,
    },
  },
  optionsTitle: {
    overflow: "hidden",
    "& p": {
      lineHeight: 1.2,
    },
  },
  options: {
    overflow: "auto",
    flexGrow: 1,
    padding: theme.spacing(0, 3),
    height: "calc(100% - 48px)",
  },
  bottomShoppingCart: {
    position: "absolute",
    bottom: 0,
    zIndex: 3,
    "&.expanded": {
      height: "100%",
    },
  },
  expansionPanel: {
    width: "100%",
    "&.Mui-expanded": {
      height: "100%",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 48,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "12px 0",
    },
    "& .MuiCollapse-root.MuiCollapse-entered": {
      maxHeight: "calc(100% - 48px)",
      overflow: "auto",
    },
  },
  expansionPanelHeading: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '& .MuiSvgIcon-root': {
      color: "white"
    }
  },
  expansionPanelContainer: {
    padding: theme.spacing(3),
    "& .MuiAccordionDetails-root": {
      paddingTop: theme.spacing(2),
    },
  },
  totalHeading: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  headerColumn: {
    flexBasis: "50%",
    flexGrow: 1,
  },
  inputBoxSearch: {
    color: "inherit",
    maxWidth: "100%",
    margin: "-3px 5px -5px 0",
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
    "& svg": {
      color: "inherit",
    },
    flexGrow: 1,
  },
  searchList: {
    maxHeight: "calc(100% - 48px)",
    overflowX: "hidden",
    overflowY: "auto",
    width: "100%",
  },
  searchListItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
});

class Page extends React.Component {
  state = {
    categories: [],
    options: [],
    selectedOptions: [],
    individualOptions: [],
    showIndividualOptions: false,
    openLeftMenu: true,
    bottomCartExpanded: false,
    openSearchOptions: false,
    searchTerm: "",
    openSearchOptionsCategorized: false,
    searchTermCategorized: "",
  };

  componentDidMount() {
    const { location } = this.props;

    this.UpdateCategories();
    this.UpdateSelectedOptions();

    const bottomCartExpanded = location && location.state && location.state.bottomCartExpanded === true;

    var intervalId = setInterval(this.timer, 10000);
    // store intervalId in the state so it can be accessed later:
    this.setState({ intervalId: intervalId, bottomCartExpanded });
  }

  componentWillUnmount() {
    // use intervalId from the state to clear the interval
    clearInterval(this.state.intervalId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.selected || !this.props.selected || prevProps.selected.buildingId.toUpperCase() !== this.props.selected.buildingId.toUpperCase()) {
      this.UpdateCategories();
      this.UpdateSelectedOptions();
    }
  }

  timer = () => {
    this.UpdateSelectedOptions();
  };

  UpdateCategories() {
    const { selected } = this.props;
    if (selected) {
      const url = webApiUrl + "api/shopping/GetStandardOptions/" + encodeURI(selected.buildingId);
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      this.setState({
        categories: [],
        options: [],
        selectedOptions: [],
        individualOptions: [],
      });

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          this.setState({
            categories: findResponse.categories,
            options: findResponse.options.map((option, index) => {
              return { ...option, index, ref: React.createRef() };
            }),
          });
        });
    }
  }

  UpdateSelectedOptions() {
    const { selected } = this.props;
    if (selected) {
      const url = webApiUrl + "api/shopping/GetSelectedOptionsWithIndividualOptions/" + encodeURI(selected.buildingId);

      if (this.abortController && this.abortController.signal.aborted !== true) {
        this.abortController.abort();
      }

      this.abortController = new window.AbortController();

      const requestOptions = {
        method: "GET",
        headers: authHeader(),
        signal: this.abortController.signal,
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          this.updateSelectedOptionsToState(findResponse);
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            //handle aborterror here
          }
        });
    }
  }

  updateSelectedOptionsToState(response) {
    this.setState({
      selectedOptions: response.filter((x) => x.type === 0),
      individualOptions: response
        .filter((x) => x.type === 1)
        .map((option, index) => {
          return { ...option, index, ref: React.createRef() };
        }),
    });
  }

  toggleOpenLeftMenu = () => {
    this.setState({ openLeftMenu: !this.state.openLeftMenu });
  };

  showIndividualOptions = () => {
    this.setState({
      showIndividualOptions: !this.state.showIndividualOptions,
      selected: null,
    });
  };

  handleToggleCategory = (categoryId) => {
    var categoriesToUpdate = this.state.categories.slice();
    var categoryToUpdate = categoriesToUpdate.find((x) => x.optionCategoryProjectId.toUpperCase() === categoryId.toUpperCase());
    if (categoryToUpdate) {
      if (categoryToUpdate.expanded === undefined) categoryToUpdate.expanded = !categoryToUpdate.closed;
      categoryToUpdate.expanded = !categoryToUpdate.expanded;
      this.setState({
        categories: categoriesToUpdate,
      });
    }
  };

  selectHeader(category, header) {
    const selected = {
      category: category.category,
      closed: category.closed,
      optionCategoryProjectId: category.optionCategoryProjectId,
      closingDate: category.closingDate,
      optionHeaderProjectId: header.optionHeaderProjectId,
      header: header.header,
    };

    this.setState({
      selected,
      openLeftMenu: false,
      showIndividualOptions: false,
      bottomCartExpanded: false,
    });
  }

  getSelectedOption(optionStandardId) {
    return this.state.selectedOptions.find((x) => x.optionStandardId.toUpperCase() === optionStandardId.toUpperCase());
  }

  handleAddOption(option, quantity, comment) {
    const { user } = this.props;
    if (option.type !== 1) {
      const url = webApiUrl + "api/shopping/AddToCartStandardOption/";
      const requestOptions = {
        method: "POST",
        headers: authHeader("application/json"),
        body: JSON.stringify({
          buildingId: option.buildingId,
          optionId: option.optionStandardId,
          quantity: quantity,
          comment: comment,
        }),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          this.updateSelectedOptionsToState(findResponse);
        });
    } else {
      const url = webApiUrl + "api/shopping/AddToCartIndividualOption";
      const requestOptions = {
        method: "POST",
        headers: authHeader("application/json"),
        body: JSON.stringify({
          buildingId: option.buildingId,
          optionId: option.optionId,
          comment: comment,
        }),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          this.updateSelectedOptionsToState(findResponse);
        });
    }
  }

  handleGoToOption = (option) => {
    var optionToScrollRef = option.ref;
    if (option.type !== 1) {
      var categoriesToUpdate = this.state.categories.slice();
      var categoryToUpdate = categoriesToUpdate.find((x) => x.optionCategoryProjectId.toUpperCase() === option.optionCategoryProjectId.toUpperCase());
      if (categoryToUpdate) {
        var headerToUpdate = categoryToUpdate.headers.find((x) => x.optionHeaderProjectId.toUpperCase() === option.optionHeaderProjectId.toUpperCase());
        if (headerToUpdate) {
          if (!categoryToUpdate.expanded) {
            categoryToUpdate.expanded = true;
            this.setState({
              categories: categoriesToUpdate,
            });
          }
          this.selectHeader(categoryToUpdate, headerToUpdate);

          var optionToScroll = this.state.options.find((x) => x.optionStandardId.toUpperCase() === option.optionStandardId.toUpperCase());
          if (optionToScroll) {
            optionToScrollRef = optionToScroll.ref;
          }
        }
      }
    } else {
      this.showIndividualOptions();
    }

    //timeout to allow open the expansion panel before calculating the actual element top location
    setTimeout(() => {
      const { optionsList } = this.refs;
      if (optionsList) {
        const scrollHeight = optionsList.scrollHeight;
        const height = optionsList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        const scrollTopValue = maxScrollTop > 0 && maxScrollTop > optionToScrollRef.current.offsetTop - 56 ? optionToScrollRef.current.offsetTop - 56 : maxScrollTop;
        ReactDOM.findDOMNode(optionsList).scrollTop = scrollTopValue;
      }
    }, 1000);
    if (this.state.bottomCartExpanded === true) {
      this.handleBottomCartExpandToggle();
    }
    if (this.state.openSearchOptions === true) {
      this.toggleOpenSearchOptions();
    }
    if (this.state.openSearchOptionsCategorized === true) {
      this.toggleOpenSearchOptionsCategorized();
    }
  };

  handleDeleteOption = (option) => {
    const url = webApiUrl + "api/shopping/DeleteSelectedOption/" + option.optionId;
    const requestOptions = {
      method: "DELETE",
      headers: authHeader(),
    };

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        var selectedOptions = this.state.selectedOptions.slice();
        var selectedIndividualOptions = this.getSelectedIndividualOptions().filter((x) => x.optionId.toUpperCase() !== option.optionId.toUpperCase());
        var updatedOptions = selectedOptions.filter((x) => x.optionId.toUpperCase() !== option.optionId.toUpperCase());
        var bottomCartExpanded = this.state.bottomCartExpanded;
        if (bottomCartExpanded === true && updatedOptions.length === 0 && selectedIndividualOptions.length === 0) {
          bottomCartExpanded = false;
        }
        this.setState({
          selectedOptions: updatedOptions,
          bottomCartExpanded: bottomCartExpanded,
        });

        this.UpdateSelectedOptions();
      });
  };

  getSelectedIndividualOptions() {
    return this.state.individualOptions.filter((x) => x.subStatus === 20);
  }

  getSelectedOptionsByHeading(headerId, categoryId) {
    return this.state.selectedOptions.filter((x) => x.optionCategoryProjectId.toUpperCase() === categoryId.toUpperCase() && x.optionHeaderProjectId.toUpperCase() === headerId.toUpperCase());
  }

  getSelectedOptionsForUnmatchedHeader() {
    const { selectedOptions, categories } = this.state;

    return !selectedOptions
      ? []
      : selectedOptions.filter((x) => {
          var category = categories.find((y) => x.optionCategoryProjectId.toUpperCase() === y.optionCategoryProjectId.toUpperCase());
          if (!!category) {
            return !category.headers.find((z) => z.optionHeaderProjectId.toUpperCase() === x.optionHeaderProjectId.toUpperCase());
          }
          return true;
        });
  }

  getTotalText() {
    const standardOptionsTotal = this.state.selectedOptions.reduce((prev, next) => prev + next.salesPriceInclVAT * next.quantity, 0);
    const individualOptionsTotal = this.getSelectedIndividualOptions().reduce((prev, next) => prev + next.salesPriceInclVAT * next.quantity, 0);
    const total = standardOptionsTotal + individualOptionsTotal;
    return (
      <React.Fragment>
        &euro;{" "}
        {total.toLocaleString("nl-NL", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </React.Fragment>
    );
  }

  isShoppingCartVisible() {
    return this.state.selectedOptions.length > 0 || this.getSelectedIndividualOptions().length > 0;
  }

  handleBottomCartExpandToggle = () => {
    this.setState({
      bottomCartExpanded: !this.state.bottomCartExpanded,
    });
  };

  handleClickRequest = (event) => {
    const { selected, t } = this.props;
    if (selected) {
      const url = webApiUrl + "api/shopping/RequestSelectedOptions/" + encodeURI(selected.buildingId);
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((findResponse) => {
          history.push("/request-complete");
        })
        .catch((error) => {
          alert(t("general.api.error"));
        });
    } else {
      event.preventDefault();
    }
  };

  toggleOpenSearchOptions = () => {
    document.activeElement.blur();
    this.setState({ openSearchOptions: !this.state.openSearchOptions });
  };

  handleChangeSearch = (event) => {
    this.setState({
      searchTerm: event.target.value,
    });
  };

  clearAndCloseSearch = () => {
    this.setState({
      openSearchOptions: false,
      searchTerm: "",
    });
  };

  toggleOpenSearchOptionsCategorized = () => {
    document.activeElement.blur();
    this.setState({
      openSearchOptionsCategorized: !this.state.openSearchOptionsCategorized,
    });
  };

  handleChangeCategorizedSearch = (event) => {
    this.setState({
      searchTermCategorized: event.target.value,
    });
  };

  clearAndCloseCategorizedSearch = () => {
    this.setState({
      openSearchOptionsCategorized: false,
      searchTermCategorized: "",
    });
  };

  getFilteredItems(options, searchTerm) {
    var text = searchTerm.toUpperCase().split(" ");
    return options.filter(function (item) {
      return text.every(function (el) {
        var content = item.optionNo + " " + item.description + " " + item.commercialDescription;
        return content.toUpperCase().indexOf(el) > -1;
      });
    });
  }

  renderOptionInBasket(option, headerText, isClosed, hideEdit = false) {
    const { t, classes, user } = this.props;
    return (
      (<React.Fragment>
        {headerText && headerText !== "" && (
          <Grid size={{xs: 12}}>
            <Typography variant="body2">{headerText}</Typography>
          </Grid>
        )}
        <Grid
          container
          sx={{
            alignItems: "flex-start"
          }}
          size={{xs:12}}>
          <Grid
            size={{
              xs: 2,
              md: 1
            }}>
            <Typography variant="body2">
              <NumericFormat displayType="text" decimalScale={option.unitDecimalPlaces} fixedDecimalScale={true} thousandSeparator="." decimalSeparator="," value={option.quantity} suffix="&nbsp; " />
              {option.unit}
            </Typography>
          </Grid>
          <Grid
            size={{
              xs: 7,
              md: 9
            }}>
            <Typography variant="body2" component="div">
              {option.optionNo + " - " + option.description}
            </Typography>
            <Typography variant="body2" component="div" sx={{
              color: "text.secondary"
            }}>
              {nl2br(option.commercialDescription)}
            </Typography>
            <Typography variant="body2" component="div" sx={{
              color: "text.secondary"
            }}>
              <FormatQuote fontSize="small" />
              {option.additionalDescription}
            </Typography>
            {isClosed === true && (
              <Typography variant="body2" component="div" sx={{
                color: "error.main"
              }}>
                <ErrorOutline fontSize="small" style={{ float: "left" }} />
                {t("buyersGuide.standardOptions.closingDatePassedWarning")}
              </Typography>
            )}
          </Grid>
          <Grid
            container
            sx={{
              alignItems: "center",
              justifyContent: "flex-end"
            }}
            size={{
              xs: 3,
              md: 2
            }}>
            <Hidden only={"xs"}>
              <Grid>
                {hideEdit !== true && (
                  <IconButton edge="start" aria-label="edit" size="small" onClick={() => this.handleGoToOption(option)}>
                    <Edit color="primary" fontSize="small" />
                  </IconButton>
                )}
              </Grid>
            </Hidden>
            <Grid
              size={{
                xs: 12,
                sm: "grow",
                md: 6
              }}>
              <Typography variant="body2" component="div" align="right">
                {!option.salesPriceEstimated && !option.salesPriceToBeDetermined ? (
                  <NumericFormat prefix="&euro;&nbsp;" displayType="text" decimalScale={2} fixedDecimalScale={true} thousandSeparator="." decimalSeparator="," value={option.quantity * option.salesPriceInclVAT} />
                ) : (
                  option.salesPriceInclVAT_Text
                )}
              </Typography>
            </Grid>
            <Hidden only={["sm", "md", "lg", "xl"]}>
              <Grid>
                {hideEdit !== true && (
                  <IconButton edge="start" aria-label="edit" size="small" onClick={() => this.handleGoToOption(option)}>
                    <Edit color="primary" fontSize="small" />
                  </IconButton>
                )}
                &nbsp;&nbsp;
              </Grid>
            </Hidden>
            <Grid>
              <IconButton edge="end" aria-label="delete" size="small" disabled={user.viewOnly === true} onClick={() => this.handleDeleteOption(option)}>
                <DeleteOutline color="primary" fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>)
    );
  }

  render() {
    const { user, t, classes, theme } = this.props;
    const { selected, openLeftMenu, categories, options, individualOptions, showIndividualOptions, openSearchOptions, searchTerm, openSearchOptionsCategorized, searchTermCategorized } = this.state;

    const matches = themeBreakpointUp(window, theme, "md");

    const optionsBySelectedCategory = selected
      ? options.filter((x) => x.optionCategoryProjectId.toUpperCase() === selected.optionCategoryProjectId.toUpperCase() && x.optionHeaderProjectId.toUpperCase() === selected.optionHeaderProjectId.toUpperCase())
      : [];

    let filteredOptions = [];
    if (searchTerm && searchTerm.trim() !== "") {
      filteredOptions = this.getFilteredItems(options, searchTerm);
    }

    let filteredCategorizedOptions = [];
    if (searchTermCategorized && searchTermCategorized.trim() !== "") {
      filteredCategorizedOptions = this.getFilteredItems(optionsBySelectedCategory, searchTermCategorized);
    }

    let key = "";

    return (
      (<Container className={classes.mainContainer}>
        <Grid container className={classes.standardOptionsContainer}>
          <Slide direction="right" appear={!matches} in={openLeftMenu || matches} mountOnEnter unmountOnExit>
            <Grid
              container
              direction="column"
              className={this.isShoppingCartVisible() ? clsx(classes.slideRight, "shoppingCartVisible") : classes.slideRight}
              size={{
                xs: 12,
                md: 4
              }}>
              <AppBar position="static" color="inherit" className={classes.appBar}>
                <Toolbar variant="dense">
                  {!matches && selected && (
                    <IconButton aria-label="GoBack" edge="start" color="inherit" onClick={this.toggleOpenLeftMenu}>
                      <ArrowBack />
                    </IconButton>
                  )}
                  <Typography className={clsx(classes.grow, classes.bold)} noWrap>
                    {t("buyersGuide.options")}
                  </Typography>
                  <RequestIndividualOption selectedBuilding={this.props.selected} user={user} />
                  <Tooltip title={t("datatable.label.toolbar.search")}>
                    <IconButton aria-label="Zoek" edge="end" color="inherit" onClick={this.toggleOpenSearchOptions}>
                      <Search />
                    </IconButton>
                  </Tooltip>
                </Toolbar>
              </AppBar>
              <List className={classes.categoryList}>
                {(categories && categories.length > 0) || (individualOptions && individualOptions.length > 0) ? (
                  <React.Fragment>
                    {categories.map((category, index) => (
                      <React.Fragment key={index}>
                        {index !== 0 && <Divider component="li" />}
                        <ListItemButton
                          className={classes.categoryHeader}
                          onClick={() => this.handleToggleCategory(category.optionCategoryProjectId)}>
                          <ListItemText
                            primary={category.category}
                            primaryTypographyProps={{ noWrap: true }}
                            secondary={
                              category.closed === true
                                ? t("buyersGuide.standardOptions.closingDatePassed")
                                : category.closingDate
                                ? t("buyersGuide.closingDate") + ": " + formatDate(new Date(category.closingDate))
                                : t("buyersGuide.closingDate") + ": " + t("buyersGuide.standardOptions.toBeDeterminedShort")
                            }
                            secondaryTypographyProps={{ noWrap: true }}
                          />
                          {(category.expanded === undefined ? !category.closed : category.expanded) ? <ExpandLess color="action" /> : <ExpandMore color="action" />}
                         </ListItemButton>

                        <Collapse in={category.expanded === undefined ? !category.closed : category.expanded} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {category.headers.map((header, indexHeader) => (
                              <React.Fragment key={indexHeader}>
                                <Divider component="li" />
                                <ListItemButton
                                  title={header.header}
                                  className={classes.nested}
                                  selected={selected && selected.optionCategoryProjectId === category.optionCategoryProjectId && selected.optionHeaderProjectId === header.optionHeaderProjectId}
                                  onClick={() => this.selectHeader(category, header)}>
                                  <ListItemText primary={header.header} primaryTypographyProps={{ noWrap: true }} />
                                  <ChevronRight color="action" />
                                 </ListItemButton>
                              </React.Fragment>
                            ))}
                          </List>
                        </Collapse>
                      </React.Fragment>
                    ))}
                    {individualOptions && individualOptions.length > 0 && (
                      <React.Fragment>
                        <Divider component="li" />
                        <ListItemButton
                          className={classes.categoryHeaderIndividual}
                          selected={showIndividualOptions}
                          onClick={() => this.showIndividualOptions()}>
                          <ListItemText primary={t("buyersGuide.options.individualOptions")} primaryTypographyProps={{ noWrap: true }} />
                          <ChevronRight color="action" />
                         </ListItemButton>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                ) : (
                  <ListItem>
                    <ListItemText secondary={t("buyersGuide.standardOptions.noOptions")} />
                   </ListItem>
                )}
              </List>
            </Grid>
          </Slide>

          <Slide direction="right" in={openSearchOptions} mountOnEnter unmountOnExit>
            <Grid
              container
              direction="column"
              className={this.isShoppingCartVisible() ? clsx(classes.slideRight, "shoppingCartVisible") : classes.slideRight}
              size={{
                xs: 12,
                md: 4
              }}>
              <AppBar position="static">
                <Toolbar variant="dense">
                  <Input
                    color="inherit"
                    size="small"
                    className={classes.inputBoxSearch}
                    type="search"
                    value={searchTerm}
                    onChange={this.handleChangeSearch}
                    endAdornment={
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    }
                  />
                  <IconButton aria-label="Close" edge="end" color="inherit" onClick={this.clearAndCloseSearch}>
                    <Close />
                  </IconButton>
                </Toolbar>
              </AppBar>

              <List className={classes.searchList} ref="searchList">
                {filteredOptions && filteredOptions.length > 0 ? (
                  filteredOptions.map((option, indexOption) => (
                    <React.Fragment key={indexOption}>
                      <ListItemButton
                        className={classes.searchListItem}
                        onClick={() => this.handleGoToOption(option)}>
                        <ListItemText
                          primary={option.optionNo + " - " + option.description}
                          primaryTypographyProps={{
                            noWrap: true,
                            component: "p",
                          }}
                          secondary={nl2br(option.commercialDescription)}
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                        <ChevronRight color="action" />
                       </ListItemButton>
                      <Divider component="li" />
                    </React.Fragment>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText secondary={t("buyersGuide.standardOptions.noOptions")} />
                   </ListItem>
                )}
              </List>
            </Grid>
          </Slide>

          <Grid
            container
            direction="column"
            className={this.isShoppingCartVisible() ? clsx(classes.optionsContainer, "shoppingCartVisible") : classes.optionsContainer}
            sx={{
              justifyContent: "center"
            }}
            size={{
              xs: 12,
              md: 8
            }}>
            <AppBar position="static" color="inherit" className={classes.appBar}>
              <Toolbar variant="dense">
                {!matches && (
                  <IconButton aria-label="OpenMenu" edge="start" color="inherit" onClick={this.toggleOpenLeftMenu}>
                    <Menu />
                  </IconButton>
                )}
                {(selected || showIndividualOptions) && (
                  <React.Fragment>
                    <div className={clsx(classes.grow, classes.optionsTitle)}>
                      {selected && (
                        <React.Fragment>
                          <Typography className={classes.bold} noWrap>
                            {selected.category}
                          </Typography>
                          <Typography component="p" variant="caption" className={classes.grow} noWrap>
                            {selected.header}
                          </Typography>
                        </React.Fragment>
                      )}
                      {showIndividualOptions && (
                        <Typography className={classes.bold} noWrap>
                          {t("buyersGuide.options.individualOptions")}
                        </Typography>
                      )}
                    </div>
                    {
                      <Tooltip title={t("datatable.label.toolbar.search")}>
                        <IconButton aria-label="Zoek" edge="end" color="inherit" onClick={this.toggleOpenSearchOptionsCategorized}>
                          <Search />
                        </IconButton>
                      </Tooltip>
                    }
                  </React.Fragment>
                )}
              </Toolbar>
            </AppBar>
            <Grid container direction="column" className={classes.options} ref="optionsList">
              {selected ? (
                <Grid size={{xs: 12}}>
                  {optionsBySelectedCategory.map((option, indexOption) => (
                    <OptionCard
                      key={option.index}
                      option={option}
                      user={user}
                      selected={this.getSelectedOption(option.optionStandardId)}
                      closed={selected.closed === true || !selected.closingDate}
                      unit={option.unit}
                      decimalPlaces={option.unitDecimalPlaces}
                      onAddOption={(quantity, comment) => this.handleAddOption(option, quantity, comment)}
                    />
                  ))}
                </Grid>
              ) : showIndividualOptions ? (
                <Grid size={{xs: 12}}>
                  {individualOptions.map((option, indexOption) => (
                    <OptionCard
                      key={indexOption}
                      option={option}
                      user={user}
                      selected={option.subStatus === 20 ? option : null}
                      unit={option.unit}
                      decimalPlaces={option.unitDecimalPlaces}
                      onAddOption={(quantity, comment) => this.handleAddOption(option, option.quantity, comment)}
                    />
                  ))}
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={2}
                  sx={{
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    height:"100%"
                  }}
                  size={{xs:12}}>
                  <Grid>
                    <img src={webApiUrl + "api/Config/WebLogo"} width="300" alt="JPDS" />
                    <Typography variant="h4" align="center" sx={{
                      color: "text.secondary"
                    }}>
                      {t("buyersGuide.standardOptions.chooseOptions")}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
            {
              //<AppBar position="static">
              //    <Toolbar variant="dense">
              //        <div className={classes.grow}></div>
              //        <Typography className={classes.bold} align="right">{t('buyersGuide.options.totalAmount')}: {this.getTotalText()}</Typography>
              //    </Toolbar>
              //</AppBar>
            }
          </Grid>

          <Slide direction="left" in={openSearchOptionsCategorized} mountOnEnter unmountOnExit>
            <Grid
              container
              direction="column"
              className={this.isShoppingCartVisible() ? clsx(classes.slideLeft, "shoppingCartVisible") : classes.slideLeft}
              size={{
                xs: 12,
                md: 4
              }}>
              <AppBar position="static">
                <Toolbar variant="dense">
                  <Input
                    color="inherit"
                    size="small"
                    className={classes.inputBoxSearch}
                    type="search"
                    value={searchTermCategorized}
                    onChange={this.handleChangeCategorizedSearch}
                    endAdornment={
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    }
                  />
                  <IconButton aria-label="Close" edge="end" color="inherit" onClick={this.clearAndCloseCategorizedSearch}>
                    <Close />
                  </IconButton>
                </Toolbar>
              </AppBar>

              <List className={classes.searchList} ref="searchList">
                {filteredCategorizedOptions && filteredCategorizedOptions.length > 0 ? (
                  filteredCategorizedOptions.map((option, indexOption) => (
                    <React.Fragment key={indexOption}>
                      <ListItemButton
                        className={classes.searchListItem}
                        onClick={() => this.handleGoToOption(option)}>
                        <ListItemText
                          primary={option.optionNo + " - " + option.description}
                          primaryTypographyProps={{
                            noWrap: true,
                            component: "p",
                          }}
                          secondary={nl2br(option.commercialDescription)}
                          secondaryTypographyProps={{ noWrap: true }}
                        />
                       </ListItemButton>
                      <Divider component="li" />
                    </React.Fragment>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText secondary={t("buyersGuide.standardOptions.noOptions")} />
                   </ListItem>
                )}
              </List>
            </Grid>
          </Slide>

          {this.isShoppingCartVisible() && (
            <Grid
              className={this.state.bottomCartExpanded === true ? clsx(classes.bottomShoppingCart, "expanded") : classes.bottomShoppingCart}
              size={{xs:12}}>
              <Accordion className={classes.expansionPanel} onChange={this.handleBottomCartExpandToggle} expanded={this.state.bottomCartExpanded === true}>
                <AccordionSummary expandIcon={<ArrowDropDown />} aria-controls="panel3a-content" id="panel3a-header" className={classes.expansionPanelHeading}>
                  <Box sx={{
                    display: { xs: "none", sm: "initial" }
                  }}>
                    <Typography className={classes.bold}>{t("buyersGuide.standardOptions.optionsToBeRequested")}</Typography>
                  </Box>
                  <Typography className={clsx(classes.headerColumn, classes.bold)} align="right">
                    {t("buyersGuide.options.totalAmount")}: {this.getTotalText()}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.expansionPanelContainer}>
                  <br />
                  <div className={classes.fullWidth}>
                    <Grid container direction="row" sx={{
                      justifyContent: "flex-start"
                    }}>
                      <Grid container spacing={2} size={{xs:12}}>
                        {this.state.categories.map((category, indexCat) =>
                          category.headers.map((header, indexHeader) =>
                            this.getSelectedOptionsByHeading(header.optionHeaderProjectId, category.optionCategoryProjectId).map((option, indexOption) => (
                              <React.Fragment key={indexOption}>{this.renderOptionInBasket(option, indexOption === 0 ? category.category + " - " + header.header : "", category.closed)}</React.Fragment>
                            ))
                          )
                        )}
                        {this.getSelectedOptionsForUnmatchedHeader()
                          .map((p) => ({
                            ...p,
                            key: (!!p.category ? p.category + " - " : "") + (!!p.header ? p.header : ""),
                          }))
                          .sort((x) => x.key)
                          .map((option, indexOption) => {
                            let showHeader = false;
                            if (key != option.key) {
                              showHeader = true;
                              key = option.key;
                            }
                            return <React.Fragment key={indexOption}>{this.renderOptionInBasket(option, showHeader ? key : "", option.closed, true)}</React.Fragment>;
                          })}
                        {this.getSelectedIndividualOptions().map((option, indexOption) => (
                          <React.Fragment key={indexOption}>{this.renderOptionInBasket(option, indexOption === 0 ? t("buyersGuide.options.individualOptions") : "", option.closed)}</React.Fragment>
                        ))}
                        <Grid size={{xs: 12}}>
                          <Divider />
                        </Grid>
                        <Grid container size={{xs:12}}>
                          <Grid size="grow">
                            <Typography variant="body2" className={classes.bold}>
                              {t("general.total")}
                            </Typography>
                            <br />
                          </Grid>
                          <Grid>
                            <Typography variant="body2" className={classes.bold}>
                              {this.getTotalText()}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container size={{xs:12}}>
                        <Grid size={{xs: 12}}>
                          <Typography variant="body2" component="div" sx={{
                            color: "text.secondary"
                          }}>
                            {t("buyersGuide.standardOptions.confirmOptionsInfo")}
                          </Typography>
                          <Typography variant="body2" sx={{
                            color: "text.secondary"
                          }}>
                            {t("buyersGuide.standardOptions.buyersGuideAssessmentProcessInfo")}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "text.secondary",
                              marginBottom: "16px"
                            }}>
                            {t("buyersGuide.standardOptions.requestingIndividualOptionInfo")}
                          </Typography>
                        </Grid>
                        <Grid size={{xs: 12}}>
                          <Button
                            onClick={this.handleClickRequest}
                            disabled={user.viewOnly === true}
                            variant="outlined"
                            color="primary"
                            size="large"
                            fullWidth
                            style={{
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            {t("general.request")}
                          </Button>
                        </Grid>
                        <Grid size={{xs: 12}}>
                          &nbsp;
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
        </Grid>
      </Container>)
    );
  }
}

function mapStateToProps(state) {
  const { authentication, buildings } = state;
  const { user } = authentication;
  const { selected } = buildings;
  return {
    user,
    selected,
    buildings,
  };
}

const connectedPage = connect(mapStateToProps)(withTheme((withTranslation()(withStyles(styles)(Page)))))
export { connectedPage as StandardOptionsPage };
