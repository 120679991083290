export const normalizePhoneNumber = (value) => {
  // return nothing if no value
  if (!value) {
    return '';
  }

 
  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;
 console.log({currentValue , cvLength})
  // returns: "x", "xx", "xxx"
  if (cvLength < 4) {
    return currentValue;
  }
 
  // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
  if (cvLength < 7) {
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  }

  if(cvLength < 9){
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3,6)}  ${currentValue.slice(6,8)}`;
  }
 
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)} ${currentValue.slice(6, 8)} ${currentValue.slice(8, 10)}`;
};
 
export const deNormalizePhoneNumber = (value) => {
  // return nothing if no value
  if (!value) {
    return '';
  }
 
  // only allows 0-9 inputs and replace all the other things with blank string
  const currentValue = value?.toString().replace(/[^\d]/g, '');
  return currentValue;
};
 

export const isValidEmail = (email)=>{
  return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email); 
}

export const isValidWebsite = (website)=>{
   // Regular expression for website validation
   const websiteRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
   return websiteRegex.test(website)
}