import { Checkbox, Stack, Typography, styled } from "@mui/material"
const StyledTypography = styled(Typography)({
  color: "#666666"
});


const options = ['Ja', 'Nee'];
const Proxy = ({ proxyCheckBoxValue, setProxyCheckBoxValue , disabled}) => {

  const handleCheckboxChange = (checkboxValue) => {
    setProxyCheckBoxValue(checkboxValue);
  }

  console.log("whatDidIPick", proxyCheckBoxValue || null);
  // console.log({ proxyCheckBoxValue })

  return (
    (<Stack sx={{
      rowGap: 1
    }}>
      <StyledTypography variant='subtitle1'>Volmacht</StyledTypography>
      <StyledTypography variant='subtitle1'>Deze optie is alleen mogelijk als u zonder hypotheek gaat aankopen
        Wenst u een volmacht te ondertekenen zodat u niet aanwezig hoeft te zijn bij het passeren van de akte van levering?
      </StyledTypography>
      {/* <StyledTypography variant='subtitle1'><Checkbox size="small" sx={{ p: 0, m: 0, pr: 1 }} />Ja</StyledTypography>
      <StyledTypography variant='subtitle1'><Checkbox size="small" sx={{ p: 0, m: 0, pr: 1 }} />Nee</StyledTypography> */}
      {options.map((singleOption, index) => {
        return (
          <StyledTypography variant='subtitle1'><Checkbox size="small" disabled={disabled} sx={{ p: 0, m: 0, pr: 1 }} checked={singleOption === proxyCheckBoxValue} onChange={() => handleCheckboxChange(singleOption)} />{singleOption}</StyledTypography>
        )
      })}
    </Stack>)
  );
}

export default Proxy