import {
  AppBar,
  Button,
  CircularProgress,
  Grid2 as Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowBack, GetApp, Lock, Warning } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Route, Switch, useHistory } from "react-router-dom";
import SurveysLists from "../components/SurveysLists";
import { useDispatch, useSelector } from "react-redux";
import PreviewAndSign from "../components/PreviewAndSign";
import { isReadOnly, SurveyType } from "../../../_helpers/enumsHelper";
import { surveyActions } from "../../../_actions";
import DeliveriesDetail from "./components/DeliveriesDetail";
import PdfPreview from "../components/PdfPreview";
import { useState } from "react";
import RepairRequestForm from "../RepairRequestForm";
import { alpha } from "@mui/material/styles"
import { apps, surveyConstants } from "../../../_constants";
import { useRedirectionPath } from "../../../_helpers/redirectHelper";
import { dossiersActions } from "../../../_actions/dossiers.actions";
import { useCanLockedInspection, isCompletedSurvey } from "../../../_helpers";

export default function Deliveries(props) {
  const app = useSelector((state) => state.app);
  const classes = useStyles({ app });
  const history = useHistory();
  const { t } = useTranslation();
  const [signatureModalOpen, setSignatureModalOpen] = useState(false);

  //Redux State
  const { drawingFiles } = useSelector((state) => state.dossier);
  const { availableSurveyModules } = useSelector(
    (state) => state.availableSurveyModules
  );
  const { user } = useSelector((state) => state.authentication);
  const {
    inspections = [],
    selectedInspection,
    isSurveyUpdating,
    assignmentLockIds,
    repairRequests,
    loadingRepairRequests,
  } = useSelector((state) => state.surveying);

  const [inspectionId, setInspectionId] = useState(null);
  const [openRepairRequestMap, setOpenRepairRequestMap] = useState(false);
  const [offlineRepairRequestData, setOfflineRepairRequestData] =
    useState(null);
  const [selectedDrawingFiles, setSelectedDrawingFiles] = useState({
    pages: 1,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pdfPreview, setPdfPreview] = useState(false);
  const [deliveryDetail, setDeliveryDetail] = useState(false);
  const [signModal, setSignModal] = useState(false);
  const [isOpenSignature, setIsOpenSignature] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchWidthOnlySm = useMediaQuery(theme.breakpoints.only("sm"));
  const [canGoBack, setCanGoBack] = useState(null);
  const [isUpdatingStatus, setUpdatingStatus] = useState(false);
  const redirectPatch = useRedirectionPath(SurveyType.Delivery);
  const canLockedInspection = useCanLockedInspection();

  useEffect(() => {
    if (redirectPatch) history.push(redirectPatch);
  }, [availableSurveyModules, user]);

  useEffect(() => {
    return () => {
      if (!history.location.pathname.includes("opleveringen")) {
        dispatch(
          surveyActions.success({
            type: surveyConstants.GET_INSPECTIONS_SUCCESS,
            inspections: [],
          })
        );
      }
    };
  }, []);

  useEffect(() => {
    if (inspectionId && !selectedInspection) {
      dispatch(surveyActions.getSurveyDetailAction(inspectionId, false));
      dispatch(surveyActions.getRepairRequests(inspectionId));
    }
  }, [inspectionId]);

  useEffect(() => {
    setSignModal(false);
  }, [!matchWidthOnlySm]);

  useEffect(() => {
    if (drawingFiles && drawingFiles.length) {
      setSelectedDrawingFiles({
        ...drawingFiles[0],
        groupBy: drawingFiles[0].isInternal ? "Internal" : "External",
      });
    } else {
      setSelectedDrawingFiles({});
    }
  }, [drawingFiles]);

  useEffect(() => {
    if (selectedInspection) {
      dispatch(
        dossiersActions.getDrawingFiles(
          selectedInspection.projectId,
          selectedInspection.buildingId
        )
      );
    }
  }, [selectedInspection]);

  const handleGoBack = () => {
    history.goBack();
  };

  const previewHandler = () => {
    history.push(`${props.match.url}/${inspectionId}/preview`);
  };

  const signatureHandler = () => {
    setSignatureModalOpen(!signatureModalOpen);
  };
  const signHandler = () => {
    setSignModal(true);
    history.push(`${props.match.url}/${inspectionId}/preview`);
  };

  const signModalBack = () => {
    setSignModal(false);
  };

  const startDelivery = async () => {
    setUpdatingStatus(true);
    await dispatch(
      surveyActions.updateSurvey(
        {
          ...selectedInspection,
          status: 1,
          surveyId: selectedInspection.surveyId,
        },
        selectedInspection.surveyType
      )
    );
    setUpdatingStatus(false);
  };

  const canShowBackButton =
    props &&
    props.match &&
    props.location &&
    props.match.url !== props.location.pathname;

  const survey =
    inspections.find(
      (a) => a.surveyId === (selectedInspection && selectedInspection.surveyId)
    ) || {};
  const { isCracked } = survey;

  const lockInfo =
    assignmentLockIds &&
    assignmentLockIds.find(
      (a) =>
        a.lockId === (selectedInspection && selectedInspection.lockId) &&
        !a.isSecondSignature
    );
  const isDownloadedSurvey =
    selectedInspection &&
    lockInfo &&
    lockInfo.userId === user.id &&
    selectedInspection.lockId;

  return (
    (<div className={classes.appbar}>
      <Grid
        className={clsx(
          classes.blocksContainer,
          selectedInspection && selectedInspection.surveyId
        )}
        size={{xs:12}}>
        {inspectionId && selectedInspection && selectedInspection.surveyId && (
          <AppBar
            position="static"
            color="transparent"
            className={classes.surveyMomentsMainHeaderSecond}
          >
            <Toolbar
              variant="dense"
              className={classes.surveyMomentsHeaderSecond}
            >
              {
                <React.Fragment>
                  {canShowBackButton && (
                    <Grid>
                      <IconButton
                        edge="start"
                        aria-label="GoBack"
                        color="inherit"
                        onClick={signModal ? signModalBack : handleGoBack}
                        size="large"
                      >
                        <ArrowBack className={classes.arrowBlackIcon} />
                      </IconButton>
                    </Grid>
                  )}
                  <div
                    className={clsx(classes.grow, classes.bold)}
                    style={{ display: "flex" }}
                    noWrap
                  >
                    <div className={classes.appHeader}>
                      <Typography className={classes.appBarText}>
                        {!signModal ? (
                          <>
                            {` ${t("survey.deliveries")}: ${
                              selectedInspection.buildingNoIntern
                            }`}
                          </>
                        ) : (
                          t("general.signature")
                        )}
                      </Typography>
                      {!isCompletedSurvey(selectedInspection.status) &&
                        (lockInfo ||
                          isCracked ||
                          selectedInspection.lockId) && (
                          <div className={classes.detailIconContainer}>
                            {isDownloadedSurvey && (
                              <Tooltip
                                title={t(`general.downloaded`, {
                                  moduleType: t("survey.delivery"),
                                })}
                              >
                                <GetApp
                                  color="primary"
                                  className={classes.icon}
                                />
                              </Tooltip>
                            )}

                            {isCracked && (
                              <Tooltip
                                title={t(`general.locked`, {
                                  moduleType: t("survey.delivery"),
                                })}
                              >
                                <Warning
                                  color="primary"
                                  className={classes.icon}
                                />
                              </Tooltip>
                            )}

                            {!lockInfo && selectedInspection.lockId && (
                              <Tooltip
                                title={t(`general.lockBroken`, {
                                  moduleType: t("survey.delivery"),
                                })}
                              >
                                <Lock
                                  color="primary"
                                  className={classes.icon}
                                />
                              </Tooltip>
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                  {inspectionId && selectedInspection && (
                    <div>
                      {selectedInspection.status === 0 && (
                        <Button
                          className={classes.signButton}
                          variant="contained"
                          disabled={
                            canLockedInspection || !selectedInspection.hasRights
                          }
                          color="primary"
                          onClick={startDelivery}
                        >
                          {isSurveyUpdating && isUpdatingStatus ? (
                            <CircularProgress
                              className={classes.loading}
                              size={20}
                            />
                          ) : (
                            t("survey.button.start")
                          )}
                        </Button>
                      )}
                    </div>
                  )}
                  <div>
                    {deliveryDetail && selectedInspection && (
                      <>
                        {!isReadOnly(selectedInspection.status) && (
                          <Button
                            disabled={
                              canLockedInspection ||
                              loadingRepairRequests ||
                              !selectedInspection.hasRights
                            }
                            className={classes.signButton}
                            variant="contained"
                            color="primary"
                            onClick={previewHandler}
                          >
                            {t("survey.button.previewAndSign")}
                          </Button>
                        )}
                      </>
                    )}
                    {!signModal &&
                      pdfPreview &&
                      selectedInspection &&
                      selectedInspection.status !== 2 &&
                      selectedInspection.status !== 3 && (
                        <Button
                          disabled={
                            canLockedInspection || !selectedInspection.hasRights
                          }
                          className={classes.signButton}
                          variant="contained"
                          color="primary"
                          onClick={
                            matchWidthOnlySm ? signHandler : signatureHandler
                          }
                        >
                          {t("general.sign")}
                        </Button>
                      )}
                  </div>
                </React.Fragment>
              }
            </Toolbar>
          </AppBar>
        )}
        <div
          className={clsx(
            classes.scrollContainer,
            selectedInspection && classes.calculateHeight
          )}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <Switch>
              <Route
                exact
                path={`${props.match.path}`}
                render={(rest) => (
                  <SurveysLists
                    {...rest}
                    showTimeColumns
                    moduleType={SurveyType.Delivery}
                    title={t("survey.deliveries")}
                    parentUrl={props.match.url}
                    data={(inspections || []).filter(
                      (a) => a.surveyType === SurveyType.Delivery
                    )}
                    gridDataTitle={t("survey.deliveries")}
                    canShowPdfColumn
                    pathKey={"opleveringen"}
                    moduleTitle={t("survey.delivery")}
                  />
                )}
              />
              <Route
                exact
                path={`${props.match.path}/:inspectionId`}
                render={(rest) => (
                  <DeliveriesDetail
                    canGoBack={canGoBack}
                    isDownloadedSurvey={isDownloadedSurvey}
                    openRepairRequestMap={openRepairRequestMap}
                    setOpenRepairRequestMap={(data) => {
                      setOpenRepairRequestMap(true);
                      data && setOfflineRepairRequestData(data);
                    }}
                    setDeliveryDetail={(data) => setDeliveryDetail(data)}
                    setIsOpenSignature={setIsOpenSignature}
                    setId={(id) => setInspectionId(id)}
                    {...rest}
                  />
                )}
              />
              <Route
                exact
                path={`${props.match.path}/:inspectionId/preview`}
                render={(rest) => (
                  <PdfPreview
                    signatureModal={signatureModalOpen}
                    offlinePDFOnly={isDownloadedSurvey}
                    isDownloadedSurvey={isDownloadedSurvey}
                    id={inspectionId}
                    setPdfPreview={(data) => setPdfPreview(data)}
                    close={() => signatureHandler()}
                    setId={(id) => setInspectionId(id)}
                    setIsOpenSignature={setIsOpenSignature}
                    signModal={signModal}
                    {...rest}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </Grid>
      <RepairRequestForm
        repairRequests={repairRequests}
        isUpdate={!!offlineRepairRequestData}
        values={offlineRepairRequestData}
        moduleType={SurveyType.Delivery}
        setSelectedDrawingFiles={setSelectedDrawingFiles}
        setCurrentPage={setCurrentPage}
        surveyId={selectedInspection && selectedInspection.surveyId}
        buildingId={selectedInspection && selectedInspection.buildingId}
        projectId={selectedInspection && selectedInspection.projectId}
        selectedDrawingFiles={selectedDrawingFiles}
        currentPage={currentPage}
        redirectToMomentDetail={console.log}
        onPreview={console.log}
        setID={(id) => setInspectionId(id)}
        openRepairRequestMap={openRepairRequestMap}
        onClose={(reload) => {
          reload && setCanGoBack(new Date().getTime());
          offlineRepairRequestData && setOfflineRepairRequestData(null);
          setOpenRepairRequestMap(false);
          setCurrentPage(1);
        }}
        drawingLocation={
          offlineRepairRequestData && {
            xrel: +offlineRepairRequestData["DrawingPinLocationModel.Xrel"],
            yrel: +offlineRepairRequestData["DrawingPinLocationModel.Yrel"],
            dossierFileId:
              offlineRepairRequestData["DrawingPinLocationModel.DossierFileId"],
          }
        }
      />
      <PreviewAndSign
        {...props}
        closeModalHandler={() => setIsOpenSignature(false)}
        openEmailModal={isOpenSignature}
        setIsOpenSignature={setIsOpenSignature}
      />
    </div>)
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  surveyMomentsMainHeaderSecond: {
    boxShadow: "none",
  },
  surveyMomentsHeaderSecond: {
    padding: theme.spacing(0, 2),
  },
  docRadius: {
    "& > .MuiDialog-container > div:nth-child(1)": {
      borderRadius: 10,
    },
  },
  loading: {
    color: theme.palette.common.white,
  },
  autoTrim: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  autoCompleteInput: {
    marginTop: 0,
    marginBottom: 0,
    "& > .MuiAutocomplete-inputRoot": {
      background: theme.palette.common.white,
      "& > input": {
        fontSize: 12,
        maxWidth: 75,
      },
    },
  },
  autoComplete: {
    width: 137,
  },
  zoomButton: {
    borderRadius: 5,
    overflow: "hidden",
    padding: theme.spacing(0.5),
    margin: `0 5px`,
    height: "28px",
    width: "28px",
    "&:focus": {
      outline: "none !important",
    },
    backgroundColor: alpha(theme.palette.common.white, 0.2),
    "& .MuiSvgIcon-root": {
      fontSize: 18,
    },
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
  },
  appBarContain: {
    display: "flex",
    alignItems: "center",
    columnGap: "5px",
    [theme.breakpoints.down("sm")]: {
      columnGap: "0px",
    },
  },
  headerNextPrevIconStyle: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.6),
    },
    "&:focus": {
      outline: "none !important",
    },
  },
  appHeader: {
    display: "flex",
    // maxWidth: '50%',
    overflow: "hidden",
  },
  bold: {
    fontWeight: "bold",
  },
  grow: {
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
  },
  scrollContainer: {
    height: "100%",
    // height: 'calc(100% - 48px)',
    // overflow: 'auto'
  },
  blocksContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "static",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    "&::before": {
      content: '""',
      [theme.breakpoints.down("md")]: {
        background: theme.palette.common.white,
      },
      backgroundImage: ({ app }) =>
        app === apps.aftercare ? null :'url("/Content/Background/House-900x604.svg")',
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundPosition: "center bottom",
      height: "100%",
      width: "100%",
      overflowX: "hidden",
      overflowY: "hidden",
      opacity: 0.2,
      zIndex: "-1",
      position: "fixed",
    },
  },
  calculateHeight: {
    height: "calc(100% - 48px) !important",
  },
  appbar: {
    height: "100%",
    overflow: "auto",
  },

  signButton: {
    fontSize: 12,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
      padding: theme.spacing(0.2, 1.9),
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
      padding: theme.spacing(0.2, 1.9),
    },
    fontWeight: "normal",
    marginLeft: 8,
    whiteSpace: "nowrap",
  },
  arrowBlackIcon: {
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      fontSize: 18,
    },
  },
  appBarText: {
    color: theme.palette.common.black,
    fontSize: 16,
    marginLeft: 10,
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  detailIconContainer: {
    marginLeft: 5,
    [theme.breakpoints.down("sm")]: {
      "& svg": {
        fontSize: 15,
      },
    },
  },
}));
