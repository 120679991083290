import * as React from 'react';
import {
  DocumentEditorContainerComponent, Toolbar
} from '@syncfusion/ej2-react-documenteditor';
import {  Box, Button, CircularProgress, Hidden, IconButton,  ListItemIcon,  Menu,  MenuItem,  Stack, Switch, Tooltip, Typography } from '@mui/material';
import { ArrowBack, AssignmentTurnedIn, BorderColor, Clear, Menu as MenuIcon,RemoveRedEye, Save,} from '@mui/icons-material';
import { useEffect,useState } from 'react';
import { useParams } from 'react-router';
import { getContractDraftAndUpdateStatus, sendForDigitalSigning, updateContractDraft } from '../../../apis/contractApis';
import { ContractStatusKeys, ModeOfSigningKeys } from '../../../_helpers';
import DocumentViewer from './DocumentViewer';
import ConfirmBox from '../../../components/ConfirmBox';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import { useSelector } from 'react-redux';
import { sleep } from '../../../_helpers/sleep';




DocumentEditorContainerComponent.Inject(Toolbar);
export function ViewContract({contractDetails, setIsPreviewEnabled, setToast, fetchContractDetails}) {
  const {all:allBuildings } = useSelector(state => state.buildings)

  const { contractId } = useParams();
  const [isEditModeOn, setIsEditModeOn] = React.useState(false)
  const [isOpenConfirmBox, setisOpenConfirmBox] = useState({
    open:false,
    for:undefined
  })
  const [isSigningLoading, setIsSigningLoading] = useState(false)
  const [isDocumentLoaded, setIsDocumentLoaded] = useState(false)
  const [isMenuOpenInMobile, setIsMenuOpenInMobile] = useState(null);

  const { t } = useTranslation()

  const handleMobileMenuOpen = (event) => {
    setIsMenuOpenInMobile(event.currentTarget);
  }

  const handleMobileMenuClose = () => {
    setIsMenuOpenInMobile(null);
  }

const buildingInfo = React.useMemo(()=>  allBuildings.find(b =>b.buildingId?.toLowerCase() === contractDetails?.buildingId?.toLowerCase()), [contractDetails?.buildingId , allBuildings])

  const [contractSfdtObject, setContractSfdtObject] = React.useState({loading:false, sfdt:null})

  let container;


  const fetchLatestSfdt = async()=>{
    try{
      setContractSfdtObject({loading:true , sfdt:undefined})
      const response = await getContractDraftAndUpdateStatus(contractId)
     
      setContractSfdtObject({loading:false , sfdt:response?.data?.content})
    }catch(e){
      console.log(e)
      setContractSfdtObject({loading:false , sfdt:undefined})
    }
  }

  const saveContract = async(isEditMode = false)=>{
    try{
      let sfdt = { content: container?.documentEditor?.serialize() };
      await updateContractDraft({content: `${sfdt?.content}`}, contractId)
      await fetchLatestSfdt()

      setIsEditModeOn(isEditMode)
      setToast({open:true, message:t("contract.details.viewContract.saveSuccess"), type:"success"})
    }catch(e){
      console.log(e)
    }
    
  }


  useEffect(() => {
    fetchLatestSfdt()
  }, [])

  useEffect(() => {
    if(contractSfdtObject?.sfdt){
      setIsDocumentLoaded(false)
      container?.documentEditor?.open(contractSfdtObject?.sfdt)
      setIsDocumentLoaded(true)
    }
  }, [contractSfdtObject?.sfdt , isEditModeOn, isDocumentLoaded])

  useEffect(() => {
    if([ContractStatusKeys.New,ContractStatusKeys.InProgress].includes(contractDetails?.status)){
      setIsEditModeOn(true)
    }else{
      setIsEditModeOn(false)
    }
  }, [contractDetails?.status])
  
  if(contractSfdtObject?.loading){
    return (
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: "100%",
          width: "100%"
        }}>  <CircularProgress /></Stack>
    );
  }


  const handleOk = ()=>{
    if(isOpenConfirmBox.for === "pdf"){

      saveContract()
      setisOpenConfirmBox({open:false , for:undefined})
    }else if(isOpenConfirmBox.for === "cancel"){
      saveContract(true)
      setisOpenConfirmBox({open:false , for:undefined});
            setIsPreviewEnabled(false)
    }
  }
  const handleCancel = ()=>{
    if(isOpenConfirmBox.for === "pdf"){
      setisOpenConfirmBox({open:false , for:undefined});
      setIsEditModeOn(true)
    container?.documentEditor?.open(contractSfdtObject?.sfdt)
  }else if(isOpenConfirmBox.for === "cancel"){

      setisOpenConfirmBox({open:false , for:undefined});
       setIsPreviewEnabled(false)
    }
  }

  const handleSigning = async ()=>{
    try{
      if([ContractStatusKeys.New,ContractStatusKeys.InProgress].includes(contractDetails?.status) && [ModeOfSigningKeys.DigitalSign,ModeOfSigningKeys.DigitalSignWithIdin].includes(contractDetails?.signatureType)){
        setIsSigningLoading(true)
        await sendForDigitalSigning(contractId)
        await sleep(500)
        await fetchContractDetails()
        setIsPreviewEnabled(false)
      }

    }catch(e){
      console.log(e)
    }finally{
      setIsSigningLoading(false)
    }
  }

  return (
    (<div style={{height:"100%"}}>
      <Stack
        direction='row'
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 1,
          px: 2
        }}>
        <Typography><Menu sx={{mr:1}}/>{buildingInfo?.projectName} {buildingInfo?.buildingNoIntern}</Typography>

     {/* display menu for mobile screen -  start */}
     <Hidden mdUp>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={handleMobileMenuOpen} sx={{ p: 0 }}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={isMenuOpenInMobile}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(isMenuOpenInMobile)}
              onClose={handleMobileMenuClose}
              onClick={handleMobileMenuClose}
            >
                {[ModeOfSigningKeys.DigitalSign, ModeOfSigningKeys.DigitalSignWithIdin].includes(contractDetails?.signatureType) && [0, 1].includes(contractDetails?.status) && <Box>
                <MenuItem divider onClick={saveContract} disabled={!isEditModeOn}>
                  <ListItemIcon><Save fontSize="small" /></ListItemIcon>
                  <Typography>{t("general.save")}</Typography>
                </MenuItem>
                {
                  isSigningLoading ? <Loader /> : <MenuItem divider disabled={isEditModeOn} onClick={handleSigning}>
                    <ListItemIcon><AssignmentTurnedIn fontSize="small" /></ListItemIcon>
                    <Typography>{t("contract.details.viewContract.sendForSigning.button")}</Typography>
                  </MenuItem>
                }
              </Box>}
         
             {!(isSigningLoading || ![ContractStatusKeys.New,ContractStatusKeys.InProgress].includes(contractDetails?.status)) && <MenuItem divider onClick={(event) => {
                  if ([ModeOfSigningKeys.DigitalSign, ModeOfSigningKeys.DigitalSignWithIdin].includes(contractDetails?.signatureType) && isEditModeOn && container?.documentEditor?.serialize() !== contractSfdtObject.sfdt) {
                    setisOpenConfirmBox({ open: true, for: "pdf" })
                  } else {
                    setIsEditModeOn(!isEditModeOn)
                  }
                }}>
                <ListItemIcon> {isEditModeOn ? <RemoveRedEye/>:<BorderColor /> } </ListItemIcon>
                <Typography>{isEditModeOn ? t("contract.details.viewContract.view") : t("contract.details.viewContract.edit")}</Typography>
              </MenuItem>}
              
              <MenuItem divider onClick={() => {
                 if(isEditModeOn && [ModeOfSigningKeys.DigitalSign,ModeOfSigningKeys.DigitalSignWithIdin].includes(contractDetails?.signatureType) && [ContractStatusKeys.New,ContractStatusKeys.InProgress].includes(contractDetails?.status) && container?.documentEditor?.serialize() !== contractSfdtObject.sfdt){
                  setisOpenConfirmBox({ open: true, for: "cancel" })
                } else {
                  setIsPreviewEnabled(false)
                }
              }}>
                <ListItemIcon><ArrowBack fontSize="small" /></ListItemIcon>
                <Typography>{t("general.back")}</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Hidden>
        {/* display menu for mobile screen-  end */}

        {/* display buttons instead of menu for mobile screen-  start */}
        <Hidden mdDown>
        <Stack
          direction='row'
          sx={{
            justifyContent: "center",
            alignItems: "center"
          }}>
          {[ModeOfSigningKeys.DigitalSign, ModeOfSigningKeys.DigitalSignWithIdin].includes(contractDetails?.signatureType) && [0, 1].includes(contractDetails?.status) && <Box>
            <Button variant='outlined' size='small' onClick={saveContract} disabled={!isEditModeOn}>{t("general.save")}</Button>
            <LoadingButton sx={{ ml: 2 }} loading={isSigningLoading} variant='contained' size='small' disabled={isEditModeOn} onClick={handleSigning}>{t("contract.details.viewContract.sendForSigning.button")}</LoadingButton>
          </Box>}
        {!(isSigningLoading || ![ContractStatusKeys.New,ContractStatusKeys.InProgress].includes(contractDetails?.status)) && <><Tooltip title={isEditModeOn ? t("contract.details.viewContract.editModeOn.title") : t("contract.details.viewContract.editModeOff.title")} arrow><Switch checked={isEditModeOn} onChange={(event)=>{
          if([ModeOfSigningKeys.DigitalSign,ModeOfSigningKeys.DigitalSignWithIdin].includes(contractDetails?.signatureType) && !event.target.checked &&  container?.documentEditor?.serialize() !== contractSfdtObject.sfdt){
            setisOpenConfirmBox({open:true, for:"pdf"})
          }else{
            setIsEditModeOn(event.target.checked)
          }
          }}/></Tooltip></>}
     
     <Tooltip title={t("general.close")} arrow>
        <IconButton disabled={isSigningLoading} onClick={()=>{
            if(isEditModeOn && [ModeOfSigningKeys.DigitalSign,ModeOfSigningKeys.DigitalSignWithIdin].includes(contractDetails?.signatureType) && [ContractStatusKeys.New,ContractStatusKeys.InProgress].includes(contractDetails?.status) && container?.documentEditor?.serialize() !== contractSfdtObject.sfdt){
              setisOpenConfirmBox({open:true, for:"cancel"})
            }else{
              setIsPreviewEnabled(false)
            }
          
          }}><Clear/></IconButton>
      </Tooltip>
        </Stack>
        </Hidden>
        {/* display buttons instead of menu for mobile screen-  end */}
      </Stack>
      {isEditModeOn ? isDocumentLoaded  ? <DocumentEditorContainerComponent id="container" ref={(scope) => { container = scope; }}
          serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
          enableToolbar={true}
          height="calc(100% - 56px)"
        />: <Loader/> :  false }
      {!isEditModeOn && contractDetails?.templateId &&  <DocumentViewer contractId={contractId}/> }
      <ConfirmBox open={isOpenConfirmBox.open} title={t("general.warning")} description={t("contract.details.viewContract.saveConfirmation")} okText={t("general.save")} cancelText={t("general.discard")} onOk={handleOk} 
      onCancel={handleCancel} />
    </div>)
  );
}

