import React from "react";
import { connect } from "react-redux";
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { userAccountTypeConstants, appsInfo, apps } from "../../_constants";
import { Card, CardActionArea, CardMedia, CardContent, Typography,  Grid2 as Grid, CssBaseline, Fab, Divider, Box, Tooltip } from "@mui/material";
import { commonActions, surveyActions } from "../../_actions";
import { history, getCommonArray, formatDate } from "../../_helpers";
import { ExitToApp, PriorityHigh } from "@mui/icons-material";

const styles = theme => ({
    wrapper: {
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center"
    },
    container: {
        backgroundColor: "rgba(255,255,255,0.9)",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(3),
        width: 750,
        maxWidth: "100%",
        [theme.breakpoints.down('md')]: {
            width: "100%"
        },
    },
    logoContainer: {
        padding: theme.spacing(1),
        margin: theme.spacing(0, 0, 2),
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        '& > img': {
            width: '100%',
            maxWidth: 500,
            maxHeight: 150,
            minWidth: 100,
            minHeight: 60
        }
    },
    grow: {
        flexGrow: 1
    },
    bold: {
        fontWeight: "bold"
    },
    actionItems: {
        position: "fixed",
        padding: theme.spacing(2),
        top: 0,
        right: 0
    },
    welcomePanel: {
        padding: theme.spacing(5, 0)
    },
    card: {
        width: 120,
        margin: theme.spacing(2),
        background: 'none',
        boxShadow: 'none',
        '& .MuiCardMedia-root': {
            backgroundSize: 'contain',
            width: '100%',
            height: 0,
            padding: '50% 0'
        }
    },
    mediaContainer: {
        padding: theme.spacing(1),
        margin: theme.spacing(-1),
        borderRadius: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
    },
    appName: {
        margin: theme.spacing(2, -2, -1)
    },
    versionInfo: {
        textShadow: "0 0 10px rgb(0 0 0)",
        color: theme.palette.common.white,
        position: "fixed",
        bottom: 5,
        right: 5
    }
});

class Page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            huisinfo: false,
            repairRequest: false
        };
    }

    componentDidMount() {
        const { user, dispatch } = this.props;

        //set app not selected
        dispatch(commonActions.selectApp(null));
        //check if only one app user has access to then go to that app
        if (user.availableApps) {
            var availableAppsForUser = getCommonArray(user.availableApps, appsInfo.map(x => x.appId))
            if (availableAppsForUser.length === 1) {
                var info = appsInfo.find(x => x.appId === availableAppsForUser[0]);
                if (info && info.path)
                    this.handleSelectApp(info);
            }
            if (user.availableApps.includes(apps.settingModule)) {
                dispatch(surveyActions.getApplicationManagerActionItem());
            }
        }
    }

    handleSelectApp(app) {
        const { dispatch, user } = this.props;
        const isBuyer = user.type === userAccountTypeConstants.buyer;
        dispatch(commonActions.selectApp(app.appId));
        history.push(app.appId === apps.aftercare && isBuyer ? app.buyerPath : app.path);
    }

    render() {
        const { t, classes, user, applicationManagerItems, currentVersionDate } = this.props;
        const { webApiUrl } = window.appConfig;
        const isBuyer = user.type === userAccountTypeConstants.buyer;
        const { wbProjectsLinkingInfo = [], wbEmailLinkingInfo = [] } = applicationManagerItems || {};
        const projectLinkUpdate = wbProjectsLinkingInfo && wbProjectsLinkingInfo.some(p => p.linked === false);
        const emailLinkUpdate = wbEmailLinkingInfo && wbEmailLinkingInfo.some(p => p.loginId === null || p.loginId === undefined || p.loginId === "");

        return <>
            <Grid
                container
                spacing={0}
                direction="column"
                className={classes.wrapper}
                style={{ minHeight: "100vh", backgroundImage: "url(" + webApiUrl + "api/Config/WebBackground)" }}
                sx={{
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                <CssBaseline />
                <div className={classes.actionItems}>
                    <Fab onClick={() => { if (user.viewOnly !== true) history.push('/login') }} size="medium" color="secondary" aria-label="logout" title={t('layout.navbar.logout')}>
                        <ExitToApp />
                    </Fab>
                </div>
                <Grid className={classes.container}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        sx={{
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                        {
                            <Grid className={classes.logoContainer}>
                                <img src={webApiUrl + "api/Config/WebLogo"} alt="JPDS" />
                            </Grid>
                        }
                        {
                            //    <Grid item xs={12} className={classes.welcomePanel}>
                            //    <Typography component="h1" variant="h4" gutterBottom align="center">{user.name}, {isBuyer ? t('dashboard.welcome.text.buyer') : t('dashboard.welcome.text')}</Typography>
                            //</Grid>
                        }
                        <Grid
                            container
                            sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                alignContent: "center"
                            }}>
                            <Grid className={classes.grow}>
                                <Divider />
                            </Grid>
                            <Typography variant="h5" align="center"  sx={{color: "text.secondary"}} >&nbsp;&nbsp; {t('general.apps')} &nbsp;&nbsp;</Typography>
                            <Grid className={classes.grow}>
                                <Divider />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            sx={{
                                justifyContent: "center",
                                alignItems: "center"
                            }}>
                            {
                                appsInfo.map((app, index) => {
                                    var appName = t(app.nameKey + (isBuyer && app.isDifferentNameForBuyer === true ? '.buyer' : ''))
                                    return user.availableApps && user.availableApps.includes(app.appId) &&
                                        <Card key={index} className={classes.card}>
                                            <CardActionArea onClick={() => this.handleSelectApp(app)}>
                                                <CardContent>
                                                    <Box className={classes.mediaContainer} sx={{
                                                        boxShadow: 1
                                                    }}>
                                                        <CardMedia
                                                            alt={appName}
                                                            image={app.icon}
                                                            title={appName}
                                                        />
                                                    </Box>
                                                    <Typography className={classes.appName} variant="body1" align="center" title={appName} noWrap sx={{
                                                        color: "text.secondary"
                                                    }}>{appName}</Typography>
                                                </CardContent>
                                                {apps.settingModule === app.appId && (projectLinkUpdate || emailLinkUpdate) && <Tooltip title={t('dossier.sidebar.priority.high')}>
                                                    <PriorityHigh color="error" style={{ position: 'absolute', right: 0, top: 10 }} />
                                                </Tooltip>}
                                            </CardActionArea>
                                        </Card>;
                                })
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <span className={classes.versionInfo}>
                {`${t("Huisinfo")} v${process.env.REACT_APP_VERSION} (${formatDate(new Date(currentVersionDate))})`}
            </span>
        </>;
    }
}

function mapStateToProps(state) {
    const { authentication, buildings, dashboardCount, surveying, currentVersionDate } = state;
    const { user } = authentication;
    const { selected } = buildings;
    const { applicationManagerItems } = surveying;
    return {
        user,
        selected,
        dashboardCount,
        applicationManagerItems,
        currentVersionDate
    };
}

const connectedLayout = connect(mapStateToProps)(withTranslation()(withStyles(styles)(Page)));
export { connectedLayout as CommonDashboard }
