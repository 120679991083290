import React, { useState, useEffect } from "react";
import { Chip, Dialog, DialogTitle, DialogContent, Button, Tooltip, Typography,  Grid2 as Grid, IconButton, FormControlLabel, Popover, alpha, Switch } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { Cancel, FilterList } from "@mui/icons-material";
import SelectCustom from "./Select";
import { groupBy } from "../_helpers";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  disabledSwitch: {
    color: `${theme.palette.primary.light} !important`,
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      maxWidth: 768,
    },
    "& .MuiDialogTitle-root": {
      background: theme.palette.primary.main,
    },
  },
  dialogTitle: {
    color: theme.palette.common.white,
  },
  dialogCancelButton: {
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
  },
  dialogSubContainer: {
    background: theme.palette.grey[100],
    padding: theme.spacing(0, 3),
    height: 40,
    display: "flex",
    alignItems: "center",
  },
  dialogFormLabel: {
    background: theme.palette.common.white,
    borderRadius: 4,
    padding: theme.spacing(0.5, 2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 2),
    },
    "& .MuiTypography-root": {
      paddingRight: theme.spacing(1),
    },
  },
  dialogAllSwitch: {
    "& .MuiTypography-root": {
      color: theme.palette.common.black,
      paddingRight: 10,
    },
  },
  dialogContents: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 1),
    },
  },
  progressIcon: {
    color: theme.palette.common.white,
  },
  objectGridContainer: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateRows: `repeat(15,1fr)`,
    gridAutoColumns: "1fr",
    justifyContent: "space-between",
    gridTemplateColumns: "repeat(auto-fill,110px)",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(auto-fill,95px)",
      justifyContent: "space-around",
    },
  },
  dialogButtonContainer: {
    display: "flex",
    columnGap: 10,
    justifyContent: "flex-end",
  },
  ctmPopoverIcon: {
    fill: theme.palette.grey[700],
    fontSize: 28,
    cursor: "pointer",
  },
  ctmPopoverLabel: {
    fontSize: 16,
    color: theme.palette.grey[700],
    fontWeight: "bold",
    paddingRight: theme.spacing(1.2),
  },
  ctmPopoverLabelRest: {
    fontSize: 16,
    color: alpha(theme.palette.primary.main, 0.8),
    fontWeight: "bold",
  },
  ctmPopover: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ctmHeaderTex: {
    display: "flex",
  },
  ctmPopoverMainContainer: {
    "& .MuiPopover-paper": {
      width: "100%",
      boxShadow: theme.shadows[1],
      background: theme.palette.common.white,
      borderRadius: 6,
      padding: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        maxWidth: 400,
      },
    },
  },
  filterChip: {
    margin: theme.spacing(0, 0.25),
  },
}));

SelectMultipleObjectsModal.defaultProps = {
  buildings: [],
};
export default function SelectMultipleObjectsModal(props) {
  const { open, buildings, selectedObjects, onSave, onClose, isReadOnly, immediateUpdate = false, isAgendaSlotLimit = false, maxObjects = "NA", onChange } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedObjectsDraft, setSelectedObjectsDraft] = useState(selectedObjects || []);
  const [changed, setChanged] = useState([]);
  const { updateLoading } = useSelector((state) => state.dossier);
  const [isUpdated, setIsUpdated] = useState(false);
  const [filterPopover, setFilterPopover] = useState(false);
  const [filterButtonRef, setFilterButtonRef] = useState(null);
  const [filters, setFilters] = useState({ propertyType: [], constructionFlow: [] });
  const remainingMaxObjects = maxObjects - selectedObjectsDraft.length;

  useEffect(() => {
    setSelectedObjectsDraft(selectedObjects);
  }, [selectedObjects]);

  useEffect(() => {
    if (updateLoading) setIsUpdated(true);
    if (!updateLoading && isUpdated) onClose();
  }, [updateLoading]);

  const openFilter = Boolean(filterPopover);

  const handleFilterChange = (filterName, data) => {
    let filterData = [];
    data.map((d) => {
      if (data.filter((x) => x.value === d.value).length === 1) filterData.push(d);
      return d;
    });
    setFilters({ ...filters, [filterName]: filterData });
  };

  const handleRemoveFilter = (key, item) => {
    if (filters[key]) {
      setFilters({ ...filters, [key]: filters[key].filter((x) => x.value !== item.value) });
    }
  };

  const selectFilterOptions = (key) => {
    const dataToGroup = buildings.filter((x) => !!x[key]);
    const groupedArray = groupBy(dataToGroup, key);
    const filteredArray = [];
    Object.keys(groupedArray).forEach((p) => filteredArray.push({ value: p, label: p }));
    return filteredArray;
  };

  const filterPopoverData = [
    { name: "propertyType", title: t("general.type"), option: selectFilterOptions("propertyType") },
    { name: "constructionFlow", title: t("general.constructionFlow.label"), option: selectFilterOptions("constructionFlow") },
  ];

  const clearFilters = () => {
    setFilters({ propertyType: [], constructionFlow: [] });
  };

  const handleCloseObjects = () => {
    onClose();
  };

  const handleSaveObjects = () => {
    onSave(selectedObjectsDraft, changed);
  };

  const handleSetSelectedObjectsDraft = (objects) => {
    objects = objects.filter((x) => !x.disableSelect || !!selectedObjects.find(y => y.buildingId === x.buildingId));
    if (immediateUpdate) {
      onChange(objects);
    } else {
      setSelectedObjectsDraft(objects);
    }
  };

  const selectAllObjects = () => {
    handleSetSelectedObjectsDraft(buildings);
  };

  const handleChangeSelectAllObjects = ({ target: { checked } }) => {
    if (checked === true) {
      selectAllObjects();
    } else {
      handleSetSelectedObjectsDraft(buildings.filter(x => x.disableSelect && !!selectedObjects.find(y => y.buildingId === x.buildingId)));
    }
    setChanged(buildings.map((b) => ({ buildingId: b.buildingId, isActive: checked })));
  };

  const filterPopoverHandler = (e) => {
    setFilterButtonRef(e.currentTarget);
    setFilterPopover(!filterPopover);
  };

  function renderSelectBuilding(building) {
    const selectedObject = selectedObjectsDraft.find((x) => x.buildingId === building.buildingId);
    const switchHandler = () => {
      let allChangedData = Object.assign([], changed);
      let object = { ...selectedObject };
      if (!!selectedObject) {
        object = {
          buildingId: object.buildingId,
          isActive: false,
        };

        const objects = selectedObjectsDraft.filter((x) => x.buildingId !== building.buildingId).slice();
        handleSetSelectedObjectsDraft(objects);
      } else {
        let objects = selectedObjectsDraft.slice();
        object = {
          buildingId: building.buildingId,
          isActive: true,
        };
        objects.push({ buildingId: building.buildingId });
        handleSetSelectedObjectsDraft(objects);
      }
      const isFound = allChangedData.findIndex((f) => f.buildingId === object.buildingId);
      if (isFound >= 0) allChangedData[isFound] = { ...allChangedData[isFound], ...object };
      else allChangedData.push(object);
      setChanged(allChangedData);
    };

    return (
      (<Grid container spacing={2} sx={{
          alignItems: "center"
        }}>
        <Grid>
          <FormControlLabel
            style={{ margin: 0 }}
            value={building.buildingId}
            disabled={isReadOnly || building.disableSelect}
            className={classes.dialogFormLabel}
            control={<Switch disabled={isReadOnly} color="primary" checked={!!selectedObject} classes={{ disabled: !!selectedObject && classes.disabledSwitch }} onChange={() => switchHandler()} />}
            label={building.buildingNoExtern}
            labelPlacement="start"
          />
        </Grid>
      </Grid>)
    );
  }

  return (open === true && (<Dialog open={open} onClose={updateLoading ? () => {} : handleCloseObjects} aria-labelledby="form-dialog-title" maxWidth="sm" scroll="paper" className={classes.dialogContainer}>
    <DialogTitle id="dialog-objects-title" className={classes.dialogTitle}>
      <Grid container spacing={1}>
        <Grid className={classes.grow}>
            {t("general.objects.select")}
        </Grid>
        <Grid>
          <Grid container spacing={1}>
            <Grid>
              <Button disabled={updateLoading} variant="outlined" className={classes.dialogCancelButton} onClick={handleCloseObjects}>
                {!immediateUpdate ? t("general.cancel") : t("general.close")}
              </Button>
            </Grid>
            {!immediateUpdate && (
              <Grid>
                <Button disabled={updateLoading || !changed.length || isReadOnly || (maxObjects !== "NA" && remainingMaxObjects < 0)} variant="outlined" onClick={handleSaveObjects} className={classes.dialogCancelButton}>
                  {updateLoading ? <CircularProgress size={20} className={classes.progressIcon} /> : t("general.save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </DialogTitle>
    <Grid container className={classes.dialogSubContainer} sx={{
      alignItems: "center"
    }}>
      <Grid>
        <FormControlLabel
          style={{ margin: 0 }}
          disabled={isReadOnly}
          className={classes.dialogAllSwitch}
          value="all"
          control={
            <Switch
              color="primary"
              classes={{ disabled: selectedObjectsDraft && selectedObjectsDraft.length === buildings.filter((x) => !x.disableSelect).length && classes.disabledSwitch }}
              disabled={isReadOnly}
              checked={selectedObjectsDraft && selectedObjectsDraft.length === buildings.filter((x) => !x.disableSelect || !!selectedObjects.find(y => y.buildingId === x.buildingId)).length}
              onChange={handleChangeSelectAllObjects}
            />
          }
          label={t("general.all")}
          labelPlacement="start"
        />
      </Grid>
              <Grid className={classes.grow}>
                  {
                      isAgendaSlotLimit &&
                      <>
                          {
                              remainingMaxObjects < 0 ?
                                  t('agenda.noSlotsRemaining')
                                  :
                                  `${t('agenda.slotsRemaining')}: ${remainingMaxObjects}`
                          }
                      </>
                  }
              </Grid>
      {filterPopoverData.filter((x) => x.option && x.option.length).length && (
        <Grid>
          <Tooltip title={t("general.filter")}>
            <IconButton size="small" aria-label="Filter" onClick={filterPopoverHandler}>
              <FilterList />
            </IconButton>
          </Tooltip>
          <Popover
            id="toolbar-filter"
            open={openFilter}
            anchorEl={filterButtonRef}
            onClose={filterPopoverHandler}
            classes={{ root: classes.ctmPopoverMainContainer }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <div className={classes.ctmPopover}>
              <div className={classes.ctmHeaderTex}>
                <Typography className={classes.ctmPopoverLabel}>{t("datatable.label.filter.title")}</Typography>
                <Typography onClick={() => clearFilters()} className={classes.ctmPopoverLabelRest}>
                  {t("datatable.label.filter.reset")}
                </Typography>
              </div>
              <Cancel className={classes.ctmPopoverIcon} onClick={filterPopoverHandler} />
            </div>
            <hr />
            <Grid container spacing={2}>
              {filterPopoverData.map((p, index) => {
                return (p.option.length && (<Grid size={{xs:12}}>
                  <SelectCustom
                    label={p.title}
                    searchKey={p.name}
                    selectProps={{
                      multiple: true,
                      value: filters[p.name],
                      onChange: (e, newValue) => {
                        handleFilterChange(p.name, newValue);
                      },
                    }}
                    selectedList={filters[p.name]}
                    idKey={"value"}
                    labelKey={"label"}
                    options={p.option}
                  />
                </Grid>));
              })}
            </Grid>
          </Popover>
        </Grid>
      )}
    </Grid>
    <DialogContent className={classes.dialogContents}>
      <Grid container spacing={1}>
        {Object.keys(filters).map((key) => {
          return filters[key].map((item) => <Chip className={classes.filterChip} key={key + item.value} label={item.value} onDelete={() => handleRemoveFilter(key, item)} />);
        })}
      </Grid>
      <Grid container spacing={1} className={classes.objectGridContainer}>
        {buildings
          .sort((a, b) => {
            if (a.buildingNoExtern < b.buildingNoExtern) return -1;
            if (a.buildingNoExtern > b.buildingNoExtern) return 1;
            return 0;
          })
          .map((building, index) => {
            var keys = Object.keys(filters);
            var isFiltered = true;
            for (var i = 0; i < keys.length; i++) {
              if (isFiltered && filters[keys[i]] && filters[keys[i]].length) {
                isFiltered = !!filters[keys[i]].find((x) => x.value === building[keys[i]]);
              }
            }
            return (isFiltered && (<Grid size="auto">
              <React.Fragment key={index}>{renderSelectBuilding(building)}</React.Fragment>
            </Grid>));
          })}
      </Grid>
    </DialogContent>
  </Dialog>));
}
