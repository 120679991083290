import { useCallback, useMemo, useState } from 'react'
import { Box, Tab, Typography, Tabs, useTheme, Badge, IconButton, Button, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'
import { OpenInFull } from '@mui/icons-material';
import Activities from './Activities';
import { useTranslation } from "react-i18next";
import { addNewRepairRequestChat } from '../../apis/aftercareApis';
import CreateNewChat from '../../components/CreateNewChat/CreateNewChat';
import ChatsWrapper from '../../components/ChatsWrapper/ChatsWrapper';

const StartNewChat = ({ handleInitiateChat }) => {
    const { t } = useTranslation();
    return (
        <Stack
            sx={{
                p: { xs: 2.5, md: 3 },
                border: "1px dashed",
                borderColor: grey[400],
                borderRadius: 1,
                width: "100%",
                minHeight: "350px",
                height: "calc(35vh + 65px)",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: 'url(/content/images/background/brickwall-bg.png)'
            }}>
            <Button variant="contained" onClick={()=>handleInitiateChat()}>{t("chat.startChat")}</Button>
        </Stack>
    );
}


const TabPanel = ({ children, value, index, icon, label, actionItem, keepMounted }) => {
    const theme = useTheme()
    return (
        (<Stack role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} sx={{
            height: "100%"
        }} >
            {(value === index || keepMounted) && (
                <>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                        {
                            icon && label && <Typography component="h2" variant="h6" sx={{
                                padding: theme.spacing(2)
                            }}>
                                {icon}&nbsp;&nbsp;
                                {label}
                            </Typography>
                        }
                        {actionItem}
                    </Box>
                    {children}
                </>
            )}
        </Stack>)
    );
}

const NotificationPanel = ({ allActivity, generateWorkOrderPageLink, repairRequestId, repairRequestNumber, resolverId, hasChats, hideWorkOrderLink, canInitiateChat, expandViewTitle, hideAddChatButton, refreshParentState }) => {
    const { t } = useTranslation();
    const [isNewChatCreate, setIsNewChatCreate] = useState(false)
    const [isExpandedView, setIsExpandedView] = useState(false)
    const [totalUnreadCount, setTotalUnreadCount] = useState(0)
    const [activeTab, setActiveTab] = useState(() => (hasChats || canInitiateChat) ? 0 : 1);
    const [selectedParticipants, setSelectedParticipants] = useState([])
    const [startChatId, setStartChatId] = useState()

    const handleActiveTab = (event, newTab) => {
        setActiveTab(newTab);
        setIsNewChatCreate(false)
        setSelectedParticipants([])
    };

    const handleCloseExpandedView = useCallback(
        () => {
            setIsExpandedView(false)
        },
        [],
    )
    const handleInitiateChat = async (groupName) => {
        if (canInitiateChat) {
            if (hideAddChatButton || (groupName && selectedParticipants.length > 0)) {
                try {
                    const response = await addNewRepairRequestChat({
                        repairRequestId, resolverId, chatSubject: groupName, loginIds: selectedParticipants
                    });
                    const responseData = response.data;
                    if (responseData?.chatId) {
                        !hasChats && canInitiateChat && refreshParentState?.()
                        setStartChatId(responseData?.chatId)
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    setIsNewChatCreate(false)
                    setSelectedParticipants([])
                }

            } else {
                setIsNewChatCreate(true)
            }
        }

    }

    const handleSelectParticipant = (id) => {
        const index = selectedParticipants.indexOf(id);
        if (index === -1) {
            setSelectedParticipants([...selectedParticipants, id])
        } else {
            const participants = [...selectedParticipants]
            participants.splice(index, 1)
            setSelectedParticipants([...participants])
        }
    };
    return (<>
        <Stack
            direction="row"
            sx={{
                justifyContent: "space-between",
                borderBottom: 1,
                borderColor: "divider"
            }}>
            <Tabs value={activeTab} onChange={handleActiveTab}>
                {(hasChats || canInitiateChat) && <Tab label={<Badge badgeContent={totalUnreadCount} color='primary'>{t("chat.messages")}&nbsp;&nbsp;&nbsp;</Badge>} value={0} />}
                <Tab label={t("repairRequest.activity")} value={1} />
            </Tabs>
            {activeTab === 0 && hasChats && !isNewChatCreate && <IconButton
                aria-label="OpenInFull"
                size="large"
                onClick={() => setIsExpandedView(true)}>
                <OpenInFull fontSize="medium" sx={{ color: 'primary' }} />
            </IconButton>}
        </Stack>
        <Box
            sx={{
                minHeight: "350px",
                height: "calc(35vh + 65px)",
                overflow: "auto"
            }}>
            <TabPanel
                value={activeTab}
                index={0}
                keepMounted
            >
                
                {isNewChatCreate ? <CreateNewChat selectedParticipants={selectedParticipants} repairRequestId={repairRequestId} repairRequestNumber={repairRequestNumber} handleCloseChat={() => { setIsNewChatCreate(false); setSelectedParticipants([]); }} handleSelectParticipant={handleSelectParticipant} handleInitiateChat={handleInitiateChat} /> : <>

                    {hasChats && <ChatsWrapper repairRequestId={repairRequestId} handleCloseExpandedView={handleCloseExpandedView} isExpandedView={isExpandedView} title={expandViewTitle} wrapperStyle={{ minHeight: "350px", height: "calc(35vh + 65px)" }} handleInitiateChat={handleInitiateChat} updateParentTotalUnreadCount={(count) => setTotalUnreadCount(count)} resolverId={resolverId} hideAddChatButton={hideAddChatButton} canInitiateChat={canInitiateChat} startChatId={startChatId} hideChatMessages={activeTab !== 0} />}

                    {!hasChats && canInitiateChat && <StartNewChat handleInitiateChat={handleInitiateChat} />}
                </>
                }
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
                <Activities repairRequestId={repairRequestId} resolverId={resolverId} allActivity={allActivity} generateWorkOrderPageLink={generateWorkOrderPageLink} hideWorkOrderLink={hideWorkOrderLink} />
            </TabPanel>
        </Box>
    </>);
}
export default NotificationPanel;