import { CircularProgress, Stack } from '@mui/material'
import React from 'react'

function Loader(props) {
  return (
    (<Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: "100%",
        width: "100%"
      }}>  <CircularProgress size={"1.5rem"} {...props}/></Stack>)
  );
}

export default Loader