import React, { useCallback, useMemo, useState } from 'react'
import { Box, StyledEngineProvider, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro'
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import DataGridToolbar from './DataGridToolbar';
import { getLocalStorageItem, setLocalStorageItem } from '../../_helpers';
import { getDataTableTextLocale } from "../../_helpers/textHelper"
import { getColumnsOrderFromLocalStorage } from '../../_helpers/getGridContextMenuList';

const DataGridV2 = ({ currentView, slotProps, slots, toolbarProps, columns, rows, apiRef, filterMode = "server", MobileCustomRowComponent, columnsForExport, rowCount, localSearchIdentifier, localColumnFilterIdentifier, localColumnSortIdentifier, localColumnOrderIdentifier, localColumnWidthIdentifier, localColumnVisibilityIdentifier, initialState, mappingOfOldFieldToNewField = {}, ...rest }) => {

  const { i18n } = useTranslation();
  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));

  // State management with localStorage
  const [filterModel, setFilterModel] = useState(() => getLocalStorageItem(localColumnFilterIdentifier, undefined
  ))
  const [sortModel, setSortModel] = useState(() => getLocalStorageItem(
    localColumnSortIdentifier, undefined
  ))
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => getLocalStorageItem(
    localColumnVisibilityIdentifier, undefined
  ))

  // Locale text configuration
  const localeText = useMemo(() => isTabletOrMobile ? {
    toolbarColumns: "",
    toolbarFilters: "",
    toolbarDensity: "",
    toolbarExport: "",
  } : {}, [isTabletOrMobile]);

  const defaultInitialState = useMemo(() => ({
    columns: {
      columnVisibilityModel: getLocalStorageItem(localColumnVisibilityIdentifier) || {},
    },
  }), [localColumnVisibilityIdentifier]);

  const locale = useMemo(() => {
    return { ...getDataTableTextLocale(i18n.language), ...localeText };
  }, [i18n.language, localeText]);

  const getSlots = useMemo(() => {
    const toolbarSlot = {
      toolbar: (props) => (
        <DataGridToolbar {...props} {...toolbarProps} totalRecords={rowCount || rows?.length || 0} apiRef={apiRef} columns={columns} filterMode={filterMode} columnsForExport={columnsForExport} rows={rows} localSearchIdentifier={localSearchIdentifier} />
      ),
    };

    const rowSlot = isTabletOrMobile && MobileCustomRowComponent && currentView === "list" ? {
      row: (rest) => <MobileCustomRowComponent {...rest} />,
    } : {};

    return { ...toolbarSlot, ...rowSlot, ...slots };
  }, [MobileCustomRowComponent, apiRef, columns, columnsForExport, currentView, filterMode, isTabletOrMobile, localSearchIdentifier, rowCount, rows, slots, toolbarProps]);

  // Handlers
  const onFilterModelChange = useCallback((newFilterModel) => {
    if (filterMode === 'client') {
      setFilterModel(newFilterModel)
      setLocalStorageItem(localColumnFilterIdentifier, newFilterModel);
    }
  }, [filterMode, localColumnFilterIdentifier])

  const onSortModelChange = useCallback((newSortModel) => {
    if (filterMode === 'client') {
      setSortModel(newSortModel)
      setLocalStorageItem(
        localColumnSortIdentifier,
        newSortModel
      )
    }
  }, [filterMode, localColumnSortIdentifier])

  const onColumnVisibilityModelChange = useCallback((newColumnVisibilityModel) => {
    setColumnVisibilityModel(newColumnVisibilityModel)
    setLocalStorageItem(
      localColumnVisibilityIdentifier,
      newColumnVisibilityModel
    );
  }, [localColumnVisibilityIdentifier])

  const onColumnOrderChange = useCallback((params) => {
    const mapValue = getLocalStorageItem(localColumnOrderIdentifier) || columns.map((column) => column.field);
    const currentOrder = [...mapValue]
    const element = currentOrder.splice(params.oldIndex - 1, 1)[0];
    currentOrder.splice(params.targetIndex - 1, 0, element);
    setLocalStorageItem(localColumnOrderIdentifier, currentOrder)
  }, [columns, localColumnOrderIdentifier])

  const onColumnWidthChange = useCallback((params) => {
    const localColumnWidth = getLocalStorageItem(localColumnWidthIdentifier, [])
    const columnIndex = localColumnWidth.findIndex(item => item.columnName === params.colDef.field);
    if (columnIndex !== -1) {
      localColumnWidth[columnIndex].width = params.width;
    } else {
      localColumnWidth.push({ field: params.colDef.field, width: params.width });
    }
    setLocalStorageItem(localColumnWidthIdentifier, localColumnWidth);
  }, [localColumnWidthIdentifier])

  const processedColumns = useMemo(() => {
    const filterColumns = getColumnsOrderFromLocalStorage({ columns, localStorageKey: localColumnOrderIdentifier, mappingOfOldFieldToNewField });
    const localColumnWidth = getLocalStorageItem(localColumnWidthIdentifier, []).filter(item => !!item.field);

    localColumnWidth.forEach(item => {
      const columnIndex = filterColumns.findIndex(filterItem => filterItem.field === item.field);
      if (columnIndex !== -1) {
        filterColumns[columnIndex].width = item.width;
        delete filterColumns[columnIndex].minWidth;
        delete filterColumns[columnIndex].flex;
      }
    });
    return filterColumns.filter(c => !c?.hidden); // remove hidden columns
  }, [columns, localColumnOrderIdentifier, localColumnWidthIdentifier, mappingOfOldFieldToNewField]);


  return (<>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box sx={{
          height: "100%"
        }}>
          <DataGridPro
            initialState={{ density: "compact", ...(initialState ? initialState : defaultInitialState) }}
            filterModel={rest?.filterModel || filterModel}
            onFilterModelChange={rest?.onFilterModelChange || onFilterModelChange}
            sortModel={rest?.sortModel || sortModel}
            onSortModelChange={onSortModelChange}
            columnVisibilityModel={rest?.columnVisibilityModel || columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            onColumnOrderChange={rest?.onColumnOrderChange || onColumnOrderChange}
            onColumnWidthChange={onColumnWidthChange}
            apiRef={apiRef}
            filterMode={filterMode}
            columns={processedColumns}
            rows={rows}
            rowCount={rowCount}
            slots={getSlots}
            rowHeight={34}
            localeText={locale}
            slotProps={{
              ...slotProps,
              toolbar: {
                showQuickFilter: true,
                printOptions: {
                  allColumns: false,
                  fileName: "Print",
                  hideFooter: true,
                  hideToolbar: true,
                },
              },
            }}
            sx={[{
              background: "white",
              fontSize: "12px",
              lineHeight: 1.43,
              letterSpacing: "0.01071em",
              padding: "0.8px 4px",

              ".MuiDataGrid-virtualScrollerRenderZone": {
                width: "100%",
              },

              "& .MuiDataGrid-row:hover": {
                "& .img-col-data": {
                  transform: "scale(3)",
                  position: "absolute",
                  zIndex: "1000000",
                },
                "& .img-col-first-data": {
                  marginTop: '10px',
                },
              },

              ".MuiCheckbox-colorPrimary ": {
                color: grey[500]
              },

              ".grey": {
                backgroundColor: grey[50]
              }
            }, currentView === "list" ? {
              ".MuiDataGrid-virtualScroller": {
                [theme.breakpoints.down("md")]: {
                  overflowX: "hidden"
                }
              }
            } : {
              ".MuiDataGrid-virtualScroller": {
                [theme.breakpoints.down("md")]: {
                  overflowX: "scroll"
                }
              }
            }, isTabletOrMobile ? {
              ".MuiDataGrid-footerContainer": {
                minHeight: "30px "
              }
            } : {
              ".MuiDataGrid-footerContainer": {
                minHeight: "45px"
              }
            }]}
            className={currentView === "list" ? classes.hideHeader : ""}
            pageSizeOptions={[5, 10, 20, 50, 75, 100]}
            disableVirtualization
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'grey' : ''
            }
            {...rest}
          />
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  </>);
}

export default DataGridV2

const theme = createTheme({
  components: {
    PrivateSwitchBase: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          maxWidth: "100%",
          ".MuiDataGrid-panelWrapper": {
            maxWidth: "100%"
          }
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          columnHeaderTitle: {
            fontWeight: "bold",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontSize: "12px",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: "20.57px",
          },
        },
      },
    },
  }
});

const useStyles = makeStyles((theme) => ({
  hideHeader: {
    [[theme.breakpoints.down("md")]]: {
      "& .MuiDataGrid-columnHeaders": {
        display: "none",
        borer: "none ",
      },
    },
  },
}));