import React, { useEffect } from "react";
import { Button, Tooltip, Typography, IconButton, Box, Avatar, Popover, CardContent } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ArrowRightAlt, PriorityHigh, Schedule } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatDate, toBase64 } from "../_helpers";
import { useState } from "react";
import clsx from "clsx";

export const IconColumn = ({ value, metaData, idName, data, ...props }) => {
  const id = metaData.rowData[0];
  const selectedRecord = data.find((x) => x[idName] === id);
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Typography noWrap>
      {value === true && (
        <Tooltip title={<Typography>{t("general.actionRequired")}</Typography>}>
          <PriorityHigh color="secondary" size="small" />
        </Tooltip>
      )}
      {selectedRecord.overdue ? (
        <Tooltip title={<Typography>{t("general.overdue")}</Typography>}>
          <Schedule fontSize="small" color="error" />
        </Tooltip>
      ) : selectedRecord.is48HoursReminder ? (
        <Tooltip title={<Typography>{t("general.48hours")}</Typography>}>
          <Schedule fontSize="small" className={classes.warning} />
        </Tooltip>
      ) : (
        ""
      )}
    </Typography>
  );
};

export const PriorityIconColumn = React.memo(({ value, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return value == 2 ? (
    <Tooltip title={t("repairRequest.priority." + value)}>
      <ArrowRightAlt className={classes.rotate45AntiClockwise} color="error" />
    </Tooltip>
  ) : value == 1 ? (
    <Tooltip title={t("repairRequest.priority." + value)}>
      <ArrowRightAlt color="primary" />
    </Tooltip>
  ) : value == 0 ? (
    <Tooltip title={t("repairRequest.priority." + value)}>
      <ArrowRightAlt className={classes.rotate45Clockwise} />
    </Tooltip>
  ) : (
    ""
  );
});

export const LinkColumn = React.memo(({ label, className = {}, ...props }) => {
  const classes = useStyles();
  return (
    <Button
      style={{ padding: 0 }}
      component={Link}
      className={clsx(className)}
      color="primary"
      {...props}
    >
      <span className={clsx(classes.content)}>
        {label}
      </span>
      <div className={classes.spacer}> {label}</div>
      <span>&nbsp;</span>
    </Button>
  );
});

export const MultiLabelColumn = React.memo(({ data = [], onClick = () => 0, ...props }) => {
  const classes = useStyles();
  const [userListPopover, setUserListPopover] = useState({
    element: null,
    data: null,
  });
  if (!data || !data.length) return "";

  const labels = [...new Map(data.map((item) => [item.name, item])).values()];
  const clickHandler = (e, user) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(user)
  }
  return (
    <div style={{ display: "flex", }}>
      <Tooltip
        enterTouchDelay={0}
        title={<span className={classes.labelTooltip}>{labels[0].name}</span>}
      >
        <span className={clsx(classes.shortenText, labels.length - 1 > 0 && classes.trimResolverLabel, classes.content)} onClick={(e) => clickHandler(e, labels[0])}>
          {labels[0].name}
        </span>
      </Tooltip>
      <UserLists
        open={!!userListPopover.element}
        data={userListPopover.data}
        onClose={(e) => {
          e.stopPropagation()
          setUserListPopover({ element: null, data: null })
        }}
        onClick={clickHandler}
        anchorEl={userListPopover.element}
      />
      <div className={classes.spacer}> {labels[0].name}</div>
      <span>&nbsp;</span>
      {labels.length - 1 > 0 && (
        <Avatar

          className={classes.smallAvatar}
          onClick={(e) => {
            e.stopPropagation()
            setUserListPopover({ element: e.currentTarget, data: labels })
          }}>

          +{labels.length - 1}
        </Avatar>
      )}
    </div>
  );
});

export const LabelColumn = React.memo(({
  spanClass = {},
  color = "",
  label,
  tooltipLabel = "",
  date,
  showIcon,
  Icon,
  onLabelClick,
  clickable,
  onClick,
  isAvatar,
  avatarUrl,
  ...props
}) => {

  const classes = useStyles();
  return (
    <div  className={classes.locationContainer}>
      {showIcon ? (
        <>
          <Tooltip
            title={
              !tooltipLabel ? (
                ""
              ) : (
                <span className={classes.labelTooltip}>{tooltipLabel}</span>
              )
            }
          >
            <span style={{ color }} className={clsx(classes.label, classes.content, onLabelClick && classes.cursor)} onClick={() => onLabelClick && onLabelClick(label)}>
              {clickable ? (
                <IconButton
                  color="primary"
                  className={classes.mapIconContainer}
                  aria-label="Repair request map redirect"
                  component="span"
                  onClick={onClick}
                  size="large">
                  <Icon className={classes.mapIcon} />
                </IconButton>
              ) : (
                isAvatar ? (
                  <div className={classes.avatarIconContainer}>
                    <Avatar src={avatarUrl} className={classes.avatarIcon} />
                  </div>
                ) : <Icon className={classes.mapIcon} />
              )}
              {date ? formatDate(new Date(date)) : label}
            </span>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip
            title={
              !tooltipLabel ? (
                ""
              ) : (
                <span className={classes.labelTooltip}>{tooltipLabel}</span>
              )
            }
          >
            <Typography
              noWrap
              variant="body2"
              component="span"
              style={{ color }}
              className={clsx(classes.iconLabel, classes.label, classes.content, spanClass)}
            >
              {Icon && <div className={classes.customIconContainer}></div>}
              <span className={clsx(Icon ? classes.iconTextLabel : classes.textLabel, onLabelClick && classes.cursor)} onClick={(e) => {
                onLabelClick && onLabelClick(label);
             }}>
                {date ? formatDate(new Date(date)) : label}
              </span>
            </Typography>
          </Tooltip>
        </>
      )
      }
      <div className={classes.spacer}> {date ? formatDate(new Date(date)) : label}</div>
      <span>&nbsp;</span>
    </div >
  );
});

export const ImageColumn = React.memo(({ src, size = 20, ...props }) => {
  const [url, setUrl] = useState();
  const [base64, setBase64] = useState();
  const classes = useStyles();

  useEffect(() => {
    if (src) {
      setUrl(src);
        if (typeof (src) === "string" && src.startsWith("data:")) {
            setBase64(src);
        }
    };
  }, [src]);

  if (!src) return <div style={{ height: `${size + 4}px` }} />;

  return (
    (<div>
      <span className={classes.centerContainer}>
        {<Box
          className={classes.thumbnail}
          style={{ backgroundImage: `url(${url})`, width: size, height: size }}
          sx={{
            boxShadow: 1,
            borderRadius: 1
          }}>
          {base64 && <img src={base64} alt={"offline-img"} className={classes.base64Image} />}
        </Box>}
        {src && (
          <aside
            className={clsx(classes.popoverImage, classes.fixedContainer)}
          >
            <Box
              className={classes.thumbnailAbsolute}
              style={{ backgroundImage: `url(${src})` }}
              sx={{
                boxShadow: 1,
                borderRadius: 1
              }}>
              {base64 && <img src={base64} alt={"offline-img"} className={classes.base64Image} />}
            </Box>
          </aside>
        )}
      </span>
    </div>)
  );
});

export const UserLists = React.memo(({
  open,
  anchorEl: anchorElModal,
  onClick,
  data,
  ...props
}) => {
  const classes = useStyles();

  return data ? (
    <Popover
      id="mouse-over-popover"
      style={{ overflow: "hidden" }}
      anchorEl={anchorElModal}
      open={!!anchorElModal && data && data.length}
      disableRestoreFocus
      classes={{ root: classes.root, paper: classes.paper }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    >
      <div>
        {data && (
          <CardContent className={classes.cardContentStyle}>
            {data.map((user) => {
              return (
                <span className={classes.headerContent} onClick={(e) => onClick(e, user)}>{user.name}</span>
              );
            })}
          </CardContent>
        )}
      </div>
    </Popover>
  ) : null;
});

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: 7,
    minWidth: 150,
  },
  cardContentStyle: {
    padding: theme.spacing(0, 2),
    paddingBottom: `${theme.spacing(0)} !important`,
    display: "flex",
    flexDirection: "column",
  },
  cardList: {
    padding: theme.spacing(0.4, 0, 0.4, 0),
  },
  cardAvatar: {
    marginRight: `8px !important`,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  mapIcon: {
    fill: theme.palette.primary.main,
    fontSize: 18
  },
  avatarIconContainer: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  avatarIcon: {
    marginRight: 5,
    width: 18,
    '& > svg': {
      height: 14,
      width: 14
    }
  },
  customIconContainer: {
    minWidth: 27,
  },
  iconTextLabel: {
    width: "calc(100% - 27px)",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  textLabel: {
    width: "100%",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  locationContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  mapIconContainer: {
    padding: theme.spacing(0.2),
  },
  tooltipImageContainer: {
    "& > img": {
      width: "100%",
    },
  },
  thumbnailAbsolute: {
    width: 20,
    height: 20,
    transition: "0.2s all",
    margin: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundColor: theme.palette.grey[300],
    marginTop: 2,
    position: "absolute",
    opacity: 0,
    marginBottom: 2,
  },
  thumbnail: {
    transition: "0.2s all",
    margin: "auto",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundColor: theme.palette.grey[300],
    marginTop: 2,
    marginBottom: 2,
    overflow: "hidden"
  },
  rotate45Clockwise: {
    transform: "rotate(45deg)",
  },
  rotate45AntiClockwise: {
    transform: "rotate(-45deg)",
  },
  smallAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 9,
    position: "relative",
    border: "2px solid white",
    right: 0,
    zIndex: 4,
  },
  shortenText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      paddingLeft: 5
    },
    cursor: "pointer"
  },
  trimResolverLabel: {
    maxWidth: "calc(100% - 25px) !important"
  },
  userListAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: theme.spacing(3),
    height: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 12,
  },
  headerContent: {
    fontSize: `14px !important`,
    margin: 4,
    cursor: "pointer"
  },
  label: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "inherit"
  },
  wrapContainer: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  labelTooltip: {
    fontSize: 14,
  },
  popoverImage: {
    "&:hover": {
      "& > div": {
        width: "160px !important",
        height: "160px !important",
      },
    },
  },
  centerContainer: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fixedContainer: {
    position: "absolute",
    zIndex: 9999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  base64Image: {
    height: "100%",
    width: "100%",
  },
  content: {
    position: "absolute",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  spacer: {
    height: 0,
    overflow: "hidden"
  },
  iconLabel: {
    display: "flex",
    alignItems: "center"
  },
  cursor: {
    cursor: "pointer"
  }
}));
