import React, { useState } from 'react'
import {
  AppBar,
  Alert,
  Snackbar,
  Tabs,
  Avatar,
  Box,
  Button,
  Divider,
  Grid2 as Grid,
  IconButton,
  List,
  ListItemText,
  ListItemIcon,
  ListItemAvatar,
  Slide,
  Stack,
  Tab,
  Toolbar,
  Typography,
  CircularProgress,
  Portal,
  DialogContentText,
  ListItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  AccordionDetails,
  AccordionSummary,
  Accordion
} from '@mui/material';
import { Close, PersonAddAltSharp, ExpandMore } from '@mui/icons-material'
import { grey } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import SelectResponsiblePerson from '../Survey/components/SelectResponsiblePerson'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { deleteChatParticipant, addChatParticipant } from '../../apis/aftercareApis'
import {  getDayName, getNameInitials } from '../../_helpers'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import i18n from '../../i18n'

const CustomTabPanel = (props) => {
  const { children, value, index } = props;
  return (
    <Box
      component='div'
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

const ParticipantsListDrawer = ({ isParticipantsListDrawerOpen, applyMinStyles, isFullWidth, toggleParticipantListDrawer, chatSubTitle, participants, isUserBuyer = false, getUserAvatar, selectedChat, isReadOnlyChat, updateChatParticipants }) => {
  const { webApiUrl } = window.appConfig;
  const { all = [] } = useSelector(state => state.buildings);
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [organizationsLoading, setOrganizationsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [isDeleteParticipateDialogOpen, setIsDeleteParticipateDialogOpen] = useState(false);
  const [currentChatParticipantAction, setCurrentChatParticipantAction] = useState({
    chatParticipantId: "",
    isLoading: false,
    chatParticipantName: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [toast, setToast] = useState({
    open: false,
    message: "",
    type: "error"
  })
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let buildingNoAndAddress = "", projectNoAndName = ""
  if (selectedChat?.buildingId && all?.length > 0) {
    const building = all.find((building) => {
      return building?.buildingId === selectedChat?.buildingId
    })
    if (building?.projectName) {
      projectNoAndName = building?.projectName
    }
    if (isUserBuyer) {
      buildingNoAndAddress = building?.buildingNoExtern
    } else {
      buildingNoAndAddress = building?.buildingNoIntern
    }
    if (building?.address) {
      buildingNoAndAddress += ` - ${building.address}`
    }
  }
  const handleChatParticipantDelete = async (chatId, chatParticipantId) => {
    setCurrentChatParticipantAction({
      ...currentChatParticipantAction,
      isLoading: true,
    })
    try {
      const response = await deleteChatParticipant(chatId, chatParticipantId);
      if (response.status === 200) {
        setToast({
          open: true,
          message: t("chat.deleteMember.success"),
          type: "success"
        });
        setAnchorEl(null);
        updateChatParticipants?.()
        setIsDeleteParticipateDialogOpen(false)
      } else {
        setToast({
          open: true,
          message: t("general.failedToDelete"),
          type: "error"
        });
      }
      setCurrentChatParticipantAction({
        isLoading: false,
        chatParticipantId: "",
        chatParticipantName: ""
      })
    } catch (error) {
      setCurrentChatParticipantAction({
        ...currentChatParticipantAction,
        isLoading: false,
      })
      setIsDeleteParticipateDialogOpen(false)
      setToast({
        open: true,
        message: t("general.failedToDelete"),
        type: "error"
      });
      setAnchorEl(null);
    }
    setIsSubmitting(false)
  }

  const handleChatParticipantUpdate = async (data) => {
    try {
      const response = await addChatParticipant(selectedChat?.chatId, data?.loginId);
      if (response.status === 200) {
        setToast({
          open: true,
          message: t("chat.addMember.success"),
          type: "success"
        });
        setAnchorEl(null);
        updateChatParticipants?.()
      } else {
        setToast({
          open: true,
          message: t("general.failedToUpdate"),
          type: "error"
        });
      }
    } catch (error) {
      setToast({
        open: true,
        message: t("general.failedToUpdate"),
        type: "error"
      });
      setAnchorEl(null);
    }
    setIsSubmitting(false)
  };

  const daysOfWeek = [...Array(5)].map((_, index) => {
    return {
      key: index + 1,
      label: getDayName({ dayOfWeek: index + 1, locale: i18n.language })
    }
  })
  
  const ListItemSecondaryAction = ({ participant }) => {
    return (
      <>
        {(participant?.canBeDeleted && selectedChat?.hasRights && !isReadOnlyChat) && (
          <>
            {
              currentChatParticipantAction?.isLoading && participant?.chatParticipantId === currentChatParticipantAction?.chatParticipantId ? <CircularProgress size={20} /> :
                <>
                  <IconButton edge={!participant.showAvailability ? "end" : false} aria-label="delete" onClick={(e) => {
                    setCurrentChatParticipantAction({ ...currentChatParticipantAction, chatParticipantId: participant?.chatParticipantId, chatParticipantName: participant?.name })
                    setIsDeleteParticipateDialogOpen(true);
                    e.stopPropagation()
                  }}>
                    <Close fontSize='small' />
                  </IconButton>
                </>
            }
          </>)
        }
        {participant.showAvailability && <IconButton edge="end"><ExpandMore /></IconButton>}
      </>
    )
  }

  return (
    (<Slide direction="left" in={isParticipantsListDrawerOpen} mountOnEnter unmountOnExit>
      <Grid
        container
        direction="column"
        className={classes.slideLeft}
        size={{
          xs: 12,
          md: applyMinStyles ? 12 : 4,
          lg: applyMinStyles ? 12 : (isFullWidth ? 3 : 4)
        }}>
        <AppBar position="static">
          <Toolbar variant="dense" className={classes.toolbar}>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}>
              {chatSubTitle}
            </Typography>
            <IconButton aria-label="Close" color="inherit" onClick={toggleParticipantListDrawer} edge="end">
              <Close />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box className={classes.participantsContainer}>
          <Box sx={{
            p: 2
          }}    >
            <Stack direction="row" spacing={3} sx={{
              alignItems: "center"
            }}>
              <Avatar sx={{ width: 83, height: 83, fontSize: "55px" }} src={`${webApiUrl}api/RepairRequest/GetRepairRequestThumbnail/${selectedChat?.repairRequestId}?resolverId=${selectedChat?.resolverId}`} alt={chatSubTitle} />
              <Stack >
                {
                  projectNoAndName && (
                    <>
                      <Typography variant="caption" sx={{
                        color: grey[600]
                      }}>{t("general.projectNoAndName")}</Typography>
                      <Typography variant="caption" sx={{
                        color: "primary.main"
                      }}>{projectNoAndName}</Typography>
                    </>
                  )
                }
                {
                  buildingNoAndAddress && (
                    <>
                      <Typography variant="caption" sx={{
                        color: grey[600]
                      }}>{t("general.buildingNoAndObjectAddress")}</Typography>
                      <Typography variant="caption" sx={{
                        color: "primary.main"
                      }}>{buildingNoAndAddress}</Typography>
                    </>
                  )
                }
              </Stack>
            </Stack>
          </Box>
          <Divider />
          <Box >
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider"
              }}>
              <Tabs value={0} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={t("chat.members")} id="tabpanel-0" aria-controls="tab-0" />
              </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0} >
              <Box>
                <Stack
                  direction="row"
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 2
                  }}>
                  <Typography variant='caption'>{participants?.filter(participant => !!participant?.active)?.length ?? 0} {t("chat.members")}</Typography>
                  {(selectedChat?.hasRights && !isReadOnlyChat) && <Button size="small" variant="contained" startIcon={<PersonAddAltSharp />} onClick={(e) => setAnchorEl(e.currentTarget)} >
                    {t("general.addMember")}
                  </Button>}
                </Stack>
                <List dense className={classes.list}>
                  {
                    participants?.length > 0 && participants.filter(p => !!p.active).map((participant, index) => {
                      const userInitials = getNameInitials(participant.name)
                      return (
                        <>
                          <Accordion key={index} {...(!participant?.showAvailability && { expanded : false })}>
                            <AccordionSummary
                              aria-controls={`panel${index}-content`}
                              id={`panel${index}-header`}
                            >
                              <ListItem disablePadding secondaryAction={<ListItemSecondaryAction participant={participant} />} >
                                  <ListItemAvatar>
                                    <ListItemIcon>
                                      {getUserAvatar(selectedChat?.chatId, participant?.loginId, userInitials)}
                                    </ListItemIcon>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={participant?.orgName ? participant.orgName : participant.name}
                                    secondary={participant?.personOrDepartmentName }
                                  />
                              </ListItem>
                            </AccordionSummary>
                            {
                              participant?.showAvailability && <AccordionDetails>
                                <Typography variant='body2'>{t("user.profile.availability.title") + " :"}</Typography>
                                <Stack>
                                  <Typography variant='body2' color="primary" pt={1}>{t("user.profile.availability.morning")}</Typography>
                                  <FormGroup row>
                                    {daysOfWeek.map(({ key, label }) => (
                                      <FormControlLabel
                                        key={`morning-${key}`}
                                        sx={{mb:0}}
                                        control={<Checkbox size="small" checked={participant?.morningAvailability?.includes(key) || false}/>}
                                        label={label}
                                        disabled
                                      />
                                    ))}
                                  </FormGroup>

                                </Stack>
                                <Stack>
                                  <Typography variant='body2' color="primary" pt={1}>{t("user.profile.availability.afternoon")}</Typography>
                                  <FormGroup row >
                                    {daysOfWeek.map(({ key, label }) => (
                                      <FormControlLabel
                                        key={`afternoon-${key}`}
                                        sx={{mb:0}}
                                        control={<Checkbox size="small" checked={participant?.afternoonAvailability?.includes(key) || false} />}
                                        label={label}
                                        disabled
                                      />
                                    ))}
                                  </FormGroup>
                                </Stack>
                              </AccordionDetails>
                            }
                          </Accordion>
                        </>
                      )
                    })
                  }
                </List>
                <Dialog
                  open={isDeleteParticipateDialogOpen}
                  onClose={() => setIsDeleteParticipateDialogOpen(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {t("chat.participant.modal.remove.title", { participantName: currentChatParticipantAction?.chatParticipantName })}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {t("chat.participant.modal.remove.subTitle")}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setIsDeleteParticipateDialogOpen(false)}>{t("general.cancel")}</Button>
                    <Button onClick={() => handleChatParticipantDelete(selectedChat?.chatId, currentChatParticipantAction?.chatParticipantId)} autoFocus loading={currentChatParticipantAction?.isLoading}>
                      {t("general.remove")}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </CustomTabPanel>
          </Box>
        </Box>
        <SelectResponsiblePerson
          onSubmit={(values, setParticipantState) => {
            handleChatParticipantUpdate(values)
            setParticipantState?.((prevState) => ({
              ...prevState,
              relationId: ""
            }))
          }
          }
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          customTitle={t("general.addMember")}
          repairRequestIds={[selectedChat?.repairRequestId]}
          setLoading={(val) => {
            setOrganizationsLoading(val);
            setIsSubmitting(true);
          }}
          loading={organizationsLoading}
          isSubmitting={isSubmitting}
          forRepairRequests
          chatId={selectedChat?.chatId}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
        />
        <Portal>
          <Snackbar
            open={toast.open}
            autoHideDuration={6000}
            onClose={() =>
              setToast({
                open: false,
                message: "",
                type: "error"
              })
            }
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            key={toast.message}
          >
            <Alert
              onClose={() =>
                setToast({
                  open: false,
                  message: "",
                  type: "error"
                })
              }
              severity={toast.type}
            >
              {toast.message}
            </Alert>
          </Snackbar>
        </Portal>
      </Grid>
    </Slide>)
  );
}



const useStyles = makeStyles((theme) => ({
  slideLeft: {
    backgroundColor: theme.palette.background.paper,
    position: "absolute",
    zIndex: 2,
    right: 0,
    height: "100%",
  },
  list: {
    height: '100%',
    flexGrow: 1,
  },
  participantsContainer: {
    maxHeight: "calc(100% - 48px)",
    overflowX: "hidden",
    overflowY: "auto",
    width: "100%",
  },
  toolbar: {
    width: "100%",
    justifyContent: "space-between"
  },
}));

export default ParticipantsListDrawer