import React from 'react';
import { Typography,  Grid2 as Grid } from '@mui/material';

const RowDetails = ({ title, value }) => {
  return (
    (<Grid container spacing={1}>
      <Grid size={{xs:5}}>
        <Typography
          variant="caption"
          component="p"
          sx={{
            color: "text.secondary",
            wordWrap: 'break-word'
          }}>
          {title}
        </Typography>
      </Grid>
      <Grid size={{xs:7}}>
        {!React.isValidElement(value) ? (
          <Typography
            variant="caption"
            component="p"
            sx={{
              color: "text.primary",
              wordWrap: 'break-word'
            }}>
            {value?.toString()}
          </Typography>
        ) : (
          value
        )}
      </Grid>
    </Grid>)
  );
};

export default RowDetails;
