import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

import { getOperator, isFilterModalSame, isFilterValid } from "../components/DataGridV2/utils";
import { debounce, generateUniqueString } from "../_helpers";

const useGridDataModalHandler = ({
  rowIdentifier = "",
  apiCall,
  modifyResponse = (data) => data,
  additionalParams = {},
  sortModal,
  filterModal,
  paginationModel: defaultPaginationModel,
  searchText: defaultSearchText,
  dateTimeFields
}) => {
  const [sortModel, setSortModel] = useState(() => sortModal || []);
  const [filterModel, setFilterModel] = useState(() => filterModal);
  const [loading, setLoading] = useState(true);
  const [loadedRows, setLoadedRows] = useState([]);
  const [searchText, setSearchText] = useState(() => defaultSearchText || "");
  const [totalRecords, setTotalRecords] = useState()
  const theme = useTheme();

  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [paginationModel, setPaginationModel] = useState(() => defaultPaginationModel || {
    pageSize: isTabletOrMobile ? 40 : 50,
    page: 0,
  });
  const apiCode = useRef(null)

  const makePayload = useCallback((args) => {
    const { isFetchAll = false, exportColumns, additionalCondition = [] } = args || {}
    const payload = {
      object: additionalParams,
      index: paginationModel.page,
      rowsPerPage: paginationModel.pageSize,
      sortModel: {},
      conditions: [...additionalCondition],
      exportColumns: exportColumns
    };

    if (isFetchAll) {
      delete payload.index
      delete payload.rowsPerPage
    }

    if (sortModel?.length > 0) {
      payload.sortModel.sortField = sortModel[0]?.field;
      payload.sortModel.orderByAsc =
        sortModel[0]?.sort === "asc";
    }

    let isFilterNull = false;
    if (filterModel?.items?.length > 0) {
      filterModel?.items?.forEach((item) => {
        isFilterNull = ["isEmpty", "isNotEmpty"].includes(item?.operator)
          ? false
          : [null, undefined, ""].includes(item?.value) ||
          item?.value?.length === 0;
        if (!isFilterNull) {
          let value =  Array.isArray(item?.value) ? item?.value : [item?.value]
          if(dateTimeFields.includes(item?.field)){
            if(Array.isArray(value)){
              value = value.map((v)=> new Date(v).toJSON())
            }else{
              value = new Date(value).toJSON()
            }
          }
          payload.conditions.push({
            field: item?.field,
            operator: item?.operator,
            value,
          });
        }
      });

      if (!isFilterNull) {
        payload.logicalOperator =
          filterModel?.logicOperator;
      } else {
        payload.conditions = [];
        payload.logicalOperator = undefined;
      }
    }

    if (searchText) {
      payload.search = searchText;
    }
    return payload;
  }, [
    filterModel?.items,
    filterModel?.logicOperator,
    paginationModel,
    additionalParams,
    searchText,
    sortModel,
  ]);

  const loadServerRows = useCallback(async (isFetchAll = false) => {
    const newApiCode = generateUniqueString();
    setLoading(true);
    try {
      apiCode.current = newApiCode
      const response = await apiCall(makePayload({ isFetchAll }));
      const updatedData = modifyResponse(response?.data?.results);
      setLoadedRows(updatedData);
      setTotalRecords(response?.data?.totalResults)
    } catch (e) {
      console.log({ error: e });
    } finally {
      apiCode.current == newApiCode && setLoading(false);
    }
  }, [apiCall, loadedRows, makePayload, modifyResponse]);

  const getAllRecords = useCallback(async () => {
    try {
      const response = await apiCall(makePayload({ isFetchAll: true }));
      return response
    } catch (e) {
      console.log({ error: e });
    }
  }, [makePayload, apiCall])

  const getAllExportRecords = useCallback(async ({ exportColumns, additionalCondition }) => {
    try {
      const response = await apiCall(makePayload({ isFetchAll: true, exportColumns, additionalCondition }));
      return response
    } catch (e) {
      console.log({ error: e });
    }
  }, [makePayload, apiCall])

  const handleSort = useCallback((newSortModel, cb) => {
    setPaginationModel({ ...paginationModel, page: 0 })
    setSortModel(newSortModel);
    typeof cb === "function" && cb(newSortModel);
  }, [paginationModel]);

  const handleFilter = useCallback((newFilterModal, cb) => {

    const isSame = isFilterModalSame(filterModal, newFilterModal)
    setFilterModel({ ...newFilterModal });
    if (isFilterValid(newFilterModal) && !isSame) {
      setPaginationModel({ ...paginationModel, page: 0 })
      typeof cb === "function" && cb(newFilterModal);
    }
  }, [paginationModel, isFilterModalSame, filterModal]);

  const handleCellFilter = useCallback((quickFilters, cb) => {
    let cloneFilterModal = filterModel ? JSON.parse(JSON.stringify(filterModel)) : {}

    // case 1 where no filter available
    if (!filterModel || filterModel?.items?.length <= 0) {
      cloneFilterModal = {
        items: quickFilters.map(({ fieldId, value, operator, type = "string" }) => ({
          field: fieldId,
          operator: operator || getOperator(type),
          value,
        })),
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }
    else {
      quickFilters.forEach(({ fieldId, value, operator, type = "string" }) => {
        //  case2 some filter available but same fieldId not in there
        const isFilterAlreadyAvailable = filterModel?.items?.find((item) => item.field === fieldId)
        if (!isFilterAlreadyAvailable) {
          cloneFilterModal.items.push({
            field: fieldId,
            operator: operator || getOperator(type),
            value,
          },)
        } else {
          // case3 some filter available and same fieldId in there
          const restModal = cloneFilterModal.items.filter((item) => item.field !== fieldId)
          cloneFilterModal = {
            ...cloneFilterModal,
            items: [...restModal, {
              field: fieldId,
              operator: operator || getOperator(type),
              value,
            }]
          }
        }
      })
    }
    setFilterModel({ ...cloneFilterModal });
    if (isFilterValid(cloneFilterModal)) {
      typeof cb === "function" &&
        cb(cloneFilterModal);
      setPaginationModel({ ...paginationModel, page: 0 })
    }


  }, [filterModel, paginationModel])

  const handleCellSort = useCallback((sortField, cb) => {
    setSortModel(sortField ? [sortField] : [])
    setPaginationModel({ ...paginationModel, page: 0 })
    typeof cb === "function" && cb(sortField ? [sortField] : []);
  }, [sortModel])

  const handleSearch = useCallback(
    debounce((value, cb) => {
      if (searchText !== value) {
        setSearchText(value);
        typeof cb === "function" && cb(value);
        setPaginationModel({ ...paginationModel, page: 0 })
      }
    }, 1000),
    [searchText, paginationModel]
  );

  const resetData = useCallback(() => {
    setPaginationModel({ ...paginationModel, page: 0 })
    setLoading(true)
  }, []);


  const rows = useMemo(
    () => loadedRows.map((item) => ({ ...item, id: item?.[rowIdentifier] })),
    [loadedRows]
  );

  const onPaginationModelChange = useCallback((paginationModel, cb) => {
    setLoading(true);
    setPaginationModel(paginationModel)
    typeof cb === "function" && cb(paginationModel);
  }, [])

  useEffect(() => {
    loadServerRows()
  }, [paginationModel])

  return {
    getAllRecords,
    loading,
    loadedRows: rows,
    sortModel,
    handleSort,
    handleCellSort,
    filterModel,
    handleFilter,
    resetData,
    handleSearch,
    searchText,
    handleCellFilter,
    totalRecords,
    paginationModel,
    onPaginationModelChange,
    getAllExportRecords
  };
};

export default useGridDataModalHandler;
