
import { Box, Stack,  Typography } from "@mui/material"
import { grey } from "@mui/material/colors"
import { useState } from "react"
import {  getScriveDocHistory,} from "../../../apis/contractApis"
import { useEffect } from "react"
import { formatDate } from "../../../_helpers"
import { useTranslation } from "react-i18next"

const TimeLine = ({contractDetails}) => {
  const contractId = contractDetails?.contractId
  const {t , i18n} = useTranslation()
  const [scriveDocHistory, setScriveDocHistory] = useState({
    isLoading:false,
    data:[]
  })

  useEffect(() => {
      const getScriveDocHistoryData = async()=>{
        try{
          setScriveDocHistory({...scriveDocHistory,isLoading:true})
          if(contractId){
            const response = await getScriveDocHistory(contractId ,i18n.language )
            setScriveDocHistory({isLoading:false, data:response.data?.events})
          }
        }catch(e){
          setScriveDocHistory({...scriveDocHistory ,isLoading:false})
          console.log(e)
        }
      }
      getScriveDocHistoryData()
  }, [contractId])

  return <>
    <Box
      sx={{
        mt: 5,
        mx: 5
      }}>
      <Typography variant='h5' sx={{
        mb: 2
      }}>{t("contract.details.timeLine.title")}</Typography>
      <Typography
        variant='body1'
        component='span'
        sx={{
          fontWeight: "500",
          borderBottom: "2px solid",
          color:'primary.main'
        }}>{t("general.events")}</Typography>
      <Stack>
        <Stack >
          {scriveDocHistory.data?.map((singleScriveDoc, index) => {
            const {party = "",text="", time="", status =""} = singleScriveDoc
            return (
              (<Stack style={{ borderBottom: `1px solid ${grey[400]}` }} key={index} sx={{
                mt: 3
              }}>
                <Typography variant="h6">{party}</Typography>
                <Typography variant="body1" sx={{
                  lineHeight: "2"
                }}>{status}</Typography>
                <Typography variant="body1" sx={{
                  lineHeight: "2"
                }}>{formatDate(new Date(time), true)}</Typography>
                <Typography
                  variant="body1"
                  sx={{
                    lineHeight: "1.5",
                    mb: 1.5
                  }}>{text}</Typography>
              </Stack>)
            );
          })}
        </Stack>
      </Stack>

    </Box>
  </>;
}

export default TimeLine