import { Box, Card, CardContent, ListItem, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: 0,
  color: theme.palette.grey[600],
  listStyleType: "disc",
  display: "list-item",
  [theme.breakpoints.up("xl")]: {
    padding: 4
  }
}));

const DesktopStepsToUploadDataFilesCard = () => {
  const { t } = useTranslation();

  return (
    (<Card sx={{ display:"flex", flexDirection:"column" }}>
      <Box
        sx={{
          color: "white",
          bgcolor: "primary.main",
          p: 2
        }}><Typography variant='body1' sx={{
        fontSize: 18
      }}>{t("settings.import.instructions.title")}</Typography></Box>
      <CardContent sx={{display:"flex", flexDirection:"column",flex:1, justifyContent:"space-evenly"}}>
        {
          [...Array(10)].map((_,index) => {
            return <StyledListItem key={index}>{t(`settings.import.instruction.${index}`)}</StyledListItem>
          })
        }
      </CardContent>
    </Card>)
  );
}

export default DesktopStepsToUploadDataFilesCard