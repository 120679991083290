import { Divider, alpha, Hidden, Icon, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import RepairRequests from '../../components/RepairRequests';
import BasicDetails from '../../components/BasicDetails'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import MeterReading from '../../components/MeterReading';
import Remarks from '../../components/Remarks';
import {  Grid2 as Grid } from '@mui/material';
import { Close, ModeCommentOutlined, WbIncandescentOutlined } from '@mui/icons-material';
import BottomDrawer from '../../components/BottomDrawer';
import { surveyActions } from '../../../../_actions';
import { groupBy, statusColors } from '../../../../_helpers';
import clsx from "clsx";

export default function DeliveriesDetail({ isDownloadedSurvey, canGoBack, openRepairRequestMap, setOpenRepairRequestMap, setDeliveryDetail, setIsOpenSignature, setId, ...props }) {
  const classes = useStyles()
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  //Redux State
  const { selected, all } = useSelector(state => state.buildings);
  const app = useSelector(state => state.app);
  const { selectedInspection, inspectionLoading, repairRequests, loadingRepairRequests } = useSelector(state => state.surveying);
  const [bottomDrawer, setBottomDrawer] = useState({ open: false, key: '' });
  const requestsStatus = repairRequests && repairRequests.length && groupBy(repairRequests, "status");
  const [requests, setRequests] = useState(repairRequests);
  const theme = useTheme();
  const matchWidthDownXs = useMediaQuery(theme.breakpoints.down('sm'));
  const matchWidthDownMd = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    setRequests(repairRequests.map(p => {
      const building = all.find(b => b.buildingId === p.buildingId);
      if (building) p.buildingNoExtern = building.buildingNoExtern;
      return p;
    }));
  }, [repairRequests]);
  const data = [
    { title: 'survey.defectsList', key: 'gebrekenlijst', description: '50', Icon: 'construction', view: matchWidthDownXs },
    { title: 'survey.meterReadings', key: 'meterkast', description: selectedInspection?.cityHeating ? '7' : '6', Icon: WbIncandescentOutlined, view: matchWidthDownMd },
    { title: 'survey.externalNotes', key: 'opmerkingen', description: '', Icon: ModeCommentOutlined, view: matchWidthDownMd },
  ]
  useEffect(() => {
    if (selected && !openRepairRequestMap) {
      dispatch(surveyActions.getSurveyDetailAction(params.inspectionId, false));
      setId(params.inspectionId);
      dispatch(surveyActions.getRepairRequests(params.inspectionId));
    }
  }, [selected, canGoBack]);



  useEffect(() => {
    setDeliveryDetail(true);
    return () => {
      setDeliveryDetail(false);
      setId(null);
    };
  }, [])

  const BottomDrawerHandler = (key) => {
    setBottomDrawer({ open: true, key })
  }

  return (
    (<div className={clsx(classes.layoutContainer, (inspectionLoading) && classes.layoutContainerHeight)}>
      {inspectionLoading ?
        <div className={classes.layoutLoading}>
          <CircularProgress size={20} />
        </div>
        : <>
          <Hidden lgUp>
            <BottomDrawer open={bottomDrawer.open && bottomDrawer.key !== 'gebrekenlijst'} close={() => setBottomDrawer({ open: false })}>
              <>
                <Grid className={classes.stickyHeader}>
                  <div className={classes.drawerHeader}>
                    <Typography className={classes.surveyMomentCardTitle}>{t(`${bottomDrawer.key === 'meterkast' ? 'survey.meterReadings' : 'survey.externalNotes'}`)}</Typography>
                    <Close className={classes.drawerIcon} onClick={() => setBottomDrawer({ open: false })} />
                  </div>
                  <Divider />
                </Grid>
                {bottomDrawer.key === 'meterkast' ?
                  <Grid size={{xs: 12}}>
                    <MeterReading deliveriesDetail data={selectedInspection} />
                  </Grid>
                  :
                  <Grid size={{xs: 12}}>
                    <Remarks selectedInspection={selectedInspection} deliveriesDetail />
                  </Grid>
                }
              </>
            </BottomDrawer>
          </Hidden>
          <BasicDetails
            onlyOfflinePDFView={isDownloadedSurvey}
            showPdfIcon
            setIsOpenSignature={setIsOpenSignature}
            data={selectedInspection} />
          <Hidden lgDown>
            <div className={classes.layoutSubContainer}>
              <Grid container spacing={1}>
                <Grid
                  size={{
                    xs: 12,
                    sm: 4,
                    md: 4,
                    lg: 4,
                    xl: 4
                  }}>
                  <MeterReading deliveriesDetail data={selectedInspection} />
                </Grid>
                <Grid
                  size={{
                    xs: 12,
                    sm: 8,
                    md: 8,
                    lg: 8,
                    xl: 8
                  }}>
                  <Remarks selectedInspection={selectedInspection} deliveriesDetail />
                </Grid>
              </Grid>
            </div>
          </Hidden>

          <Hidden lgUp>
            <Grid container spacing={1}>
              {data && data.map((p) => {
                return p.view && (
                  <Grid
                    size={{
                      xs: 12,
                      sm: 6,
                      md: 6
                    }}>
                    <div className={classes.layoutSubContainer} key={p.id}>
                      <Paper className={classes.cardPaper} elevation={0} onClick={() => BottomDrawerHandler(p.key)}>
                        <div className={classes.cardLayout}>
                          <Typography className={classes.cardTitle}>{t(p.title)}</Typography>
                          <Typography className={classes.cardSubTitle}>{
                            p.key === 'gebrekenlijst' ?
                              <div className={classes.statusContainer}>
                                <div className={classes.status}>
                                  <Typography className={classes.cardStatusTitle}>{t("general.total")}</Typography>
                                  <div className={classes.cardStatusSquare}>
                                    <Typography className={classes.cardStatusNumber}>{repairRequests && repairRequests.length}</Typography>
                                  </div>
                                </div>
                                {Object.keys(requestsStatus).map(key => (
                                  <div key={key} className={classes.status}>
                                    <Typography className={classes.cardStatusTitle}>{t(key)}</Typography>
                                    <div className={classes.cardStatusSquare} style={{ backgroundColor: statusColors[key] }}>
                                      <Typography className={classes.cardStatusNumber}>{requestsStatus[key].length}</Typography>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              :
                              t(p.description)
                          }</Typography>
                        </div>
                        <div className={classes.square}>
                          {typeof p.Icon !== "string" ? <p.Icon className={classes.bugIcon} /> : <Icon fontSize="default" className={classes.bugIcon}>{p.Icon}</Icon>}
                        </div>
                      </Paper>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Hidden>
          {selectedInspection && <div className={classes.layoutSubContainer}>
            <RepairRequests
              setOpenRepairRequestMap={setOpenRepairRequestMap}
              selectedInspection={selectedInspection}
              keyPath={'inspectionId'}
              loading={loadingRepairRequests}
              inspection
              bottomDrawer={bottomDrawer}
              bottomDrawerClose={() => setBottomDrawer({ open: false })}
              data={requests}
              selectedApp={app}
              title={'survey.defectsList'}
              buttonText={t('survey.defects')} />
          </div>}
        </>}
    </div>)
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiButtonBase-root:focus': {
      outline: 0,
    },
  },
  drawerHeader: {
    display: 'flex',
    padding: theme.spacing(1),
    justifyContent: 'space-between',
    // borderBottom: `1px solid ${theme.palette.grey[300]}`,
    alignItems: 'center',
    // marginBottom: 10,
    paddingBottom: 7
  },
  surveyMomentCardTitle: {
    fontSize: 12,
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 16,
    },
    fontWeight: 'bold',
    color: theme.palette.common.black,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  drawerIcon: {
    fill: theme.palette.grey[300],
    cursor: 'pointer'
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    background: theme.palette.common.white,
    zIndex: 1,
  },
  square: {
    height: 60,
    width: 60,
    background: alpha(theme.palette.primary.main, 0.05),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50
  },
  bugIcon: {
    fontSize: 40,
    fill: theme.palette.grey[500],
    color: theme.palette.grey[500],
  },
  cardTitle: {
    fontSize: 12,
    [theme.breakpoints.only('xs')]: {
      fontSize: 14,
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: 16,
    },
    color: theme.palette.grey[700],
    fontWeight: 'bold',
    marginBottom: 7
  },
  cardSubTitle: {
    fontSize: 18,
    color: theme.palette.grey[700],
    fontWeight: 'bold'
  },
  cardLayout: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardPaper: {
    display: 'flex',
    justifyContent: "space-between",
    flexGrow: 1,
    alignItems: 'center',
    padding: theme.spacing(2),
    cursor: 'pointer'
  },
  layoutLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  layoutContainer: {
    padding: theme.spacing(0, 2, 2, 2),
    height: 'auto',
  },
  layoutContainerHeight: {
    height: '100% !important'
  },
  layoutSubContainer: {
    marginTop: theme.spacing(1),
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;'
  },
  statusContainer: {
    display: "flex"
  },
  status: {
    marginRight: 5
  },
  cardStatusTitle: {
    color: theme.palette.grey[700],
    fontWeight: 'bold',
    fontSize: 10,
  },
  cardStatusNumber: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: 15,
  },
  cardStatusSquare: {
    width: 30,
    height: 25,
    background: "#006ED8",
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
}))
