import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CircularProgress,
  Container,
  Stack,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getLocalStorageItem,
  groupBy,
  repairRequestStatusValue,
  setLocalStorageItem,
} from "../../_helpers";
import { getRRDashboardCounts } from "../../apis/surveyingApis";
import { apps, LOCAL_STORAGE_GRID_KEYS, userAccountRoleConstants } from "../../_constants";
import DataGridV2 from "../../components/DataGridV2";
import { LabelColumnV2, LinkColumnV2 } from "../../components/DataGridV2/DataColumnsV2";
import { getColumnsOrderFromLocalStorage, getGridContextMenuList } from "../../_helpers/getGridContextMenuList";
import { useGridApiRef } from "@mui/x-data-grid-pro";

const Dashboard = (props) => {
  const { history } = props;
  const { t, i18n } = useTranslation();
  const [rowsData, setRowsData] = useState([]);
  const classes = useStyles();
  const { all = [], loading: buildingLoading } = useSelector(
    (state) => state.buildings
  );
  const app = useSelector((state) => state.app);
  const isAfterCareApp = app === apps.aftercare;

  const [isAfterCareEmployeeOrSiteManager, setIsAfterCareEmployeeOrSiteManager] = useState(false);
  const [paginationModel, setPaginationModel] = useState(() => getLocalStorageItem(
    LOCAL_STORAGE_GRID_KEYS.DASHBOARD_PAGINATION[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"], undefined
  ))
  const [isRowsDataLoading, setIsRowsDataLoading] = useState(false)
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const apiRef = useGridApiRef();

  useEffect(() => {
    if (
      all.every(
        (a) =>
          !a.roles ||
          a.roles.length === 0 ||
          a.roles.every(
            (b) =>
              b === userAccountRoleConstants.subContractorSupervisor ||
              b === userAccountRoleConstants.subContractorEmployee
          )
      )
    ) {
      if (app === apps.aftercare) {
        history.push("/home")
      } else if (app === apps.constructionQuality) {
        history.push("/kwaliteitsborging");
      }
    } else
      setIsAfterCareEmployeeOrSiteManager(
        all.some(
          (a) =>
            a.roles &&
            (
              a.roles.includes(userAccountRoleConstants.aftercareEmployee) ||
              a.roles.includes(userAccountRoleConstants.siteManager)
            )
        )
      );
  }, [all]);

  useEffect(() => {
    setIsRowsDataLoading(true)
    const projectsGroup = groupBy(all, "projectId");
    const projects = Object.keys(projectsGroup);
    if (!buildingLoading) {
      (async () => {
        try {
          const allProjects = (await getRRDashboardCounts(app, projects)).data ?? [];
          const dashboardRowsData = [];
          for (let i = 0; i < allProjects?.length; i++) {
            const singleProject = allProjects[i];
            const { new: newRepairRequests, projectId, projectNo, projectName, noOfObjects, inProgress, overDue, workOrdersOverdue, type, constructionType, phase, city, workOrdersInProgress } = singleProject || {}

            dashboardRowsData.push({
              id: projectId,
              objects: noOfObjects,
              projectName,
              newRepairRequests: newRepairRequests,
              address: city,
              inProgressRepairRequests: inProgress,
              overdueRepairRequests: overDue,
              openWorkOrders: workOrdersInProgress,
              overdueWorkOrders: workOrdersOverdue,
              projectId,
              type: t(`project.type.${type}`),
              constructionType: t(`project.constructionType.${constructionType}`),
              phase,
              projectNo
            });
          }
          setRowsData(dashboardRowsData)
          setIsRowsDataLoading(false)
        } catch (err) {
          console.error(err);
          setIsRowsDataLoading(false)
        }
      })();
    }
    return () => {
      setRowsData([]);
    };
  }, [history.location.key, all, app, buildingLoading, i18n.language]);

  const getSelectedProjectAndPathname = (projectId, isWorkOrderColumnClicked = false) => {
    const selectedProject = all.filter(
      (x) => x.projectId === projectId
    )[0];
    let pathname = "/nazorg"
    if (app === apps.aftercare) {
      pathname += isWorkOrderColumnClicked ? "/werkbonnen" : "/meldingen";
    } else if (app === apps.constructionQuality) {
      pathname = `/werk/${selectedProject.projectNo}` + (isWorkOrderColumnClicked ? "/werkbonnen" : "/kwaliteitsborging");
    }
    return { selectedProject, pathname }
  }

  const getOptions = getGridContextMenuList({
    filterKey: LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_FILTER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
    sortKey: LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_SORT[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
    i18n,
    t,
    apiRef,
    mode: "client"
  })

  const columns = useMemo(() => {
    const baseColumns  = [
      {
        field: "projectNo",
        headerName: t("general.projectNumber"),
        flex: 1,
        download: true,
        renderCell: (params) => {
          const rowData = params.row;
          const projectBuildings = all.filter(building => building.projectId === rowData.projectId) 
          const isSiteManagerOrAftercareEmployeeRoleAvailable  = projectBuildings.some(building=>building.roles && (building.roles.includes(userAccountRoleConstants.aftercareEmployee) || building.roles.includes(userAccountRoleConstants.siteManager))) 
          return  <>{isSiteManagerOrAftercareEmployeeRoleAvailable ? <LinkColumnV2 label={rowData.projectNo} to={`/dashboard/${rowData.projectId}`} /> : <LabelColumnV2 label={rowData.projectNo} />}</>
        },
      },
      {
        field: "projectName",
        headerName: t("general.projectName"),
        type: "singleSelect",
        valueOptions: rowsData.map(row => row.projectName),
        flex: 1,
        download: true,
        renderCell: (params) => {
          const rowData = params.row;
          const projectBuildings = all.filter(building => building.projectId === rowData.projectId)
          const isSiteManagerOrAftercareEmployeeRoleAvailable = projectBuildings.some(building => building.roles && (building.roles.includes(userAccountRoleConstants.aftercareEmployee) || building.roles.includes(userAccountRoleConstants.siteManager)))
          const cellSubMenuOptions = {
            options: getOptions({ field: "projectName", isQuickFilterDisplay: Boolean(rowData.projectName) }), extraData: {
              fieldName: "projectName",
              type: "singleSelect"
            }
          }

          return <>{isSiteManagerOrAftercareEmployeeRoleAvailable ? <LinkColumnV2 request={rowData} cellSubMenuOptions={cellSubMenuOptions} label={rowData.projectName} to={`/dashboard/${rowData.projectId}`} /> : <LabelColumnV2 label={rowData.projectName} cellSubMenuOptions={cellSubMenuOptions}
          />}</>
        },
      },
      {
        field: "address",
        headerName: t("general.address.city"),
        flex: 1,
        download: true,
        renderCell: (params) => {
          const rowData = params.row;
          return (
            <LabelColumnV2
              label={rowData.address}
              cellSubMenuOptions={{ options: getOptions({ field: "address", isQuickFilterDisplay: Boolean(rowData.address) }), extraData: { fieldName: "address" } }}
              request={rowData}
            />
          );
        },
      },
      {
        field: "objects",
        type: 'number',
        headerName: t("general.objects"),
        flex: 1,
        download: true,
        renderCell: (params) => {
          const rowData = params.row;
          return (
            <LabelColumnV2
              label={rowData.objects}
              cellSubMenuOptions={{ options: getOptions({ field: "objects", isQuickFilterDisplay: Boolean(rowData.objects?.toString()) }), extraData: { fieldName: "objects", type : "number" } }}
              request={rowData}
              isNumeric
              />
          );
        },
      },
      {
        field: "newRepairRequests",
        type: 'number',
        headerName: t("site.manager.new.repairrequests"),
        flex: 1,
        download: true,
        renderCell: (params) => {
          const rowData = params.row;
          const { selectedProject, pathname } = getSelectedProjectAndPathname(rowData.projectId)
          return <LinkColumnV2 
           label={rowData.newRepairRequests}
           request={rowData} 
           to={{ pathname, state: { filter: "newRepairRequests", selectedProject, status: [repairRequestStatusValue.NEW] } }}
           cellSubMenuOptions={{ options: getOptions({ field: "newRepairRequests", isQuickFilterDisplay: Boolean(rowData.newRepairRequests?.toString()) }),  extraData: { fieldName: "newRepairRequests", type : "number"} }}
           isNumeric
           />
        },
      },
      {
        field: "inProgressRepairRequests",
        type: 'number',
        headerName: t("site.manager.inprogress.repairrequests"),
        flex: 1,
        download: true,
        renderCell: (params) => {
          const rowData = params.row;
          const { selectedProject, pathname } = getSelectedProjectAndPathname(rowData.projectId)
          return  <LinkColumnV2
          label={rowData.inProgressRepairRequests}
          to={{ pathname, state: { filter: "inProgressRepairRequests", selectedProject, status: [repairRequestStatusValue.IN_PROGRESS] } }}
          cellSubMenuOptions={{
            options: getOptions({ field: "inProgressRepairRequests", isQuickFilterDisplay: Boolean(rowData.inProgressRepairRequests?.toString()) }),
            extraData: { fieldName: "inProgressRepairRequests", type : "number" }
          }}
          isNumeric
          request={rowData}
        />
        },
      },
      {
        field: "overdueRepairRequests",
        type: 'number',
        headerName: t("site.manager.overdue.repairrequests"),
        flex: 1,
        download: true,
        hidden: !isAfterCareEmployeeOrSiteManager,
        renderCell: (params) => {
          const rowData = params.row;
          const { selectedProject, pathname } = getSelectedProjectAndPathname(rowData.projectId)
          return <LinkColumnV2 color="error" 
          label={rowData.overdueRepairRequests}
          request={rowData}
           to={{ pathname, state: { filter: "overdueRepairRequests", selectedProject, isOverDue: true } }} 
           cellSubMenuOptions={{ options: getOptions({ field: "overdueRepairRequests", isQuickFilterDisplay: Boolean(rowData.overdueRepairRequests?.toString()) }),  extraData: { fieldName: "overdueRepairRequests", type : "number"} }}
           isNumeric
          />
        },
      },
      {
        field: "openWorkOrders",
        type: 'number',
        headerName: t("site.manager.open.work.orders"),
        flex: 1,
        download: true,
        hidden: !isAfterCareEmployeeOrSiteManager,
        renderCell: (params) => {
          const rowData = params.row;
          const { selectedProject, pathname } = getSelectedProjectAndPathname(rowData.projectId, true)
          return <LinkColumnV2 label={rowData.openWorkOrders}
           to={{ pathname, state: { filter: "openWorkOrders", selectedProject } }}
           request={rowData}
           cellSubMenuOptions={{
            options: getOptions({ field: "openWorkOrders", isQuickFilterDisplay: Boolean(rowData.openWorkOrders?.toString()) }),
            extraData: { fieldName: "openWorkOrders", type : "number" }
          }}
           isNumeric
          />
        },
      },
      {
        field: "overdueWorkOrders",
        type: 'number',
        headerName: t("site.manager.overdue.work.orders"),
        flex: 1,
        download: true,
        hidden: !isAfterCareEmployeeOrSiteManager,
        renderCell: (params) => {
          const rowData = params.row;
          const { selectedProject, pathname } = getSelectedProjectAndPathname(rowData.projectId, true)
          return <LinkColumnV2 color="error"
           label={rowData.overdueWorkOrders}
           to={{ pathname, state: { filter: "overdueWorkOrders", selectedProject } }} 
           request={rowData}
            cellSubMenuOptions={{
            options: getOptions({ field: "overdueWorkOrders", isQuickFilterDisplay: Boolean(rowData.overdueWorkOrders?.toString()) }),
            extraData: { fieldName: "overdueWorkOrders", type : "number" }
          }}
           isNumeric
          />
        },
      },
      {
        field: "type",
        headerName: t("general.type"),
        flex: 1,
        download: true,
        renderCell: (params) => {
          const rowData = params.row;
          return (
            <LabelColumnV2
              label={rowData.type}
              cellSubMenuOptions={{ options: getOptions({ field: "type", isQuickFilterDisplay: Boolean(rowData.address) }), extraData: { fieldName: "type" } }}
              request={rowData}
            />
          );
        },
      },
      {
        field: "constructionType",
        headerName: t("general.project.constructionType"),
        flex: 1,
        download: true,
        renderCell: (params) => {
          const rowData = params.row;
          return (
            <LabelColumnV2
              label={rowData.constructionType}
              cellSubMenuOptions={{ options: getOptions({ field: "constructionType", isQuickFilterDisplay: Boolean(rowData.constructionType) }), extraData: { fieldName: "constructionType" } }}
              request={rowData}
            />
          );
        },
      },
      {
        field: "phase",
        headerName: t("general.phase"),
        flex: 1,
        download: true,
        renderCell: (params) => {
          const rowData = params.row;
          return (
            <LabelColumnV2
              label={rowData.phase}
              cellSubMenuOptions={{ options: getOptions({ field: "phase", isQuickFilterDisplay: Boolean(rowData.phase) }), extraData: { fieldName: "phase" } }}
              request={rowData}
            />
          );
        },
      }
    ];
    return baseColumns 
  }, [getSelectedProjectAndPathname, isAfterCareApp, isAfterCareEmployeeOrSiteManager, t])

  const onPaginationModelChange = useCallback((newPaginationModel) => {
    setPaginationModel(newPaginationModel)
    setLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.DASHBOARD_PAGINATION[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"],
      newPaginationModel
    );
  }, [isAfterCareApp])

  if (buildingLoading)
    return (
      (<Stack
        sx={{
          height: "100%",
          justifyContent: "center",
          alignItems: "center"
        }}>
        <CircularProgress size={25} />
      </Stack>)
    );

  return (
    <Container maxWidth={false} className={classes.mainContainer}>
      <DataGridV2
        localSearchIdentifier={LOCAL_STORAGE_GRID_KEYS.DASHBOARD_SEARCH_TEXT[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"]}
        localColumnFilterIdentifier={LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_FILTER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"]}
        localColumnSortIdentifier={LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_SORT[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"]}
        localColumnOrderIdentifier={LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_ORDER[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"]}
        localColumnWidthIdentifier={LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_WIDTH[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"]}
        localColumnVisibilityIdentifier={LOCAL_STORAGE_GRID_KEYS.DASHBOARD_COLUMN_VISIBILITY[isAfterCareApp ? "AFTERCARE" : "CONSTRUCTION_QUALITY"]}
        apiRef={apiRef}
        loading={buildingLoading || isRowsDataLoading}
        checkboxSelection
        rows={rowsData}
        columns={columns}
        pagination
        {...(!!paginationModel && { paginationModel })}
        onPaginationModelChange={onPaginationModelChange}
        toolbarProps={{
          title: t("general.projects"),
          selectedRows: rowSelectionModel
        }}
        filterMode="client"
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
      />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
}));

export { Dashboard };
