import React, { useEffect, useState } from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { Close, CreateNewFolder } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent,  Grid2 as Grid, Button, IconButton, TextField, Typography, ListItemText ,Autocomplete, createFilterOptions}  from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Axios from 'axios';


const { webApiUrl } = window.appConfig;

const filter = createFilterOptions();

export default function AddDrawingLocation({ state, existingFloors, buildingId, canOpen, onSave = () => { }, ...props }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (open) {
      setValue(state)
      setError(null);
    }
  }, [state, open]);

  useEffect(() => {
    if (value) {
      const isExist = existingFloors && existingFloors.find(p => p.name === value.name);
      setError(isExist ? "drawing.dossier.create.title.exist" : value.name.length > 100 ? "drawing.dossier.create.title.too.long" : null);
    }
  }, [value, existingFloors]);

  useEffect(() => {
    if (open) {
      Axios.get(`${webApiUrl}api/Dossier/GetDrawingLocationNames`).then(res => {
        setOptions(res.data.filter(p => buildingId ? p.forBuilding : p.forProject));
      }).catch(er => {
        console.log(er)
      });
    }
  }, [open]);

  useEffect(() => {
    if (canOpen) {
      setOpen(canOpen);
    }
  }, [canOpen]);


  const modalTogglerHandler = (data, dataIndex) => {
    setOpen(p => !p);
  };


  const DialogTitle = (props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {open ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              modalTogglerHandler();
              onSave();
            }}
            size="large">
            <Close className={classes.closeIcon} />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };


  return <>
    <Grid className={classes.thumnailContainer} container onClick={() => setOpen(p => ({ ...p, modal: true }))} sx={{
      justifyContent: "center"
    }}>
      <div className={clsx(classes.dropzone, classes.customDropStyle)}>
        <CreateNewFolder className={classes.createIcon} />
      </div>
    </Grid>
    {!!open ?
      <Dialog onClose={() => { modalTogglerHandler(); onSave(); }} aria-labelledby="customized-dialog-title" className={classes.dataSyncDialog} open={open}>
        <DialogTitle classes={classes} id="customized-dialog-title" onClose={() => { modalTogglerHandler(); onSave(); }}>
          <Typography className={classes.dialogHeaderTitle}>{t('drawing.dossier.create.folder.title')}</Typography>
        </DialogTitle>
        <DialogContent dividers className={classes.dataSyncDialogContainer}>
          <Grid size={{xs: 12}}>
            <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  setValue({
                    name: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  setValue({
                    name: newValue.inputValue,
                  });
                } else {
                  setValue(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    name: `${t("drawing.dossier.create.folder.title")} "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="select-or-creatable-folder-input"
              options={options}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.name;
              }}
              renderOption={(props, option) => (
                <ListItemText {...props}>
                    {option.name}
                </ListItemText>
              )}
              freeSolo
              renderInput={(params) => {
                return (
                  <TextField {...params} label={t("drawing.dossier.create.title.placeholder")} error={!!error} helperText={t(error)} variant="outlined" />
                )
              }}
            />
          </Grid>
          <div className={classes.recordButtonContainer}>
            <Button onClick={() => { modalTogglerHandler(); onSave(); }}
              className={classes.dialogButtonSecondary}>
              {t('general.cancel')}
            </Button>
            <Button
              disabled={!!error}
              onClick={() => {
                onSave(value);
                modalTogglerHandler();
              }}
              className={classes.dialogButtonPrimary}
            >
              {t('general.save')}
            </Button>
          </div>
        </DialogContent>
      </Dialog> : null}
  </>;
}

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiButtonBase-root:focus': {
      outline: 0,
    },
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  dialogHeaderTitle: {
    fontSize: '14px !important',
    color: theme.palette.common.white
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  closeIcon: {
    fill: theme.palette.common.white,
    fontSize: 24,
  },

  grow: {
    flexGrow: 1
  },
  bold: {
    fontWeight: "bold"
  },
  mainContainer: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    padding: 0
  },
  dataTable: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '& > .MuiPaper-root': {
      flex: '0 1 auto',
    },
    '& > .MuiToolbar-root.MuiToolbar-regular': {
      display: 'flex',
      '& > div': {
        padding: 0,
        textAlign: 'right',
        flex: '1 0 auto',
        '& .MuiTypography-root': {
          textAlign: 'left'
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(0),
      '&.MuiTableCell-paddingCheckbox': {
        paddingLeft: theme.spacing(0.5),
        '& > div': {
          justifyContent: 'center'
        },
        '& .MuiCheckbox-root': {
          padding: theme.spacing(0.25)
        }
      },
      '&.MuiTableCell-head': {
        fontWeight: 'bold'
      }
    },
  },
  fullWidth: {
    width: '100%'
  },
  dataSyncDialog: {
    '& .MuiPaper-rounded': {
      borderRadius: 10,
      minWidth: '384px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '270px',
      }
    }
  },
  dataSyncDialogContainer: {
    padding: theme.spacing(2)
  },
  recordButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px 0 10px 0',
    columnGap: 10

  },
  recordButton: {
    padding: theme.spacing(0.8, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.8, 5),
    },
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(0.8, 3),
    },
  },
  dialogButtonPrimary: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 80,
      padding: theme.spacing(0.3),
    },
    minWidth: 100,
    width: '100%',
    background: theme.palette.primary.main,
    padding: theme.spacing(0.875, 1.25),
    color: `${theme.palette.common.white} !important`,
    '&:hover': {
      background: theme.palette.primary.main,
    }
  },
  dialogButtonSecondary: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 80,
      padding: theme.spacing(0.3),
    },
    minWidth: 100,
    width: '100%',
    background: theme.palette.grey[500],
    padding: theme.spacing(0.875, 1.25),
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.grey[500],
    }
  },
  checkboxTitle: {
    marginBottom: 0,
    '& .MuiTypography-root': {
      fontSize: 12,
      fontWeight: 'bold',
      color: theme.palette.common.black

    },
  },
  errorMessage: {
    fontSize: 12
  },
  thumnailContainer: {
    width: 135,
    height: 135,
    cursor: 'pointer',
    minHeight: 120,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  dropzone: {
    padding: 0,
    height: '100%',
  },
  createIcon: {
    // fontSize: 85,
    // color: theme.palette.grey[500]
    opacity: 0.3,
    height: 64,
    width: 64
  },
  customDropStyle: {
    width: '100%',
    border: '2px dashed rgb(187, 186, 186)',
    display: ' flex',
    fontSize: '16px',
    alignItems: 'center',
    borderRadius: '5px',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
}));