import {  CalendarToday, Clear, DateRange,  } from '@mui/icons-material';
import {  Box,  Checkbox,  Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, IconButton, InputLabel, MenuItem, Select, Stack, Switch, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { getBanks, getIndustries, getPersonMiddleNames, getPersonTitleBehind, getPersonTitleFor,} from '../../../apis/profileApis';
import { useState } from 'react';
import { deNormalizePhoneNumber, } from '../utils';
import { DatePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import { userAccountTypeConstants } from '../../../_constants';
import { useSelector } from 'react-redux';
import i18n from '../../../i18n';
import { getDayName } from '../../../_helpers';
import RichTextEditor from '../../Chat/RichTextEditor';
import dayjs from 'dayjs';

const ProfileBody = ({ profileData, updateProfileData, errors, clearError }) => {
  const { t } = useTranslation();
  const { user  } = useSelector((state) => state.authentication);
  const theme = useTheme();
  const { userAvailabilityInfo } = profileData || {}

  const [dropdownValues, setDropdownValues] = useState({})

  useEffect(() => {
    (async () => {
      const calls = [getPersonTitleFor(), getPersonMiddleNames(), getPersonTitleBehind(), getBanks(), getIndustries()]
      const results = await Promise.all(calls);

      setDropdownValues({ titleFor: results[0].data, middleNames: results[1].data, titleBehind: results[2].data, banks: results[3].data, industries: results[4].data })

    })()
  }, [])

  useEffect(() => {
    if(Object.keys(errors).length > 0){
      const errorElement = document.querySelector('.Mui-error');
      (errorElement?.parentElement ?? errorElement)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [errors])

  const formatAddress = (addressObject) => {
    const { houseNo = "", houseNoAddition = "", place = "", postcode = "", street = "" } = addressObject ?? {}
    let address = ""
    if (street) address += `${street} `
    if (houseNo) address += `${houseNo} `
    if (houseNoAddition) address += `${houseNoAddition} `
    if (postcode) address += `, ${postcode}`
    if (place) address += `${place}`
    return address
  }

  const isDisabledProfile = useMemo(() => profileData?.lastName?.toUpperCase()?.replaceAll(".", "") === "NVT", [profileData?.lastName])

  const daysOfWeek = [...Array(5)].map((_, index) => {
    return {
      key: index + 1,
      label: getDayName({ dayOfWeek: index + 1, locale: i18n.language })
    }
  })
  const handleMorningOrAfternoonAvailability = (e, key) => {
    const { name, checked } = e.target;
    const updatedSchedule = new Set(userAvailabilityInfo?.[name] || []);
    checked ? updatedSchedule.add(key) : updatedSchedule.delete(key);
    updateProfileData({ 
        userAvailabilityInfo: { 
            ...userAvailabilityInfo,
            [name]: [...updatedSchedule] 
        }
    });
};

  return (<>
    <Stack
      sx={{
        rowGap: 2.5,
        mt: 2.5
      }}>
      <Box>
        <Typography variant='body2' sx={{
          fontWeight: 600
        }}>{t("general.person")}</Typography>
        <Typography variant='caption'>{t("general.general")}</Typography>
        <Divider sx={{ mt: 1 }} />
      </Box>
      <TextField
        fullWidth
        label={t("general.lastName")}
        size='small'
        type="text"
        value={profileData?.lastName}
        onChange={(e) => {
          updateProfileData({ lastName: e.target.value })
          clearError("lastName")
        }}
        disabled={isDisabledProfile}
        error={errors?.lastName}
        helperText={errors?.lastName}
        slotProps={{
          htmlInput: { maxLength: 250 },

          inputLabel: {
            shrink: true,
          }
        }} />
      <FormControl size="small" fullWidth>
        <InputLabel>{t("general.namePrefixes")}</InputLabel>
        <Select
          value={profileData?.middleName?.id}
          label={t("general.namePrefixes")}
          size='small'
          disabled={isDisabledProfile}
          onChange={(e) => { updateProfileData({ middleName: dropdownValues?.middleNames?.find((item) => item?.id === e.target.value) }) }}
          sx={{ "& .MuiSelect-iconOutlined": { display: profileData?.middleName?.id ? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
          endAdornment={<IconButton sx={{ visibility: profileData?.middleName?.id ? "visible" : "hidden" }}
            onClick={(e) => {
              if (!isDisabledProfile) {
                e.stopPropagation()
                updateProfileData({ middleName: null })
              }
            }}
          ><Clear /></IconButton>}
        >
          {dropdownValues?.middleNames?.map((item) => <MenuItem value={item?.id}>{item?.name}</MenuItem>)}

        </Select>
      </FormControl>
      <TextField
        fullWidth
        label={t("general.initials")}
        size='small'
        type="text"
        value={profileData?.initials}
        disabled={isDisabledProfile}
        onChange={(e) => {
          updateProfileData({ initials: e.target.value })
          clearError("initials")
        }}
        error={errors?.initials}
        helperText={errors?.initials}
        slotProps={{
          inputLabel: {
            shrink: true,
          }
        }}
      />
      <TextField
        fullWidth
        label={t("general.firstName")}
        size='small'
        type="text"
        disabled={isDisabledProfile}
        value={profileData?.firstName}
        onChange={(e) => { updateProfileData({ firstName: e.target.value }) }}
        slotProps={{
          inputLabel: {
            shrink: true,
          }
        }}
      />
      <FormControl size="small" fullWidth>
        <InputLabel>{t("general.gender")}</InputLabel>
        <Select
          value={profileData?.sex}
          label={t("general.gender")}
          size='small'
          onChange={(e) => { updateProfileData({ sex: e.target.value }) }}
          disabled={isDisabledProfile}
          sx={{ "& .MuiSelect-iconOutlined": { display: profileData?.sex?.toString() ? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
          
        >

          <MenuItem value="0">{t("general.male")}</MenuItem>
          <MenuItem value="1">{t("general.female")}</MenuItem>
          <MenuItem value="2">{t("general.unknown")}</MenuItem>
        </Select>
      </FormControl>


      <FormControl size="small" fullWidth>
        <InputLabel>{t("user.profile.general.titleFor")}</InputLabel>
        <Select
          value={profileData?.titleFor?.id}
          label={t("user.profile.general.titleFor")}
          size='small'
          onChange={(e) => { updateProfileData({ titleFor: dropdownValues?.titleFor?.find((item) => item?.id === e.target.value) }) }}
          disabled={isDisabledProfile}
          sx={{ "& .MuiSelect-iconOutlined": { display: profileData?.titleFor?.id ? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
          endAdornment={<IconButton sx={{ visibility: profileData?.titleFor?.id ? "visible" : "hidden" }}
            onClick={(e) => {
              if (!isDisabledProfile) {
                e.stopPropagation()
                updateProfileData({ titleFor: null })
              }
            }}
          ><Clear /></IconButton>}
        >
          {dropdownValues?.titleFor?.map((item) => <MenuItem value={item?.id}>{item?.name}</MenuItem>)}
       
        </Select>
      </FormControl>

      <FormControl size="small" fullWidth>
        <InputLabel>{t("user.profile.general.titleBehind")}</InputLabel>
        <Select
          value={profileData?.titleBehind?.id}
          label={t("user.profile.general.titleBehind")}
          size='small'
          disabled={isDisabledProfile}
          onChange={(e) => { updateProfileData({ titleBehind: dropdownValues?.titleBehind?.find((item) => item?.id === e.target.value) }) }}
          sx={{ "& .MuiSelect-iconOutlined": { display: profileData?.titleBehind?.id ? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
          endAdornment={<IconButton sx={{ visibility: profileData?.titleBehind?.id ? "visible" : "hidden" }}
            onClick={(e) => {
              if (!isDisabledProfile) {
                e.stopPropagation()
                updateProfileData({ titleBehind: null })
              }
            }}
          ><Clear /></IconButton>}
        >
          {dropdownValues?.titleBehind?.map((item) => <MenuItem value={item?.id}>{item?.name}</MenuItem>)}
        </Select>
      </FormControl>

   

      <FormControl size="small" fullWidth>
        <InputLabel>{t("general.maritalStatus")}</InputLabel>
        <Select
          value={profileData?.maritalStatus}
          label={t("general.maritalStatus")}
          size='small'
          disabled={isDisabledProfile}
          onChange={(e) => { updateProfileData({ maritalStatus: e.target.value }) }}
          sx={{ "& .MuiSelect-iconOutlined": { display:profileData?.maritalStatus? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
          endAdornment={<IconButton sx={{ visibility:profileData?.maritalStatus? "visible" : "hidden" }}
            onClick={(e) => {
              if (!isDisabledProfile) {
                e.stopPropagation()
                updateProfileData({ maritalStatus: "" })
              }
            }}
          ><Clear /></IconButton>}
    
        >
          {
            [...Array(5)].map((_, index) => {
              return (
                <MenuItem value={index}>{t(`general.maritalStatus.${index}`)}</MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    </Stack>
    <Stack
      sx={{
        rowGap: 2.5,
        mt: 2.5
      }}>
      <Box>
        <Typography variant='body2' sx={{
          fontWeight: 600
        }}>{t("user.profile.privateData.title")}</Typography>
        <Typography variant='caption'>{t("general.contact")}</Typography>
        <Divider sx={{ mt: 1 }} />
      </Box>
      <TextField
        fullWidth
        label={t("user.profile.privateData.address")}
        size='small'
        type="text"
        value={formatAddress(profileData?.address)}
        disabled
        slotProps={{
          inputLabel: {
            shrink: true,
          }
        }}
      />
      <TextField
        fullWidth
        label={t("user.profile.privateData.email")}
        size='small'
        type="text"
        value={profileData?.email}
        disabled
        slotProps={{
          inputLabel: {
            shrink: true,
          }
        }}
      />
      <TextField
        fullWidth
        label={t("user.profile.privateData.telePhone")}
        size='small'
        type="text"
        value={deNormalizePhoneNumber(profileData?.telephone)}
        disabled={isDisabledProfile}
        onChange={(e)=>{
          updateProfileData({telephone: deNormalizePhoneNumber(e.target.value)})
          clearError("telephone")
        }
        }
        error={errors?.telephone}
        helperText={errors?.telephone}
        slotProps={{
          htmlInput: { maxLength: 10 },

          inputLabel: {
            shrink: true,
          }
        }} />
      <TextField
        fullWidth
        label={t("user.profile.privateData.mobile")}
        size='small'
        type="text"
        value={deNormalizePhoneNumber(profileData?.mobile)}
        disabled={isDisabledProfile}
        onChange={(e) => { 
          updateProfileData({ mobile: deNormalizePhoneNumber(e.target.value) }) 
          clearError("mobile")
        }
        }
        error={errors?.mobile}
        helperText={errors?.mobile}
        slotProps={{
          htmlInput: { maxLength: 10 },

          inputLabel: {
            shrink: true,
          }
        }} />
    </Stack>
    <Stack
      sx={{
        rowGap: 2.5,
        mt: 2.5
      }}>
      <Box>
        <Typography variant='body2' sx={{
          fontWeight: 600
        }}>{t("user.profile.miscellaneous.title")}</Typography>
        <Divider sx={{ mt: 1 }} />
      </Box>
       <DatePicker
      variant="standard"
      format="dd-MM-yyyy"
      id="birth-date-picker"
      label={t("user.profile.miscellaneous.dob")}
      autoOk
      maxDate={Date.now()}
      slotProps={{
        openPickerButton: { edge: "end", size: "small" },
        textField: {
          variant: "outlined",
          size: "small",
          InputLabelProps: {
            shrink: true,
          }
        },
      }}
      slots={{
        openPickerButton: IconButton,
        openPickerIcon: DateRange,
      }}
      value={profileData?.birthDate ? new Date(profileData?.birthDate) : profileData?.birthDate}
      onChange={(date) => { 
        updateProfileData({ birthDate: new Date(date).toJSON() })
       }}
    />
      <TextField
        fullWidth
        label={t("user.profile.miscellaneous.birthPlace")}
        size='small'
        type="text"
        value={profileData?.birthPlace}
        disabled={isDisabledProfile}
        onChange={(e) => { updateProfileData({ birthPlace: e.target.value }) }}
        slotProps={{
          htmlInput: { maxLength: 40 },

          inputLabel: {
            shrink: true,
          }
        }} />
    </Stack>
    <Stack
      sx={{
        rowGap: 2.5,
        mt: 2.5
      }}>
      <Box>
        <Typography variant='body2' sx={{
          fontWeight: 600
        }}>{t("general.financial")}</Typography>
        <Typography variant='caption'>{t("general.bankDetails")}</Typography>
        <Divider sx={{ mt: 1 }} />
      </Box>
      <FormControl size="small" fullWidth>
        <InputLabel>{t("general.bank")}</InputLabel>
        <Select
          value={profileData?.bank?.id}
          label={t("general.bank")}
          size='small'
          disabled={isDisabledProfile}
          onChange={(e) => { updateProfileData({ bank: dropdownValues?.banks?.find((item) => item?.id === e.target.value) }) }}
          sx={{ "& .MuiSelect-iconOutlined": { display: profileData?.bank?.id ? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
          endAdornment={<IconButton sx={{ visibility: profileData?.bank?.id ? "visible" : "hidden" }}
            onClick={(e) => {
              if (!isDisabledProfile) {
                e.stopPropagation()
                updateProfileData({ bank: null })
              }
            }}
          ><Clear /></IconButton>}
        >
          {dropdownValues?.banks?.map((item) => <MenuItem value={item?.id}>{item?.name}</MenuItem>)}

        </Select>
      </FormControl>
      <TextField
        fullWidth
        label={t("general.bic")}
        size='small'
        type="text"
        value={profileData?.bic}
        disabled={isDisabledProfile}
        onChange={(e) => { updateProfileData({ bic: e.target.value }) }}
        slotProps={{
          htmlInput: { maxLength: 15 },

          inputLabel: {
            shrink: true,
          }
        }} />
      <TextField
        fullWidth
        label={t("general.iban")}
        size='small'
        type="text"
        value={profileData?.iban}
        disabled={isDisabledProfile}
        onChange={(e) => { updateProfileData({ iban: e.target.value }) }}
        slotProps={{
          htmlInput: { maxLength: 34 },

          inputLabel: {
            shrink: true,
          }
        }} />
    </Stack>
    {userAccountTypeConstants.employee === user.type && <>
      <Stack  mt={2.5} spacing={1}>
        <Box>
          <Typography variant='body2' fontWeight={600}>{t("user.profile.availability.title")}</Typography>
          <Divider sx={{ mt: 1 }} />
        </Box>
        
       <Stack>
        <Typography variant='body2' color="primary" pt={1}>{t("user.profile.availability.morning")}</Typography>
          <FormGroup row>
          {daysOfWeek.map(({ key, label, value }) => (
          <FormControlLabel
            key={`morning-${key}`}
            sx={{mb:0}}
            control={
              <Checkbox
                value={value}
                size={'small'}
                onChange={(e) => handleMorningOrAfternoonAvailability(e, key)}
                checked={userAvailabilityInfo?.morning?.includes(key)}
                name='morning'
              />
            }
            label={label}
          />
        ))}
          </FormGroup>
        </Stack>

        <Stack>
          <Typography variant='body2' color="primary" pt={1}>{t("user.profile.availability.afternoon")}</Typography>
          <FormGroup row>
          {daysOfWeek.map(({ key, label, value }) => (
            <FormControlLabel
            sx={{mb:0}}
            key={`afternoon-${key}`}
            control={
              <Checkbox
              size={'small'}
              value={value}
              onChange={(e) => handleMorningOrAfternoonAvailability(e, key)}
              checked={userAvailabilityInfo?.afternoon?.includes(key)}
                name='afternoon'
                />
            }
            label={label}
          />
        ))}
          </FormGroup>
       </Stack>
      </Stack>

      <Stack mt={2} spacing={1}>
        <FormGroup>
          <FormControlLabel control={<Checkbox onChange={(e) => {
            updateProfileData({
              userAvailabilityInfo: {
                ...userAvailabilityInfo,
                showAvailability: e.target.checked
              }
            });
          }} checked={userAvailabilityInfo?.showAvailability} size={'small'} />} label={t("user.profile.availability.showMyAvailability")} />
          <FormControlLabel
            control={<Checkbox onChange={(e) => {
              updateProfileData({
                userAvailabilityInfo: {
                  ...userAvailabilityInfo,
                  sendAutomaticReply: e.target.checked,
                }
              })
            }} checked={userAvailabilityInfo?.sendAutomaticReply} size={'small'} />} label={t("user.profile.availability.automaticReplyMessage ")} />
        </FormGroup>
        <FormLabel>
          {t("user.profile.autoReply")}
        </FormLabel>
        <Box padding={theme.spacing(1.25)} height={"100%"} border={`1px solid ${theme.palette.grey[300]}`} overflow="hidden">
          <RichTextEditor
            id="automaticReplyEditor"
            label={`${t("general.message")}...`}
            showToolbar
            readOnly={!userAvailabilityInfo?.sendAutomaticReply}
            value={userAvailabilityInfo?.automaticReplyMessage || ""}
            onChange={(value, plainText) => {           
                updateProfileData({
                  userAvailabilityInfo: {
                    ...userAvailabilityInfo,
                    automaticReplyMessage: value,
                    automaticReplyMessageLength: plainText?.length ?? 0,
                  }
                })
              clearError("userAvailabilityInfo.automaticReplyMessage")
            }}
            disableAutoFocus
          />
        </Box>
        {
          errors?.userAvailabilityInfo?.automaticReplyMessage &&
          <FormHelperText error={errors?.userAvailabilityInfo?.automaticReplyMessage}> {errors.userAvailabilityInfo.automaticReplyMessage}</FormHelperText>
        }
      </Stack>

      <Stack mt={1} spacing={1}>
        <Box>
          <FormGroup aria-label="position" row >
            <FormControlLabel 
              sx={{ m: 0 }}
              value="left"
              control={<Switch color="primary"
                checked={userAvailabilityInfo?.outOfOffice || false}
                onChange={(e) => {
                  updateProfileData({
                    userAvailabilityInfo: {
                      ...userAvailabilityInfo,
                      outOfOffice: e.target.checked
                    }
                  })
                }}
              />
              }
              label={<Typography variant='body2' fontWeight={600}>{t("user.profile.autoReply.outOfOffice.title")}</Typography>}
              labelPlacement="start"
            />
          </FormGroup>
          <Typography variant='caption'>{t("user.profile.autoReply.outOfOffice.subtitle")}</Typography>
          <Divider sx={{ mt: 1 }} />
        </Box>

        <Stack direction={'row'} spacing={1} py={0.5}>
            <MobileDateTimePicker
              label={t("general.startDateTime")}
              disablePast
              value={userAvailabilityInfo?.outOfOfficeStartDateTime ? new Date(userAvailabilityInfo?.outOfOfficeStartDateTime) : null}
              format="dd-MM-yyyy HH:mm"
              onChange={(date) => {
                updateProfileData({
                  userAvailabilityInfo: {
                    ...userAvailabilityInfo,
                    outOfOfficeStartDateTime: new Date(date).toJSON()
                  }
                })
              clearError("userAvailabilityInfo.outOfOfficeStartDateTime")
              }}
              disabled={!userAvailabilityInfo?.outOfOffice}
              inputVariant="outlined"
              autoOk
              slotProps={{
                textField: {
                  helperText: errors?.userAvailabilityInfo?.outOfOfficeStartDateTime,
                  error: !!errors?.userAvailabilityInfo?.outOfOfficeStartDateTime,
                  size: "small",
                  InputProps: {
                    endAdornment: <IconButton ><CalendarToday /></IconButton>
                  }
                },
              }}
              onError={()=>errors?.userAvailabilityInfo?.outOfOfficeStartDateTime}
            />

            <MobileDateTimePicker
              label={t("general.endDateTime")}
              disablePast  
              value={userAvailabilityInfo?.outOfOfficeEndDateTime ? new Date(userAvailabilityInfo?.outOfOfficeEndDateTime) : null}
              format="dd-MM-yyyy HH:mm"
              onChange={(date) => {
                updateProfileData({
                  userAvailabilityInfo: {
                    ...userAvailabilityInfo,
                    outOfOfficeEndDateTime: new Date(date).toJSON()
                  }
                })
              clearError("userAvailabilityInfo.outOfOfficeEndDateTime")
              }}
              disabled={!userAvailabilityInfo?.outOfOffice || !userAvailabilityInfo?.outOfOfficeStartDateTime}
              inputVariant="outlined"
              autoOk
              slotProps={{
                textField: {
                  size: "small",
                  helperText: errors?.userAvailabilityInfo?.outOfOfficeEndDateTime,
                  error: !!errors?.userAvailabilityInfo?.outOfOfficeEndDateTime,
                  InputProps: {
                    endAdornment: <IconButton ><CalendarToday /></IconButton>
                  }
                },
              }}
              onError={()=>errors?.userAvailabilityInfo?.outOfOfficeEndDateTime}
              minDateTime={
                userAvailabilityInfo?.outOfOfficeStartDateTime
                  ? dayjs(userAvailabilityInfo?.outOfOfficeStartDateTime).add(1, 'hour').startOf('minute').toDate()
                  : dayjs().toDate()
              }
            />
        </Stack>
        
        <FormLabel>
            {t("user.profile.autoReply")}
          </FormLabel>
          <Box padding={theme.spacing(1.25)} height="100%" border={`1px solid ${theme.palette.grey[300]}`} overflow="hidden">
          <RichTextEditor
            label={`${t("general.message")}...`}
            id="outOfOfficeEditor"
            showToolbar
            readOnly={!userAvailabilityInfo?.outOfOffice}
            value={userAvailabilityInfo?.outOfOfficeReplyMessage || ""}
            onChange={(value, plainText) => {
              updateProfileData({
                userAvailabilityInfo: {
                  ...userAvailabilityInfo,
                  outOfOfficeReplyMessage: value,
                  outOfOfficeReplyMessageLength: plainText?.length ?? 0,
                }
              })
              clearError("userAvailabilityInfo.outOfOfficeReplyMessage")
            }}
            disableAutoFocus
          />
          </Box>
          {
            errors?.userAvailabilityInfo?.outOfOfficeReplyMessage &&
            <FormHelperText error={errors?.userAvailabilityInfo?.outOfOfficeReplyMessage}>{errors.userAvailabilityInfo.outOfOfficeReplyMessage}</FormHelperText>
          }
      </Stack>
     </>}
  </>)
}

export default ProfileBody