import React, { useEffect } from 'react'
import { Dialog } from '@mui/material'

const Christmas = (props) => {
    const { user } = props;
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        function checkLocalStorage() {
            if (user) {
                var localStorageValue = localStorage.getItem(`christmas_${user.id}_played`);
                setOpen(localStorageValue !== '2024-25')
            }
        }
        checkLocalStorage()
        const interval = setInterval(() => checkLocalStorage(), 1000)
        return () => {
            clearInterval(interval);
        }
    }, [])

    function handleClose() {
        setOpen(false);
        if (user) {
            localStorage.setItem(`christmas_${user.id}_played`, '2024-25');
        }
    }

    return (
        !!open &&
        <Dialog onClose={handleClose} open={open} maxWidth="lg">
            <div style={{ maxWidth: "100%", width: 1080 }}>
                <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                    <iframe id="vimeoVideo" src="https://player.vimeo.com/video/1038228711?autoplay=1&amp;title=0&amp;muted=0&amp;byline=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} title="Vrolijk kerstfeest namens JPDS Huisinfo 2024"></iframe>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                </div>
            </div>
        </Dialog>
    )
}

export default Christmas