import React, { useEffect, useState } from 'react'
import { CalendarToday, Cancel, Info, Schedule } from '@mui/icons-material'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, TextField, useTheme } from '@mui/material'
import { Box, Typography, Snackbar } from '@mui/material'
import { grey } from '@mui/material/colors'
import { MobileDateTimePicker } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close';
import { addOrRescheduleAppointment, cancelAppointment } from '../../apis/aftercareApis'

const WorkOrderAppointment = ({ workOrder, getWorkOrderDetails }) => {
    const [open, setOpen] = useState(false);
    const [isAppoinmentCancelDialogOpen, setIsAppoinmentCancelDialogOpen] = useState(false);
    const [selectedDateTime, setSelectedDateTime] = useState(workOrder?.appointmentDateTime ?? "");
    const [isAppoinmentBooked, setIsAppoinmentBooked] = useState(false)
    const [appoinmentCancelReason, setAppoinmentCancelReason] = useState("");
    const [toast, setToast] = useState({ open: false, message: "", severity: "" })
    const { t } = useTranslation()
    const theme = useTheme()

    const handleDateTimeChange = (newValue) => {
        setSelectedDateTime(newValue);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleAppoinmentCancelDialogOpen = () => setIsAppoinmentCancelDialogOpen(true);
    const handleAppoinmentCancelDialogClose = () => setIsAppoinmentCancelDialogOpen(false);

    const handleAppoinmentSchedule = async () => {
        setOpen(false);
        try {
            if (selectedDateTime) {
                const res = await addOrRescheduleAppointment({
                    resolverId: workOrder?.resolverId,
                    appointmentDateTime: new Date(selectedDateTime).toISOString()
                })
                if (res.data?.success) {
                    setIsAppoinmentBooked(true)
                    setToast({ open: true, message: isAppoinmentBooked ? t("workOrders.appointmentReschedule.success") : t("workOrders.appointmentSchedule.success"), severity: "success" })
                    getWorkOrderDetails(true)
                } else {
                    setToast({ open: true, message: t("workOrders.appointmentSchedule.error"), severity: "error" })
                }
            }

        }
        catch (error) {
            setToast({ open: true, message: t("workOrders.appointmentSchedule.error"), severity: "error" })
        }
    }

    const handleAppoinmentCancel = async () => {
        try {
            if (appoinmentCancelReason) {
                setIsAppoinmentCancelDialogOpen(false)
                const res = await cancelAppointment({
                    resolverId: workOrder?.resolverId,
                    cancelled: true,
                    reason: appoinmentCancelReason
                })
                if (res.data?.success) {
                    setIsAppoinmentBooked(false)
                    setSelectedDateTime(null)
                    setToast({ open: true, message: t("workOrders.appointmentCancel.success"), severity: "success" })
                    getWorkOrderDetails(true)
                } else {
                    setToast({ open: true, message: t("workOrders.appointmentSchedule.error"), severity: "error" })
                }
            }
        }
        catch (error) {
            setToast({ open: true, message: t("workOrders.appointmentSchedule.error"), severity: "error" })
        }
    }

    useEffect(() => {
        if (workOrder?.appointmentDateTime && !workOrder?.appointmentCancelled) {
            setIsAppoinmentBooked(true)
        }
        if (workOrder?.appointmentCancelled) {
            setIsAppoinmentBooked(false)
            setSelectedDateTime(null)
        }
    }, [workOrder?.appointmentDateTime, workOrder?.appointmentCancelled])


    return (
        (<Box
                sx={{
                    p: theme.spacing(1, 1, 3),
                    bgcolor: theme.palette.grey[100]
                }}>
            <Typography component="h2" variant="h6" sx={{
                p: theme.spacing(2)
            }}>
                <Schedule color="primary" sx={{ fontSize: 30 }} /> &nbsp;
                {t("workOrders.workOrderAppoinment")}
            </Typography>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    p: 1.5,
                    alignItems: 'center',
                    justifyContent: "space-between"
                }}>
                <Typography>{t("workOrders.workOrderDateAndTime")}</Typography>
                <MobileDateTimePicker
                    disablePast
                    value={selectedDateTime ? (new Date(selectedDateTime)) : null}
                    format="dd-MM-yyyy HH:mm"
                    onChange={handleDateTimeChange}
                    inputVariant="outlined"
                    autoOk
                    ampm={false}
                    slotProps={{
                        textField: {
                            size: "small",
                            InputProps: {
                                endAdornment: <IconButton ><CalendarToday /></IconButton>
                            }
                        },
                    }}
                />
            </Stack>
            <Divider />
            <Stack direction="row-reverse" sx={{
                p: 1.5
            }}>
                {/* Appoinment shedule reshedule dialog start */}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle sx={{ m: 0, p: 1.5, bgcolor: grey[200] }} id="customized-dialog-title">
                        <Typography component="h2" variant="h6">
                            <Schedule color="primary" sx={{ fontSize: 30 }} /> &nbsp;
                            {t("workOrders.workOrderAppoinment")}
                        </Typography>
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={theme => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500]
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <Typography
                            variant='h6'
                            sx={{
                                fontWeight: 400,
                                textAlign: "center"
                            }}>
                            {t(isAppoinmentBooked ? "workOrders.appointmentReschedule.subtitle" : "workOrders.appointmentSchedule.subtitle")}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            {t("general.cancel")}
                        </Button>
                        <Button autoFocus onClick={handleAppoinmentSchedule} variant='contained'>
                            {t("general.confirm")}
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* Appoinment shedule reshedule dialog end */}

                <Snackbar
                    open={toast.open}
                    autoHideDuration={6000}
                    onClose={() => setToast({ open: false })}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}>
                    <Alert severity={toast.severity} onClose={() => setToast({ open: false })} > {toast.message}</Alert>
                </Snackbar>

                {/* Appoinment cancel dialog start */}
                <Dialog
                    open={isAppoinmentCancelDialogOpen}
                    onClose={handleAppoinmentCancelDialogClose}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle sx={{ m: 0, p: 1.5, bgcolor: grey[200] }} id="customized-dialog-title">
                        <Typography component="h2" variant="h6">
                            <Cancel color="primary" sx={{ fontSize: 30 }} /> &nbsp;
                            {t("workOrders.workOrderAppoinment")}
                        </Typography>
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleAppoinmentCancelDialogClose}
                        sx={theme => ({
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: theme.palette.grey[500]
                        })}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent>
                        <Typography variant="h6" sx={{
                            textAlign: "center"
                        }}>
                            {t("workOrders.appointmentCancellation.title")}
                        </Typography>
                        <Typography variant="body1" sx={{
                            mt: 1
                        }}>
                            {t("workOrders.appointmentCancellation.subTitle")}
                        </Typography>
                        <TextField
                            label={t("workOrders.appointmentCancellation.reason")}
                            placeholder={t("workOrders.appointmentCancellation.reason.placeholder")}
                            multiline
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            rows={10}
                            required
                            autoFocus
                            onChange={(e) => setAppoinmentCancelReason(e.target.value)
                            }
                        />
                        <Alert
                            icon={<Info fontSize="inherit" />}
                            sx={{ mt: 2.5 }} severity='warning'>{t("workOrders.appointmentCancellation.warning")}</Alert>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleAppoinmentCancelDialogClose}>
                            {t("general.cancel")}
                        </Button>
                        <Button autoFocus onClick={handleAppoinmentCancel} variant='contained' disabled={appoinmentCancelReason.length === 0}>
                            {t("general.confirm")}
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* Appoinment cancel dialog end */}

                {
                    isAppoinmentBooked ?
                        <Stack direction="row-reverse" sx={{
                            columnGap: 1
                        }}>
                            <Button size="small" variant="outlined" onClick={handleAppoinmentCancelDialogOpen}>{t("general.cancel")}</Button>
                            <Button size="small" variant="contained" disabled={selectedDateTime && workOrder?.appointmentDateTime && new Date(selectedDateTime).toISOString() === new Date(workOrder?.appointmentDateTime).toISOString()} onClick={handleOpen}>{t("workOrders.reschedule")}</Button>
                        </Stack>
                        :
                        <Button size="small" variant="contained" onClick={handleOpen} disabled={!selectedDateTime}>{t("general.save")}</Button>
                }
            </Stack>
        </Box>)
    );
}

export default WorkOrderAppointment