import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
  Grid2 as Grid,
  FormControlLabel,
  Radio,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { sortAlphaNum } from "../_helpers";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  disabledSwitch: {
    color: `${theme.palette.primary.light} !important`,
  },
  objectModalContainer: {
    display: "flex",
    columnGap: 10,
  },
  dialogButtonPrimary: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 80,
      padding: theme.spacing(0.3),
    },
    minWidth: 100,
    background: theme.palette.primary.main,
    padding: theme.spacing(0.875, 1.25),
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  dialogButtonSecondary: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 80,
      padding: theme.spacing(0.3),
    },
    minWidth: 100,
    background: theme.palette.grey[500],
    padding: theme.spacing(0.875, 1.25),
    color: theme.palette.common.white,
    boxShadow: "none",
    "&:hover": {
      background: theme.palette.grey[500],
    },
  },
  objectGridContainer: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function SelectSingleObjectModal(props) {
  const {
    open,
    buildings,
    onSave,
    onClose,
    selectedBuilding,
    canProjectLevel,
    isLoading,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedObject, setSelectedObject] = useState();
  const [isProjectLevel, setIsProjectLevel] = useState(false);
  const {
    buildings: { selected: selectedProject },
    dossier: { updateLoading },
  } = useSelector((state) => state);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (open && selectedBuilding) {
      setIsProjectLevel(false);
      setSelectedObject(selectedBuilding);
    }
  }, [open]);

  useEffect(() => {
    if (updateLoading) setIsUpdated(true);
    if (!updateLoading && isUpdated) onClose();
  }, [updateLoading]);

  const handleCloseObjects = () => {
    onClose();
  };

  const handleSaveObjects = () => {
    onSave(isProjectLevel ? null : selectedObject);
  };

  return (open === true && (<Dialog
    open={open}
    onClose={updateLoading || isLoading ? () => {} : handleCloseObjects}
    aria-labelledby="form-dialog-title"
    maxWidth="sm"
    scroll="paper"
  >
    <DialogTitle id="dialog-objects-title">
      <Grid container spacing={1} className={classes.objectGridContainer}>
        <Grid className={classes.grow}>
          <Typography variant="h6" className={classes.ObjectModalHeader}>
            {t("general.objects.select")}
          </Typography>
        </Grid>
        <div className={classes.objectModalContainer}>
          <Grid>
            <Button
              disabled={updateLoading || isLoading}
              variant="contained"
              onClick={handleCloseObjects}
              className={classes.dialogButtonSecondary}
            >
              {t("general.cancel")}
            </Button>
          </Grid>
          <Grid>
            <Button
              className={classes.dialogButtonPrimary}
              variant="outlined"
              color="primary"
              disabled={updateLoading || isLoading || !selectedObject}
              onClick={handleSaveObjects}
            >
              {updateLoading || isLoading ? (
                <CircularProgress size={22} />
              ) : (
                t("general.save")
              )}
            </Button>
          </Grid>
        </div>
      </Grid>
    </DialogTitle>
    <DialogContent>
      <Grid container spacing={1}>
        {selectedProject && canProjectLevel && (
          <Grid size={{xs:12}}>
            <FormControlLabel
              style={{ margin: 0 }}
              disabled={isLoading || updateLoading}
              value={selectedProject.projectId}
              control={
                <Radio
                  disabled={isLoading || updateLoading}
                  className={classes.customRadioButton}
                  color="primary"
                  checked={selectedObject === selectedProject.projectId}
                  onChange={() => {
                    setIsProjectLevel(true);
                    setSelectedObject(selectedProject.projectId);
                  }}
                  value={selectedProject.projectId}
                  name="person"
                  inputProps={{ "aria-label": "test" }}
                />
              }
              label={t("select.building.project.level")}
              labelPlacement="end"
            />
          </Grid>
        )}
        {buildings
          .sort((p, c) =>
            sortAlphaNum(p.buildingNoExtern, c.buildingNoExtern)
          )
          .map((building) => {
            return (
              (<Grid size={{xs:4}}>
                <FormControlLabel
                  style={{ margin: 0 }}
                  disabled={isLoading || updateLoading}
                  value={building.buildingId}
                  control={
                    <Radio
                      disabled={isLoading || updateLoading}
                      className={classes.customRadioButton}
                      color="primary"
                      checked={selectedObject === building.buildingId}
                      onChange={() => {
                        setIsProjectLevel(false);
                        setSelectedObject(building.buildingId);
                      }}
                      value={building.buildingId}
                      name="person"
                      inputProps={{ "aria-label": "test" }}
                    />
                  }
                  label={building.buildingNoExtern}
                  labelPlacement="end"
                />
              </Grid>)
            );
          })}
      </Grid>
    </DialogContent>
  </Dialog>));
}
