import React from "react";
import { connect } from "react-redux";
import { Container,  Grid2 as Grid, Typography } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import { history } from "../../_helpers";
import { apps, userAccountRoleConstants } from "../../_constants";

const { webApiUrl } = window.appConfig;

const styles = theme => ({
    wrapper: {
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center bottom',
        height: '100%',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    welcomePanel: {
        color: theme.palette.common.white,
        height: '40vh',
        position: 'relative',
        padding: theme.spacing(5, 0),
        '& h1': {
            textShadow: '0 0 10px rgb(0,0,0)'
        }
    },
});


class Page extends React.Component {
    state = {
        actions: [],
        plannings: [],
        messages: []
    };

    componentDidMount() {
        const { selected, app, buyerGuidesBuildings, loading, workOrderCounts, getWorkOrderCountsLoading } = this.props;
        if (selected && !loading) {
            const canRedirectToDashboard = [userAccountRoleConstants.aftercareEmployee, userAccountRoleConstants.propertyManager, userAccountRoleConstants.siteManager].some(a => selected.roles && selected.roles.includes(a));
            if (app === apps.resolverModule) {
                if (!getWorkOrderCountsLoading && workOrderCounts) history.push('/werk/' + selected.projectNo + '/werkbonnen');
            } else if (app === apps.constructionQuality || app === apps.aftercare) {
                if (canRedirectToDashboard) history.push('/dashboard');
            } else {
                var url = '/werk/' + selected.projectNo;
                if (buyerGuidesBuildings.length === 0 && !selected.roles.includes(userAccountRoleConstants.subContractorSupervisor) && !selected.roles.includes(userAccountRoleConstants.subContractorEmployee))
                    url += '/dossier';
                history.push(url)
            }
        }
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState) {
        const { selected, app, buyerGuidesBuildings, loading, workOrderCounts, getWorkOrderCountsLoading } = this.props;
        if (selected && !loading) {
            const canRedirectToDashboard = [userAccountRoleConstants.aftercareEmployee, userAccountRoleConstants.propertyManager, userAccountRoleConstants.siteManager].some(a => selected.roles && selected.roles.includes(a));
            if (app === apps.resolverModule) {
                if (!getWorkOrderCountsLoading && workOrderCounts) history.push('/werk/' + selected.projectNo + '/werkbonnen');
            } else if (app === apps.constructionQuality || app === apps.aftercare) {
                if (canRedirectToDashboard) history.push('/dashboard');
            }
            else {
                var url = '/werk/' + selected.projectNo;
                if (buyerGuidesBuildings.length === 0 && !selected.roles.includes(userAccountRoleConstants.subContractorSupervisor) && !selected.roles.includes(userAccountRoleConstants.subContractorEmployee))
                    url += '/dossier';
                history.push(url)
            }
        }
    }

    timer = () => {
        this.UpdateMessages(false);
    }

    render() {
        const { user, t, classes, selected } = this.props;
        return (
            (<div className={classes.wrapper} style={{
                    backgroundImage:
                        (
                            selected
                                ?
                                'url(' + webApiUrl + 'api/home/ProjectBackground/' + selected.projectId + '), '
                                :
                                ''
                        )
                        + 'url(' + webApiUrl + 'api/Config/WebBackground)'
                }}>
                <Container>
                    <Grid
                        container
                        direction="row"
                        className={classes.welcomePanel}
                        sx={{
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                        <Grid size="grow">
                            <Typography component="h1" variant="h4" gutterBottom align="center">{user.name}, {t('dashboard.welcome.text')}</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </div>)
        );
    }
}

function mapStateToProps(state) {
    const { authentication, buildings, dashboardCount, app, workOrderCounts: { workOrderCounts, getWorkOrderCountsLoading } } = state;
    const { user } = authentication;
    const { selected, all, buyerGuidesBuildings, loading } = buildings;
    const { quotationsCount } = dashboardCount;
    return {
        user,
        selected,
        loading,
        buildings: all, buyerGuidesBuildings,
        quotationsCount,
        app,
        workOrderCounts,
        getWorkOrderCountsLoading
    };
}

const connectedPage = connect(mapStateToProps)(withTranslation()(withStyles(styles)(Page)));
export { connectedPage as HomeInternal };
