import React, { useEffect, useRef, useState } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid2 as Grid,
    Hidden,
    Icon,
    IconButton,
    Popover,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
import {  Clear, Description, Edit, Share,} from "@mui/icons-material";
import { DatePicker} from "@mui/x-date-pickers"
import { formatDate, nl2br, toBase64, validateFile } from "../../_helpers";
import Markdown from "../../components/Markdown";
import RichTextEditor from "../Chat/RichTextEditor";
import { useDispatch, useSelector } from "react-redux";
import { dossiersActions } from "../../_actions/dossiers.actions";
import { userAccountTypeConstants } from "../../_constants";
import clsx from "clsx";
import ShareDossier from "./Common/ShareDossier";

export const GeneralSection = ({
    buildingId, accessRights, canDrawingDossier, selectedDossierBuilding, buyerContactInfo, selectedDossierContacts,
    dossierUpdateType, selected, selectedDossier, updating, buildings, handleUpdateDossierClose,
    handleGetAllImages, handlePreviewOfFiles, handleSelectFiles, updateDossier, isReadOnly: readOnly,
    openDossierRights, openEditObjects, uploadingBackground, edit, setEdit, updateStatus, markFileAsViewed, ...props
}) => {
    const { t } = useTranslation();
    const fileInputRef = useRef()
    const classes = useStyles();
    const [isReadOnly, setIsReadOnly] = useState(readOnly);
    const params = new URLSearchParams(window.location.search)
    const [isUpdating, setIsUpdating] = useState(false);
    const [deadlineConfirmationDialog, setDeadlineConfirmationDialog] = useState({ open: false, date: null });
    const { updateLoading, isDeadLineUpdating } = useSelector(state => state.dossier);
    const { user } = useSelector(state => state.authentication);
    const { rights } = useSelector(state => state.buildings);
    const dispatch = useDispatch();
    const status = selectedDossierBuilding.buildingId ? selectedDossierBuilding.status : selectedDossier.status;
    const deadline = selectedDossierBuilding.buildingId ? selectedDossierBuilding.deadline : selectedDossier.deadline;
    const isBuyer = user.type === userAccountTypeConstants.buyer;
    const canEdit = rights['dossier.generalInformation.write'] || rights['selected.object.write'];
    const [openShareDossierMenu, setOpenShareDossierMenu] = useState(false)
    const isDrawingDossier = selectedDossier && selectedDossier.isSystem;
    const [openDatePicker, setOpenDatePicker] = useState(false);
    useEffect(() => {
        const buildingIdParam = params.get('buildingId');
        if (buildingIdParam) {
            setIsReadOnly(true);
        } else {
            setIsReadOnly(readOnly);
        }
    }, [params]);

    useEffect(() => {
        if (updateLoading) setIsUpdating(true);
        if (!updateLoading && isUpdating) {
            handleUpdateDossierClose()
        }
    }, [updateLoading]);

    const handleUploadBackgroundImage = async ({ target: { files } }) => {
        if (files.length) {
            let file = files[0];
            if (validateFile({file:file, allowImage:true}) === true) {
                let currenturl = await toBase64(file)
                file.url = currenturl;
                const backgroundImage = {
                    content: file.url.split(',')[1],
                    name: file.name
                }
                updateDossier('backgroundImage', backgroundImage)
            }
        }
    }

    const handleDossierDeadlineUpdate = (deadlineDate, isUpdateBuildings) => {
        dispatch(dossiersActions.dossierDeadlineUpdate({
            dossierId: selectedDossier.id,
            deadlineDate,
            isUpdateBuildings,
        }));
    }
    const renderEditTextbox = (title, key, value, multi = false, rich = false) => {
        return (
            (<div style={{ position: 'relative', minHeight: 20 }}>
                {value && <div>{rich ? <Markdown value={value} /> : nl2br(value)}</div>}
                {selectedDossier.status !== 2 &&
                    <div>{updating === key ?
                        <Icon color="inherit" fontSize="small" style={{ position: 'absolute', right: -9, top: -36 }}>
                            <CircularProgress size="small" style={{ height: 20, width: 20 }} />
                        </Icon> :
                        <div>
                            {!isReadOnly && <Tooltip title={t('general.editText')}>
                                <IconButton
                                    aria-describedby={'edit-' + key} color="inherit" aria-label="edit" component="span" size="small"
                                    edge="end" style={{ position: 'absolute', right: -9, top: -36 }}
                                    disabled={isReadOnly || !canEdit}
                                    onClick={e => setEdit({ key, value, anchorEl: e.currentTarget })}>
                                    <Edit />
                                </IconButton>
                            </Tooltip>}
                            {edit && edit.key === key &&
                                <Popover open={true}
                                    transformOrigin={{ vertical: 'top', }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    id={'edit-' + key}
                                    classes={{ paper: classes.generalInfoTextPopover }}
                                    anchorEl={edit.anchorEl}
                                    onClose={() => setEdit(null)}>
                                    <div className={classes.generalInfoText}>
                                        <Grid container spacing={1} direction="column">
                                            <Grid>
                                                <Typography variant="h6">{title}</Typography>
                                            </Grid>
                                            <Grid>
                                                {rich ? <RichTextEditor label={t('general.generalInformation')}
                                                    showToolbar={true} value={edit.value}
                                                    onChange={value => setEdit({ anchorEl: edit.anchorEl, key, value })} />
                                                    :
                                                    <TextField className={classes.textField} value={edit.value}
                                                        onChange={e => setEdit({ anchorEl: edit.anchorEl, key, value: e.target.value })}
                                                        margin="dense" variant="outlined" multiline={multi} rows={10} fullWidth
                                                        autoFocus disabled={selectedDossier.status === 2} />}
                                            </Grid>
                                            <Grid>
                                                <Grid container spacing={1} sx={{
                                                    justifyContent: "flex-end"
                                                }}>
                                                    <Grid>
                                                        <Button
                                                            disabled={updateLoading && dossierUpdateType === key}
                                                            variant="outlined"
                                                            onClick={() => setEdit(null)}>
                                                            {t('general.cancel')}
                                                        </Button>
                                                    </Grid>
                                                    <Grid>
                                                        <Button variant="outlined"
                                                            disabled={updateLoading && dossierUpdateType === key}
                                                            onClick={() => updateDossier(key, edit.value)}>
                                                            {
                                                                updateLoading && dossierUpdateType === key ? <CircularProgress size={20} /> : t('general.save')
                                                            }
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Popover>
                            }
                        </div>
                    }
                    </div>
                }
            </div>)
        );
    };

    return (
        (<Grid className={classes.generalMainContainer} size={{xs:12}}>
            <input ref={fileInputRef} className={classes.fileInput} type="file" accept={"image/x-png,image/gif,image/jpeg"} onChange={handleUploadBackgroundImage} />
            <div className={classes.block}>
              <Grid container spacing={1}>
                <Grid size={{xs: 12}}>
                  <Hidden mdUp>
                    <div className={classes.subHeader}>
                      <Description color="primary" />
                      <div className={classes.subHeaderTextContainer}>
                        <Typography className={classes.subHeaderText}>{selectedDossier.name}</Typography>
                      </div>
                      {!isBuyer && !isDrawingDossier && rights["dossier.canShare"] && (
                        <Tooltip title={t("general.share")}>
                          <IconButton aria-label="Delen" edge="end" color="inherit" className={classes.shareIcon} onClick={() => setOpenShareDossierMenu(true)} size="large">
                            <Share />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </Hidden>
                  <Grid container spacing={2} className={classes.cardContainer}>
                    <Grid
                        size={{
                            xs: 12,
                            sm: 7,
                            md: 8
                        }}>
                      <Grid container className={classes.generalInfoTextContainer}>
                        <Hidden mdDown>
                          <Typography component="h2" variant="h6" className={classes.subHeader}>
                            <Description color="primary" /> &nbsp;
                            {selectedDossier.name}
                            {!isBuyer && !isDrawingDossier && rights["dossier.canShare"] && (
                              <Tooltip title={t("general.share")}>
                                <IconButton aria-label="Delen" edge="end" color="inherit" className={classes.shareIcon} onClick={() => setOpenShareDossierMenu(true)} size="large">
                                  <Share />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Typography>
                        </Hidden>
                        <Grid container className={clsx(classes.infoGridRow)}>
                          <Grid className={classes.generalInfoTextHeader} size={{xs:12}}>
                            {t("general.generalInformation") + ":"}
                          </Grid>
                          <Grid className={classes.generalContent} size={{xs:12}}>
                            {isBuyer
                              ? selectedDossier.generalInformation && <Markdown value={selectedDossier.generalInformation} />
                              : renderEditTextbox(t("general.generalInformation"), "generalinformation", selectedDossier.generalInformation, true, true)}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                        size={{
                            xs: 12,
                            sm: 5,
                            md: 4
                        }}>
                      <Grid container className={classes.generalInfoOptions}>
                        {!canDrawingDossier ? (
                          <>
                            <Grid size={{xs: 12}}>
                              <Grid container>
                                <Grid
                                    container
                                    className={clsx(classes.infoGridRow, classes.alignItemsCenter)}
                                    sx={{
                                        alignItems: "center"
                                    }}
                                    size={{
                                        xs: 12,
                                        sm: 12,
                                        md: 12
                                    }}>
                                  <Grid size={{xs:4}}>
                                    {t("general.status") + ":"}
                                  </Grid>
                                  <Grid size={{xs:8}}>
                                    {canEdit && !selectedDossier.isArchived && ((selectedDossier.buildingInfoList && selectedDossier.buildingInfoList.length === 0) || selectedDossierBuilding.buildingId) ? (
                                      <Grid component="label" container spacing={1} sx={{
                                          alignItems: "center"
                                      }}>
                                        <Grid>{t("dossier.status.1")}</Grid>
                                        <Grid>{props.isUpdateStatus ? <CircularProgress size={24} /> : <Switch onChange={() => updateStatus(status === 1)} color="primary" checked={status !== 1} />}</Grid>
                                        <Grid>{t("dossier.status.2")}</Grid>
                                      </Grid>
                                    ) : (
                                      t("dossier.status." + status)
                                    )}
                                  </Grid>
                                  {/* <Hidden mdDown>
                                                                <Grid item xs={12}><Divider /></Grid>
                                                            </Hidden> */}
                                </Grid>
                                <Grid
                                    size={{
                                        xs: 12,
                                        sm: 12,
                                        md: 12
                                    }}>
                                  <Dialog open={deadlineConfirmationDialog.open}>
                                    <DialogTitle>{t("general.confirm")}</DialogTitle>
                                    <DialogContent>
                                      <DialogContentText> {t("dossier.deadline.confirmation.message")} </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        color="primary"
                                        onClick={() => {
                                          setDeadlineConfirmationDialog({ date: null, open: false })
                                          handleDossierDeadlineUpdate(deadlineConfirmationDialog.date || "", false)
                                        }}
                                      >
                                        {t("button.action.no")}
                                      </Button>
                                      <Button
                                        color="primary"
                                        autoFocus
                                        onClick={() => {
                                          setDeadlineConfirmationDialog({ date: null, open: false })
                                          handleDossierDeadlineUpdate(deadlineConfirmationDialog.date || "", true)
                                        }}
                                      >
                                        {t("button.action.yes")}
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                  <Grid container className={clsx(classes.infoGridRow, classes.alignItemsCenter)}>
                                    <Grid size={{xs:4}}>
                                      {t("dossier.general.deadline.title") + ":"}
                                    </Grid>
                                    <Grid size={{xs:8}}>
                                      {!readOnly && canEdit ? (
                                        <DatePicker
                                          fullWidth
                                          variant="outlined"
                                          format="dd-MM-yyyy"
                                          margin="dense"
                                          id="date-time-picker"
                                          label={t("dossier.general.deadline.title")}
                                          name="startDateTime"
                                          value={deadline ? new Date(deadline) : null}
                                          onChange={(date) => {
                                            if (selectedDossierBuilding.buildingId || !selectedDossier.buildingInfoList.length) updateDossier("deadline", date.toJSON())
                                            else {
                                              setDeadlineConfirmationDialog({ open: true, date: date.toJSON() })
                                              setOpenDatePicker(false)
                                            }
                                          }}
                                          open={openDatePicker}
                                          autoOk
                                          slotProps={{
                                            openPickerButton: { edge: "start", size: "large" },
                                            textField: {
                                              variant: "outlined",
                                              size: "small",
                                              onClick: () => {
                                                setOpenDatePicker(true)
                                              },
                                            },
                                          }}
                                          slots={{
                                            openPickerButton: () =>
                                               (
                                                <IconButton
                                                  disabled={readOnly || (updateLoading && dossierUpdateType === "deadline") || isDeadLineUpdating}
                                                  style={{ padding: 0 }}
                                                  onClick={(e) => {
                                                    e.stopPropagation()
                                                    if (selectedDossierBuilding.buildingId || !selectedDossier.buildingInfoList.length) updateDossier("deadline", null)
                                                    else setDeadlineConfirmationDialog({ open: true, date: null })
                                                  }}
                                                  size="large"
                                                >
                                                  {(updateLoading && dossierUpdateType === "deadline") || isDeadLineUpdating ? <CircularProgress size={20} /> : <Clear />}
                                                </IconButton>
                                              ),
                                          }}
                                          disabled={props.isReadOnly || (updateLoading && props.dossierUpdateType === "deadline") || isDeadLineUpdating}
                                          clearable
                                        />
                                      ) : deadline ? (
                                        formatDate(new Date(deadline))
                                      ) : null}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <ShareDossier
              selectedDossier={selectedDossier}
              buildingId={buildingId}
              isReadOnly={!rights["selected.object.write"] && !rights["dossier.canShare"]}
              open={openShareDossierMenu}
              onClose={() => {
                setOpenShareDossierMenu(false)
              }}
            />
        </Grid>)
    );
};


const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1
    },
    filesContainer: {
        maxHeight: 410,
        overflow: 'auto'
    },
    attachmentContainer: {
        padding: theme.spacing(0.5)
    },
    block: {
        width: '100%',
        borderRadius: 6,
        [theme.breakpoints.up("md")]: {
            backgroundColor: theme.palette.common.white,
        },
        padding: theme.spacing(1, 1, 1),
    },

    button: {
        '&:hover': {
            color: theme.palette.primary.contrastText
        }
    },
    caption: {
        width: '100%',
        textAlign: 'center'
    },
    subHeader: {
        padding: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(0, 2, 2),
        },
        fontSize: 16,
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            fontSize: 24
        },
    },
    subHeaderTextContainer: {
        overflow: 'hidden'
    },
    subHeaderText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    shareIcon: {
        marginLeft: 'auto'
    },
    alignItemsCenter: {
        alignItems: "center"
    },
    infoGridRow: {
        '& > div': {
            padding: theme.spacing(0.5, 2),
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
            "& $allSelectCheckbox": {
                opacity: 1
            }
        },
        '& .MuiInputLabel-outlined': {
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        [theme.breakpoints.down('md')]: {
            '& > div': {
                padding: theme.spacing(0.5, 1),
            }
        }
    },
    thumbnail: {
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        height: 80,
        // padding: '35% 0',
        width: '100%',
        display: 'block',

    },
    checked: {
        fill: theme.palette.common.white
    },
    overlay: {
        position: 'absolute',
        bottom: 0,
        background: 'rgba(0, 0, 0, 0.5)', /* Black see-through */
        color: ' #f1f1f1',
        width: '100%',
        transition: '.5s ease',
        opacity: 0,
        fontSize: '20px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    thumnailContainer: {
        position: 'relative',
        cursor: 'pointer',
        height: '120px',
        width: '120px',
        minHeight: 120,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        '&:hover': {
            "& $overlay": {
                opacity: 1
            },
            "& $attachmentStatus": {
                opacity: 1,
                right: 10
            },
        }
    },
    imagePreviewIcon: {
        fill: theme.palette.common.white,
        width: '1.5em',
        height: '1.5em'
    },
    datePicker: {
        paddingRight: `${theme.spacing(0.5)} !important`
    },
    allSelectCheckbox: {
        opacity: 0,
        transition: '0.5s all'
    },
    attachmentStatus: {
        position: 'fixed',
        backgroundColor: '#fff',
        boxShadow: '1px 1px 6px #eee',
        bottom: 10,
        padding: theme.spacing(1.25),
        marginTop: '23px',
        right: '-100%',
        opacity: 0,
        minWidth: 300,
        transition: '1s all',
        zIndex: 2,
        ['@media screen and (max-width:668px)']: {
            bottom: 66,
        },
    },
    fileInput: {
        display: 'none'
    },
    rightAlignCheckBox: {
        position: 'absolute',
        right: 0,
        top: 0,
        padding: 0
    },
    generalInfoText: {
        padding: theme.spacing(2)
    },
    cardContainer: {
        width: "auto",
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(0, 1),
            background: theme.palette.common.white,
            borderRadius: 5
        }
    },
    generalInfoOptions: {
        [theme.breakpoints.up("sm")]: {
            paddingTop: theme.spacing(2),
        }
    },
    generalInfoTextContainer: {
        display: "block",
        height: "100%",
        [theme.breakpoints.up("sm")]: {
            borderRight: `1px solid ${theme.palette.grey[300]}`,
            paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.only("xs")]: {
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
        }
    },
    generalInfoTextPopover: {
        maxWidth: 575
    },
    generalInfoTextHeader: {
        [theme.breakpoints.down('md')]: {
            fontWeight: "bold",
            fontSize: 16
        }
    },
    generalContent: {
        [theme.breakpoints.down('md')]: {
            color: theme.palette.grey[500]
        }
    },
    generalMainContainer: {
        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(2)
        },
    },
}));
