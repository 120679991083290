import { DateRange } from '@mui/icons-material';
import { FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import React, { useMemo, useState } from 'react'
import { makeStyles } from '@mui/styles';
import Loader from './Loader';
import { useTranslation } from 'react-i18next';
import { ContractStatusKeys, ModeOfSigningKeys } from '../../../_helpers/enumsHelper';

function IndividualContractDetail( {contractDetails, updateContractDetails ,loadingKey, isDisabledEditing}) {
  const classes = useStyles();
  const [daysToSign, setDaysToSign] = useState(contractDetails?.daysToSign)
  const {t} = useTranslation()



  const numberOfSignature = useMemo(()=>{
    const emailIds = [contractDetails?.seller1?.email,contractDetails?.seller2?.email , contractDetails?.buyer1?.email, contractDetails?.buyer2?.email]
    return emailIds?.filter(email => Boolean(email))?.length || 0
  },[contractDetails])

  return (
    (<div> <Typography variant='subtitle1' sx={{
            mb: 1.5
          }}>{t("contract.details.title")}</Typography>
      <Stack sx={{
        rowGap: 2
      }}>
        <FormControl size="small" fullWidth>
          {loadingKey === "signaturetype" ? <Loader /> :<><InputLabel>{t("contract.details.signing")}</InputLabel>
          <Select
            value={contractDetails?.signatureType}
            label={t("contract.details.signing")}
            size='small'
            onChange={(e)=>{
              updateContractDetails("signaturetype" , e.target.value , "signatureType")
            }}
            disabled={isDisabledEditing}
          >
            <MenuItem value="20">{t("contract.signatureType.20")}</MenuItem>
            <MenuItem value="30">{t("contract.signatureType.30")}</MenuItem>
            {/* <MenuItem value="10">{t("contract.signatureType.10")}</MenuItem> */}
          </Select></>}
        </FormControl>
        <TextField
          fullWidth
          label={t("contract.details.signing.numberOfSignatures")}
          type="number"
          size='small'
          disabled
          value={numberOfSignature}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}
        />
        {![ContractStatusKeys.New,ContractStatusKeys.InProgress].includes(contractDetails?.status)&&<>  <DatePicker
          variant="standard"
          format="dd-MM-yyyy"
          id="start-date-picker"
          label={t("contract.details.contractCreationDate")}
          className={classes.customDatePicker}
          name="contractCreationDate"
          autoOk
          slotProps={{
            openPickerIcon: { className: classes.inputFieldIcon },
            openPickerButton: { edge: "end", size: "small" },
            textField: {
              variant: "outlined",
              size: "small",
              InputLabelProps: {
                shrink: true,
              }
            },
          }}
          slots={{
            openPickerButton: IconButton,
            openPickerIcon: DateRange,
          }}
          disabled
          value={contractDetails?.creationDate ? new Date(contractDetails?.creationDate) : contractDetails?.creationDate}
        />

          <DatePicker
          variant="standard"
          format="dd-MM-yyyy"
          id="expiry-date-picker"
          label={t("contract.details.contractExpiryDate")}
          className={classes.customDatePicker}
          name="contractExpiryDate"
          autoOk
          slotProps={{
            openPickerIcon: { className: classes.inputFieldIcon },
            openPickerButton: { edge: "end", size: "small" },
            textField: {
              variant: "outlined",
              size: "small",
              InputLabelProps: {
                shrink: true,
              }
            },
          }}
          slots={{
            openPickerButton: IconButton,
            openPickerIcon: DateRange,
          }}
          disabled
          value={contractDetails?.expiryDate ? new Date(contractDetails?.expiryDate) : contractDetails?.expiryDate}
        />
  </>}
        
         {loadingKey === "daystosign" ? <Loader /> :  [ModeOfSigningKeys.DigitalSign ,ModeOfSigningKeys.DigitalSignWithIdin ].includes(contractDetails?.signatureType) && <TextField
           fullWidth
           label={t("contract.details.signing.daysToSign")}
           type="number"
           size='small'
           value={daysToSign}
           onChange={(e)=>{
            const val = e.target.value
             if(!val){
               setDaysToSign("")
             }
             const numberValue = Number(val)
             if(numberValue <= 99 && numberValue>0){
               setDaysToSign(numberValue)
             }
           }}
           onBlur={(e)=>{
             updateContractDetails("daystosign" , e.target.value , "daysToSign")
           }}
           disabled={isDisabledEditing}
           slotProps={{
             input: {
               inputProps: { 
                   max: 99, min: 1
               }
           },

             inputLabel: {
               shrink: true,
             }
           }} />}

        <TextField
          fullWidth
          label={t("general.responsible")}
          type="text"
          size='small'
          disabled
          value={contractDetails?.salesRepresentative}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}        
        />

      <TextField
            fullWidth
            label={t("contract.status")}
            type="text"
            size='small'
            disabled
            value={t(`contract.status.${contractDetails?.status}`)}
            slotProps={{
              inputLabel: {
                shrink: true,
              }
            }}        
            />
       {![ContractStatusKeys.New,ContractStatusKeys.InProgress].includes(contractDetails?.status)&& [ModeOfSigningKeys.DigitalSign ,ModeOfSigningKeys.DigitalSignWithIdin ].includes(contractDetails?.signatureType) &&<> <TextField
          fullWidth
          label={t("scrive.scriveId")}
          type="text"
          size='small'
          disabled
          value={contractDetails?.scriveDocumentId}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}        
          />
        <TextField
          fullWidth
          label={t("scrive.scriveStatus")}
          type="text"
          size='small'
          disabled
          value={t(`scrive.documentStatus.${contractDetails?.scriveDocumentStatus}`)}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}        
        />
        </>}
      </Stack></div>)
  );
}

export default IndividualContractDetail


const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 999
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
    },
    height: "100%",
  },
  grow: {
    flexGrow: 1,
  },
  bold: {
    fontWeight: "bold",
  },
  icon: {
    fontSize: "1.4em",
  },
}));