import { useEffect, useState } from 'react';
import { ArrowDownward as ArrowDownwardIcon } from '@mui/icons-material';
import { Box, Stack,  Grid2 as Grid } from '@mui/material';
import { makeStyles } from "@mui/styles";
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DesktopStepsToUploadDataFilesCard from "./Components/DesktopStepsToUploadDataFilesCard"
import Dropzone from '../../../components/Dropzone';
import FileUploadProgress from './Components/FileUploadProgress';
import ImportFileHeader from './Components/ImportFileHeader';
import { URLS } from '../../../apis/urls';
import { createAndDownloadTextFile, downloadFile } from '../../../_helpers';
import { jsonIsAvailableForImport, proceedToImport, uploadExcelFileForImportData } from '../../../apis/aftercareApis';
import { getErrorTextContent } from './utils';
import CustomAlert from './Components/CustomAlert';

export const ImportFilesForDataLoad = () => {
  const { user } = useSelector(state => state.authentication)
  const classes = useStyles();
  const [progressInfo, setProgressInfo] = useState({progress:0, fileName:"import", fileSize:""});
  const [isDownloadingExcelFile, setIsDownloadingExcelFile] = useState(false)
  const [uploadedFileInfo, setUploadedFileInfo] = useState({
    isError: false,
    successInfo: null,
    errorInfo: null,
  })
  const [proceedFileInfo, setProceedFileInfo] = useState({
    successInfo: null,
    errorInfo: null,
    warningInfo: null,
    isProceeding: false,
    isProceedAlertEnable: false
  })
  const { t } = useTranslation();

  const config = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total  } = progressEvent;
      let percent = Math.floor((loaded * 100) / total)
      if (percent <= 100) {
        setProgressInfo({...progressInfo,progress: percent,fileSize:`${Math.round(
          (total / (1024 * 1024)) * 100
        ) / 100} mb`}) 
      }
    }
  }

  const handleSelectFiles = async (e) => {
    try {
      // Reseting value
      setProgressInfo({...progressInfo,progress: 0,fileSize:""})
      setUploadedFileInfo({
        isError: false,
        successInfo: null,
        errorInfo: null,
      })
      setProceedFileInfo({
        errorInfo: null,
        warningInfo: null,
        successInfo: null,
        isProceeding: false,
        isProceedAlertEnable: false
      })

      try {
        // check if it is super admin, if yes then check already file is present in backend
        if (user.isSuperAdmin) {
          const isExistJsonRes = await jsonIsAvailableForImport()
          if (isExistJsonRes?.data?.success) {
            const isOkay = window.confirm(t("settings.import.errorLog.isJsonAlreadyExist"))
            if (!isOkay) {
              setUploadedFileInfo({
                isError: false,
                successInfo: null,
                errorInfo: null,
              })
              setProceedFileInfo({
                errorInfo: null,
                warningInfo: null,
                successInfo: null,
                isProceeding: false,
                isProceedAlertEnable: true
              })
              setProgressInfo({...progressInfo,progress: 0,fileSize:""})
              return
            }
          }
        }
      } catch (e) {
        console.log(e)
      }

      // if user want to override or want to validate then this flow will be run

      let formData = new FormData();
      formData.append("file", e[0])
      const res = await uploadExcelFileForImportData(formData, config)
      let data = {}

      // if validation success
      if (res?.data?.validateSucces) {

        // show success alert and proceed but enable
        setUploadedFileInfo({ isError: false, errorInfo: null, successInfo: res?.data?.results })
        setProceedFileInfo({
          errorInfo: null,
          warningInfo: null,
          successInfo: null,
          isProceeding: false,
          isProceedAlertEnable: true
        })
      } else {
        // if error then show error alert and display download error button
        res?.data?.excelErrors?.forEach((error) => {
          if (error?.sheetName && error?.rowNo) {
            data[error.sheetName] = data[error.sheetName] || {}
            data[error.sheetName][error.rowNo] = [...(data[error.sheetName][error?.rowNo] || []), error]
          }
        })

        if (res?.data?.missingMandatoryFields) {
          Object.keys(res?.data?.missingMandatoryFields).map((sheetName) => {
            data[sheetName] = data[sheetName] || {}
            data[sheetName] = { ...data[sheetName], sheetErrors: res?.data?.missingMandatoryFields?.[sheetName] }
          })
        }
        setUploadedFileInfo({ isError: true, errorInfo: data, successInfo: null })
        setProceedFileInfo({
          errorInfo: null,
          warningInfo: null,
          successInfo: null,
          isProceeding: false,
          isProceedAlertEnable: false
        })
      }
      setProgressInfo({...progressInfo,progress: 0,fileSize:""})
    } catch (e) {
      console.log(e)
    }
  };

  const handleDownload = async () => {
    try {
      setIsDownloadingExcelFile(true)
      await downloadFile(URLS.DOWNLOAD_EXCEL_TEMPLATE_FOR_IMPORT, "import")
    } catch (e) {
      console.log(e)
    } finally {
      setIsDownloadingExcelFile(false)
    }
  }

  const getTotalCount = (data, sheetName, key = "importCount") => {
    return data?.find(sheetInfo => sheetInfo?.name === sheetName)?.[key]
  }

 


  const hanldeProceedToImport = async () => {

    try {
      // Reseting value
      setProgressInfo({...progressInfo,progress: 0,fileSize:""})
      setUploadedFileInfo({
        isError: false,
        successInfo: null,
        errorInfo: null,
      })
      setProceedFileInfo({
        isProceeding: true,
        errorInfo: null,
        warningInfo: null,
        successInfo: null
        , isProceedAlertEnable: true
      })
      const res = await proceedToImport()

      if (!res.data?.validateSucces) {
        setProceedFileInfo({
          isProceeding: false,
          errorInfo: res.data?.errorMessage,
          warningInfo: null,
          successInfo: null,
          isProceedAlertEnable: false
        })
      }
      if (res.data.validateSucces && res.data.errorMessage) {
        setProceedFileInfo({
          isProceeding: false,
          errorInfo: res.data?.errorMessage,
          warningInfo: res.data.results,
          successInfo: null,
          isProceedAlertEnable: false
        })
      }

      if (res.data.validateSucces && !res.data.errorMessage && res.data.results) {
        setProceedFileInfo({
          isProceeding: false,
          errorInfo: null,
          warningInfo: null,
          successInfo: res.data.results,
          isProceedAlertEnable: false
        })
      }
    } catch (error) {
      setProceedFileInfo({ ...proceedFileInfo, isProceeding: false })
      console.log(error)
    }
  }

  useEffect(() => {
    (async () => {
      try {
        if (user.isSuperAdmin) {
          const isExistJsonRes = await jsonIsAvailableForImport()
          if (isExistJsonRes?.data?.success) {
            setProceedFileInfo({
              ...proceedFileInfo,
              isProceedAlertEnable: true
            })
          }
        }
      } catch (e) {
        console.log(e)
      }
    })()
  }, [])


  return (
    (<Box
        sx={{
          height: "100%",
          display: "flex"
        }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          overflow: "scroll",
          width: "100%"
        }}>
        <Grid container spacing={2} sx={{
          width: { xs: "100%", md: "90%" }
        }}>
          <Grid
            size={{
              xs: "grow",
              md: 6
            }}>
            <Stack
              sx={{
                justifyContent: 'center',
                p: { md: 4 }
              }}>
              <Box sx={{
                display: { xs: "block", md: 'none' }
              }}>
                <ImportFileHeader />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  mb: 5
                }}>
                <LoadingButton loading={isDownloadingExcelFile} disabled={proceedFileInfo?.isProceeding} endIcon={<ArrowDownwardIcon />} variant='contained' size='small'
                  onClick={handleDownload}
                >{t("settings.import.downloadTemplate")}</LoadingButton>
              </Box>
              <Box
                sx={{
                  mx: "auto",
                  minWidth: "60%"
                }}>
                <Dropzone
                  multiple={false}
                  iconClasses={classes.cloudIcon}
                  accept=".xlsx, .xls"
                  onFilesAdded={(e) => {
                    handleSelectFiles(e)
                  }}
                  disabled={proceedFileInfo?.isProceeding}
                />
              </Box>
              <Box
                sx={{
                  p: 2,
                  mt: { md: 2 }
                }}>
                {progressInfo?.progress ? <FileUploadProgress progress={progressInfo?.progress} fileName={progressInfo?.fileName} fileSize={progressInfo?.fileSize}/> : false}
                {uploadedFileInfo?.isError && uploadedFileInfo?.errorInfo && <CustomAlert
                  type="error"
                  title={t("settings.import.validation.error.title")}
                  description={t("settings.import.validation.error.description")}
                  endButtonProps={{
                    onClick: () => createAndDownloadTextFile({ content: getErrorTextContent({ errors: uploadedFileInfo?.errorInfo, t }) }),
                    buttonText: t("general.download")
                  }} />}

                {!uploadedFileInfo?.isError && uploadedFileInfo.successInfo && <CustomAlert
                  type="success"
                  title={t("settings.import.success.title")}
                  description={t("settings.import.validation.success.description", {
                    noOfProjets: getTotalCount(uploadedFileInfo?.successInfo, "Werken"), noOfObjects: getTotalCount(uploadedFileInfo?.successInfo, "Objecten"), noOfOrganization: getTotalCount(uploadedFileInfo?.successInfo, "Organisaties"), skipOrganisationCount: getTotalCount(uploadedFileInfo?.successInfo, "Organisaties", "toBeSkipped")?.length, noOfRelations: getTotalCount(uploadedFileInfo?.successInfo, "Relaties"), noOfBuyers: getTotalCount(uploadedFileInfo?.successInfo, "KoperHuurders"), noOfRepairRequest: getTotalCount(uploadedFileInfo?.successInfo, "Meldingen")
                  })} />}

                {user.isSuperAdmin && proceedFileInfo.isProceedAlertEnable && <CustomAlert
                  type="info"
                  title={t("general.admin")}
                  description={t("settings.import.proceedToImportInfo")}
                  endButtonProps={{
                    onClick: hanldeProceedToImport,
                    buttonText: t("general.proceed"),
                    endIcon: null,
                    loading: proceedFileInfo?.isProceeding
                  }} />}


                {user.isSuperAdmin && proceedFileInfo.warningInfo && <CustomAlert
                  type="warning"
                  title={t("general.admin")}
                  description={t("settings.import.warning.description", {
                    noOfProjets: getTotalCount(proceedFileInfo?.warningInfo, "Werken"), noOfObjects: getTotalCount(proceedFileInfo?.warningInfo, "Objecten"), noOfOrganization: getTotalCount(proceedFileInfo?.warningInfo, "Organisaties"), noOfRelations: getTotalCount(proceedFileInfo?.warningInfo, "Relaties"), noOfBuyers: getTotalCount(proceedFileInfo?.warningInfo, "KoperHuurders"), noOfRepairRequest: getTotalCount(proceedFileInfo?.warningInfo, "Meldingen"), noOfAdress: getTotalCount(proceedFileInfo?.warningInfo, "Adres"), noOfPerson: getTotalCount(proceedFileInfo?.warningInfo, "Persoon"), noOfOplosser: getTotalCount(proceedFileInfo?.warningInfo, "Oplosser"), noOfWoningTypes: getTotalCount(proceedFileInfo?.warningInfo, "WoningTypes")
                  })}
                  endButtonProps={{
                    onClick: () => createAndDownloadTextFile({ content: proceedFileInfo.errorInfo }),
                    buttonText: t("general.download")
                  }}
                />}

                {user.isSuperAdmin && proceedFileInfo?.errorInfo && <CustomAlert
                  type="error"
                  title={t("general.admin")}
                  description={t("settings.import.error.description")}
                  endButtonProps={{
                    onClick: () => createAndDownloadTextFile({ content: proceedFileInfo.errorInfo }),
                    buttonText: t("general.download")
                  }} />}

                {user.isSuperAdmin && proceedFileInfo.successInfo && <CustomAlert
                  type="success"
                  title={t("general.admin")}
                  description={t("settings.import.success.description", {
                    noOfProjets: getTotalCount(proceedFileInfo?.successInfo, "Werken"), noOfObjects: getTotalCount(proceedFileInfo?.successInfo, "Objecten"), noOfOrganization: getTotalCount(proceedFileInfo?.successInfo, "Organisaties"), noOfRelations: getTotalCount(proceedFileInfo?.successInfo, "Relaties"), noOfBuyers: getTotalCount(proceedFileInfo?.successInfo, "KoperHuurders"), noOfRepairRequest: getTotalCount(proceedFileInfo?.successInfo, "Meldingen"), noOfAdress: getTotalCount(proceedFileInfo?.successInfo, "Adres"), noOfPerson: getTotalCount(proceedFileInfo?.successInfo, "Persoon"), noOfOplosser: getTotalCount(proceedFileInfo?.successInfo, "Oplosser"), noOfWoningTypes: getTotalCount(proceedFileInfo?.warningInfo, "WoningTypes")
                  })} />}
              </Box>
            </Stack >
          </Grid>
          <Grid
            sx={{
              display: { xs: 'none', md: 'block' }
            }}
            size={{
              md: 6
            }}>
            <Box sx={{
              p: { md: 4 }
            }}>
              <DesktopStepsToUploadDataFilesCard />
            </Box>
          </Grid>
        </Grid >
      </Box >
    </Box>)
  );
}


const useStyles = makeStyles({
  cloudIcon: {
    height: 150,
    width: 150,
    maxHeight: 253,
    maxWidth: 253
  }
});
