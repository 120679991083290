import React, { useState } from "react";
import { Typography, IconButton, Popover, CardContent,  Grid2 as Grid, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {
  Business,
  Close,
  HomeWork,
  Person,
  AllInclusive,
  Info,
  DateRange,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { formatDate } from "../../../_helpers";

const WBProjectInfo = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const { wbProjectInfo, getWBProjectLoading } = useSelector(
    (state) => state.surveying
  );

  const getWBProjectInfoHandler = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return <>
    <IconButton
      disabled={getWBProjectLoading}
      onClick={getWBProjectInfoHandler}
      className={classes.projectInfoIcon}
      size="large">
      {getWBProjectLoading ? (
        <CircularProgress size={20} />
      ) : (
        <Info style={{ fontSize: 20 }} />
      )}
    </IconButton>
    {!!anchorEl && wbProjectInfo ? (
      <Popover
        id="wb-project-info"
        style={{ overflow: "hidden" }}
        open={!!anchorEl}
        anchorEl={anchorEl}
        classes={{
          root: classes.root,
          paper: classes.paper,
        }}
        onClose={onClose}
      >
        <>
          <CardContent
            className={clsx(classes.cardContentStyle, classes.titleContainer)}
          >
            <Typography className={classes.projectTitle} gutterBottom>
              {t("wkb.wbProjectInfo")}
            </Typography>
            <IconButton
              className={classes.closeIcon}
              aria-label="settings"
              onClick={onClose}
              size="large">
              <Close className={clsx(classes.create, classes.closeSVGIcon)} />
            </IconButton>
          </CardContent>
          <CardContent className={classes.cardContentStyle}>
            <div className={classes.paperContainer}>
              {wbProjectInfo.wbProjectName && (
                <Paper className={classes.papers}>
                  <div className={classes.paperSubContainer}>
                    <Typography className={classes.cardTitle}>
                      {t("wkb.wbProjectName")}
                    </Typography>
                    <span className={classes.cardIcon}>
                      <HomeWork />
                    </span>
                  </div>
                  <Typography className={classes.cardSubTitle}>
                    {t(wbProjectInfo.wbProjectName)}
                  </Typography>
                </Paper>
              )}

              {wbProjectInfo.planNumber && (
                <Paper className={classes.papers}>
                  <div className={classes.paperSubContainer}>
                    <Typography className={classes.cardTitle}>
                      {t("wkb.wbProject.planNumber")}
                    </Typography>
                    <span className={classes.cardIcon}>
                      <AllInclusive />
                    </span>
                  </div>
                  <Typography className={classes.cardSubTitle}>
                    {t(wbProjectInfo.planNumber)}
                  </Typography>
                </Paper>
              )}

              {wbProjectInfo.wbProjectManager && (
                <Paper
                  className={classes.papers}
                  style={{ minWidth: "100%" }}
                >
                  <div className={classes.paperSubContainer}>
                    <Typography className={classes.cardTitle}>
                      {t("wkb.wbResponsible")}
                    </Typography>
                    <span className={classes.cardIcon}>
                      <Person />
                    </span>
                  </div>
                  <Typography className={classes.cardSubTitle}>
                    {t(wbProjectInfo.wbProjectManager) || "-"}
                  </Typography>
                </Paper>
              )}

              {wbProjectInfo.archivedAt && (
                <Paper className={classes.papers} style={{ width: "100%" }}>
                  <div className={classes.paperSubContainer}>
                    <Typography className={classes.cardTitle}>
                      {t("general.archivedDate")}
                    </Typography>
                    <span className={classes.cardIcon}>
                      <DateRange />
                    </span>
                  </div>
                  <Typography className={classes.cardSubTitle}>
                    {formatDate(new Date(wbProjectInfo.archivedAt))}
                  </Typography>
                </Paper>
              )}

              <Paper className={classes.papers}>
                <div className={classes.paperSubContainer}>
                  <Typography className={classes.cardTitle}>
                    {t("layout.navbar.address")}
                  </Typography>
                  <span className={classes.cardIcon}>
                    <Business />
                  </span>
                </div>
                <Grid container>
                  <Grid size={{xs:5}}>
                    <Typography className={classes.cardSubTitle}>
                      {" "}
                      {t("general.streetAndHouseNumber")}
                    </Typography>
                  </Grid>
                  <Grid size={{xs: 1}}>
                    <Typography className={classes.cardSubTitle}>
                      :
                    </Typography>
                  </Grid>
                  <Grid size={{xs: 6}}>
                    {wbProjectInfo.address && (
                      <Typography className={classes.cardSubTitle}>
                        {`${wbProjectInfo.address.street || ""},${
                          wbProjectInfo.address.houseNo || ""
                        }`}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid size={{xs:5}}>
                    <Typography className={classes.cardSubTitle}>
                      {" "}
                      {t("general.address.postcodeAndCity")}
                    </Typography>
                  </Grid>
                  <Grid size={{xs: 1}}>
                    <Typography className={classes.cardSubTitle}>
                      :
                    </Typography>
                  </Grid>
                  <Grid size={{xs: 6}}>
                    {wbProjectInfo.address && (
                      <Typography className={classes.cardSubTitle}>
                        {`${wbProjectInfo.address.place || ""},${
                          wbProjectInfo.address.postcode || ""
                        }`}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </CardContent>
        </>
      </Popover>
    ) : null}
  </>;
};

export default WBProjectInfo;

const useStyles = makeStyles((theme) => ({
  projectTitle: {
    margin: 0,
    marginLeft: 5,
    fontSize: 16,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    color: theme.palette.grey[800],
    fontWeight: "bold",
  },
  grow: {
    flexGrow: 1,
  },
  paper: {
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    width: 370,
    borderRadius: 7,
  },
  create: {
    color: "inherit",
  },
  cardContentStyle: {
    position: "relative",
    padding: theme.spacing(1),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.7),
    justifyContent: "space-between",
  },
  closeIcon: {
    padding: theme.spacing(0.4),
  },
  closeSVGIcon: {
    fontSize: 17,
    fontWeight: "bold",
    color: theme.palette.grey[800],
  },
  infoGridRow: {
    "& > div": {
      padding: theme.spacing(0.5, 2),
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "& span:last-child": {
      color: theme.palette.primary.main,
    },
    "& .MuiInputLabel-outlined": {
      whiteSpace: "nowrap",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  checkboxRoot: {
    padding: theme.spacing(0, 1, 0, 0),
  },
  papers: {
    // minWidth: 150,
    flexGrow: 1,
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[200],
    borderRadius: 6,
    boxShadow: "none",
  },
  paperSubContainer: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: 22,
  },
  cardTitle: {
    fontSize: 16,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    color: theme.palette.grey[500],
  },
  cardSubTitle: {
    fontSize: 14,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
  },
  cardIcon: {
    color: theme.palette.grey[500],
  },
  paperContainer: {
    display: "flex",
    gap: 10,
    flexWrap: "wrap",
    overflow: "hidden",
  },
  projectInfoIcon: {
    padding: 3,
    marginLeft: 5,
  },
}));
