import { Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';

function Address({contract}) {

  const {t} = useTranslation()

  return (
    (<div><Typography
            variant='subtitle1'
            sx={{
              mb: 1.5,
              mt: 2
            }}>{t("general.address")}</Typography>
      <Stack sx={{
        rowGap: 2
      }}>
        <TextField
          fullWidth
          label={t("general.address.street")}
          size='small'
          type="text"
          disabled
          value={contract?.objectAddress?.street}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}
        />
        <Stack direction='row' sx={{
          columnGap: 1.5
        }}>
          <TextField
            fullWidth
            size='small'
            label={t("general.number")}
            type="number"
            disabled
          value={contract?.objectAddress?.houseNo}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}
          />
          <TextField
            fullWidth
            size='small'
            label={t("general.address.numberAddition")}
            type="number"
            disabled
          value={contract?.objectAddress?.houseNoAddition}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}
          />
        </Stack>
        <Stack direction='row' sx={{
          columnGap: 1.5
        }}>
          <TextField
            fullWidth
            size='small'
            label={t("general.address.postcode")}
            type="text"
            disabled
          value={contract?.objectAddress?.postcode}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}
            
          />
          <TextField
            fullWidth
            size='small'
            label={t("general.address.city")}
            type="text"
            disabled
          value={contract?.objectAddress?.place}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}
          />
        </Stack>
        <TextField
          fullWidth
          size='small'
          label={t("general.country")}
          type="text"
          disabled
          value={contract?.objectAddress?.country}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}
        />
      </Stack> </div>)
  );
}

export default Address