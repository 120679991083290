import React, { useEffect, useState } from 'react'
import DataGrid from '../../../components/DataGrid';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getBuildingsInfoForGeneration } from '../../../apis/agendaApis';
import { useParams } from 'react-router-dom';
import { Container,  Grid2 as Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { formatDate, generateLocalStorageKey } from '../../../_helpers';

const BuildingsListForGeneration = () => {
  const [buildingListInfoForGeneration, setBuildingListInfoForGeneration] = useState([])
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState({ name: "buildingNoIntern", direction: "asc" })
  
  const { t } = useTranslation();
  const {all:allBuildings } = useSelector(state => state.buildings)
  let { generationId } = useParams();
  const classes = useStyles();

  const buildingsInfoForGenerationColumns = [
    
    {
      name:"buildingNoIntern",
      label:t("general.buildingNumber"),
      options:{
        filter: false,
        sort: true,
        print: true,
        download: false,
  },
    },
    {
      name:"projectNo",
      label:t("general.projectNumber"),
      options:{
        filter: false,
        sort: false,
        print: true,
        download: false,
      },
    }, {
      name:"projectName",
      label:t("general.projectName"),
      options:{
        filter: false,
        sort: false,
        print: true,
        download: false,
      },
    }, {
      name:"status",
      label:t("general.status"),
      options:{
        filter: false,
        sort: false,
        print: true,
        download: false,
      },
    }, {
      name:"buildingType",
      label:t("general.buildingType"),
      options:{
        filter: false,
        sort: false,
        print: true,
        download: false
      },
    },
    {
      name:"buyerRenterName",
      label:t("general.buyerRenterName"),
      options:{
        filter: false,
        sort: false,
        print: true,
        download: false,
    },
    },
    {
      name: "startDateTime",
      label: t("general.startDate"),
    },
    {
      name: "endDateTime",
      label: t("general.endDate"),
    },
  ]


  const getBuildingListAllInfo = (data)=>{
    return data?.map((item)=>{
      const buildingInfo = allBuildings?.find(building=>building.buildingId === item.buildingId)
      return {...item,
       endDateTime:item.endDateTime ?formatDate(new Date(item.endDateTime), true):"",
       startDateTime: item.endDateTime ?formatDate(new Date(item.startDateTime), true):"",
       buyerRenterName :buildingInfo?.buyerRenterName || "",
       buildingType :buildingInfo?.buildingType || "",
       status :buildingInfo?.status || "",
       projectName :buildingInfo?.projectName || "",
       projectNo :buildingInfo?.projectNo || "",
       buildingNoIntern:buildingInfo?.buildingNoIntern || "",
      }
    })
  }


  const getBuildingListInfoForGeneration = async () => {
    setLoading(true);
    const generation = await getBuildingsInfoForGeneration(generationId)

    setBuildingListInfoForGeneration(getBuildingListAllInfo(generation.data) || [])
   
    setLoading(false)
}


  useEffect(() => {
    getBuildingListInfoForGeneration()
  }, [])

  const tableOptions = {

    sortOrder: sortOrder,
    onTableChange: (action, tableState) => {
      const sortDataLocal = Object.keys(tableState.sortOrder).length ? tableState.sortOrder : null;
      switch (action) {
        case "sort": {
          setSortOrder(sortDataLocal);
          break;
        }
        default: {
          break;
        }
      }
    },
  }
  
  return (
    (<Container maxWidth={false} className={clsx(classes.mainContainer, classes.activeStepMainContainer)}>
      <Grid size={{xs:12}}>
       <DataGrid
       title={t("agenda.timeSlots.objectOverview")}
       options={tableOptions}
       localColumnSortIdentifier={generateLocalStorageKey("BuidingListForGeneration_SortOrder")}
       columns={buildingsInfoForGenerationColumns}
       data={buildingListInfoForGeneration}
       loading={loading}
       getData={true}
       fontSize={12}
       canGoBack
       defaultSort={sortOrder}
     />
       </Grid>
    </Container>)
  );
}

export default BuildingsListForGeneration


const useStyles = makeStyles(theme => ({
  mainContainer: {
    overflow: "auto",
  },
  activeStepMainContainer: {
    padding: theme.spacing(2),
  },
 
}))