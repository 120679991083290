import React, { useEffect, useState } from "react";
import { ArrowBack, Edit, ExpandMore } from "@mui/icons-material";
import { AccordionDetails, Box,  Grid2 as Grid, IconButton, Accordion, Divider, Paper, Stack, Typography, AccordionSummary, Snackbar, Alert } from "@mui/material";
import { common, grey } from "@mui/material/colors";
import SelectResponsiblePerson from "../../Survey/components/SelectResponsiblePerson";
import { history } from "../../../_helpers";
import { getProjectDetails, updateResponsiblePersonForProject } from "../../../apis/aftercareApis";
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { userAccountRoleConstants } from "../../../_constants";

const ProjectDetails = () => {
    const { t } = useTranslation();
    const { projectId } = useParams();
    const [organizationsLoading, setOrganizationsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [projectDetails, setProjectDetails] = useState(null);
    const [responsiblePersonRole, setResponsiblePersonRole] = useState("");
    const [toast, setToast] = useState({
        open: false,
        message: "",
        type: "error"
    })

    const getProjectInfo = async () => {
        try {
            const response = await getProjectDetails(projectId);
            const responseData = response.data;
            setProjectDetails(responseData)
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        getProjectInfo();
    }, []);


    const handleResponsiblePerson = async (data) => {
        try {
            const response = await updateResponsiblePersonForProject(projectId, data.loginId, responsiblePersonRole);
            if (response.status === 200) {
                getProjectInfo()
                setToast({
                    open: true,
                    message: t(userAccountRoleConstants?.aftercareEmployee?.toLowerCase() === responsiblePersonRole ? "project.updateResponsiblePersonAfterCareEmployee.success" : "project.updateResponsiblePersonSiteManager.success"),
                    type: "success"
                });
            } else {
                setToast({
                    open: true,
                    message: t("general.failedToUpdate"),
                    type: "error"
                });
            }
            setAnchorEl(null);
            setIsSubmitting(false)
        } catch (error) {
            setToast({
                open: true,
                message: t("general.failedToUpdate"),
                type: "error"
            });
            setAnchorEl(null);
            setIsSubmitting(false)
        }
    }

    return (
        (<Stack
                    sx={{
                        width: "100%",
                        minHeight: "100%",
                        height: "100%"
                    }}>
            <Stack
                direction="row"
                sx={{
                    bgcolor: common.white,
                    justifyContent: "start",
                    alignItems: "center",
                    py: 1,
                    px: 3
                }}>
                <IconButton edge="start" onClick={() => history.goBack()}>
                    <ArrowBack sx={{ cursor: "pointer" }} />
                </IconButton>
                <Typography variant="subtitle1" sx={{
                    fontWeight: 500
                }}>{`${t("general.project")} - ${projectDetails?.projectNo} - ${projectDetails?.projectName}`}</Typography>
            </Stack>
            <Box
                sx={{
                    flex: 1,
                    bgcolor: grey[100],
                    p: 2
                }}>
                <Paper sx={{ width: "100%", bgcolor: "white" }} elevation={0}>
                    <Typography
                        variant="h6"
                        sx={{
                            p: 1,
                            px: 2
                        }}>
                        {t("general.responsible")}
                    </Typography>
                    <Divider />
                    <Grid container sx={{
                        p: 1
                    }} size={{xs:12}}>
                        <Grid
                            sx={{
                                p: 1,
                                borderRight: { xs: "none", md: `1px dashed  ${grey[200]}` }
                            }}
                            size={{
                                xs: 12,
                                md: 4
                            }}>
                            <Typography variant="subtitle2">{t("general.general")}</Typography>
                            <Box sx={{
                                pt: 2
                            }}>
                                <Stack direction="row" sx={{
                                    alignItems: "center"
                                }}>
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            width: "40%",
                                            color: grey[600]
                                        }}>
                                        {t("general.afterCareEmployee")}
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        sx={{
                                            width: "60%",
                                            justifyContent: "space-between",
                                            alignItems: "center"
                                        }}>
                                        <Typography variant="caption">{projectDetails?.responsibleAfterCareEmployee?.relationName}</Typography>
                                        <IconButton onClick={(e) => {
                                            setAnchorEl(e.currentTarget)
                                            setResponsiblePersonRole(userAccountRoleConstants?.aftercareEmployee?.toLowerCase())
                                        }}>
                                            <Edit
                                                color={grey[600]}
                                                sx={{ fontSize: "medium", cursor: "pointer" }}
                                            />
                                        </IconButton>

                                    </Stack>
                                </Stack>
                                <Stack direction="row" sx={{
                                    alignItems: "center"
                                }}>
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            width: "40%",
                                            color: grey[600]
                                        }}>
                                        {t("general.siteManager")}
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        sx={{
                                            width: "60%",
                                            justifyContent: "space-between",
                                            alignItems: "center"
                                        }}>
                                        <Typography variant="caption">{projectDetails?.responsibleSiteManager?.relationName}</Typography>
                                        <IconButton onClick={(e) => {
                                            setAnchorEl(e.currentTarget)
                                            setResponsiblePersonRole(userAccountRoleConstants?.siteManager?.toLowerCase())
                                        }}>
                                            <Edit
                                                color={grey[600]}
                                                sx={{ fontSize: "medium", cursor: "pointer" }}
                                            />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>

                {/* FIXME: uncomment below section and update it later */}
                {/* <Accordion defaultExpanded sx={{ mt: 2 }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="h6">Kaartinformatie</Typography>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails sx={{ p: 1 }}>
                        <Grid container xs={12}>
                            <Grid
                                xs={12}
                                md={4}
                                p={1}
                                borderRight={{ xs: "none", md: `1px dashed ${grey[200]}` }}
                            >
                                <Typography variant="subtitle2">Mutatiehistorie</Typography>
                                <Box pt={2}>
                                    <Stack
                                        width="100%"
                                        direction="row"
                                        justifyContent="space-between"
                                    >
                                        <Stack width="50%">
                                            <Typography variant="caption" color={grey[600]}>
                                                Ingevoerd op:
                                            </Typography>
                                        </Stack>
                                        <Stack width="50%">
                                            <Typography variant="caption">
                                                10-4-2024 09:39:58
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction="row">
                                        <Typography width="50%" variant="caption" color={grey[600]}>
                                            Ingevoerd door:
                                        </Typography>
                                        <Typography width="50%" variant="caption">
                                            JPDS
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Typography width="50%" variant="caption" color={grey[600]}>
                                            Gewijzigd op:
                                        </Typography>
                                        <Typography width="50%" variant="caption">
                                            10-4-2024 09:39:58
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row">
                                        <Typography width="50%" variant="caption" color={grey[600]}>
                                            Gewijzigd door:
                                        </Typography>
                                        <Typography width="50%" variant="caption">
                                            Johan van Eijsden
                                        </Typography>
                                    </Stack>
                                    <Stack direction="row">
                                        <Typography width="50%" variant="caption" color={grey[600]}>
                                            Sleutelwaarde:
                                        </Typography>
                                        <Typography width="50%" variant="caption">
                                            0014971A-BED1-4C86-BBD9-99AF2AA3FE36
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion> */}

                <SelectResponsiblePerson
                    loading={organizationsLoading}
                    isSubmitting={isSubmitting}
                    customTitle={t("general.responsible")}
                    projectId={projectId}
                    onSubmit={async (values, setSelectResponsiblePersonState) => {
                        await handleResponsiblePerson(values)
                        setSelectResponsiblePersonState?.((prevState) => ({
                            ...prevState,
                            relationId: ""
                        }))
                    }
                    }
                    setLoading={(val) => {
                        setOrganizationsLoading(val);
                        setIsSubmitting(true);
                    }}
                    forProjects
                    role={responsiblePersonRole}
                    anchorEl={anchorEl}
                    onClose={() => {
                        setAnchorEl(null);
                    }}
                />

                <Snackbar
                    open={toast.open}
                    autoHideDuration={6000}
                    onClose={() =>
                        setToast({
                            open: false,
                            message: "",
                            type: "error"
                        })
                    }
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    key={toast.message}
                >
                    <Alert
                        onClose={() =>
                            setToast({
                                open: false,
                                message: "",
                                type: "error"
                            })
                        }
                        severity={toast.type}
                    >
                        {toast.message}
                    </Alert>
                </Snackbar>
            </Box>
        </Stack>)
    );
};



export default ProjectDetails;
