import { Stack, TextField, Typography } from '@mui/material'
import React  from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
const General = () => {
  const { selected} = useSelector(state => state.buildings)
  const {t} = useTranslation()

  return (
    (<div><Typography variant='subtitle1' sx={{
            mb: 1.5
          }}>{t("general.general")}</Typography>
      <Stack sx={{
        rowGap: 2
      }}>
        <TextField
          fullWidth
          label={t("general.projectName")}
          type="text"
          size='small'
          disabled
          value={selected?.projectName}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}
        />
        <TextField
          fullWidth
          size='small'
          label={t("general.objectNumber")}
          type="text"
          disabled
          value={selected?.buildingNoIntern}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}
        />
        <TextField
          fullWidth
          label={t("general.building.type")}
          size='small'
          type="text"
          disabled
          value={selected?.buildingType}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}
        />
        <TextField
          fullWidth
          label={t("general.type")}
          size='small'
          type="text"
          disabled
          value={selected?.propertyType}
          slotProps={{
            inputLabel: {
              shrink: true,
            }
          }}
        />
      
      </Stack></div>)
  );
}

export default General