import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Popper,
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  Tooltip,
  IconButton,
  AppBar,
  TextField,
  FormGroup,
  Grid2 as Grid,
  Switch,
  CircularProgress,
  Popover,
  Paper,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import {
  ZoomIn,
  ZoomOut,
  FiberManualRecord,
  LocalPrintshop,
  CloudDownload,
  FilterList,
  ArrowBack,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Autocomplete } from "@mui/material";
import clsx from "clsx";
import { red, green, yellow } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getDrawingFiles } from "../../apis/dossiersApi";
import { URLS } from "../../apis/urls";
import {
  base64ToFile,
  downloadFile,
  fileToBlob,
  formatDate,
  groupBy,
  history,
  useInternetStatusDetector,
} from "../../_helpers";
import { ApplicationContext } from "../..";
import { useLocation } from "react-router-dom";
import BuildingFilter from "./BuildingFilter";
import { userAccountTypeConstants } from "../../_constants";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ENUMS = {
  Nieuw: 0,
  "In behandeling": 1,
  Afgehandeld: 2,
};
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 51,
    height: 24,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 4,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(26px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: "50px",
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function roundRect(ctx, x, y, width, height, radius, fill, stroke) {
  if (typeof stroke == "undefined") {
    stroke = true;
  }
  if (typeof radius === "undefined") {
    radius = 5;
  }
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();

  if (stroke) {
    ctx.stroke();
  }
  if (fill) {
    ctx.fill();
  }
}

function drawTriangle(context, PosX, PosY, radius, rotate) {
  context.beginPath();
  var sides = 3;
  var a = (Math.PI * 2) / sides;
  for (var i = 0; i < sides; i++) {
    context.lineTo(
      PosX + radius + 10 * Math.cos(a * i + rotate),
      PosY + radius * Math.sin(a * i + rotate)
    );
  }
  context.closePath();
  context.strokeStyle = "#0000001c";
  context.stroke();
  context.fillStyle = "#fff";
  context.fill();
  return true;
}

export default function ConstructionQualityMapPage({ ...props }) {
  const { t } = useTranslation();
  const [selectedDrawingFiles, setSelectedDrawingFiles] = useState(null);
  const TextLength =
    selectedDrawingFiles &&
    selectedDrawingFiles.name &&
    selectedDrawingFiles.name.length;
  const [touchAction, setTouchAction] = useState("auto");
  const classes = useStyles({ TextLength, touchAction });
  const { selected, all } = useSelector((reduxState) => reduxState.buildings);
  const app = useSelector((reduxState) => reduxState.app);
  const { user } = useSelector((reduxState) => reduxState.authentication);
  const [isLoading, setIsLoading] = useState(false);
  const [drawingFiles, setDrawingFiles] = useState([]);
  const [canvasImage, setCanvasImage] = useState(null);
  const [canvas, setCanvas] = useState({ current: null });
  const [dummyCanvas, setDummyCanvas] = useState({ current: null });
  const [measure, setMeasure] = useState({ current: null });
  const [transformComponentRef, setTransformComponentRef] = useState({
    current: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [canvasContainerSize, setCanvasContainerSize] = useState({
    height: "100%",
    width: "100%",
  });
  const [originalValue, setOriginalValue] = useState({});
  const [clientValue, setClientValue] = useState(null);
  const [selectedPinMark, setSelectedPinMark] = useState(null);
  const [requestsData, setRequestsData] = useState([]);
  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [floorOptions, setFloorOptions] = useState([]);
  const [filterListOptions, setFilterListOptions] = useState({
    status: [],
    soort: [],
  });
  const [mapFilter, setMapFilter] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState({
    status: [],
    soort: [],
    overdue: false,
  });
  const [buildingId, setBuildingId] = useState(
    props.location && props.location.state && props.location.state.buildingId
  );
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [orignalCanvasSize, setOrignalCanvasSize] = useState({
    width: 0,
    height: 0,
  });
  const [zoom, setZoom] = useState(1);
  const [appState, setAppState] = useContext(ApplicationContext);
  const location = useLocation();
  const [canvasImageLoading, setCanvasImageLoading] = useState(false);
  const [closeAutocomplete, setCloseAutocomplete] = useState(false);
  const isOnline = useInternetStatusDetector();
  const isBuyer = user && user.type === userAccountTypeConstants.buyer;
  const [state, setState] = useState({
    view: "map",
    showNumber: true,
    measure: { current: null },
    transformComponentRef: {},
    mapCursorStyle: "pointer",
    openConfirmation: false,
  });

  const canvasRefNode = useCallback(
    (node) => {
      if (node !== null) {
        setTimeout(() => {
          setCanvas({ current: node });
        }, 100);
      }
    },
    [isLoading]
  );
  const dummyCanvasRefNode = useCallback(
    (node) => {
      if (node !== null) {
        setTimeout(() => {
          setDummyCanvas({ current: node });
        }, 100);
      }
    },
    [isLoading]
  );
  const transformComponentRefNode = useCallback(
    (node) => {
      if (node !== null) {
        setTimeout(() => {
          setTransformComponentRef({ current: node });
        }, 100);
      }
    },
    [isLoading]
  );
  const measureRefNode = useCallback(
    (node) => {
      if (node !== null) {
        setTimeout(() => {
          setMeasure({ current: node });
        }, 100);
      }
    },
    [isLoading]
  );

  //Browser History Listener
  useEffect(() => {
    const unListen = history.listen(({ state: locationState }) => {
      const { canDetailPage } = locationState || {};
      if (!canDetailPage)
        setAppState((p) => ({ ...p, repairRequestMapState: null }));
    });
    return () => setTimeout(() => unListen(), 500);
  }, [location]);

  useEffect(() => {
    if (appState && appState.repairRequestMapState) {
      const { repairRequestMapState: RRMState } = appState;
      setCurrentPage(RRMState.currentPage);
      setFilterListOptions(RRMState.filterListOptions);
      setAppliedFilters(RRMState.appliedFilters);
      setMapFilter(RRMState.mapFilter);
      setState(RRMState.state);
    }
  }, []);

  useEffect(() => {
    if (selected) {
      const selectdB = all.find((a) => a.buildingId === buildingId);
      setSelectedBuilding(selectdB);
    }
  }, [all, selected, buildingId]);

  const getDrawingDossierFiles = async () => {
    setIsLoading(true);
    setFloorOptions([]);
    setSelectedDrawingFiles(null);
    setDrawingFiles([]);
    const projectId = !selectedBuilding
      ? !buildingId && selected.projectId
      : selectedBuilding.projectId;
    if (projectId) {
      const { data } = await getDrawingFiles(projectId, buildingId);
      setDrawingFiles(data);
    }
  };

  const clearPersistSelectedMap = () => {
    const selectedPersistFile =
      appState &&
      appState.repairRequestMapState &&
      appState.repairRequestMapState.selectedDrawingFiles;
    selectedPersistFile &&
      setAppState((p) => ({
        ...p,
        repairRequestMapState: {
          ...p.repairRequestMapState,
          selectedDrawingFiles: null,
        },
      }));
  };

  useEffect(() => {
    if (drawingFiles && drawingFiles.length) {
      const options = drawingFiles
        .map((p) => ({
          ...p,
          count: requests
            ? requests.filter(
                (r) =>
                  r.drawingPinLocation &&
                  r.drawingPinLocation.dossierFileId === p.dossierFileId
              ).length
            : 0,
          groupBy: p.isInternal
            ? `${t("dossier.objects.rights.internal.title")}`
            : `${t("dossier.objects.rights.external.title")}`,
        }))
        .filter((p) => p.count);
      const selectedFile =
        options.find(
          (p) =>
            p.dossierFileId ===
            (selectedRequest &&
              selectedRequest.drawingPinLocation &&
              selectedRequest.drawingPinLocation.dossierFileId)
        ) || options[0];
      setFloorOptions(options);
      if (selectedFile) {
        const selectedPersistFile =
          appState &&
          appState.repairRequestMapState &&
          appState.repairRequestMapState.selectedDrawingFiles;
        setSelectedDrawingFiles(
          selectedPersistFile || {
            ...selectedFile,
            groupBy: selectedFile.isInternal ? "Internal" : "External",
          }
        );
      } else {
        setSelectedDrawingFiles(null);
      }
      // selectedPersistFile && setAppState(p => ({ ...p, repairRequestMapState:{ ...p.repairRequestMapState,selectedDrawingFiles:null} }));
    } else {
      setFloorOptions([]);
    }
    setIsLoading(false);
  }, [drawingFiles, selectedRequest, requests]);

  const mapFilterHandler = (event) => {
    setMapFilter(event.currentTarget);
  };
  const mapFilterClose = (event) => {
    setMapFilter(null);
  };

  useEffect(() => {
    if (props.location && props.location.state) {
      const { requests: allRequests, selectedRequest: request } =
        props.location.state;
      setRequests(allRequests);
      setSelectedRequest(request);
    } else {
      history.push("/");
    }
  }, [props.location]);

  useEffect(() => {
    setFilterListOptions((p) => ({
      ...p,
      status: Object.keys(groupBy(requests || [], "status")),
      soort: Object.keys(groupBy(requests || [], "carryOutAsType")),
    }));
  }, [requests]);

  useEffect(() => {
    setCanvasImageLoading(true);
    const img = new Image();
    if (
      selectedDrawingFiles &&
      selectedDrawingFiles.dossierFileId &&
      currentPage
    ) {
      img.src =
        URLS.GET_DOSSIER_PDF_IMAGE +
        selectedDrawingFiles.dossierFileId +
        `?page=${currentPage}`;
    } else {
      setCanvasImageLoading(false);
      img.src = "";
      setCanvasImage(null);
    }
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      if (measure.current) {
        measure.current.appendChild(img);
        setOriginalValue({ height: img.height, width: img.width });
        measure.current.removeChild(img);
      }
      setTimeout(() => setCanvasImage(img), 500);
    };
  }, [
    selectedDrawingFiles && selectedDrawingFiles.dossierFileId,
    currentPage,
    measure,
  ]);

  useEffect(() => {
    if (canvasImage && canvas.current && transformComponentRef.current) {
      if (!clientValue) {
        setClientValue({
          clientHeight: canvas.current.clientHeight,
          clientWidth: canvas.current.clientWidth,
        });
      }
      let { clientHeight } = clientValue || canvas.current;
      const wrh = originalValue.width / originalValue.height;
      let newHeight = clientHeight;
      let newWidth = newHeight * wrh;
      if (
        transformComponentRef.current &&
        newWidth > transformComponentRef.current.clientWidth
      ) {
        newWidth = transformComponentRef.current.clientWidth;
        newHeight = newWidth / wrh;
      }
      canvas.current.width = newWidth;
      canvas.current.height = newHeight;
      setOrignalCanvasSize({ width: newWidth, height: newHeight });
      const persistSize =
        appState &&
        appState.repairRequestMapState &&
        appState.repairRequestMapState.canvasContainerSize;
      if (persistSize) {
        newHeight = persistSize.height;
        newWidth = persistSize.width;
      }
      setCanvasContainerSize({ height: newHeight, width: newWidth });
      setCanvasImageLoading(false);
    }
  }, [canvasImage]);

  useEffect(() => {
    if (requests && requests.length && selectedDrawingFiles) {
      const data = requests.filter((p) => {
        let filter = false;
        if (
          p.drawingPinLocation &&
          p.drawingPinLocation.dossierFileId ===
            selectedDrawingFiles.dossierFileId
        ) {
          filter = true;
          if (selectedRequest)
            filter = selectedRequest.resolverId
              ? selectedRequest.resolverId === p.resolverId
              : selectedRequest.requestId === p.requestId;
          else {
            filter = Object.keys(appliedFilters).reduce((prev, key) => {
              switch (key) {
                case "overdue": {
                  return prev && (appliedFilters[key] ? p.overdue : true);
                }
                case "status": {
                  return (
                    prev &&
                    (appliedFilters[key].length
                      ? appliedFilters[key].includes(p.status)
                      : true)
                  );
                }
                case "soort": {
                  return (
                    prev &&
                    (appliedFilters[key].length
                      ? appliedFilters[key].includes(p.carryOutAsType)
                      : true)
                  );
                }
                default: {
                  return prev;
                }
              }
            }, filter);
          }
        }
        return filter;
      });
      setRequestsData(data);
    }
  }, [requests, selectedRequest, selectedDrawingFiles, appliedFilters]);

  useEffect(() => {
    if (selected) getDrawingDossierFiles();
  }, [selected, selectedRequest, selectedBuilding, buildingId]);

  const showNumberHandleChange = () => {
    setState((p) => ({ ...p, showNumber: !p.showNumber }));
  };

  const selectedPinMarkDetailHandler = () => {
    setAppState((p) => ({
      ...p,
      repairRequestMapState: {
        selectedDrawingFiles,
        canvasImage,
        currentPage,
        canvasContainerSize,
        originalValue,
        filterListOptions,
        mapFilter,
        appliedFilters,
        orignalCanvasSize,
        zoom,
        state,
      },
    }));
    const projectNo = !selectedBuilding
      ? selected.projectNo
      : selectedBuilding.projectNo;
    if (selectedPinMark.resolverId)
      history.push({
        pathname:
          "/werk/" + projectNo + "/werkbon/" + selectedPinMark.resolverId,
        state: { goBack: true, canDetailPage: true },
      });
    else
      history.push({
        pathname:
          "/werk/" +
          projectNo +
          "/kwaliteitsborging/" +
          selectedPinMark.requestId,
        state: { fromWorkOrder: true, canDetailPage: true },
      });
  };

  const handleRemoveCustomSelectedRequest = () => {
    setSelectedRequest(null);
    const locationState = { ...props.location.state };
    delete locationState.selectedRequest;
    history.replace({ ...props.location, state: locationState });
  };
  const handleRemoveFilter = (key, item) => {
    selectedRequest && handleRemoveCustomSelectedRequest();
    setAppliedFilters((p) => ({
      ...p,
      [key]: key === "overdue" ? false : p[key].filter((a) => a !== item),
    }));
  };
  const filterHandleChange = ({ target: { checked, value } }, name) => {
    setAppliedFilters((p) => ({
      ...p,
      [name]: name === "overdue" ? checked : value,
    }));
  };

  const extractImageFromCanvas = async () => {
    const { height, width } = orignalCanvasSize;
    const ctxDummy = dummyCanvas.current.getContext("2d");
    ctxDummy.webkitImageSmoothingEnabled = false;
    ctxDummy.mozImageSmoothingEnabled = false;
    ctxDummy.imageSmoothingEnabled = false;
    dummyCanvas.current.width = width;
    dummyCanvas.current.height = height;
    ctxDummy.rect(0, 0, width, height);
    ctxDummy.fillStyle = "#fcfcfc";
    ctxDummy.fill();
    ctxDummy.drawImage(canvasImage, 0, 0, width, height);
    const canvasCopy = dummyCanvas.current;
    const ctx = canvasCopy.getContext("2d");
    ctx.font = "14px Arial";

    for (let i = 0; i < requestsData.length; i++) {
      const img1 = new Image(); // Create new img element
      const request = requestsData[i];
      const {
        drawingPinLocation: { xrel, yrel },
        number,
      } = request;
      const locationPinIcon = pinIconCreator(request);
      await new Promise((resolve, reject) => {
        try {
          img1.src = locationPinIcon;
          img1.onload = async () => {
            if (state.showNumber) {
              ctx.font = "14px Arial";
              const textWidth = ctx.measureText(number).width;
              ctx.strokeStyle = "#0000001c";
              ctx.fillStyle = "white";
              roundRect(
                ctx,
                xrel * width - 40,
                yrel * height - 73,
                textWidth + 28,
                30,
                7,
                true
              );
              drawTriangle(
                ctx,
                xrel * width + textWidth / 2 - 32,
                yrel * height - 40,
                6,
                Math.PI / 2
              );
              ctx.fillStyle = "black";
              ctx.fillText(number, xrel * width - 30, yrel * height - 53);
            }
            await ctx.drawImage(
              img1,
              xrel * width - 12,
              yrel * height - 34,
              24,
              30
            );
            resolve();
          };
        } catch (er) {
          reject(er);
        }
      });
    }
    const base64Image = await canvasCopy.toDataURL("image/png");
    const img = await fileToBlob(
      await base64ToFile(
        base64Image,
        selectedDrawingFiles && selectedDrawingFiles.name + ".png",
        "image/png"
      )
    );
    return {
      base64Image,
      blobURL: window.URL.createObjectURL(img),
    };
  };

  const handleDownloadMap = async () => {
    const { blobURL } = await extractImageFromCanvas();
    await downloadFile(
      blobURL,
      selectedDrawingFiles && selectedDrawingFiles.name + ".png"
    );
  };

  const mapPrintHandler = async () => {
    const { base64Image } = await extractImageFromCanvas();
    const windowContent = `<!DOCTYPE html>
    <html>
      <body style="margin:0;padding:0;height:98vh;width:100vw">
        <img src="${base64Image}" style="width:100%;height:100%;object-fit:contain">
      </body>
    </html>`;
    const printWin = window.open("?", "iframe");

    //write document in window
    printWin.document.open();
    await printWin.document.write(windowContent);
    printWin.document.close();

    printWin.focus();
    printWin.print();
    printWin.close();
  };

  const pinIconCreator = (request) => {
    let IconComponent = "/Content/icon/newPin.png";
    switch (ENUMS[request.status]) {
      case 0: {
        if (request.overdue) {
          IconComponent = "/Content/icon/overduePin.png";
        } else {
          IconComponent ="/Content/icon/newPin.png";
        }
        break;
      }
      case 1: {
        if (request.overdue) {
          IconComponent = "/Content/icon/overduePin.png";
        } else {
          IconComponent = "/Content/icon/inprogressPin.png";
        }
        break;
      }
      case 2: {
        IconComponent = "/Content/icon/complatePin.png";
        break;
      }
      default: {
        break;
      }
    }
    return IconComponent;
  };

  const handleChangeBuilding = (object) => {
    setBuildingId((object || {}).buildingId);
    clearPersistSelectedMap();
  };

  const renderResolvers = (key) => {
    return (
      selectedPinMark[key] &&
      !!selectedPinMark[key].length && (
        <div className={classes.infoContainer}>
          <h6 className={clsx(classes.infoTitle, classes.topAlign)}>
            {t("general.resolvers")}:
          </h6>
          <div>
            {selectedPinMark[key].map((companyName) => (
              <h6 title={companyName} className={classes.infoDescription}>
                {companyName}
              </h6>
            ))}
          </div>
        </div>
      )
    );
  };

  return (<>
    <Popover
      id={mapFilter ? "simple-popover" : undefined}
      open={!!mapFilter}
      anchorEl={mapFilter}
      disableRestoreFocus
      classes={{
        paper: classes.mapPopoverContainer,
      }}
      onClose={mapFilterClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <div className={classes.filterContainer}>
        <Grid container spacing={3}>
          <Grid className={classes.headerContainer} size={{xs:12}}>
            <div className={classes.headerTextContainer}>
              <h6 className={classes.headerText}>{t("general.filters")}</h6>
              <Button
                onClick={() =>
                  setAppliedFilters({ status: [], soort: [], overdue: false })
                }
                className={classes.headerTextReset}
              >
                {t("datatable.label.filter.reset")}
              </Button>
            </div>
            <div>
              <Tooltip title={t("general.close")}>
                <IconButton
                  aria-label="close"
                  edge="end"
                  className={classes.mapCloseIcon}
                  onClick={mapFilterClose}
                  color="inherit"
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
          <Grid size={{xs:12}}>
            <FormControl className={classes.filterFormControl}>
              <InputLabel id="demo-simple-select-label">
                {t("general.status")}
              </InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={appliedFilters.status}
                onChange={(e) => filterHandleChange(e, "status")}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {filterListOptions.status.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox
                      color="primary"
                      checked={appliedFilters.status.includes(name)}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid size={{xs:12}}>
            <FormControl className={classes.filterFormControl}>
              <InputLabel id="demo-simple-select-label">
                {t("general.type")}
              </InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={appliedFilters.soort}
                onChange={(e) => filterHandleChange(e, "soort")}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}
              >
                {filterListOptions.soort.map(
                  (name) =>
                    name !== "null" && (
                      <MenuItem key={name} value={name}>
                        <Checkbox
                          color="primary"
                          checked={appliedFilters.soort.includes(name)}
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid size={{xs:6}}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={appliedFilters.overdue}
                  onChange={(e) => filterHandleChange(e, "overdue")}
                  name="overdue"
                  color="primary"
                />
              }
              label={t("general.overdue")}
            />
          </Grid>
        </Grid>
      </div>
    </Popover>
    <TransformWrapper
      initialScale={1}
      minScale={1}
      maxScale={15}
      centerOnInit={true}
      centerZoomedOut={true}
      wheel={{ disabled: false }}
    >
      {({ zoomIn, zoomOut, resetTransform, state: scale, ...rest }) => (
        <>
          <div className={classes.fullHeight}>
            <div className={classes.rootContainer}>
              <AppBar
                position="sticky"
                elevation={0}
                className={classes.surveyMomentsMainHeaderSecond}
              >
                <div item xs={12} className={classes.documentNameContainer}>
                  <div className={classes.appBarContainer}>
                    <div className={classes.grow}>
                      <div className={classes.squareIconContainer}>
                        <IconButton
                          aria-label="GoBack"
                          color="inherit"
                          onClick={history.goBack}
                          size="large"
                        >
                          <ArrowBack className={classes.arrowIcon} />
                        </IconButton>
                        {!isBuyer && selectedBuilding && (
                          <Chip
                            className={classes.filterChip}
                            label={t(
                              "repairRequest.buildingNumberWithNumber.label",
                              {
                                buildingNoIntern:
                                  selectedBuilding.buildingNoIntern,
                              }
                            )}
                          />
                        )}
                        {selectedRequest && (
                          <Chip
                            className={classes.filterChip}
                            label={selectedRequest.number}
                            onDelete={handleRemoveCustomSelectedRequest}
                          />
                        )}
                        {Object.keys(appliedFilters).map((key) => {
                          if (key === "status" || key === "soort") {
                            return appliedFilters[key].map((item) => (
                              <Chip
                                className={classes.filterChip}
                                key={key + item}
                                label={item}
                                onDelete={() => handleRemoveFilter(key, item)}
                              />
                            ));
                          }
                          return (
                            appliedFilters[key] && (
                              <Chip
                                className={classes.filterChip}
                                key={key}
                                label={t("general.overdue")}
                                onDelete={() => handleRemoveFilter(key)}
                              />
                            )
                          );
                        })}

                        <div className={classes.toggleNumber}>
                          <FormGroup>
                            <Typography component="div">
                              <Grid
                                component="label"
                                container
                                spacing={1}
                                className={classes.numberContainer}
                                sx={{
                                  alignItems: "center"
                                }}
                              >
                                <Grid>
                                  <span className={classes.mapSubHeaderTitle}>
                                    {t("general.number.2chars")}
                                  </span>
                                </Grid>
                                <Grid>
                                  <AntSwitch
                                    checked={state.showNumber}
                                    onChange={showNumberHandleChange}
                                    name="checked"
                                  />
                                </Grid>
                              </Grid>
                            </Typography>
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    {!isBuyer && (
                      <div className={classes.buttonsContainer}>
                        <div style={{ display: "flex" }}>
                          <BuildingFilter
                            requests={requests}
                            selectedBuildingId={buildingId}
                            getSelectedBuilding={handleChangeBuilding}
                          />
                        </div>
                      </div>
                    )}
                    <Autocomplete
                      className={classes.autoComplete}
                      onClose={() => setCloseAutocomplete(false)}
                      onOpen={() => setCloseAutocomplete(true)}
                      open={closeAutocomplete}
                      fullWidth
                      disableClearable
                      disableListWrap
                      groupBy={(option) => option.groupBy}
                      options={floorOptions}
                      value={selectedDrawingFiles}
                      onChange={(e, v) => {
                        setSelectedDrawingFiles(v);
                        clearPersistSelectedMap();
                      }}
                      onClick={() => setCloseAutocomplete((a) => !a)}
                      getOptionLabel={(option) => option.name}
                      PopperComponent={(props) => (
                        <Popper
                          {...props}
                          sx={{
                            "& .MuiAutocomplete-groupUl": {
                              marginBottom: "0px"
                            },
                            width: "auto",
                            zIndex: 1,
                            minWidth: 200
                          }}
                          placement="bottom-end"
                        />
                      )}
                      renderOption={(props, option) => (
                        <ListItemButton {...props} className={classes.optionRender}>
                          <Chip
                            label={option.count}
                            size="small"
                            color="primary"
                            className={classes.repairRequestCount}
                          />
                          <span className={classes.dropDownText}>
                            {option.name}
                          </span>
                        </ListItemButton>
                      )}
                      renderInput={({ inputProps, ...params }) => (
                        <TextField
                          {...params}
                          className={classes.autoCompleteInput}
                          placeholder={t(
                            "repairRequest.chooseFloorPlan.label"
                          )}
                          variant="outlined"
                          size="small"
                          slotProps={{
                            htmlInput: { ...inputProps, disabled: true, }
                          }}
                        />
                      )}
                    />
                    <div className={classes.buttonsContainer}>
                      <div style={{ display: "flex" }}>
                        <Tooltip title={t("general.download")}>
                          <IconButton
                            onClick={handleDownloadMap}
                            edge="end"
                            color="inherit"
                            className={classes.zoomButton}
                            size="large"
                          >
                            <CloudDownload
                              className={classes.mapSubHeaderIcon}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("general.print")}>
                          <IconButton
                            onClick={mapPrintHandler}
                            edge="end"
                            color="inherit"
                            className={classes.zoomButton}
                            size="large"
                          >
                            <LocalPrintshop
                              className={classes.mapSubHeaderIcon}
                            />
                          </IconButton>
                        </Tooltip>
                        {!isBuyer && (
                          <Tooltip title={t("general.apply")}>
                            <IconButton
                              onClick={mapFilterHandler}
                              aria-describedby={
                                mapFilter ? "simple-popover" : undefined
                              }
                              aria-label="apply"
                              edge="end"
                              color="inherit"
                              className={classes.zoomButton}
                              size="large"
                            >
                              <FilterList
                                className={classes.mapSubHeaderIcon}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title={t("repairRequest.drawing.zoomIn")}>
                          <IconButton
                            disabled={scale.scale === 15}
                            onClick={() => zoomIn()}
                            aria-label="ZoomIn"
                            edge="end"
                            color="inherit"
                            className={classes.zoomButton}
                            size="large"
                          >
                            <ZoomIn className={classes.mapSubHeaderIcon} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("repairRequest.drawing.zoomOut")}>
                          <IconButton
                            disabled={scale.scale === 1}
                            onClick={() => zoomOut()}
                            aria-label="ZoomOut"
                            edge="end"
                            color="inherit"
                            className={classes.zoomButton}
                            size="large"
                          >
                            <ZoomOut className={classes.mapSubHeaderIcon} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </AppBar>
              <div className={classes.root}>
                <div ref={measureRefNode} className={classes.measure}></div>
                <TransformComponent
                  wrapperClass={classes.wrapperClass}
                  contentClass={clsx(classes.contentClass)}
                >
                  <div
                    onClick={() => setCloseAutocomplete(false)}
                    ref={transformComponentRefNode}
                    className={classes.mapContainer}
                    style={{ ...canvasContainerSize }}
                  >
                    <div
                      className={clsx(
                        classes.docContainer,
                        classes.canvasContainer
                      )}
                      style={{
                        ...canvasContainerSize,
                        cursor: state.mapCursorStyle,
                      }}
                    >
                      <canvas
                        ref={dummyCanvasRefNode}
                        crossorigin="anonymous"
                        className={clsx(
                          classes.docContainer,
                          classes.dummyCanvas
                        )}
                        style={{ ...canvasContainerSize }}
                      />
                      <img
                        alt={"repair-request-map"}
                        ref={canvasRefNode}
                        src={
                          selectedDrawingFiles &&
                          URLS.GET_DOSSIER_PDF_IMAGE +
                            selectedDrawingFiles.dossierFileId +
                            `?page=${currentPage}`
                        }
                        crossorigin="anonymous"
                        className={clsx(classes.docContainer, classes.canvas)}
                        style={{
                          ...canvasContainerSize,
                          cursor: state.mapCursorStyle,
                        }}
                      />
                      {!canvasImageLoading &&
                        selectedDrawingFiles &&
                        !isLoading &&
                        requestsData.map((request, i, data) => {
                          const pinLocation = request.drawingPinLocation;
                          const locationPinIcon = pinIconCreator(request);

                          return (
                            <div
                              className={classes.pinIconContainer}
                              style={{
                                  transform: `scale(${1 / scale.scale})`,
                                  position: "absolute",
                                  left: `calc(${pinLocation.xrel} * 100%)`,
                                  top: `calc(${pinLocation.yrel} * 100%)`,
                                }}
                              >
                                <div
                                  className={classes.tooltipRoot}
                                  style={{ display: state.showNumber ? "flex" : "none" }}
                                >
                                  <div style={{ whiteSpace: "nowrap" }}>
                                    {request.number}
                                  </div>
                                </div>
                                <img
                                  alt={"location-marker"}
                                  src={locationPinIcon}
                                  onClick={(e) => {
                                    let attachments = request.attachments;
                                    if (!attachments?.length && request.firstFileId)
                                        attachments = [{ attachmentId: request.firstFileId }];
                                    setSelectedPinMark({ attachments, ...request });
                                    setAnchorEl(e.currentTarget);
                                  }}
                                  color="primary"
                                  className={classes.locationOn}
                                />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </TransformComponent>
              </div>
              {canvasImageLoading && (
                <div className={classes.loaderComponent}>
                  {" "}
                  <CircularProgress size={45} />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </TransformWrapper>
    {selectedPinMark && (
      <Popover
        id={"MELDING-MAP"}
        open={!!anchorEl}
        classes={{
          paper: classes.popoverContainer,
        }}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setSelectedPinMark(null);
        }}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper elevation={3} className={classes.statusModal}>
          <Grid container>
            {selectedPinMark?.attachments && selectedPinMark.attachments[0] && (
              <Grid className={classes.gridContainer} size={{xs:4}}>
                <img
                  src={
                    selectedPinMark.attachments[0] &&
                    `${URLS.GET_ATTACHMENT_THUMBNAIL}${selectedPinMark.attachments[0].attachmentId}`
                  }
                  alt="repair-request-img"
                  className={classes.modalImg}
                />
                <div className={classes.statusContainer}>
                  <FiberManualRecord
                    className={clsx(
                      classes.fiberManualRecordImg,
                      classes.mapSubHeaderIcon
                    )}
                  />
                  <span className={classes.statusText}>
                    {selectedPinMark.status}
                  </span>
                </div>
              </Grid>
            )}
            <Grid
              className={classes.infoGridContainer}
              size={{xs:selectedPinMark?.attachments && selectedPinMark.attachments[0] ? 8 : 12}}>
              <div
                className={classes.CloseIcon}
                onClick={() => {
                  setAnchorEl(null);
                  setSelectedPinMark(null);
                }}
              >
                <CloseIcon />
              </div>
              {selectedPinMark.date && (
                <div className={classes.infoContainer}>
                  <h6 className={classes.infoTitle}>
                    {t("repairRequest.map.pinDetail.createdDate")}:
                  </h6>
                  <h6 className={classes.infoDescription}>
                    {" "}
                    {formatDate(new Date(selectedPinMark.date))}
                  </h6>
                </div>
              )}
              {selectedPinMark.surveyType && (
                <div className={classes.infoContainer}>
                  <h6 className={classes.infoTitle}>
                    {t("repairRequest.map.pinDetail.inspectionType")}:
                  </h6>
                  <h6 className={classes.infoDescription}>
                    {selectedPinMark.surveyType}
                  </h6>
                </div>
              )}
              <div className={classes.infoContainer}>
                <h6 className={classes.infoTitle}>{t("general.number")} :</h6>
                <h6 className={classes.infoDescription}>
                  {selectedPinMark.number}
                </h6>
              </div>
              {renderResolvers(
                selectedPinMark.textResolversNames
                  ? "textResolversNames"
                  : "textResolvers"
              )}
              {selectedPinMark.targetCompletionDate && (
                <div className={classes.infoContainer}>
                  <h6 className={classes.infoTitle}>
                    {t("general.deadlineDate")}:
                  </h6>
                  <h6 className={classes.infoDescription}>
                    {formatDate(
                      new Date(selectedPinMark.targetCompletionDate)
                    )}
                  </h6>
                </div>
              )}
              {!selectedPinMark?.attachments?.length && (
                <div className={classes.infoContainer}>
                  <h6 className={classes.infoTitle}>
                    {t("general.status")}:
                  </h6>
                  <h6 className={classes.infoDescription}>
                    {" "}
                    <FiberManualRecord
                      className={clsx(
                        classes.fiberManual,
                        classes.mapSubHeaderIcon
                      )}
                    />
                    {selectedPinMark.status}
                  </h6>
                </div>
              )}
              <hr className={classes.hrContainer} />
              <div>
                <h6 className={classes.infoTitle}>
                  {t("general.shortDescription")}:
                </h6>
                <Paper
                  elevation={0}
                  aria-describedby={"assignment-details"}
                  className={classes.assignmentPaper}
                >
                  <div className={classes.assignmentContent}>
                    {selectedPinMark.desc}
                  </div>
                </Paper>
              </div>
              {app === 3 && isOnline && (
                <div className={classes.buttonContainer}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.editButton}
                    onClick={selectedPinMarkDetailHandler}
                  >
                    {t("repairRequest.map.details")}
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Popover>
    )}
  </>);
}

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: "100%",
  },
  grow: {
    flexGrow: 1,
    display: "flex",
    whiteSpace: "nowrap",
  },
  arrowIcon: {
    fill: theme.palette.common.black,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  headerTextContainer: {
    display: "flex",
    columnGap: 10,
    alignItems: "center",
  },
  headerText: {
    color: theme.palette.grey[900],
    textTransform: "uppercase",
    fontSize: 14,
    marginBottom: 0,
  },
  mapCloseIcon: {
    padding: 0,
    "& svg": {
      fill: theme.palette.grey[700],
      fontSize: 20,
      cursor: "pointer",
    },
  },
  headerTextReset: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    fontSize: 12,
    marginBottom: 0,
    cursor: "pointer",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mapPopoverContainer: {
    boxShadow:
      "rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px",
  },
  filterContainer: {
    padding: theme.spacing(3, 3),
    maxWidth: 300,
    width: 300,
    background: theme.palette.grey[100],
  },
  filterFormControl: {
    width: "100%",
  },

  CloseIcon: {
    position: "absolute",
    cursor: "pointer",
    right: 10,
    "& svg": {
      fill: theme.palette.grey[700],
      fontSize: 18,
    },
  },
  statusContainer: {
    background: theme.palette.common.white,
    width: 115,
    position: "absolute",
    bottom: 10,
    display: "flex",
    alignItems: "center",
    left: "50%",
    maxWidth: "90%",
    transform: "translate(-50%, 0)",
    borderRadius: 50,
    padding: theme.spacing(0.5, 1),
    fontSize: 10,
  },
  statusContainer2: {
    background: theme.palette.common.white,
    width: 115,
    display: "flex",
    alignItems: "center",
    maxWidth: "90%",
    borderRadius: 50,
    padding: theme.spacing(0.5, 1),
    fontSize: 10,
    boxShadow: `0px 0px 12px #eee`,
    marginLeft: 10,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 5,
  },
  editButton: {
    padding: `${theme.spacing(0.3, 4)}`,
    fontSize: 12,
  },
  hrContainer: {
    margin: `${theme.spacing(0.7, 0)} !important`,
  },
  assignmentPaper: {
    padding: theme.spacing(0.5, 1),
    background: theme.palette.grey[100],
    fontSize: 14,
    height: "100%",
  },
  assignmentContent: {
    fontSize: 12,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    wordBreak: "break-all" /* number of lines to show */,
    display: "-webkit-box",
    boxOrient: "vertical",
    color: theme.palette.grey[600],
    letterSpacing: 0,
  },
  popper: {
    width: "auto",
  },
  infoTitle: {
    color: theme.palette.grey[900],
    fontSize: 12,
    fontWeight: "bold",
    marginRight: 5,
  },
  topAlign: {
    alignSelf: "flex-start",
  },
  infoDescription: {
    color: theme.palette.grey[600],
    fontSize: 12,
    fontWeight: 100,
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    wordBreak: "break-all",
    display: "-webkit-box",
    boxOrient: "vertical",
    alignItems: "center",
  },
  infoContainer: {
    display: "flex",
    marginBottom: 3,
    alignItems: "center",
  },
  gridContainer: {
    padding: `0px`,
    position: "relative",
  },
  infoGridContainer: {
    padding: `${theme.spacing(1.5, 1, 1, 1)}`,
  },
  popoverContainer: {
    borderRadius: 15,
  },
  statusModal: {
    maxWidth: 385,
    minWidth: 385,
    [theme.breakpoints.down("sm")]: {
      minWidth: 300,
    },
    width: "100%",
    height: "100%",
  },
  modalImg: {
    maxWidth: 126,
    minWidth: 126,
    [theme.breakpoints.down("sm")]: {
      minWidth: 100,
    },
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
  },
  bold: {
    fontWeight: "bold",
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  tooltipImageContainer: {
    //maxWidth: '50vw',
    "& > img": {
      width: "100%",
    },
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      display: "flex",
      "& > div": {
        "& button": {
          [theme.breakpoints.down("sm")]: {
            padding: `${theme.spacing(1.2, 0.5)} !important`,
          },
        },
        "& div:nth-child(1)": {
          flexGrow: 1,
        },
        padding: 0,
        textAlign: "right",
        flex: "1 0 auto",
        alignItems: "center",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
        display: "flex",
        flexGrow: 1,
        justifyContent: "end",
      },
    },
    "& .MuiTableCell-root": {
      padding: theme.spacing(0, 0.5, 0, 0),
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "center",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
      },
    },
  },
  fullWidth: {
    width: "100%",
  },
  thumbnail: {
    width: 50,
    height: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  rotate45Clockwise: {
    transform: "rotate(45deg)",
  },
  rotate45AntiClockwise: {
    transform: "rotate(-45deg)",
  },
  mapTabsText: {
    color: theme.palette.grey[700],
    fontSize: 12,
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  tabButton: {
    height: 40,
    [theme.breakpoints.down("sm")]: {
      height: 28,
    },
    padding: `${theme.spacing(0, 1.3)} !important`,
    display: "flex",
    alignItems: "center",
    borderRadius: "7px",
  },
  toggleButtonGroup: {
    "& .Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      "& span": {
        color: `${theme.palette.common.white} !important`,
      },
    },
    "& .Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      "& span": {
        color: `${theme.palette.common.white} !important`,
      },
    },
  },
  tabsIcon: {
    fontSize: 18,
    marginRight: 8,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      marginRight: 5,
    },
  },
  mainHeader: {
    backgroundColor: theme.palette.common.white,
    height: 64,
    display: "flex",
    alignItems: "center",
  },
  mapNavbar: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  navTitle: {
    display: "flex",
    alignItems: "center",
  },
  mapHeaderTitle: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.6,
    color: theme.palette.common.black,
    marginBottom: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
  numberContainer: {
    display: "flex",
    justifyContent: "center",
  },
  mapSubHeaderTitle: {
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: 1.6,
    color: theme.palette.grey[700],
    marginBottom: 0,
  },
  surveyMomentsMainHeaderSecond: {
    backgroundColor: "#fff",
    // height: 64,
    borderRadius: "0 !important",
    margin: "0 !important",
    padding: theme.spacing(1),

    [theme.breakpoints.up("xs")]: {
      height: "initial !important",
    },
    height: "100px !important",
    width: "100% !important",
  },
  documentNameContainer: {
    display: "flex",
    alignItems: "center",
  },
  zoomButton: {
    borderRadius: 5,
    overflow: "hidden",
    padding: theme.spacing(0.5),
    margin: `0 10px 0 0`,
    height: "38px",
    width: "38px",
    [theme.breakpoints.down("sm")]: {
      height: "31px",
      width: "31px",
    },
    "&:focus": {
      outline: "none !important",
    },
    "&::before": {
      content: '""',
      height: "100%",
      width: "100%",
      overflow: "hidden",
      opacity: 0.2,
      zIndex: "-1",
      position: "absolute",
      backgroundColor: theme.palette.grey[700],
    },
    "& .MuiSvgIcon-root": {
      fontSize: 24,
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
    },
  },
  filterChip: {
    margin: theme.spacing(0, 0.15),
  },
  toggleNumber: {
    flexGrow: 1,
    marginLeft: 10,
    display: "flex",
  },
  squareIconContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    rowGap: 10,
  },
  squareIcon: {
    borderRadius: 5,
    overflow: "hidden",
    padding: theme.spacing(0.5),
    margin: `0 5px`,
    height: "38px",
    width: "38px",
    [theme.breakpoints.down("sm")]: {
      height: "31px",
      width: "31px",
    },
    "&:focus": {
      outline: "none !important",
    },
    "&::before": {
      content: '""',
      height: "100%",
      width: "100%",
      overflow: "hidden",
      opacity: 0.2,
      zIndex: "-1",
      position: "absolute",
      backgroundColor: theme.palette.grey[700],
    },
    "& .MuiSvgIcon-root": {
      fontSize: 30,
      [theme.breakpoints.down("sm")]: {
        fontSize: 20,
      },
    },
  },
  mapSubHeaderIcon: {
    fill: theme.palette.grey[900],
  },
  playArrow: {
    transform: "rotate(270deg)",
    fill: `${red[500]} !important`,
  },
  fiberManualRecord: {
    fill: `${yellow[500]} !important`,
  },
  fiberManualRecordImg: {
    fill: `${yellow[500]} !important`,
    fontSize: 14,
    position: "absolute",
  },
  fiberManual: {
    fill: `${yellow[500]} !important`,
    fontSize: 14,
  },
  statusText: {
    textAlign: "center",
    flexGrow: 1,
    fontWeight: "bold",
    fill: theme.palette.grey[900],
  },
  stopIcon: {
    fill: `${green[500]} !important`,
  },
  appBarContainer: {
    rowGap: 10,
    flexWrap: "wrap",
    display: "flex",
    "-webkit-line-clamp": 1,
    // '-webkit-box-orient': 'vertical',
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      // display: '-webkit-box',
      flexFlow: "row wrap",
    },
    flexDirection: "row",
    justifyContent: "end",
    flexGrow: 1,
  },
  title: {
    fontSize: 16,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: "bold",
  },
  appBarSubTitle: {
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  autoComplete: {
    width: "auto",
    minWidth: 150,
    marginLeft: 10,
    marginRight: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  repairRequestCount: {
    padding: "0px !important",
    margin: "0px !important",
    width: "27px !important ",
    maxWidth: "27px !important ",
    minWidth: "27px !important ",
    height: "27px !important",
    fontSize: 9,
  },
  dropDownText: {
    marginLeft: 10,
    overflow: "visible",
    textOverflow: "clip",
    whiteSpace: "nowrap",
  },
  optionRender: {
    whiteSpace: "nowrap",
    overflow: "visible",
    display: "flex",
    alignItems: "center",
    position: "relative",
    padding: "5px 18px",
  },
  autoCompleteInput: {
    marginTop: 0,
    marginBottom: 0,
    maxWidth: 250,
    minWidth: 110,
    overflow: "visible",
    "& > .MuiAutocomplete-inputRoot": {
      backgroundColor: theme.palette.grey[300],
      width: "auto !important",
      "& > .MuiOutlinedInput-notchedOutline": {
        borderWidth: 0,
      },
      "& > input": {
        width: "auto",
        fontSize: 14,
        fontWeight: "bold",
        textOverflow: "clip",
      },
    },
  },
  rootContainer: {
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  root: {
    background: theme.palette.grey[200],
    height: "100%",
    flex: 1,
    overflow: "auto",
    position: "relative",
    width: "100%",
    display: "flex",
    "& > .MuiAutocomplete-popper": {
      width: "auto",
    },
    touchAction: ({ touchAction }) => `${touchAction} !important`,
  },
  measure: {
    position: "absolute",
  },
  loaderComponent: {
    top: 64,
    height: "100%",
    flex: 1,
    width: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#ffffffd6",
    zIndex: 2,
  },
  notFoundWrapper: {
    top: 64,
    height: "calc(100% - 64px)",
    width: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.common.white,
    zIndex: 2,
  },
  wrapperClass: {
    height: "100%",
    width: "100%",
  },
  contentClass: {
    height: "100%",
    width: "100%",
  },
  mapContainer: {
    width: "100%",
    margin: "auto",
  },
  docContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  dummyCanvas: {
    display: "none !important",
  },
  canvasContainer: {
    height: "calc(100% - 35px)",
    margin: "auto",
    // transition: '0.5s all'
  },
  locationOn: {
    position: "absolute",
    zIndex: 1,
    height: 30,
    width: 24,
    pointerEvents: "auto !important",
    left: "-12px",
    top: "-30px",
  },
  pinIconContainer: {
    background: "red",
    "& $infoDetail": {
      display: "none",
    },
    "&:hover": {
      "& $infoDetail": {
        display: "block",
        background: "blue",
      },
    },
  },
  tooltipRoot: {
    left: "0px",
    top: "-51px",
    height: 0,
    width: 0,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute !important",
    "& > div": {
      display: "flex",
      borderRadius: 6,
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      background: theme.palette.common.white,
      color: theme.palette.common.black,
      padding: theme.spacing(0.625, 2),
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      fontSize: 14,
      width: "fit-content",
      "&:before": {
        content: '""',
        display: "block",
        width: 0,
        height: 0,
        position: "absolute",
        borderTop: `8px solid ${theme.palette.common.white}`,
        borderRight: `8px solid transparent`,
        borderLeft: `8px solid transparent`,
        // left: -8,
        top: "95%",
      },
    },
  },
  tooltipRootArrow: {
    color: theme.palette.common.white,
  },
  tooltipPopperRoot: {
    // background:"red"
  },
  mapDailog: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    "& .MuiPaper-root": {
      width: "100%",
      height: "100%",
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: "88%",
        borderRadius: 10,
        marginLeft: 18,
        marginTop: 60,
        marginRight: 18,
      },
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(3.75, 3.75, 0, 3.75),
      "& p": {
        color: theme.palette.grey[700],
        textAlign: "center",
      },
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",

      "& .MuiButtonBase-root": {
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(1, 1, 2, 1),
        },
        margin: "10px 17px 25px 17px",
      },
    },
  },
}));
