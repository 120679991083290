import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { Stack, Tooltip, Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link,  Grid2 as Grid, Box, Modal, CircularProgress, Typography } from "@mui/material";
import { userActions } from "../_actions";
import { withTranslation } from "react-i18next";
import { formatDate } from "../_helpers";
import "./login.css";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(userActions.logout());

    this.state = {
      username: "",
      password: "",
      remember: false,
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckUncheck = this.handleCheckUncheck.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleCheckUncheck(e) {
    this.setState({ remember: !this.state.remember });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password, remember } = this.state;
    const { dispatch } = this.props;
    if (username && password) {
      dispatch(userActions.login(username, password, remember));
    }
  }

  render() {
    const { loggingIn, loggedIn, alert, t, history, currentVersionDate } =
      this.props;
    const { username, password, remember, submitted } = this.state;
    const { webApiUrl } = window.appConfig;
    const { cookieEnabled } = navigator;

    const referer =
      (history.location.state && history.location.state.from) || "/";
    if (loggedIn) return <Redirect to={referer} />;

    return (
      (<Grid
            container
            spacing={0}
            direction="column"
            className="login-grid"
            sx={{
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              backgroundImage: "url(" + webApiUrl + "api/Config/WebBackground)"
            }}>
        <CssBaseline />
        <Grid className="login-container">
          <Grid
            container
            spacing={0}
            direction="column"
            sx={{
              alignItems: "center",
              justifyContent: "center"
            }}>
            {
              //<img src="/Content/Images/Logos/Logo-JPDS-transparant.svg" width="50" alt="JPDS" />
              <img
                src={webApiUrl + "api/Config/WebLogo"}
                style={{ maxWidth: 200, maxHeight: 50 }}
                alt="JPDS"
              />
              //<img src="/Content/Images/Logos/logo_home4u.jpg" width="200" alt="JPDS" />
            }
          </Grid>

          {cookieEnabled !== true ? (
            <Box className={`alert alert-danger`} sx={{
              my: 1
            }}>
              {t("login.cookie.error")}
            </Box>
          ) : (
            <React.Fragment>
              <Typography component="h1" variant="h5" align="center">
                {t("login.title")}
              </Typography>

              {alert.message && (
                <Box className={`alert ${alert.type}`} sx={{
                  my: 1
                }}>
                  {t(alert.message)}
                </Box>
              )}
              <form noValidate onSubmit={this.handleSubmit}>
                <TextField
                  error={submitted && !username}
                  required
                  variant="standard"
                  margin="normal"
                  fullWidth
                  id="email"
                  label={t("general.email.label")}
                  name="username"
                  autoComplete="email"
                  autoFocus
                  value={username}
                  onChange={this.handleChange}
                  disabled={loggingIn}
                />
                <TextField
                  error={submitted && !password}
                  required
                  variant="standard"
                  margin="normal"
                  fullWidth
                  name="password"
                  label={t("login.password.label")}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={this.handleChange}
                  disabled={loggingIn}
                />
                <Grid container sx={{
                  alignItems: "center"
                }}>
                  <Grid
                    size={{
                      sm: 6
                    }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="remember"
                          color="primary"
                          checked={remember}
                          onChange={this.handleCheckUncheck}
                          disabled={loggingIn}
                        />
                      }
                      label={t("login.rememberme.label")}
                      className="remember-me"
                    />
                  </Grid>
                  <Grid
                    align="right"
                    size={{
                      sm: 6
                    }}>
                    <Link component={RouterLink} to="/forgot" variant="body1">
                      {t("login.forgotpassword.text")}
                    </Link>
                  </Grid>
                </Grid>
                <Grid align="center" size={{xs:12}}>
                  <Box sx={{
                    mt: 2
                  }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loggingIn}
                    >
                      {t("login.button")}
                    </Button>
                  </Box>
                </Grid>
                <Grid align="center" size={{xs:12}}>
                  <Stack
                    direction="row"
                    spacing={0.5}
                    sx={{
                      mt: 2,
                      justifyContent: "center"
                    }}>
                    <Typography variant="body1" >
                      {t('loginRequest.noAccount')}
                    </Typography>
                    <Tooltip
                      title={t("loginRequest.requestALogin.tooltip")}
                      arrow
                      slotProps={{
                        tooltip: {
                          sx: { maxWidth: 'none' },
                        },
                      }}
                    >
                      <Link component={RouterLink} to="/login-aanvraag">
                        <Typography variant="body1">{t("loginRequest.requestALogin")}</Typography>
                      </Link>
                    </Tooltip>
                  </Stack>
                </Grid>
              </form>
            </React.Fragment>
          )}
        </Grid>
        <Modal open={loggingIn === true}>
          <Grid
            container
            direction="column"
            style={{ minHeight: "100vh" }}
            sx={{
              alignItems: "center",
              justifyContent: "center"
            }}>
            <Grid>
              <CircularProgress />
            </Grid>
          </Grid>
        </Modal>
        <span className="versionInfo">
          {`${t("Huisinfo")} v${process.env.REACT_APP_VERSION} (${formatDate(
            new Date(currentVersionDate)
          )})`}
        </span>
      </Grid>)
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, loggedIn } = state.authentication;
  const { alert, currentVersionDate } = state;
  return {
    loggingIn,
    loggedIn,
    alert,
    currentVersionDate,
  };
}

const connectedLoginPage = connect(mapStateToProps)(
  withTranslation()(LoginPage)
);
export { connectedLoginPage as LoginPage };
