import React, { useState, useEffect } from "react";
import {
  InputAdornment,
  Input,
  Collapse,
  FormControl,
  InputBase,
  InputLabel,
  Select,
  MenuItem,
  MenuList,
  TextField,
  ListItemText,
  List,
  Radio,
  Typography,
  IconButton,
  Popover,
  Grid2 as Grid,
  Button,
  Checkbox,
  useTheme,
  Autocomplete,
  CircularProgress,
  ListItemButton,
  useMediaQuery
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  Close,
  Search,
  FilterList,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import BottomDrawer from "./BottomDrawer";
import {
  getAvailableExecutorsForSurvey,
  getOrganizations,
  getProductsServices,
} from "../../../apis/surveyingApis";
import { useSelector } from "react-redux";
import { getAvailableParticipantsForChat, getAvailableResponsiblePersonsForRepairRequests, getAvailableResponsiblePersonsForProject } from "../../../apis/aftercareApis";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  input: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: "86%",
    padding: "5px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

export default function SelectResponsiblePerson({
  projectId,
  isSubmitting,
  isSecondSignature,
  forSurvey = false,
  forRepairRequests = false,
  repairRequestIds = [],
  surveyIds,
  customTitle,
  forProjects = false,
  chatId,
  role,
  customButtonTitle,
  selectOrg = false,
  hideExtraInfo = false,
  onSubmit = () => 0,
  setLoading,
  canCreateRepairRequest,
  loading,
  customOplosser,
  anchorEl,
  onClose,
  isCanSendToWB = false,
  closeModal = () => {},
  anchorOrigin = { vertical: "bottom", horizontal: "left" },
  transformOrigin = { vertical: "top", horizontal: "right" },
  ...props
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [organizationsData, setAllOrganizationsData] = useState([]);
  const [filterSearchTerm, setFilterSearchTerm] = useState([]);
  const [searchTerm, setSearchTerm] = useState(null);
  const [selectOrgMethod, setSelectOrgMethod] = useState("all");
  const [selectOrgProductService, setSelectOrgProductService] = useState(null);
  const [showEditFilterAnchor, setShowEditFilterAnchor] = useState(false);
  const [isCollapse, setCollapse] = useState(null);
  const [collapseContainer, setCollapseContainer] = useState(null);
  const [productServices, setProductServices] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const theme = useTheme();
  const app = useSelector((state) => state.app);
  const { selected } = useSelector((state) => state.buildings);
  const { isUpdatingPerson, updateExecutorLoading } = useSelector(
    (state) => state.surveying
  );
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [isShowSearchBox, setIsShowSearchBox] = useState(false);
  const [state, setState] = useState({
    orgId: null,
    relationId: null,
    canSendToWB: false,
    workOrderText: "",
    sendWorkOrder: false,
    item: "",
    person: "",
    repairRequestRight: false,
    workOrderRight: false,
    createWorkOrder: false,
    notify: false,
  });

  useEffect(() => {
    if (
      (!isUpdatingPerson || !updateExecutorLoading || !isSubmitting) &&
      isUpdating
    ) {
      closeModal();
      onClose();
      setIsUpdating(false);
    }
  }, [isUpdatingPerson, updateExecutorLoading, isSubmitting]);

  useEffect(() => {
    if (!!anchorEl) {
      setLoading(true);
      if (chatId) {
        getAvailableParticipantsForChat(chatId).then((res) => {
          setAllOrganizationsData(res.data);
          setFilterSearchTerm(res.data);
          setLoading(false);
        })
          .catch((er) => {
            setLoading(false);
          })
      } else if (forRepairRequests) {
        getAvailableResponsiblePersonsForRepairRequests(app, repairRequestIds).then((res) => {
          setAllOrganizationsData(res.data);
          setFilterSearchTerm(res.data);
          setLoading(false);
        })
          .catch((er) => {
            setLoading(false);
          });
      } else if (forProjects) {
        getAvailableResponsiblePersonsForProject(projectId, role).then((res) => {
          setAllOrganizationsData(res.data);
          setFilterSearchTerm(res.data);
          setLoading(false);
        })
          .catch((er) => {
            setLoading(false);
          });
      } else if (!forSurvey) {
        getOrganizations(selected && selected.projectId, "", app.toString())
          .then((res) => {
            setAllOrganizationsData(res.data);
            setFilterSearchTerm(res.data);
            setLoading(false);
          })
          .catch((er) => {
            setLoading(false);
          });
      } else {
        getAvailableExecutorsForSurvey(surveyIds, isSecondSignature)
          .then((res) => {
            setLoading(false);
            setAllOrganizationsData(res.data);
            setFilterSearchTerm(res.data);
          })
          .catch((er) => {
            setLoading(false);
            console.log(er, "er");
          });
      }
      getProductsServices()
        .then((res) => {
          setProductServices(res.data);
        })
        .catch((er) => {
          console.log(er);
        });
    }
  }, [!!anchorEl]);

  const filterHandler = (method, searchQuery = "", product) => {
    const SearchDissolver = organizationsData.filter((res) => {
      const isExist =
        (res.name || "").toLowerCase().includes(searchQuery.toLowerCase()) &&
        (method !== "all"
          ? !method
            ? res.projectIds && res.projectIds.includes(projectId)
            : res.isOwnOrganisation == method
          : true) &&
        (product ? (res.productServiceIds || "").includes(product.id) : true);
      return isExist;
    });
    method !== selectOrgMethod && setSelectOrgMethod(method);
    product !== selectOrgProductService && setSelectOrgProductService(product);
    searchQuery !== searchTerm && setSearchTerm(searchQuery);
    setFilterSearchTerm(SearchDissolver);
  };

  const handleChangePerson = (relationId, orgId, item, person, loginId) => {
    setState((p) => ({ ...p, relationId, orgId, item, person, loginId }));
    if (relationId) {
      setCollapse(relationId);
    } else {
      setCollapse(false);
    }
  };

  const childRenderer = () => (
    <>
      <Grid className={classes.stickyHeader}>
        <Grid container direction="row">
          <Grid className={classes.grow}>
            {!isShowSearchBox ? (
              <Typography className={classes.title} variant="h6">
                {t(customTitle || "general.resolver")}
              </Typography>
            ) : (
              <Input
                onFocus={(event) => event.stopPropagation()}
                className={classes.InputStyle}
                autoFocus
                value={searchTerm}
                onChange={(e) => filterHandler(selectOrgMethod, e.target.value, selectOrgProductService)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={() => {
                        filterHandler(selectOrgMethod, "", selectOrgProductService)
                        setIsShowSearchBox(false)
                      }}
                    >
                      <Close />
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          </Grid>
          {!isShowSearchBox && (
            <Grid>
              <IconButton
                color="inherit"
                aria-label="search"
                component="span"
                style={{ marginTop: -8, marginBottom: -8 }}
                onClick={(e) => {
                  setIsShowSearchBox((a) => !a)
                  filterHandler(selectOrgMethod, "", selectOrgProductService)
                }}
                size="large"
              >
                <Search className={classes.icons} />
              </IconButton>
            </Grid>
          )}
          <Grid>
            <IconButton aria-describedby={"filter-list"} color="inherit" aria-label="filter-resolvers" component="span" style={{ marginTop: -8, marginBottom: -8 }} onClick={(e) => setShowEditFilterAnchor(!showEditFilterAnchor)} size="large">
              <FilterList className={classes.icons} />
            </IconButton>
          </Grid>
          <Grid>
            <IconButton
              color="inherit"
              component="span"
              edge="end"
              style={{ marginTop: -8, marginBottom: -8 }}
              onClick={() => {
                onClose({
                  relationId: state.relationId,
                  canSendToWB: state.canSendToWB,
                })
              }}
              size="large"
            >
              <Close className={classes.icons} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.oploserContain}>
        <Grid container direction="column">
          <Grid>
            <Collapse in={showEditFilterAnchor}>
              <Grid container spacing={1}>
                <Grid
                  size={{
                    xs: 12,
                    sm: 6
                  }}>
                  <FormControl variant="standard" margin="dense" fullWidth>
                    <InputLabel id="change-method-select-label">{t("organization.search.method.label")}</InputLabel>
                    <Select
                      labelId="change-method-select-label"
                      id="change-method-select"
                      value={selectOrgMethod}
                      onChange={(e) => filterHandler(e.target.value, searchTerm || "", selectOrgProductService)}
                      label={t("organization.search.method.label")}
                    >
                      {[0, 1, "all"].map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {t("organization.search.method." + index)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  size={{
                    xs: 12,
                    sm: 6
                  }}>
                  <Autocomplete
                    id="product-service-method-select-addworkorder"
                    fullWidth
                    options={productServices}
                    value={selectOrgProductService}
                    onChange={(e, v) => filterHandler(selectOrgMethod, searchTerm || "", v)}
                    getOptionLabel={(option) => option.code + " - " + option.description}
                    renderInput={(params) => <TextField {...params} classes={{ root: classes.customTextField }} placeholder={t("repairRequest.productOrService")} variant="outlined" margin="dense" />}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
          <Grid>
            <MenuList style={{ margin: "0 -16px -12px" }}>
              {!!filterSearchTerm &&
                filterSearchTerm.map((a, index) => {
                  const item = Object.assign({}, a)
                  if (!item.id) item.id = index
                  return (<>
                    {((item.relations && item.relations.length) || selectOrg) && (
                      <ListItemButton
                        component={"label"}
                        for={item.id}
                        className={classes.removePaddingTopBottom}
                        onClick={() => {
                          !selectOrg && setCollapseContainer(item.id === collapseContainer ? false : item.id)
                        }}>
                        <ListItemText primary={item.name || (!!forSurvey && t("general.buyerRenter"))} className={classes.collapseListStyle} />
                        {selectOrg ? (
                          <Radio
                            id={item.id}
                            className={classes.customRadioButton}
                            color="primary"
                            checked={state.orgId === item.id}
                            onChange={() => {
                              handleChangePerson(item.id, item.id, item)
                              setCollapseContainer(item.id === collapseContainer ? false : item.id)
                            }}
                            value={item.id}
                            name="org"
                            inputProps={{ "aria-label": item.name }}
                          />
                        ) : (
                          <IconButton className={classes.collapseButton} size="large">
                            {item.id === collapseContainer ? <ExpandLess className={classes.icons} /> : <ExpandMore className={classes.icons} />}
                          </IconButton>
                        )}
                       </ListItemButton>
                    )}
                    {item.relations && !!item.relations.length && (
                      <Collapse in={collapseContainer === item.id} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding className={classes.innerbg}>
                          {item.relations
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((p, i) => {
                              const person = Object.assign({}, p)
                              if (!person.id) person.id = person.loginId
                              return (<>
                                <ListItemButton component="label" className={classes.innerOption} for={person.id}>
                                  <ListItemText secondary={person.name} className={classes.radioTypo} />
                                  <Radio
                                    id={person.id}
                                    className={classes.customRadioButton}
                                    color="primary"
                                    checked={state.relationId === person.id}
                                    onChange={() => handleChangePerson(person.id, item.id, item, person.name, person.loginId)}
                                    value={person.id}
                                    name="person"
                                    inputProps={{
                                      "aria-label": person.name,
                                    }}
                                  />
                                 </ListItemButton>
                                {!hideExtraInfo && (
                                  <Collapse in={isCollapse === person.id} timeout="auto" unmountOnExit>
                                    {state.relationId && (
                                      <>
                                        {state.sendWorkOrder && (
                                          <div className={classes.TextBoxContainer}>
                                            <FormControl className={classes.formContainer}>
                                              <Typography className={classes.typoTextBox}>{t("workOrders.extraWorkOrderText")}</Typography>
                                              <BootstrapInput
                                                onChange={({ target: { value } }) => {
                                                  setState((p) => ({
                                                    ...p,
                                                    workOrderText: value,
                                                  }))
                                                }}
                                                value={state.workOrderText}
                                                defaultValue=""
                                                id="bootstrap-input"
                                              />
                                            </FormControl>
                                          </div>
                                        )}
                                        {canCreateRepairRequest && (
                                          <div className={classes.checkboxContainer}>
                                            <Checkbox
                                              checked={state.repairRequestRight && state.relationId === person.id}
                                              onChange={({ target: { checked } }) => {
                                                setState((p) => ({
                                                  ...p,
                                                  repairRequestRight: checked,
                                                }))
                                              }}
                                              className={clsx(classes.checkboxRoot, classes.lightCheckBox)}
                                              color="primary"
                                            />
                                            &nbsp;
                                            <Typography className={classes.typo}>{t("survey.canCreateRepairRequests.label")}</Typography>
                                          </div>
                                        )}
                                        {state.repairRequestRight && (
                                          <div className={classes.checkboxContainer}>
                                            <Checkbox
                                              checked={state.workOrderRight && state.relationId === person.id}
                                              onChange={({ target: { checked } }) => {
                                                setState((p) => ({
                                                  ...p,
                                                  workOrderRight: checked,
                                                }))
                                              }}
                                              className={clsx(classes.checkboxRoot, classes.lightCheckBox)}
                                              color="primary"
                                            />
                                            &nbsp;
                                            <Typography className={classes.typo}>{t("survey.canCreateWorkOrders.label")}</Typography>
                                          </div>
                                        )}
                                        {selectOrg && (
                                          <div className={classes.checkboxContainer}>
                                            <Checkbox
                                              checked={state.createWorkOrder && state.relationId === person.id}
                                              onChange={({ target: { checked } }) => {
                                                setState((p) => ({
                                                  ...p,
                                                  createWorkOrder: checked,
                                                }))
                                              }}
                                              className={clsx(classes.checkboxRoot, classes.lightCheckBox)}
                                              color="primary"
                                            />
                                            &nbsp;
                                            <Typography className={classes.typo}>{t("workOrders.createWorkOrderImmediately")}</Typography>
                                          </div>
                                        )}
                                        {selectOrg && state.createWorkOrder && (
                                          <div className={classes.checkboxContainer}>
                                            <Checkbox
                                              checked={state.notify && state.relationId === person.id}
                                              onChange={({ target: { checked } }) => {
                                                setState((p) => ({
                                                  ...p,
                                                  notify: checked,
                                                }))
                                              }}
                                              className={clsx(classes.checkboxRoot, classes.lightCheckBox)}
                                              color="primary"
                                            />
                                            &nbsp;
                                            <Typography className={classes.typo}>{t("general.notifyImmediately")}</Typography>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </Collapse>
                                )}
                              </>);
                            })}
                        </List>
                      </Collapse>
                    )}
                  </>);
                })}
              <div className={classes.OpslaanStyle}>
                <Button
                  disabled={isUpdatingPerson || !state.relationId || updateExecutorLoading || (isUpdating && isSubmitting)}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    onSubmit(state, setState)
                    setIsUpdating(true)
                  }}
                  className={classes.OpslaanButtonStyle}
                >
                  {isUpdatingPerson || updateExecutorLoading || (isUpdating && isSubmitting) ? <CircularProgress size={20} /> : t(customButtonTitle || "general.save")}
                </Button>
              </div>
            </MenuList>
          </Grid>
        </Grid>
      </div>
    </>
  )

  return (
    !loading && (
      <>
        {!isSmallDevice ? (
          <Popover
            open={!!anchorEl}
            disableRestoreFocus
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            id={"relations-list"}
            anchorEl={anchorEl}
            classes={{ paper: classes.roundedPaper }}
            onClose={
              !isUpdatingPerson && !updateExecutorLoading ? onClose : () => 0
            }
          >
            {childRenderer()}
          </Popover>
        ) : (
          <BottomDrawer
            className={classes.responsiblePersonDrawer}
            open={!!anchorEl}
            customOplosser={customOplosser}
            close={
              !isUpdatingPerson && !updateExecutorLoading ? onClose : () => 0
            }
          >
            {childRenderer()}
          </BottomDrawer>
        )}
      </>
    )
  );
}

const useStyles = makeStyles((theme) => ({
  oploserContain: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  OpslaanStyle: {
    width: "100%",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    position: "sticky",
    bottom: 0,
    paddingBottom : theme.spacing(1),
    backgroundColor: theme.palette.common.white
  },
  customTextField: {
    "& .MuiInputBase-root": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& input::placeholder": {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
  OpslaanButtonStyle: {
    fontSize: 14,
    marginTop: "10px",
    "&.Mui-disabled": {
      background: theme.palette.grey[200],
      color: theme.palette.grey[400],
    },
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    background: theme.palette.common.white,
    zIndex: 2,
    padding: theme.spacing(1.3, 2, 0, 2),
  },
  grow: {
    flexGrow: 1,
    width: 0,
    display: "flex",
    alignItems: "center",
  },
  InputStyle: {
    width: "100%",
  },
  checkboxRoot: {
    padding: theme.spacing(0, 1, 0, 0),
  },
  lightCheckBox: {
    padding: theme.spacing(0),
    "& .MuiSvgIcon-root": {
      fill: theme.palette.grey[400],
    },
  },
  roundedPaper: {
    borderRadius: 10,
    width: 303,
    height: 303,
  },
  removePaddingTopBottom: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0.5),
  },
  checkboxContainer: {
    padding: theme.spacing(0, 2.25),
    paddingRight: theme.spacing(0.625),
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },
  TextBoxContainer: {
    padding: theme.spacing(0, 2.25),
    paddingRight: theme.spacing(0.625),
    display: "flex",
    alignItems: "center",
  },
  formContainer: {
    width: "100%",
  },
  innerOption: {
    padding: theme.spacing(0, 2.25),
    paddingRight: theme.spacing(1.2),
  },
  innerbg: {
    background: theme.palette.grey[100],
  },
  typo: {
    color: theme.palette.grey[800],
    fontSize: 12,
    marginLeft: 7,
  },
  typoTextBox: {
    color: theme.palette.grey[800],
    fontSize: 12,
  },
  title: {
    color: theme.palette.grey[700],
    fontWeight: "bold",
    fontSize: 16,
  },
  collapseListStyle: {
    "& .MuiTypography-root": {
      color: theme.palette.grey[700],
      fontSize: 14,
    },
  },
  collapseButton: {
    "&:focus": {
      outline: "none",
    },
  },
  radioTypo: {
    "& .MuiTypography-root": {
      color: theme.palette.grey[700],
      fontSize: 12,
    },
  },
  customRadioButton: {
    "& .MuiSvgIcon-root": {
      fontSize: 17,
    },
  },
  icons: {
    fill: theme.palette.grey[700],
  },
  responsiblePersonDrawer: {
    zIndex: `${theme.zIndex.modal + 1} !important`,
  },
}));
